.white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF !important; }
  .bg-white .card-header, .bg-white .card-footer {
    background-color: transparent; }

.alert-white {
  border-color: #FFFFFF !important;
  background-color: white !important;
  color: #a6a6a6 !important; }
  .alert-white .alert-link {
    color: #919191 !important; }

.border-white {
  border-color: #FFFFFF; }

.bg-white.tag-glow, .border-white.tag-glow {
  box-shadow: 0px 0px 10px #FFFFFF; }

.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.card-outline-white {
  border-width: 1px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: transparent; }
  .card-outline-white .card-header, .card-outline-white .card-footer {
    background-color: transparent; }

.btn-white {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #FFFFFF; }
  .btn-white:hover {
    color: #FFF !important; }

.btn-outline-white {
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF; }
  .btn-outline-white:hover {
    background-color: #FFFFFF;
    color: #FFF !important; }

.progress-white[value]::-webkit-progress-value {
  background-color: #FFFFFF; }

.progress-white[value]::-moz-progress-bar {
  background-color: #FFFFFF; }

.progress-white[value]::-ms-fill {
  background-color: #FFFFFF; }

@media screen and (min-width: 0\0) {
  .progress-white .progress-bar {
    background-color: #FFFFFF; } }

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-white {
  border: 1px solid #FFFFFF !important; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

.border-left-white {
  border-left: 1px solid #FFFFFF; }

.border-right-white {
  border-right: 1px solid #FFFFFF; }

.black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }
  .bg-black .card-header, .bg-black .card-footer {
    background-color: transparent; }

.alert-black {
  border-color: #000000 !important;
  background-color: #242424 !important;
  color: black !important; }
  .alert-black .alert-link {
    color: black !important; }

.border-black {
  border-color: #000000; }

.bg-black.tag-glow, .border-black.tag-glow {
  box-shadow: 0px 0px 10px #000000; }

.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.card-outline-black {
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  background-color: transparent; }
  .card-outline-black .card-header, .card-outline-black .card-footer {
    background-color: transparent; }

.btn-black {
  border-color: #000000;
  background-color: #000000;
  color: #FFFFFF; }
  .btn-black:hover {
    color: #FFF !important; }

.btn-outline-black {
  border-color: #000000;
  background-color: transparent;
  color: #000000; }
  .btn-outline-black:hover {
    background-color: #000000;
    color: #FFF !important; }

.progress-black[value]::-webkit-progress-value {
  background-color: #000000; }

.progress-black[value]::-moz-progress-bar {
  background-color: #000000; }

.progress-black[value]::-ms-fill {
  background-color: #000000; }

@media screen and (min-width: 0\0) {
  .progress-black .progress-bar {
    background-color: #000000; } }

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.border-black {
  border: 1px solid #000000 !important; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

.border-left-black {
  border-left: 1px solid #000000; }

.border-right-black {
  border-right: 1px solid #000000; }

.primary.lighten-5 {
  color: #F1EBFF !important; }

.bg-primary.bg-lighten-5 {
  background-color: #F1EBFF !important; }

.btn-primary.btn-lighten-5 {
  border-color: #F1EBFF !important;
  background-color: #F1EBFF !important; }
  .btn-primary.btn-lighten-5:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-lighten-5 {
  border-color: #F1EBFF !important;
  color: #F1EBFF !important; }
  .btn-outline-primary.btn-outline-lighten-5:hover {
    background-color: #F1EBFF !important; }

.progress-primary.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #F1EBFF; }

.progress-primary.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #F1EBFF; }

.progress-primary.progress-lighten-5[value]::-ms-fill {
  background-color: #F1EBFF; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-lighten-5 .progress-bar {
    background-color: #F1EBFF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F1EBFF !important; }

.border-primary.border-lighten-5 {
  border: 1px solid #F1EBFF !important; }

.border-top-primary.border-top-lighten-5 {
  border-top: 1px solid #F1EBFF !important; }

.border-bottom-primary.border-bottom-lighten-5 {
  border-bottom: 1px solid #F1EBFF !important; }

.border-left-primary.border-left-lighten-5 {
  border-left: 1px solid #F1EBFF !important; }

.border-right-primary.border-right-lighten-5 {
  border-right: 1px solid #F1EBFF !important; }

.overlay-primary.overlay-lighten-5 {
  background: #F1EBFF;
  /* The Fallback */
  background: rgba(241, 235, 255, 0.8); }

.primary.lighten-4 {
  color: #E9E3F8 !important; }

.bg-primary.bg-lighten-4 {
  background-color: #E9E3F8 !important; }

.btn-primary.btn-lighten-4 {
  border-color: #E9E3F8 !important;
  background-color: #E9E3F8 !important; }
  .btn-primary.btn-lighten-4:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-lighten-4 {
  border-color: #E9E3F8 !important;
  color: #E9E3F8 !important; }
  .btn-outline-primary.btn-outline-lighten-4:hover {
    background-color: #E9E3F8 !important; }

.progress-primary.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #E9E3F8; }

.progress-primary.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #E9E3F8; }

.progress-primary.progress-lighten-4[value]::-ms-fill {
  background-color: #E9E3F8; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-lighten-4 .progress-bar {
    background-color: #E9E3F8; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E9E3F8 !important; }

.border-primary.border-lighten-4 {
  border: 1px solid #E9E3F8 !important; }

.border-top-primary.border-top-lighten-4 {
  border-top: 1px solid #E9E3F8 !important; }

.border-bottom-primary.border-bottom-lighten-4 {
  border-bottom: 1px solid #E9E3F8 !important; }

.border-left-primary.border-left-lighten-4 {
  border-left: 1px solid #E9E3F8 !important; }

.border-right-primary.border-right-lighten-4 {
  border-right: 1px solid #E9E3F8 !important; }

.overlay-primary.overlay-lighten-4 {
  background: #E9E3F8;
  /* The Fallback */
  background: rgba(233, 227, 248, 0.8); }

.primary.lighten-3 {
  color: #BCAAE9 !important; }

.bg-primary.bg-lighten-3 {
  background-color: #BCAAE9 !important; }

.btn-primary.btn-lighten-3 {
  border-color: #BCAAE9 !important;
  background-color: #BCAAE9 !important; }
  .btn-primary.btn-lighten-3:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-lighten-3 {
  border-color: #BCAAE9 !important;
  color: #BCAAE9 !important; }
  .btn-outline-primary.btn-outline-lighten-3:hover {
    background-color: #BCAAE9 !important; }

.progress-primary.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #BCAAE9; }

.progress-primary.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #BCAAE9; }

.progress-primary.progress-lighten-3[value]::-ms-fill {
  background-color: #BCAAE9; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-lighten-3 .progress-bar {
    background-color: #BCAAE9; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BCAAE9 !important; }

.border-primary.border-lighten-3 {
  border: 1px solid #BCAAE9 !important; }

.border-top-primary.border-top-lighten-3 {
  border-top: 1px solid #BCAAE9 !important; }

.border-bottom-primary.border-bottom-lighten-3 {
  border-bottom: 1px solid #BCAAE9 !important; }

.border-left-primary.border-left-lighten-3 {
  border-left: 1px solid #BCAAE9 !important; }

.border-right-primary.border-right-lighten-3 {
  border-right: 1px solid #BCAAE9 !important; }

.overlay-primary.overlay-lighten-3 {
  background: #BCAAE9;
  /* The Fallback */
  background: rgba(188, 170, 233, 0.8); }

.primary.lighten-2 {
  color: #AC92EC !important; }

.bg-primary.bg-lighten-2 {
  background-color: #AC92EC !important; }

.btn-primary.btn-lighten-2 {
  border-color: #AC92EC !important;
  background-color: #AC92EC !important; }
  .btn-primary.btn-lighten-2:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-lighten-2 {
  border-color: #AC92EC !important;
  color: #AC92EC !important; }
  .btn-outline-primary.btn-outline-lighten-2:hover {
    background-color: #AC92EC !important; }

.progress-primary.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #AC92EC; }

.progress-primary.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #AC92EC; }

.progress-primary.progress-lighten-2[value]::-ms-fill {
  background-color: #AC92EC; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-lighten-2 .progress-bar {
    background-color: #AC92EC; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AC92EC !important; }

.border-primary.border-lighten-2 {
  border: 1px solid #AC92EC !important; }

.border-top-primary.border-top-lighten-2 {
  border-top: 1px solid #AC92EC !important; }

.border-bottom-primary.border-bottom-lighten-2 {
  border-bottom: 1px solid #AC92EC !important; }

.border-left-primary.border-left-lighten-2 {
  border-left: 1px solid #AC92EC !important; }

.border-right-primary.border-right-lighten-2 {
  border-right: 1px solid #AC92EC !important; }

.overlay-primary.overlay-lighten-2 {
  background: #AC92EC;
  /* The Fallback */
  background: rgba(172, 146, 236, 0.8); }

.primary.lighten-1 {
  color: #A992E2 !important; }

.bg-primary.bg-lighten-1 {
  background-color: #A992E2 !important; }

.btn-primary.btn-lighten-1 {
  border-color: #A992E2 !important;
  background-color: #A992E2 !important; }
  .btn-primary.btn-lighten-1:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-lighten-1 {
  border-color: #A992E2 !important;
  color: #A992E2 !important; }
  .btn-outline-primary.btn-outline-lighten-1:hover {
    background-color: #A992E2 !important; }

.progress-primary.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #A992E2; }

.progress-primary.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #A992E2; }

.progress-primary.progress-lighten-1[value]::-ms-fill {
  background-color: #A992E2; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-lighten-1 .progress-bar {
    background-color: #A992E2; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A992E2 !important; }

.border-primary.border-lighten-1 {
  border: 1px solid #A992E2 !important; }

.border-top-primary.border-top-lighten-1 {
  border-top: 1px solid #A992E2 !important; }

.border-bottom-primary.border-bottom-lighten-1 {
  border-bottom: 1px solid #A992E2 !important; }

.border-left-primary.border-left-lighten-1 {
  border-left: 1px solid #A992E2 !important; }

.border-right-primary.border-right-lighten-1 {
  border-right: 1px solid #A992E2 !important; }

.overlay-primary.overlay-lighten-1 {
  background: #A992E2;
  /* The Fallback */
  background: rgba(169, 146, 226, 0.8); }

.primary {
  color: #967ADC !important; }

.bg-primary {
  background-color: #967ADC !important; }
  .bg-primary .card-header, .bg-primary .card-footer {
    background-color: transparent; }

.alert-primary {
  border-color: #967ADC !important;
  background-color: #c3b3eb !important;
  color: #3d2281 !important; }
  .alert-primary .alert-link {
    color: #2e1a61 !important; }

.border-primary {
  border-color: #967ADC; }

.bg-primary.tag-glow, .border-primary.tag-glow {
  box-shadow: 0px 0px 10px #967ADC; }

.overlay-primary {
  background: #967ADC;
  /* The Fallback */
  background: rgba(150, 122, 220, 0.8); }

.card-outline-primary {
  border-width: 1px;
  border-style: solid;
  border-color: #967ADC;
  background-color: transparent; }
  .card-outline-primary .card-header, .card-outline-primary .card-footer {
    background-color: transparent; }

.btn-primary {
  border-color: #967ADC;
  background-color: #967ADC;
  color: #FFFFFF; }
  .btn-primary:hover {
    border-color: #AC92EC;
    background-color: #AC92EC;
    color: #FFF !important; }

.btn-outline-primary {
  border-color: #967ADC;
  background-color: transparent;
  color: #967ADC; }
  .btn-outline-primary:hover {
    background-color: #967ADC;
    color: #FFF !important; }

.progress-primary[value]::-webkit-progress-value {
  background-color: #967ADC; }

.progress-primary[value]::-moz-progress-bar {
  background-color: #967ADC; }

.progress-primary[value]::-ms-fill {
  background-color: #967ADC; }

@media screen and (min-width: 0\0) {
  .progress-primary .progress-bar {
    background-color: #967ADC; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #967ADC !important; }

.border-primary {
  border: 1px solid #967ADC !important; }

.border-top-primary {
  border-top: 1px solid #967ADC; }

.border-bottom-primary {
  border-bottom: 1px solid #967ADC; }

.border-left-primary {
  border-left: 1px solid #967ADC; }

.border-right-primary {
  border-right: 1px solid #967ADC; }

.primary.darken-1 {
  color: #8362D6 !important; }

.bg-primary.bg-darken-1 {
  background-color: #8362D6 !important; }

.btn-primary.btn-darken-1 {
  border-color: #8362D6 !important;
  background-color: #8362D6 !important; }
  .btn-primary.btn-darken-1:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-darken-1 {
  border-color: #8362D6 !important;
  color: #8362D6 !important; }
  .btn-outline-primary.btn-outline-darken-1:hover {
    background-color: #8362D6 !important; }

.progress-primary.progress-darken-1[value]::-webkit-progress-value {
  background-color: #8362D6; }

.progress-primary.progress-darken-1[value]::-moz-progress-bar {
  background-color: #8362D6; }

.progress-primary.progress-darken-1[value]::-ms-fill {
  background-color: #8362D6; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-darken-1 .progress-bar {
    background-color: #8362D6; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8362D6 !important; }

.border-primary.border-darken-1 {
  border: 1px solid #8362D6 !important; }

.border-top-primary.border-top-darken-1 {
  border-top: 1px solid #8362D6 !important; }

.border-bottom-primary.border-bottom-darken-1 {
  border-bottom: 1px solid #8362D6 !important; }

.border-left-primary.border-left-darken-1 {
  border-left: 1px solid #8362D6 !important; }

.border-right-primary.border-right-darken-1 {
  border-right: 1px solid #8362D6 !important; }

.overlay-primary.overlay-darken-1 {
  background: #8362D6;
  /* The Fallback */
  background: rgba(131, 98, 214, 0.8); }

.primary.darken-2 {
  color: #704ACF !important; }

.bg-primary.bg-darken-2 {
  background-color: #704ACF !important; }

.btn-primary.btn-darken-2 {
  border-color: #704ACF !important;
  background-color: #704ACF !important; }
  .btn-primary.btn-darken-2:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-darken-2 {
  border-color: #704ACF !important;
  color: #704ACF !important; }
  .btn-outline-primary.btn-outline-darken-2:hover {
    background-color: #704ACF !important; }

.progress-primary.progress-darken-2[value]::-webkit-progress-value {
  background-color: #704ACF; }

.progress-primary.progress-darken-2[value]::-moz-progress-bar {
  background-color: #704ACF; }

.progress-primary.progress-darken-2[value]::-ms-fill {
  background-color: #704ACF; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-darken-2 .progress-bar {
    background-color: #704ACF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #704ACF !important; }

.border-primary.border-darken-2 {
  border: 1px solid #704ACF !important; }

.border-top-primary.border-top-darken-2 {
  border-top: 1px solid #704ACF !important; }

.border-bottom-primary.border-bottom-darken-2 {
  border-bottom: 1px solid #704ACF !important; }

.border-left-primary.border-left-darken-2 {
  border-left: 1px solid #704ACF !important; }

.border-right-primary.border-right-darken-2 {
  border-right: 1px solid #704ACF !important; }

.overlay-primary.overlay-darken-2 {
  background: #704ACF;
  /* The Fallback */
  background: rgba(112, 74, 207, 0.8); }

.primary.darken-3 {
  color: #5E34C6 !important; }

.bg-primary.bg-darken-3 {
  background-color: #5E34C6 !important; }

.btn-primary.btn-darken-3 {
  border-color: #5E34C6 !important;
  background-color: #5E34C6 !important; }
  .btn-primary.btn-darken-3:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-darken-3 {
  border-color: #5E34C6 !important;
  color: #5E34C6 !important; }
  .btn-outline-primary.btn-outline-darken-3:hover {
    background-color: #5E34C6 !important; }

.progress-primary.progress-darken-3[value]::-webkit-progress-value {
  background-color: #5E34C6; }

.progress-primary.progress-darken-3[value]::-moz-progress-bar {
  background-color: #5E34C6; }

.progress-primary.progress-darken-3[value]::-ms-fill {
  background-color: #5E34C6; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-darken-3 .progress-bar {
    background-color: #5E34C6; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5E34C6 !important; }

.border-primary.border-darken-3 {
  border: 1px solid #5E34C6 !important; }

.border-top-primary.border-top-darken-3 {
  border-top: 1px solid #5E34C6 !important; }

.border-bottom-primary.border-bottom-darken-3 {
  border-bottom: 1px solid #5E34C6 !important; }

.border-left-primary.border-left-darken-3 {
  border-left: 1px solid #5E34C6 !important; }

.border-right-primary.border-right-darken-3 {
  border-right: 1px solid #5E34C6 !important; }

.overlay-primary.overlay-darken-3 {
  background: #5E34C6;
  /* The Fallback */
  background: rgba(94, 52, 198, 0.8); }

.primary.darken-4 {
  color: #522EAE !important; }

.bg-primary.bg-darken-4 {
  background-color: #522EAE !important; }

.btn-primary.btn-darken-4 {
  border-color: #522EAE !important;
  background-color: #522EAE !important; }
  .btn-primary.btn-darken-4:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-darken-4 {
  border-color: #522EAE !important;
  color: #522EAE !important; }
  .btn-outline-primary.btn-outline-darken-4:hover {
    background-color: #522EAE !important; }

.progress-primary.progress-darken-4[value]::-webkit-progress-value {
  background-color: #522EAE; }

.progress-primary.progress-darken-4[value]::-moz-progress-bar {
  background-color: #522EAE; }

.progress-primary.progress-darken-4[value]::-ms-fill {
  background-color: #522EAE; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-darken-4 .progress-bar {
    background-color: #522EAE; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #522EAE !important; }

.border-primary.border-darken-4 {
  border: 1px solid #522EAE !important; }

.border-top-primary.border-top-darken-4 {
  border-top: 1px solid #522EAE !important; }

.border-bottom-primary.border-bottom-darken-4 {
  border-bottom: 1px solid #522EAE !important; }

.border-left-primary.border-left-darken-4 {
  border-left: 1px solid #522EAE !important; }

.border-right-primary.border-right-darken-4 {
  border-right: 1px solid #522EAE !important; }

.overlay-primary.overlay-darken-4 {
  background: #522EAE;
  /* The Fallback */
  background: rgba(82, 46, 174, 0.8); }

.primary.accent-1 {
  color: #9e77fd !important; }

.bg-primary.bg-accent-1 {
  background-color: #9e77fd !important; }

.btn-primary.btn-accent-1 {
  border-color: #9e77fd !important;
  background-color: #9e77fd !important; }
  .btn-primary.btn-accent-1:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-accent-1 {
  border-color: #9e77fd !important;
  color: #9e77fd !important; }
  .btn-outline-primary.btn-outline-accent-1:hover {
    background-color: #9e77fd !important; }

.progress-primary.progress-accent-1[value]::-webkit-progress-value {
  background-color: #9e77fd; }

.progress-primary.progress-accent-1[value]::-moz-progress-bar {
  background-color: #9e77fd; }

.progress-primary.progress-accent-1[value]::-ms-fill {
  background-color: #9e77fd; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-accent-1 .progress-bar {
    background-color: #9e77fd; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e77fd !important; }

.border-primary.border-accent-1 {
  border: 1px solid #9e77fd !important; }

.border-top-primary.border-top-accent-1 {
  border-top: 1px solid #9e77fd !important; }

.border-bottom-primary.border-bottom-accent-1 {
  border-bottom: 1px solid #9e77fd !important; }

.border-left-primary.border-left-accent-1 {
  border-left: 1px solid #9e77fd !important; }

.border-right-primary.border-right-accent-1 {
  border-right: 1px solid #9e77fd !important; }

.overlay-primary.overlay-accent-1 {
  background: #9e77fd;
  /* The Fallback */
  background: rgba(158, 119, 253, 0.8); }

.primary.accent-2 {
  color: #9067f5 !important; }

.bg-primary.bg-accent-2 {
  background-color: #9067f5 !important; }

.btn-primary.btn-accent-2 {
  border-color: #9067f5 !important;
  background-color: #9067f5 !important; }
  .btn-primary.btn-accent-2:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-accent-2 {
  border-color: #9067f5 !important;
  color: #9067f5 !important; }
  .btn-outline-primary.btn-outline-accent-2:hover {
    background-color: #9067f5 !important; }

.progress-primary.progress-accent-2[value]::-webkit-progress-value {
  background-color: #9067f5; }

.progress-primary.progress-accent-2[value]::-moz-progress-bar {
  background-color: #9067f5; }

.progress-primary.progress-accent-2[value]::-ms-fill {
  background-color: #9067f5; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-accent-2 .progress-bar {
    background-color: #9067f5; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9067f5 !important; }

.border-primary.border-accent-2 {
  border: 1px solid #9067f5 !important; }

.border-top-primary.border-top-accent-2 {
  border-top: 1px solid #9067f5 !important; }

.border-bottom-primary.border-bottom-accent-2 {
  border-bottom: 1px solid #9067f5 !important; }

.border-left-primary.border-left-accent-2 {
  border-left: 1px solid #9067f5 !important; }

.border-right-primary.border-right-accent-2 {
  border-right: 1px solid #9067f5 !important; }

.overlay-primary.overlay-accent-2 {
  background: #9067f5;
  /* The Fallback */
  background: rgba(144, 103, 245, 0.8); }

.primary.accent-3 {
  color: #845de2 !important; }

.bg-primary.bg-accent-3 {
  background-color: #845de2 !important; }

.btn-primary.btn-accent-3 {
  border-color: #845de2 !important;
  background-color: #845de2 !important; }
  .btn-primary.btn-accent-3:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-accent-3 {
  border-color: #845de2 !important;
  color: #845de2 !important; }
  .btn-outline-primary.btn-outline-accent-3:hover {
    background-color: #845de2 !important; }

.progress-primary.progress-accent-3[value]::-webkit-progress-value {
  background-color: #845de2; }

.progress-primary.progress-accent-3[value]::-moz-progress-bar {
  background-color: #845de2; }

.progress-primary.progress-accent-3[value]::-ms-fill {
  background-color: #845de2; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-accent-3 .progress-bar {
    background-color: #845de2; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #845de2 !important; }

.border-primary.border-accent-3 {
  border: 1px solid #845de2 !important; }

.border-top-primary.border-top-accent-3 {
  border-top: 1px solid #845de2 !important; }

.border-bottom-primary.border-bottom-accent-3 {
  border-bottom: 1px solid #845de2 !important; }

.border-left-primary.border-left-accent-3 {
  border-left: 1px solid #845de2 !important; }

.border-right-primary.border-right-accent-3 {
  border-right: 1px solid #845de2 !important; }

.overlay-primary.overlay-accent-3 {
  background: #845de2;
  /* The Fallback */
  background: rgba(132, 93, 226, 0.8); }

.primary.accent-4 {
  color: #7452c7 !important; }

.bg-primary.bg-accent-4 {
  background-color: #7452c7 !important; }

.btn-primary.btn-accent-4 {
  border-color: #7452c7 !important;
  background-color: #7452c7 !important; }
  .btn-primary.btn-accent-4:hover {
    border-color: #AC92EC !important;
    background-color: #AC92EC !important; }

.btn-outline-primary.btn-outline-accent-4 {
  border-color: #7452c7 !important;
  color: #7452c7 !important; }
  .btn-outline-primary.btn-outline-accent-4:hover {
    background-color: #7452c7 !important; }

.progress-primary.progress-accent-4[value]::-webkit-progress-value {
  background-color: #7452c7; }

.progress-primary.progress-accent-4[value]::-moz-progress-bar {
  background-color: #7452c7; }

.progress-primary.progress-accent-4[value]::-ms-fill {
  background-color: #7452c7; }

@media screen and (min-width: 0\0) {
  .progress-primary.progress-accent-4 .progress-bar {
    background-color: #7452c7; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7452c7 !important; }

.border-primary.border-accent-4 {
  border: 1px solid #7452c7 !important; }

.border-top-primary.border-top-accent-4 {
  border-top: 1px solid #7452c7 !important; }

.border-bottom-primary.border-bottom-accent-4 {
  border-bottom: 1px solid #7452c7 !important; }

.border-left-primary.border-left-accent-4 {
  border-left: 1px solid #7452c7 !important; }

.border-right-primary.border-right-accent-4 {
  border-right: 1px solid #7452c7 !important; }

.overlay-primary.overlay-accent-4 {
  background: #7452c7;
  /* The Fallback */
  background: rgba(116, 82, 199, 0.8); }

.success.lighten-5 {
  color: #C2EDE3 !important; }

.bg-success.bg-lighten-5 {
  background-color: #C2EDE3 !important; }

.btn-success.btn-lighten-5 {
  border-color: #C2EDE3 !important;
  background-color: #C2EDE3 !important; }
  .btn-success.btn-lighten-5:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-lighten-5 {
  border-color: #C2EDE3 !important;
  color: #C2EDE3 !important; }
  .btn-outline-success.btn-outline-lighten-5:hover {
    background-color: #C2EDE3 !important; }

.progress-success.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #C2EDE3; }

.progress-success.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #C2EDE3; }

.progress-success.progress-lighten-5[value]::-ms-fill {
  background-color: #C2EDE3; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-lighten-5 .progress-bar {
    background-color: #C2EDE3; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C2EDE3 !important; }

.border-success.border-lighten-5 {
  border: 1px solid #C2EDE3 !important; }

.border-top-success.border-top-lighten-5 {
  border-top: 1px solid #C2EDE3 !important; }

.border-bottom-success.border-bottom-lighten-5 {
  border-bottom: 1px solid #C2EDE3 !important; }

.border-left-success.border-left-lighten-5 {
  border-left: 1px solid #C2EDE3 !important; }

.border-right-success.border-right-lighten-5 {
  border-right: 1px solid #C2EDE3 !important; }

.overlay-success.overlay-lighten-5 {
  background: #C2EDE3;
  /* The Fallback */
  background: rgba(194, 237, 227, 0.8); }

.success.lighten-4 {
  color: #97E1CE !important; }

.bg-success.bg-lighten-4 {
  background-color: #97E1CE !important; }

.btn-success.btn-lighten-4 {
  border-color: #97E1CE !important;
  background-color: #97E1CE !important; }
  .btn-success.btn-lighten-4:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-lighten-4 {
  border-color: #97E1CE !important;
  color: #97E1CE !important; }
  .btn-outline-success.btn-outline-lighten-4:hover {
    background-color: #97E1CE !important; }

.progress-success.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #97E1CE; }

.progress-success.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #97E1CE; }

.progress-success.progress-lighten-4[value]::-ms-fill {
  background-color: #97E1CE; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-lighten-4 .progress-bar {
    background-color: #97E1CE; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #97E1CE !important; }

.border-success.border-lighten-4 {
  border: 1px solid #97E1CE !important; }

.border-top-success.border-top-lighten-4 {
  border-top: 1px solid #97E1CE !important; }

.border-bottom-success.border-bottom-lighten-4 {
  border-bottom: 1px solid #97E1CE !important; }

.border-left-success.border-left-lighten-4 {
  border-left: 1px solid #97E1CE !important; }

.border-right-success.border-right-lighten-4 {
  border-right: 1px solid #97E1CE !important; }

.overlay-success.overlay-lighten-4 {
  background: #97E1CE;
  /* The Fallback */
  background: rgba(151, 225, 206, 0.8); }

.success.lighten-3 {
  color: #60D0B4 !important; }

.bg-success.bg-lighten-3 {
  background-color: #60D0B4 !important; }

.btn-success.btn-lighten-3 {
  border-color: #60D0B4 !important;
  background-color: #60D0B4 !important; }
  .btn-success.btn-lighten-3:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-lighten-3 {
  border-color: #60D0B4 !important;
  color: #60D0B4 !important; }
  .btn-outline-success.btn-outline-lighten-3:hover {
    background-color: #60D0B4 !important; }

.progress-success.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #60D0B4; }

.progress-success.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #60D0B4; }

.progress-success.progress-lighten-3[value]::-ms-fill {
  background-color: #60D0B4; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-lighten-3 .progress-bar {
    background-color: #60D0B4; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #60D0B4 !important; }

.border-success.border-lighten-3 {
  border: 1px solid #60D0B4 !important; }

.border-top-success.border-top-lighten-3 {
  border-top: 1px solid #60D0B4 !important; }

.border-bottom-success.border-bottom-lighten-3 {
  border-bottom: 1px solid #60D0B4 !important; }

.border-left-success.border-left-lighten-3 {
  border-left: 1px solid #60D0B4 !important; }

.border-right-success.border-right-lighten-3 {
  border-right: 1px solid #60D0B4 !important; }

.overlay-success.overlay-lighten-3 {
  background: #60D0B4;
  /* The Fallback */
  background: rgba(96, 208, 180, 0.8); }

.success.lighten-2 {
  color: #48CFAD !important; }

.bg-success.bg-lighten-2 {
  background-color: #48CFAD !important; }

.btn-success.btn-lighten-2 {
  border-color: #48CFAD !important;
  background-color: #48CFAD !important; }
  .btn-success.btn-lighten-2:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-lighten-2 {
  border-color: #48CFAD !important;
  color: #48CFAD !important; }
  .btn-outline-success.btn-outline-lighten-2:hover {
    background-color: #48CFAD !important; }

.progress-success.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #48CFAD; }

.progress-success.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #48CFAD; }

.progress-success.progress-lighten-2[value]::-ms-fill {
  background-color: #48CFAD; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-lighten-2 .progress-bar {
    background-color: #48CFAD; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #48CFAD !important; }

.border-success.border-lighten-2 {
  border: 1px solid #48CFAD !important; }

.border-top-success.border-top-lighten-2 {
  border-top: 1px solid #48CFAD !important; }

.border-bottom-success.border-bottom-lighten-2 {
  border-bottom: 1px solid #48CFAD !important; }

.border-left-success.border-left-lighten-2 {
  border-left: 1px solid #48CFAD !important; }

.border-right-success.border-right-lighten-2 {
  border-right: 1px solid #48CFAD !important; }

.overlay-success.overlay-lighten-2 {
  background: #48CFAD;
  /* The Fallback */
  background: rgba(72, 207, 173, 0.8); }

.success.lighten-1 {
  color: #48C9A9 !important; }

.bg-success.bg-lighten-1 {
  background-color: #48C9A9 !important; }

.btn-success.btn-lighten-1 {
  border-color: #48C9A9 !important;
  background-color: #48C9A9 !important; }
  .btn-success.btn-lighten-1:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-lighten-1 {
  border-color: #48C9A9 !important;
  color: #48C9A9 !important; }
  .btn-outline-success.btn-outline-lighten-1:hover {
    background-color: #48C9A9 !important; }

.progress-success.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #48C9A9; }

.progress-success.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #48C9A9; }

.progress-success.progress-lighten-1[value]::-ms-fill {
  background-color: #48C9A9; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-lighten-1 .progress-bar {
    background-color: #48C9A9; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #48C9A9 !important; }

.border-success.border-lighten-1 {
  border: 1px solid #48C9A9 !important; }

.border-top-success.border-top-lighten-1 {
  border-top: 1px solid #48C9A9 !important; }

.border-bottom-success.border-bottom-lighten-1 {
  border-bottom: 1px solid #48C9A9 !important; }

.border-left-success.border-left-lighten-1 {
  border-left: 1px solid #48C9A9 !important; }

.border-right-success.border-right-lighten-1 {
  border-right: 1px solid #48C9A9 !important; }

.overlay-success.overlay-lighten-1 {
  background: #48C9A9;
  /* The Fallback */
  background: rgba(72, 201, 169, 0.8); }

.success {
  color: #37BC9B !important; }

.bg-success {
  background-color: #37BC9B !important; }
  .bg-success .card-header, .bg-success .card-footer {
    background-color: transparent; }

.alert-success {
  border-color: #37BC9B !important;
  background-color: #68d3b8 !important;
  color: #0f3229 !important; }
  .alert-success .alert-link {
    color: #05120f !important; }

.border-success {
  border-color: #37BC9B; }

.bg-success.tag-glow, .border-success.tag-glow {
  box-shadow: 0px 0px 10px #37BC9B; }

.overlay-success {
  background: #37BC9B;
  /* The Fallback */
  background: rgba(55, 188, 155, 0.8); }

.card-outline-success {
  border-width: 1px;
  border-style: solid;
  border-color: #37BC9B;
  background-color: transparent; }
  .card-outline-success .card-header, .card-outline-success .card-footer {
    background-color: transparent; }

.btn-success {
  border-color: #37BC9B;
  background-color: #37BC9B;
  color: #FFFFFF; }
  .btn-success:hover {
    border-color: #48CFAD;
    background-color: #48CFAD;
    color: #FFF !important; }

.btn-outline-success {
  border-color: #37BC9B;
  background-color: transparent;
  color: #37BC9B; }
  .btn-outline-success:hover {
    background-color: #37BC9B;
    color: #FFF !important; }

.progress-success[value]::-webkit-progress-value {
  background-color: #37BC9B; }

.progress-success[value]::-moz-progress-bar {
  background-color: #37BC9B; }

.progress-success[value]::-ms-fill {
  background-color: #37BC9B; }

@media screen and (min-width: 0\0) {
  .progress-success .progress-bar {
    background-color: #37BC9B; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37BC9B !important; }

.border-success {
  border: 1px solid #37BC9B !important; }

.border-top-success {
  border-top: 1px solid #37BC9B; }

.border-bottom-success {
  border-bottom: 1px solid #37BC9B; }

.border-left-success {
  border-left: 1px solid #37BC9B; }

.border-right-success {
  border-right: 1px solid #37BC9B; }

.success.darken-1 {
  color: #30A487 !important; }

.bg-success.bg-darken-1 {
  background-color: #30A487 !important; }

.btn-success.btn-darken-1 {
  border-color: #30A487 !important;
  background-color: #30A487 !important; }
  .btn-success.btn-darken-1:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-darken-1 {
  border-color: #30A487 !important;
  color: #30A487 !important; }
  .btn-outline-success.btn-outline-darken-1:hover {
    background-color: #30A487 !important; }

.progress-success.progress-darken-1[value]::-webkit-progress-value {
  background-color: #30A487; }

.progress-success.progress-darken-1[value]::-moz-progress-bar {
  background-color: #30A487; }

.progress-success.progress-darken-1[value]::-ms-fill {
  background-color: #30A487; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-darken-1 .progress-bar {
    background-color: #30A487; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #30A487 !important; }

.border-success.border-darken-1 {
  border: 1px solid #30A487 !important; }

.border-top-success.border-top-darken-1 {
  border-top: 1px solid #30A487 !important; }

.border-bottom-success.border-bottom-darken-1 {
  border-bottom: 1px solid #30A487 !important; }

.border-left-success.border-left-darken-1 {
  border-left: 1px solid #30A487 !important; }

.border-right-success.border-right-darken-1 {
  border-right: 1px solid #30A487 !important; }

.overlay-success.overlay-darken-1 {
  background: #30A487;
  /* The Fallback */
  background: rgba(48, 164, 135, 0.8); }

.success.darken-2 {
  color: #298D74 !important; }

.bg-success.bg-darken-2 {
  background-color: #298D74 !important; }

.btn-success.btn-darken-2 {
  border-color: #298D74 !important;
  background-color: #298D74 !important; }
  .btn-success.btn-darken-2:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-darken-2 {
  border-color: #298D74 !important;
  color: #298D74 !important; }
  .btn-outline-success.btn-outline-darken-2:hover {
    background-color: #298D74 !important; }

.progress-success.progress-darken-2[value]::-webkit-progress-value {
  background-color: #298D74; }

.progress-success.progress-darken-2[value]::-moz-progress-bar {
  background-color: #298D74; }

.progress-success.progress-darken-2[value]::-ms-fill {
  background-color: #298D74; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-darken-2 .progress-bar {
    background-color: #298D74; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #298D74 !important; }

.border-success.border-darken-2 {
  border: 1px solid #298D74 !important; }

.border-top-success.border-top-darken-2 {
  border-top: 1px solid #298D74 !important; }

.border-bottom-success.border-bottom-darken-2 {
  border-bottom: 1px solid #298D74 !important; }

.border-left-success.border-left-darken-2 {
  border-left: 1px solid #298D74 !important; }

.border-right-success.border-right-darken-2 {
  border-right: 1px solid #298D74 !important; }

.overlay-success.overlay-darken-2 {
  background: #298D74;
  /* The Fallback */
  background: rgba(41, 141, 116, 0.8); }

.success.darken-3 {
  color: #227560 !important; }

.bg-success.bg-darken-3 {
  background-color: #227560 !important; }

.btn-success.btn-darken-3 {
  border-color: #227560 !important;
  background-color: #227560 !important; }
  .btn-success.btn-darken-3:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-darken-3 {
  border-color: #227560 !important;
  color: #227560 !important; }
  .btn-outline-success.btn-outline-darken-3:hover {
    background-color: #227560 !important; }

.progress-success.progress-darken-3[value]::-webkit-progress-value {
  background-color: #227560; }

.progress-success.progress-darken-3[value]::-moz-progress-bar {
  background-color: #227560; }

.progress-success.progress-darken-3[value]::-ms-fill {
  background-color: #227560; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-darken-3 .progress-bar {
    background-color: #227560; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #227560 !important; }

.border-success.border-darken-3 {
  border: 1px solid #227560 !important; }

.border-top-success.border-top-darken-3 {
  border-top: 1px solid #227560 !important; }

.border-bottom-success.border-bottom-darken-3 {
  border-bottom: 1px solid #227560 !important; }

.border-left-success.border-left-darken-3 {
  border-left: 1px solid #227560 !important; }

.border-right-success.border-right-darken-3 {
  border-right: 1px solid #227560 !important; }

.overlay-success.overlay-darken-3 {
  background: #227560;
  /* The Fallback */
  background: rgba(34, 117, 96, 0.8); }

.success.darken-4 {
  color: #1B5D4D !important; }

.bg-success.bg-darken-4 {
  background-color: #1B5D4D !important; }

.btn-success.btn-darken-4 {
  border-color: #1B5D4D !important;
  background-color: #1B5D4D !important; }
  .btn-success.btn-darken-4:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-darken-4 {
  border-color: #1B5D4D !important;
  color: #1B5D4D !important; }
  .btn-outline-success.btn-outline-darken-4:hover {
    background-color: #1B5D4D !important; }

.progress-success.progress-darken-4[value]::-webkit-progress-value {
  background-color: #1B5D4D; }

.progress-success.progress-darken-4[value]::-moz-progress-bar {
  background-color: #1B5D4D; }

.progress-success.progress-darken-4[value]::-ms-fill {
  background-color: #1B5D4D; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-darken-4 .progress-bar {
    background-color: #1B5D4D; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1B5D4D !important; }

.border-success.border-darken-4 {
  border: 1px solid #1B5D4D !important; }

.border-top-success.border-top-darken-4 {
  border-top: 1px solid #1B5D4D !important; }

.border-bottom-success.border-bottom-darken-4 {
  border-bottom: 1px solid #1B5D4D !important; }

.border-left-success.border-left-darken-4 {
  border-left: 1px solid #1B5D4D !important; }

.border-right-success.border-right-darken-4 {
  border-right: 1px solid #1B5D4D !important; }

.overlay-success.overlay-darken-4 {
  background: #1B5D4D;
  /* The Fallback */
  background: rgba(27, 93, 77, 0.8); }

.success.accent-1 {
  color: #D8F7F0 !important; }

.bg-success.bg-accent-1 {
  background-color: #D8F7F0 !important; }

.btn-success.btn-accent-1 {
  border-color: #D8F7F0 !important;
  background-color: #D8F7F0 !important; }
  .btn-success.btn-accent-1:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-accent-1 {
  border-color: #D8F7F0 !important;
  color: #D8F7F0 !important; }
  .btn-outline-success.btn-outline-accent-1:hover {
    background-color: #D8F7F0 !important; }

.progress-success.progress-accent-1[value]::-webkit-progress-value {
  background-color: #D8F7F0; }

.progress-success.progress-accent-1[value]::-moz-progress-bar {
  background-color: #D8F7F0; }

.progress-success.progress-accent-1[value]::-ms-fill {
  background-color: #D8F7F0; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-accent-1 .progress-bar {
    background-color: #D8F7F0; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D8F7F0 !important; }

.border-success.border-accent-1 {
  border: 1px solid #D8F7F0 !important; }

.border-top-success.border-top-accent-1 {
  border-top: 1px solid #D8F7F0 !important; }

.border-bottom-success.border-bottom-accent-1 {
  border-bottom: 1px solid #D8F7F0 !important; }

.border-left-success.border-left-accent-1 {
  border-left: 1px solid #D8F7F0 !important; }

.border-right-success.border-right-accent-1 {
  border-right: 1px solid #D8F7F0 !important; }

.overlay-success.overlay-accent-1 {
  background: #D8F7F0;
  /* The Fallback */
  background: rgba(216, 247, 240, 0.8); }

.success.accent-2 {
  color: #C2EDE3 !important; }

.bg-success.bg-accent-2 {
  background-color: #C2EDE3 !important; }

.btn-success.btn-accent-2 {
  border-color: #C2EDE3 !important;
  background-color: #C2EDE3 !important; }
  .btn-success.btn-accent-2:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-accent-2 {
  border-color: #C2EDE3 !important;
  color: #C2EDE3 !important; }
  .btn-outline-success.btn-outline-accent-2:hover {
    background-color: #C2EDE3 !important; }

.progress-success.progress-accent-2[value]::-webkit-progress-value {
  background-color: #C2EDE3; }

.progress-success.progress-accent-2[value]::-moz-progress-bar {
  background-color: #C2EDE3; }

.progress-success.progress-accent-2[value]::-ms-fill {
  background-color: #C2EDE3; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-accent-2 .progress-bar {
    background-color: #C2EDE3; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C2EDE3 !important; }

.border-success.border-accent-2 {
  border: 1px solid #C2EDE3 !important; }

.border-top-success.border-top-accent-2 {
  border-top: 1px solid #C2EDE3 !important; }

.border-bottom-success.border-bottom-accent-2 {
  border-bottom: 1px solid #C2EDE3 !important; }

.border-left-success.border-left-accent-2 {
  border-left: 1px solid #C2EDE3 !important; }

.border-right-success.border-right-accent-2 {
  border-right: 1px solid #C2EDE3 !important; }

.overlay-success.overlay-accent-2 {
  background: #C2EDE3;
  /* The Fallback */
  background: rgba(194, 237, 227, 0.8); }

.success.accent-3 {
  color: #3EC3A2 !important; }

.bg-success.bg-accent-3 {
  background-color: #3EC3A2 !important; }

.btn-success.btn-accent-3 {
  border-color: #3EC3A2 !important;
  background-color: #3EC3A2 !important; }
  .btn-success.btn-accent-3:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-accent-3 {
  border-color: #3EC3A2 !important;
  color: #3EC3A2 !important; }
  .btn-outline-success.btn-outline-accent-3:hover {
    background-color: #3EC3A2 !important; }

.progress-success.progress-accent-3[value]::-webkit-progress-value {
  background-color: #3EC3A2; }

.progress-success.progress-accent-3[value]::-moz-progress-bar {
  background-color: #3EC3A2; }

.progress-success.progress-accent-3[value]::-ms-fill {
  background-color: #3EC3A2; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-accent-3 .progress-bar {
    background-color: #3EC3A2; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3EC3A2 !important; }

.border-success.border-accent-3 {
  border: 1px solid #3EC3A2 !important; }

.border-top-success.border-top-accent-3 {
  border-top: 1px solid #3EC3A2 !important; }

.border-bottom-success.border-bottom-accent-3 {
  border-bottom: 1px solid #3EC3A2 !important; }

.border-left-success.border-left-accent-3 {
  border-left: 1px solid #3EC3A2 !important; }

.border-right-success.border-right-accent-3 {
  border-right: 1px solid #3EC3A2 !important; }

.overlay-success.overlay-accent-3 {
  background: #3EC3A2;
  /* The Fallback */
  background: rgba(62, 195, 162, 0.8); }

.success.accent-4 {
  color: #298D74 !important; }

.bg-success.bg-accent-4 {
  background-color: #298D74 !important; }

.btn-success.btn-accent-4 {
  border-color: #298D74 !important;
  background-color: #298D74 !important; }
  .btn-success.btn-accent-4:hover {
    border-color: #48CFAD !important;
    background-color: #48CFAD !important; }

.btn-outline-success.btn-outline-accent-4 {
  border-color: #298D74 !important;
  color: #298D74 !important; }
  .btn-outline-success.btn-outline-accent-4:hover {
    background-color: #298D74 !important; }

.progress-success.progress-accent-4[value]::-webkit-progress-value {
  background-color: #298D74; }

.progress-success.progress-accent-4[value]::-moz-progress-bar {
  background-color: #298D74; }

.progress-success.progress-accent-4[value]::-ms-fill {
  background-color: #298D74; }

@media screen and (min-width: 0\0) {
  .progress-success.progress-accent-4 .progress-bar {
    background-color: #298D74; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #298D74 !important; }

.border-success.border-accent-4 {
  border: 1px solid #298D74 !important; }

.border-top-success.border-top-accent-4 {
  border-top: 1px solid #298D74 !important; }

.border-bottom-success.border-bottom-accent-4 {
  border-bottom: 1px solid #298D74 !important; }

.border-left-success.border-left-accent-4 {
  border-left: 1px solid #298D74 !important; }

.border-right-success.border-right-accent-4 {
  border-right: 1px solid #298D74 !important; }

.overlay-success.overlay-accent-4 {
  background: #298D74;
  /* The Fallback */
  background: rgba(41, 141, 116, 0.8); }

.info.lighten-5 {
  color: #DAF0F8 !important; }

.bg-info.bg-lighten-5 {
  background-color: #DAF0F8 !important; }

.btn-info.btn-lighten-5 {
  border-color: #DAF0F8 !important;
  background-color: #DAF0F8 !important; }
  .btn-info.btn-lighten-5:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-lighten-5 {
  border-color: #DAF0F8 !important;
  color: #DAF0F8 !important; }
  .btn-outline-info.btn-outline-lighten-5:hover {
    background-color: #DAF0F8 !important; }

.progress-info.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #DAF0F8; }

.progress-info.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #DAF0F8; }

.progress-info.progress-lighten-5[value]::-ms-fill {
  background-color: #DAF0F8; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-lighten-5 .progress-bar {
    background-color: #DAF0F8; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DAF0F8 !important; }

.border-info.border-lighten-5 {
  border: 1px solid #DAF0F8 !important; }

.border-top-info.border-top-lighten-5 {
  border-top: 1px solid #DAF0F8 !important; }

.border-bottom-info.border-bottom-lighten-5 {
  border-bottom: 1px solid #DAF0F8 !important; }

.border-left-info.border-left-lighten-5 {
  border-left: 1px solid #DAF0F8 !important; }

.border-right-info.border-right-lighten-5 {
  border-right: 1px solid #DAF0F8 !important; }

.overlay-info.overlay-lighten-5 {
  background: #DAF0F8;
  /* The Fallback */
  background: rgba(218, 240, 248, 0.8); }

.info.lighten-4 {
  color: #ABDDEF !important; }

.bg-info.bg-lighten-4 {
  background-color: #ABDDEF !important; }

.btn-info.btn-lighten-4 {
  border-color: #ABDDEF !important;
  background-color: #ABDDEF !important; }
  .btn-info.btn-lighten-4:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-lighten-4 {
  border-color: #ABDDEF !important;
  color: #ABDDEF !important; }
  .btn-outline-info.btn-outline-lighten-4:hover {
    background-color: #ABDDEF !important; }

.progress-info.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #ABDDEF; }

.progress-info.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #ABDDEF; }

.progress-info.progress-lighten-4[value]::-ms-fill {
  background-color: #ABDDEF; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-lighten-4 .progress-bar {
    background-color: #ABDDEF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ABDDEF !important; }

.border-info.border-lighten-4 {
  border: 1px solid #ABDDEF !important; }

.border-top-info.border-top-lighten-4 {
  border-top: 1px solid #ABDDEF !important; }

.border-bottom-info.border-bottom-lighten-4 {
  border-bottom: 1px solid #ABDDEF !important; }

.border-left-info.border-left-lighten-4 {
  border-left: 1px solid #ABDDEF !important; }

.border-right-info.border-right-lighten-4 {
  border-right: 1px solid #ABDDEF !important; }

.overlay-info.overlay-lighten-4 {
  background: #ABDDEF;
  /* The Fallback */
  background: rgba(171, 221, 239, 0.8); }

.info.lighten-3 {
  color: #6EC4E4 !important; }

.bg-info.bg-lighten-3 {
  background-color: #6EC4E4 !important; }

.btn-info.btn-lighten-3 {
  border-color: #6EC4E4 !important;
  background-color: #6EC4E4 !important; }
  .btn-info.btn-lighten-3:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-lighten-3 {
  border-color: #6EC4E4 !important;
  color: #6EC4E4 !important; }
  .btn-outline-info.btn-outline-lighten-3:hover {
    background-color: #6EC4E4 !important; }

.progress-info.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #6EC4E4; }

.progress-info.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #6EC4E4; }

.progress-info.progress-lighten-3[value]::-ms-fill {
  background-color: #6EC4E4; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-lighten-3 .progress-bar {
    background-color: #6EC4E4; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6EC4E4 !important; }

.border-info.border-lighten-3 {
  border: 1px solid #6EC4E4 !important; }

.border-top-info.border-top-lighten-3 {
  border-top: 1px solid #6EC4E4 !important; }

.border-bottom-info.border-bottom-lighten-3 {
  border-bottom: 1px solid #6EC4E4 !important; }

.border-left-info.border-left-lighten-3 {
  border-left: 1px solid #6EC4E4 !important; }

.border-right-info.border-right-lighten-3 {
  border-right: 1px solid #6EC4E4 !important; }

.overlay-info.overlay-lighten-3 {
  background: #6EC4E4;
  /* The Fallback */
  background: rgba(110, 196, 228, 0.8); }

.info.lighten-2 {
  color: #4FC1E9 !important; }

.bg-info.bg-lighten-2 {
  background-color: #4FC1E9 !important; }

.btn-info.btn-lighten-2 {
  border-color: #4FC1E9 !important;
  background-color: #4FC1E9 !important; }
  .btn-info.btn-lighten-2:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-lighten-2 {
  border-color: #4FC1E9 !important;
  color: #4FC1E9 !important; }
  .btn-outline-info.btn-outline-lighten-2:hover {
    background-color: #4FC1E9 !important; }

.progress-info.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #4FC1E9; }

.progress-info.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #4FC1E9; }

.progress-info.progress-lighten-2[value]::-ms-fill {
  background-color: #4FC1E9; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-lighten-2 .progress-bar {
    background-color: #4FC1E9; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4FC1E9 !important; }

.border-info.border-lighten-2 {
  border: 1px solid #4FC1E9 !important; }

.border-top-info.border-top-lighten-2 {
  border-top: 1px solid #4FC1E9 !important; }

.border-bottom-info.border-bottom-lighten-2 {
  border-bottom: 1px solid #4FC1E9 !important; }

.border-left-info.border-left-lighten-2 {
  border-left: 1px solid #4FC1E9 !important; }

.border-right-info.border-right-lighten-2 {
  border-right: 1px solid #4FC1E9 !important; }

.overlay-info.overlay-lighten-2 {
  background: #4FC1E9;
  /* The Fallback */
  background: rgba(79, 193, 233, 0.8); }

.info.lighten-1 {
  color: #55B9DF !important; }

.bg-info.bg-lighten-1 {
  background-color: #55B9DF !important; }

.btn-info.btn-lighten-1 {
  border-color: #55B9DF !important;
  background-color: #55B9DF !important; }
  .btn-info.btn-lighten-1:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-lighten-1 {
  border-color: #55B9DF !important;
  color: #55B9DF !important; }
  .btn-outline-info.btn-outline-lighten-1:hover {
    background-color: #55B9DF !important; }

.progress-info.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #55B9DF; }

.progress-info.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #55B9DF; }

.progress-info.progress-lighten-1[value]::-ms-fill {
  background-color: #55B9DF; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-lighten-1 .progress-bar {
    background-color: #55B9DF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #55B9DF !important; }

.border-info.border-lighten-1 {
  border: 1px solid #55B9DF !important; }

.border-top-info.border-top-lighten-1 {
  border-top: 1px solid #55B9DF !important; }

.border-bottom-info.border-bottom-lighten-1 {
  border-bottom: 1px solid #55B9DF !important; }

.border-left-info.border-left-lighten-1 {
  border-left: 1px solid #55B9DF !important; }

.border-right-info.border-right-lighten-1 {
  border-right: 1px solid #55B9DF !important; }

.overlay-info.overlay-lighten-1 {
  background: #55B9DF;
  /* The Fallback */
  background: rgba(85, 185, 223, 0.8); }

.info {
  color: #3BAFDA !important; }

.bg-info {
  background-color: #3BAFDA !important; }
  .bg-info .card-header, .bg-info .card-footer {
    background-color: transparent; }

.alert-info {
  border-color: #3BAFDA !important;
  background-color: #77c8e5 !important;
  color: #104153 !important; }
  .alert-info .alert-link {
    color: #092631 !important; }

.border-info {
  border-color: #3BAFDA; }

.bg-info.tag-glow, .border-info.tag-glow {
  box-shadow: 0px 0px 10px #3BAFDA; }

.overlay-info {
  background: #3BAFDA;
  /* The Fallback */
  background: rgba(59, 175, 218, 0.8); }

.card-outline-info {
  border-width: 1px;
  border-style: solid;
  border-color: #3BAFDA;
  background-color: transparent; }
  .card-outline-info .card-header, .card-outline-info .card-footer {
    background-color: transparent; }

.btn-info {
  border-color: #3BAFDA;
  background-color: #3BAFDA;
  color: #FFFFFF; }
  .btn-info:hover {
    border-color: #4FC1E9;
    background-color: #4FC1E9;
    color: #FFF !important; }

.btn-outline-info {
  border-color: #3BAFDA;
  background-color: transparent;
  color: #3BAFDA; }
  .btn-outline-info:hover {
    background-color: #3BAFDA;
    color: #FFF !important; }

.progress-info[value]::-webkit-progress-value {
  background-color: #3BAFDA; }

.progress-info[value]::-moz-progress-bar {
  background-color: #3BAFDA; }

.progress-info[value]::-ms-fill {
  background-color: #3BAFDA; }

@media screen and (min-width: 0\0) {
  .progress-info .progress-bar {
    background-color: #3BAFDA; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3BAFDA !important; }

.border-info {
  border: 1px solid #3BAFDA !important; }

.border-top-info {
  border-top: 1px solid #3BAFDA; }

.border-bottom-info {
  border-bottom: 1px solid #3BAFDA; }

.border-left-info {
  border-left: 1px solid #3BAFDA; }

.border-right-info {
  border-right: 1px solid #3BAFDA; }

.info.darken-1 {
  color: #27A2CF !important; }

.bg-info.bg-darken-1 {
  background-color: #27A2CF !important; }

.btn-info.btn-darken-1 {
  border-color: #27A2CF !important;
  background-color: #27A2CF !important; }
  .btn-info.btn-darken-1:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-darken-1 {
  border-color: #27A2CF !important;
  color: #27A2CF !important; }
  .btn-outline-info.btn-outline-darken-1:hover {
    background-color: #27A2CF !important; }

.progress-info.progress-darken-1[value]::-webkit-progress-value {
  background-color: #27A2CF; }

.progress-info.progress-darken-1[value]::-moz-progress-bar {
  background-color: #27A2CF; }

.progress-info.progress-darken-1[value]::-ms-fill {
  background-color: #27A2CF; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-darken-1 .progress-bar {
    background-color: #27A2CF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #27A2CF !important; }

.border-info.border-darken-1 {
  border: 1px solid #27A2CF !important; }

.border-top-info.border-top-darken-1 {
  border-top: 1px solid #27A2CF !important; }

.border-bottom-info.border-bottom-darken-1 {
  border-bottom: 1px solid #27A2CF !important; }

.border-left-info.border-left-darken-1 {
  border-left: 1px solid #27A2CF !important; }

.border-right-info.border-right-darken-1 {
  border-right: 1px solid #27A2CF !important; }

.overlay-info.overlay-darken-1 {
  background: #27A2CF;
  /* The Fallback */
  background: rgba(39, 162, 207, 0.8); }

.info.darken-2 {
  color: #228EB6 !important; }

.bg-info.bg-darken-2 {
  background-color: #228EB6 !important; }

.btn-info.btn-darken-2 {
  border-color: #228EB6 !important;
  background-color: #228EB6 !important; }
  .btn-info.btn-darken-2:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-darken-2 {
  border-color: #228EB6 !important;
  color: #228EB6 !important; }
  .btn-outline-info.btn-outline-darken-2:hover {
    background-color: #228EB6 !important; }

.progress-info.progress-darken-2[value]::-webkit-progress-value {
  background-color: #228EB6; }

.progress-info.progress-darken-2[value]::-moz-progress-bar {
  background-color: #228EB6; }

.progress-info.progress-darken-2[value]::-ms-fill {
  background-color: #228EB6; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-darken-2 .progress-bar {
    background-color: #228EB6; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #228EB6 !important; }

.border-info.border-darken-2 {
  border: 1px solid #228EB6 !important; }

.border-top-info.border-top-darken-2 {
  border-top: 1px solid #228EB6 !important; }

.border-bottom-info.border-bottom-darken-2 {
  border-bottom: 1px solid #228EB6 !important; }

.border-left-info.border-left-darken-2 {
  border-left: 1px solid #228EB6 !important; }

.border-right-info.border-right-darken-2 {
  border-right: 1px solid #228EB6 !important; }

.overlay-info.overlay-darken-2 {
  background: #228EB6;
  /* The Fallback */
  background: rgba(34, 142, 182, 0.8); }

.info.darken-3 {
  color: #1D7A9C !important; }

.bg-info.bg-darken-3 {
  background-color: #1D7A9C !important; }

.btn-info.btn-darken-3 {
  border-color: #1D7A9C !important;
  background-color: #1D7A9C !important; }
  .btn-info.btn-darken-3:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-darken-3 {
  border-color: #1D7A9C !important;
  color: #1D7A9C !important; }
  .btn-outline-info.btn-outline-darken-3:hover {
    background-color: #1D7A9C !important; }

.progress-info.progress-darken-3[value]::-webkit-progress-value {
  background-color: #1D7A9C; }

.progress-info.progress-darken-3[value]::-moz-progress-bar {
  background-color: #1D7A9C; }

.progress-info.progress-darken-3[value]::-ms-fill {
  background-color: #1D7A9C; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-darken-3 .progress-bar {
    background-color: #1D7A9C; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1D7A9C !important; }

.border-info.border-darken-3 {
  border: 1px solid #1D7A9C !important; }

.border-top-info.border-top-darken-3 {
  border-top: 1px solid #1D7A9C !important; }

.border-bottom-info.border-bottom-darken-3 {
  border-bottom: 1px solid #1D7A9C !important; }

.border-left-info.border-left-darken-3 {
  border-left: 1px solid #1D7A9C !important; }

.border-right-info.border-right-darken-3 {
  border-right: 1px solid #1D7A9C !important; }

.overlay-info.overlay-darken-3 {
  background: #1D7A9C;
  /* The Fallback */
  background: rgba(29, 122, 156, 0.8); }

.info.darken-4 {
  color: #196682 !important; }

.bg-info.bg-darken-4 {
  background-color: #196682 !important; }

.btn-info.btn-darken-4 {
  border-color: #196682 !important;
  background-color: #196682 !important; }
  .btn-info.btn-darken-4:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-darken-4 {
  border-color: #196682 !important;
  color: #196682 !important; }
  .btn-outline-info.btn-outline-darken-4:hover {
    background-color: #196682 !important; }

.progress-info.progress-darken-4[value]::-webkit-progress-value {
  background-color: #196682; }

.progress-info.progress-darken-4[value]::-moz-progress-bar {
  background-color: #196682; }

.progress-info.progress-darken-4[value]::-ms-fill {
  background-color: #196682; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-darken-4 .progress-bar {
    background-color: #196682; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #196682 !important; }

.border-info.border-darken-4 {
  border: 1px solid #196682 !important; }

.border-top-info.border-top-darken-4 {
  border-top: 1px solid #196682 !important; }

.border-bottom-info.border-bottom-darken-4 {
  border-bottom: 1px solid #196682 !important; }

.border-left-info.border-left-darken-4 {
  border-left: 1px solid #196682 !important; }

.border-right-info.border-right-darken-4 {
  border-right: 1px solid #196682 !important; }

.overlay-info.overlay-darken-4 {
  background: #196682;
  /* The Fallback */
  background: rgba(25, 102, 130, 0.8); }

.info.accent-1 {
  color: #80D2EF !important; }

.bg-info.bg-accent-1 {
  background-color: #80D2EF !important; }

.btn-info.btn-accent-1 {
  border-color: #80D2EF !important;
  background-color: #80D2EF !important; }
  .btn-info.btn-accent-1:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-accent-1 {
  border-color: #80D2EF !important;
  color: #80D2EF !important; }
  .btn-outline-info.btn-outline-accent-1:hover {
    background-color: #80D2EF !important; }

.progress-info.progress-accent-1[value]::-webkit-progress-value {
  background-color: #80D2EF; }

.progress-info.progress-accent-1[value]::-moz-progress-bar {
  background-color: #80D2EF; }

.progress-info.progress-accent-1[value]::-ms-fill {
  background-color: #80D2EF; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-accent-1 .progress-bar {
    background-color: #80D2EF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80D2EF !important; }

.border-info.border-accent-1 {
  border: 1px solid #80D2EF !important; }

.border-top-info.border-top-accent-1 {
  border-top: 1px solid #80D2EF !important; }

.border-bottom-info.border-bottom-accent-1 {
  border-bottom: 1px solid #80D2EF !important; }

.border-left-info.border-left-accent-1 {
  border-left: 1px solid #80D2EF !important; }

.border-right-info.border-right-accent-1 {
  border-right: 1px solid #80D2EF !important; }

.overlay-info.overlay-accent-1 {
  background: #80D2EF;
  /* The Fallback */
  background: rgba(128, 210, 239, 0.8); }

.info.accent-2 {
  color: #25B1E4 !important; }

.bg-info.bg-accent-2 {
  background-color: #25B1E4 !important; }

.btn-info.btn-accent-2 {
  border-color: #25B1E4 !important;
  background-color: #25B1E4 !important; }
  .btn-info.btn-accent-2:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-accent-2 {
  border-color: #25B1E4 !important;
  color: #25B1E4 !important; }
  .btn-outline-info.btn-outline-accent-2:hover {
    background-color: #25B1E4 !important; }

.progress-info.progress-accent-2[value]::-webkit-progress-value {
  background-color: #25B1E4; }

.progress-info.progress-accent-2[value]::-moz-progress-bar {
  background-color: #25B1E4; }

.progress-info.progress-accent-2[value]::-ms-fill {
  background-color: #25B1E4; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-accent-2 .progress-bar {
    background-color: #25B1E4; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #25B1E4 !important; }

.border-info.border-accent-2 {
  border: 1px solid #25B1E4 !important; }

.border-top-info.border-top-accent-2 {
  border-top: 1px solid #25B1E4 !important; }

.border-bottom-info.border-bottom-accent-2 {
  border-bottom: 1px solid #25B1E4 !important; }

.border-left-info.border-left-accent-2 {
  border-left: 1px solid #25B1E4 !important; }

.border-right-info.border-right-accent-2 {
  border-right: 1px solid #25B1E4 !important; }

.overlay-info.overlay-accent-2 {
  background: #25B1E4;
  /* The Fallback */
  background: rgba(37, 177, 228, 0.8); }

.info.accent-3 {
  color: #168BB6 !important; }

.bg-info.bg-accent-3 {
  background-color: #168BB6 !important; }

.btn-info.btn-accent-3 {
  border-color: #168BB6 !important;
  background-color: #168BB6 !important; }
  .btn-info.btn-accent-3:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-accent-3 {
  border-color: #168BB6 !important;
  color: #168BB6 !important; }
  .btn-outline-info.btn-outline-accent-3:hover {
    background-color: #168BB6 !important; }

.progress-info.progress-accent-3[value]::-webkit-progress-value {
  background-color: #168BB6; }

.progress-info.progress-accent-3[value]::-moz-progress-bar {
  background-color: #168BB6; }

.progress-info.progress-accent-3[value]::-ms-fill {
  background-color: #168BB6; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-accent-3 .progress-bar {
    background-color: #168BB6; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #168BB6 !important; }

.border-info.border-accent-3 {
  border: 1px solid #168BB6 !important; }

.border-top-info.border-top-accent-3 {
  border-top: 1px solid #168BB6 !important; }

.border-bottom-info.border-bottom-accent-3 {
  border-bottom: 1px solid #168BB6 !important; }

.border-left-info.border-left-accent-3 {
  border-left: 1px solid #168BB6 !important; }

.border-right-info.border-right-accent-3 {
  border-right: 1px solid #168BB6 !important; }

.overlay-info.overlay-accent-3 {
  background: #168BB6;
  /* The Fallback */
  background: rgba(22, 139, 182, 0.8); }

.info.accent-4 {
  color: #126F91 !important; }

.bg-info.bg-accent-4 {
  background-color: #126F91 !important; }

.btn-info.btn-accent-4 {
  border-color: #126F91 !important;
  background-color: #126F91 !important; }
  .btn-info.btn-accent-4:hover {
    border-color: #4FC1E9 !important;
    background-color: #4FC1E9 !important; }

.btn-outline-info.btn-outline-accent-4 {
  border-color: #126F91 !important;
  color: #126F91 !important; }
  .btn-outline-info.btn-outline-accent-4:hover {
    background-color: #126F91 !important; }

.progress-info.progress-accent-4[value]::-webkit-progress-value {
  background-color: #126F91; }

.progress-info.progress-accent-4[value]::-moz-progress-bar {
  background-color: #126F91; }

.progress-info.progress-accent-4[value]::-ms-fill {
  background-color: #126F91; }

@media screen and (min-width: 0\0) {
  .progress-info.progress-accent-4 .progress-bar {
    background-color: #126F91; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #126F91 !important; }

.border-info.border-accent-4 {
  border: 1px solid #126F91 !important; }

.border-top-info.border-top-accent-4 {
  border-top: 1px solid #126F91 !important; }

.border-bottom-info.border-bottom-accent-4 {
  border-bottom: 1px solid #126F91 !important; }

.border-left-info.border-left-accent-4 {
  border-left: 1px solid #126F91 !important; }

.border-right-info.border-right-accent-4 {
  border-right: 1px solid #126F91 !important; }

.overlay-info.overlay-accent-4 {
  background: #126F91;
  /* The Fallback */
  background: rgba(18, 111, 145, 0.8); }

.warning.lighten-5 {
  color: #FFFCF6 !important; }

.bg-warning.bg-lighten-5 {
  background-color: #FFFCF6 !important; }

.btn-warning.btn-lighten-5 {
  border-color: #FFFCF6 !important;
  background-color: #FFFCF6 !important; }
  .btn-warning.btn-lighten-5:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-lighten-5 {
  border-color: #FFFCF6 !important;
  color: #FFFCF6 !important; }
  .btn-outline-warning.btn-outline-lighten-5:hover {
    background-color: #FFFCF6 !important; }

.progress-warning.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FFFCF6; }

.progress-warning.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FFFCF6; }

.progress-warning.progress-lighten-5[value]::-ms-fill {
  background-color: #FFFCF6; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-lighten-5 .progress-bar {
    background-color: #FFFCF6; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFCF6 !important; }

.border-warning.border-lighten-5 {
  border: 1px solid #FFFCF6 !important; }

.border-top-warning.border-top-lighten-5 {
  border-top: 1px solid #FFFCF6 !important; }

.border-bottom-warning.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFFCF6 !important; }

.border-left-warning.border-left-lighten-5 {
  border-left: 1px solid #FFFCF6 !important; }

.border-right-warning.border-right-lighten-5 {
  border-right: 1px solid #FFFCF6 !important; }

.overlay-warning.overlay-lighten-5 {
  background: #FFFCF6;
  /* The Fallback */
  background: rgba(255, 252, 246, 0.8); }

.warning.lighten-4 {
  color: #FCE9C1 !important; }

.bg-warning.bg-lighten-4 {
  background-color: #FCE9C1 !important; }

.btn-warning.btn-lighten-4 {
  border-color: #FCE9C1 !important;
  background-color: #FCE9C1 !important; }
  .btn-warning.btn-lighten-4:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-lighten-4 {
  border-color: #FCE9C1 !important;
  color: #FCE9C1 !important; }
  .btn-outline-warning.btn-outline-lighten-4:hover {
    background-color: #FCE9C1 !important; }

.progress-warning.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #FCE9C1; }

.progress-warning.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #FCE9C1; }

.progress-warning.progress-lighten-4[value]::-ms-fill {
  background-color: #FCE9C1; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-lighten-4 .progress-bar {
    background-color: #FCE9C1; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FCE9C1 !important; }

.border-warning.border-lighten-4 {
  border: 1px solid #FCE9C1 !important; }

.border-top-warning.border-top-lighten-4 {
  border-top: 1px solid #FCE9C1 !important; }

.border-bottom-warning.border-bottom-lighten-4 {
  border-bottom: 1px solid #FCE9C1 !important; }

.border-left-warning.border-left-lighten-4 {
  border-left: 1px solid #FCE9C1 !important; }

.border-right-warning.border-right-lighten-4 {
  border-right: 1px solid #FCE9C1 !important; }

.overlay-warning.overlay-lighten-4 {
  background: #FCE9C1;
  /* The Fallback */
  background: rgba(252, 233, 193, 0.8); }

.warning.lighten-3 {
  color: #F9D07C !important; }

.bg-warning.bg-lighten-3 {
  background-color: #F9D07C !important; }

.btn-warning.btn-lighten-3 {
  border-color: #F9D07C !important;
  background-color: #F9D07C !important; }
  .btn-warning.btn-lighten-3:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-lighten-3 {
  border-color: #F9D07C !important;
  color: #F9D07C !important; }
  .btn-outline-warning.btn-outline-lighten-3:hover {
    background-color: #F9D07C !important; }

.progress-warning.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #F9D07C; }

.progress-warning.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #F9D07C; }

.progress-warning.progress-lighten-3[value]::-ms-fill {
  background-color: #F9D07C; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-lighten-3 .progress-bar {
    background-color: #F9D07C; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9D07C !important; }

.border-warning.border-lighten-3 {
  border: 1px solid #F9D07C !important; }

.border-top-warning.border-top-lighten-3 {
  border-top: 1px solid #F9D07C !important; }

.border-bottom-warning.border-bottom-lighten-3 {
  border-bottom: 1px solid #F9D07C !important; }

.border-left-warning.border-left-lighten-3 {
  border-left: 1px solid #F9D07C !important; }

.border-right-warning.border-right-lighten-3 {
  border-right: 1px solid #F9D07C !important; }

.overlay-warning.overlay-lighten-3 {
  background: #F9D07C;
  /* The Fallback */
  background: rgba(249, 208, 124, 0.8); }

.warning.lighten-2 {
  color: #FFCE54 !important; }

.bg-warning.bg-lighten-2 {
  background-color: #FFCE54 !important; }

.btn-warning.btn-lighten-2 {
  border-color: #FFCE54 !important;
  background-color: #FFCE54 !important; }
  .btn-warning.btn-lighten-2:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-lighten-2 {
  border-color: #FFCE54 !important;
  color: #FFCE54 !important; }
  .btn-outline-warning.btn-outline-lighten-2:hover {
    background-color: #FFCE54 !important; }

.progress-warning.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #FFCE54; }

.progress-warning.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #FFCE54; }

.progress-warning.progress-lighten-2[value]::-ms-fill {
  background-color: #FFCE54; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-lighten-2 .progress-bar {
    background-color: #FFCE54; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCE54 !important; }

.border-warning.border-lighten-2 {
  border: 1px solid #FFCE54 !important; }

.border-top-warning.border-top-lighten-2 {
  border-top: 1px solid #FFCE54 !important; }

.border-bottom-warning.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFCE54 !important; }

.border-left-warning.border-left-lighten-2 {
  border-left: 1px solid #FFCE54 !important; }

.border-right-warning.border-right-lighten-2 {
  border-right: 1px solid #FFCE54 !important; }

.overlay-warning.overlay-lighten-2 {
  background: #FFCE54;
  /* The Fallback */
  background: rgba(255, 206, 84, 0.8); }

.warning.lighten-1 {
  color: #F7C55F !important; }

.bg-warning.bg-lighten-1 {
  background-color: #F7C55F !important; }

.btn-warning.btn-lighten-1 {
  border-color: #F7C55F !important;
  background-color: #F7C55F !important; }
  .btn-warning.btn-lighten-1:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-lighten-1 {
  border-color: #F7C55F !important;
  color: #F7C55F !important; }
  .btn-outline-warning.btn-outline-lighten-1:hover {
    background-color: #F7C55F !important; }

.progress-warning.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #F7C55F; }

.progress-warning.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #F7C55F; }

.progress-warning.progress-lighten-1[value]::-ms-fill {
  background-color: #F7C55F; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-lighten-1 .progress-bar {
    background-color: #F7C55F; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F7C55F !important; }

.border-warning.border-lighten-1 {
  border: 1px solid #F7C55F !important; }

.border-top-warning.border-top-lighten-1 {
  border-top: 1px solid #F7C55F !important; }

.border-bottom-warning.border-bottom-lighten-1 {
  border-bottom: 1px solid #F7C55F !important; }

.border-left-warning.border-left-lighten-1 {
  border-left: 1px solid #F7C55F !important; }

.border-right-warning.border-right-lighten-1 {
  border-right: 1px solid #F7C55F !important; }

.overlay-warning.overlay-lighten-1 {
  background: #F7C55F;
  /* The Fallback */
  background: rgba(247, 197, 95, 0.8); }

.warning {
  color: #F6BB42 !important; }

.bg-warning {
  background-color: #F6BB42 !important; }
  .bg-warning .card-header, .bg-warning .card-footer {
    background-color: transparent; }

.alert-warning {
  border-color: #F6BB42 !important;
  background-color: #f9d486 !important;
  color: #7f5806 !important; }
  .alert-warning .alert-link {
    color: #583d04 !important; }

.border-warning {
  border-color: #F6BB42; }

.bg-warning.tag-glow, .border-warning.tag-glow {
  box-shadow: 0px 0px 10px #F6BB42; }

.overlay-warning {
  background: #F6BB42;
  /* The Fallback */
  background: rgba(246, 187, 66, 0.8); }

.card-outline-warning {
  border-width: 1px;
  border-style: solid;
  border-color: #F6BB42;
  background-color: transparent; }
  .card-outline-warning .card-header, .card-outline-warning .card-footer {
    background-color: transparent; }

.btn-warning {
  border-color: #F6BB42;
  background-color: #F6BB42;
  color: #FFFFFF; }
  .btn-warning:hover {
    border-color: #FFCE54;
    background-color: #FFCE54;
    color: #FFF !important; }

.btn-outline-warning {
  border-color: #F6BB42;
  background-color: transparent;
  color: #F6BB42; }
  .btn-outline-warning:hover {
    background-color: #F6BB42;
    color: #FFF !important; }

.progress-warning[value]::-webkit-progress-value {
  background-color: #F6BB42; }

.progress-warning[value]::-moz-progress-bar {
  background-color: #F6BB42; }

.progress-warning[value]::-ms-fill {
  background-color: #F6BB42; }

@media screen and (min-width: 0\0) {
  .progress-warning .progress-bar {
    background-color: #F6BB42; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F6BB42 !important; }

.border-warning {
  border: 1px solid #F6BB42 !important; }

.border-top-warning {
  border-top: 1px solid #F6BB42; }

.border-bottom-warning {
  border-bottom: 1px solid #F6BB42; }

.border-left-warning {
  border-left: 1px solid #F6BB42; }

.border-right-warning {
  border-right: 1px solid #F6BB42; }

.warning.darken-1 {
  color: #F5B025 !important; }

.bg-warning.bg-darken-1 {
  background-color: #F5B025 !important; }

.btn-warning.btn-darken-1 {
  border-color: #F5B025 !important;
  background-color: #F5B025 !important; }
  .btn-warning.btn-darken-1:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-darken-1 {
  border-color: #F5B025 !important;
  color: #F5B025 !important; }
  .btn-outline-warning.btn-outline-darken-1:hover {
    background-color: #F5B025 !important; }

.progress-warning.progress-darken-1[value]::-webkit-progress-value {
  background-color: #F5B025; }

.progress-warning.progress-darken-1[value]::-moz-progress-bar {
  background-color: #F5B025; }

.progress-warning.progress-darken-1[value]::-ms-fill {
  background-color: #F5B025; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-darken-1 .progress-bar {
    background-color: #F5B025; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F5B025 !important; }

.border-warning.border-darken-1 {
  border: 1px solid #F5B025 !important; }

.border-top-warning.border-top-darken-1 {
  border-top: 1px solid #F5B025 !important; }

.border-bottom-warning.border-bottom-darken-1 {
  border-bottom: 1px solid #F5B025 !important; }

.border-left-warning.border-left-darken-1 {
  border-left: 1px solid #F5B025 !important; }

.border-right-warning.border-right-darken-1 {
  border-right: 1px solid #F5B025 !important; }

.overlay-warning.overlay-darken-1 {
  background: #F5B025;
  /* The Fallback */
  background: rgba(245, 176, 37, 0.8); }

.warning.darken-2 {
  color: #EFA50B !important; }

.bg-warning.bg-darken-2 {
  background-color: #EFA50B !important; }

.btn-warning.btn-darken-2 {
  border-color: #EFA50B !important;
  background-color: #EFA50B !important; }
  .btn-warning.btn-darken-2:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-darken-2 {
  border-color: #EFA50B !important;
  color: #EFA50B !important; }
  .btn-outline-warning.btn-outline-darken-2:hover {
    background-color: #EFA50B !important; }

.progress-warning.progress-darken-2[value]::-webkit-progress-value {
  background-color: #EFA50B; }

.progress-warning.progress-darken-2[value]::-moz-progress-bar {
  background-color: #EFA50B; }

.progress-warning.progress-darken-2[value]::-ms-fill {
  background-color: #EFA50B; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-darken-2 .progress-bar {
    background-color: #EFA50B; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EFA50B !important; }

.border-warning.border-darken-2 {
  border: 1px solid #EFA50B !important; }

.border-top-warning.border-top-darken-2 {
  border-top: 1px solid #EFA50B !important; }

.border-bottom-warning.border-bottom-darken-2 {
  border-bottom: 1px solid #EFA50B !important; }

.border-left-warning.border-left-darken-2 {
  border-left: 1px solid #EFA50B !important; }

.border-right-warning.border-right-darken-2 {
  border-right: 1px solid #EFA50B !important; }

.overlay-warning.overlay-darken-2 {
  background: #EFA50B;
  /* The Fallback */
  background: rgba(239, 165, 11, 0.8); }

.warning.darken-3 {
  color: #D2910A !important; }

.bg-warning.bg-darken-3 {
  background-color: #D2910A !important; }

.btn-warning.btn-darken-3 {
  border-color: #D2910A !important;
  background-color: #D2910A !important; }
  .btn-warning.btn-darken-3:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-darken-3 {
  border-color: #D2910A !important;
  color: #D2910A !important; }
  .btn-outline-warning.btn-outline-darken-3:hover {
    background-color: #D2910A !important; }

.progress-warning.progress-darken-3[value]::-webkit-progress-value {
  background-color: #D2910A; }

.progress-warning.progress-darken-3[value]::-moz-progress-bar {
  background-color: #D2910A; }

.progress-warning.progress-darken-3[value]::-ms-fill {
  background-color: #D2910A; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-darken-3 .progress-bar {
    background-color: #D2910A; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D2910A !important; }

.border-warning.border-darken-3 {
  border: 1px solid #D2910A !important; }

.border-top-warning.border-top-darken-3 {
  border-top: 1px solid #D2910A !important; }

.border-bottom-warning.border-bottom-darken-3 {
  border-bottom: 1px solid #D2910A !important; }

.border-left-warning.border-left-darken-3 {
  border-left: 1px solid #D2910A !important; }

.border-right-warning.border-right-darken-3 {
  border-right: 1px solid #D2910A !important; }

.overlay-warning.overlay-darken-3 {
  background: #D2910A;
  /* The Fallback */
  background: rgba(210, 145, 10, 0.8); }

.warning.darken-4 {
  color: #B57C09 !important; }

.bg-warning.bg-darken-4 {
  background-color: #B57C09 !important; }

.btn-warning.btn-darken-4 {
  border-color: #B57C09 !important;
  background-color: #B57C09 !important; }
  .btn-warning.btn-darken-4:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-darken-4 {
  border-color: #B57C09 !important;
  color: #B57C09 !important; }
  .btn-outline-warning.btn-outline-darken-4:hover {
    background-color: #B57C09 !important; }

.progress-warning.progress-darken-4[value]::-webkit-progress-value {
  background-color: #B57C09; }

.progress-warning.progress-darken-4[value]::-moz-progress-bar {
  background-color: #B57C09; }

.progress-warning.progress-darken-4[value]::-ms-fill {
  background-color: #B57C09; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-darken-4 .progress-bar {
    background-color: #B57C09; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B57C09 !important; }

.border-warning.border-darken-4 {
  border: 1px solid #B57C09 !important; }

.border-top-warning.border-top-darken-4 {
  border-top: 1px solid #B57C09 !important; }

.border-bottom-warning.border-bottom-darken-4 {
  border-bottom: 1px solid #B57C09 !important; }

.border-left-warning.border-left-darken-4 {
  border-left: 1px solid #B57C09 !important; }

.border-right-warning.border-right-darken-4 {
  border-right: 1px solid #B57C09 !important; }

.overlay-warning.overlay-darken-4 {
  background: #B57C09;
  /* The Fallback */
  background: rgba(181, 124, 9, 0.8); }

.warning.accent-1 {
  color: #fddea1 !important; }

.bg-warning.bg-accent-1 {
  background-color: #fddea1 !important; }

.btn-warning.btn-accent-1 {
  border-color: #fddea1 !important;
  background-color: #fddea1 !important; }
  .btn-warning.btn-accent-1:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-accent-1 {
  border-color: #fddea1 !important;
  color: #fddea1 !important; }
  .btn-outline-warning.btn-outline-accent-1:hover {
    background-color: #fddea1 !important; }

.progress-warning.progress-accent-1[value]::-webkit-progress-value {
  background-color: #fddea1; }

.progress-warning.progress-accent-1[value]::-moz-progress-bar {
  background-color: #fddea1; }

.progress-warning.progress-accent-1[value]::-ms-fill {
  background-color: #fddea1; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-accent-1 .progress-bar {
    background-color: #fddea1; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fddea1 !important; }

.border-warning.border-accent-1 {
  border: 1px solid #fddea1 !important; }

.border-top-warning.border-top-accent-1 {
  border-top: 1px solid #fddea1 !important; }

.border-bottom-warning.border-bottom-accent-1 {
  border-bottom: 1px solid #fddea1 !important; }

.border-left-warning.border-left-accent-1 {
  border-left: 1px solid #fddea1 !important; }

.border-right-warning.border-right-accent-1 {
  border-right: 1px solid #fddea1 !important; }

.overlay-warning.overlay-accent-1 {
  background: #fddea1;
  /* The Fallback */
  background: rgba(253, 222, 161, 0.8); }

.warning.accent-2 {
  color: #f9d07d !important; }

.bg-warning.bg-accent-2 {
  background-color: #f9d07d !important; }

.btn-warning.btn-accent-2 {
  border-color: #f9d07d !important;
  background-color: #f9d07d !important; }
  .btn-warning.btn-accent-2:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-accent-2 {
  border-color: #f9d07d !important;
  color: #f9d07d !important; }
  .btn-outline-warning.btn-outline-accent-2:hover {
    background-color: #f9d07d !important; }

.progress-warning.progress-accent-2[value]::-webkit-progress-value {
  background-color: #f9d07d; }

.progress-warning.progress-accent-2[value]::-moz-progress-bar {
  background-color: #f9d07d; }

.progress-warning.progress-accent-2[value]::-ms-fill {
  background-color: #f9d07d; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-accent-2 .progress-bar {
    background-color: #f9d07d; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9d07d !important; }

.border-warning.border-accent-2 {
  border: 1px solid #f9d07d !important; }

.border-top-warning.border-top-accent-2 {
  border-top: 1px solid #f9d07d !important; }

.border-bottom-warning.border-bottom-accent-2 {
  border-bottom: 1px solid #f9d07d !important; }

.border-left-warning.border-left-accent-2 {
  border-left: 1px solid #f9d07d !important; }

.border-right-warning.border-right-accent-2 {
  border-right: 1px solid #f9d07d !important; }

.overlay-warning.overlay-accent-2 {
  background: #f9d07d;
  /* The Fallback */
  background: rgba(249, 208, 125, 0.8); }

.warning.accent-3 {
  color: #FDA85C !important; }

.bg-warning.bg-accent-3 {
  background-color: #FDA85C !important; }

.btn-warning.btn-accent-3 {
  border-color: #FDA85C !important;
  background-color: #FDA85C !important; }
  .btn-warning.btn-accent-3:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-accent-3 {
  border-color: #FDA85C !important;
  color: #FDA85C !important; }
  .btn-outline-warning.btn-outline-accent-3:hover {
    background-color: #FDA85C !important; }

.progress-warning.progress-accent-3[value]::-webkit-progress-value {
  background-color: #FDA85C; }

.progress-warning.progress-accent-3[value]::-moz-progress-bar {
  background-color: #FDA85C; }

.progress-warning.progress-accent-3[value]::-ms-fill {
  background-color: #FDA85C; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-accent-3 .progress-bar {
    background-color: #FDA85C; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FDA85C !important; }

.border-warning.border-accent-3 {
  border: 1px solid #FDA85C !important; }

.border-top-warning.border-top-accent-3 {
  border-top: 1px solid #FDA85C !important; }

.border-bottom-warning.border-bottom-accent-3 {
  border-bottom: 1px solid #FDA85C !important; }

.border-left-warning.border-left-accent-3 {
  border-left: 1px solid #FDA85C !important; }

.border-right-warning.border-right-accent-3 {
  border-right: 1px solid #FDA85C !important; }

.overlay-warning.overlay-accent-3 {
  background: #FDA85C;
  /* The Fallback */
  background: rgba(253, 168, 92, 0.8); }

.warning.accent-4 {
  color: #F5A90B !important; }

.bg-warning.bg-accent-4 {
  background-color: #F5A90B !important; }

.btn-warning.btn-accent-4 {
  border-color: #F5A90B !important;
  background-color: #F5A90B !important; }
  .btn-warning.btn-accent-4:hover {
    border-color: #FFCE54 !important;
    background-color: #FFCE54 !important; }

.btn-outline-warning.btn-outline-accent-4 {
  border-color: #F5A90B !important;
  color: #F5A90B !important; }
  .btn-outline-warning.btn-outline-accent-4:hover {
    background-color: #F5A90B !important; }

.progress-warning.progress-accent-4[value]::-webkit-progress-value {
  background-color: #F5A90B; }

.progress-warning.progress-accent-4[value]::-moz-progress-bar {
  background-color: #F5A90B; }

.progress-warning.progress-accent-4[value]::-ms-fill {
  background-color: #F5A90B; }

@media screen and (min-width: 0\0) {
  .progress-warning.progress-accent-4 .progress-bar {
    background-color: #F5A90B; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F5A90B !important; }

.border-warning.border-accent-4 {
  border: 1px solid #F5A90B !important; }

.border-top-warning.border-top-accent-4 {
  border-top: 1px solid #F5A90B !important; }

.border-bottom-warning.border-bottom-accent-4 {
  border-bottom: 1px solid #F5A90B !important; }

.border-left-warning.border-left-accent-4 {
  border-left: 1px solid #F5A90B !important; }

.border-right-warning.border-right-accent-4 {
  border-right: 1px solid #F5A90B !important; }

.overlay-warning.overlay-accent-4 {
  background: #F5A90B;
  /* The Fallback */
  background: rgba(245, 169, 11, 0.8); }

.danger.lighten-5 {
  color: #F9E1E4 !important; }

.bg-danger.bg-lighten-5 {
  background-color: #F9E1E4 !important; }

.btn-danger.btn-lighten-5 {
  border-color: #F9E1E4 !important;
  background-color: #F9E1E4 !important; }
  .btn-danger.btn-lighten-5:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-lighten-5 {
  border-color: #F9E1E4 !important;
  color: #F9E1E4 !important; }
  .btn-outline-danger.btn-outline-lighten-5:hover {
    background-color: #F9E1E4 !important; }

.progress-danger.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #F9E1E4; }

.progress-danger.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #F9E1E4; }

.progress-danger.progress-lighten-5[value]::-ms-fill {
  background-color: #F9E1E4; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-lighten-5 .progress-bar {
    background-color: #F9E1E4; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9E1E4 !important; }

.border-danger.border-lighten-5 {
  border: 1px solid #F9E1E4 !important; }

.border-top-danger.border-top-lighten-5 {
  border-top: 1px solid #F9E1E4 !important; }

.border-bottom-danger.border-bottom-lighten-5 {
  border-bottom: 1px solid #F9E1E4 !important; }

.border-left-danger.border-left-lighten-5 {
  border-left: 1px solid #F9E1E4 !important; }

.border-right-danger.border-right-lighten-5 {
  border-right: 1px solid #F9E1E4 !important; }

.overlay-danger.overlay-lighten-5 {
  background: #F9E1E4;
  /* The Fallback */
  background: rgba(249, 225, 228, 0.8); }

.danger.lighten-4 {
  color: #F0B3B9 !important; }

.bg-danger.bg-lighten-4 {
  background-color: #F0B3B9 !important; }

.btn-danger.btn-lighten-4 {
  border-color: #F0B3B9 !important;
  background-color: #F0B3B9 !important; }
  .btn-danger.btn-lighten-4:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-lighten-4 {
  border-color: #F0B3B9 !important;
  color: #F0B3B9 !important; }
  .btn-outline-danger.btn-outline-lighten-4:hover {
    background-color: #F0B3B9 !important; }

.progress-danger.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #F0B3B9; }

.progress-danger.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #F0B3B9; }

.progress-danger.progress-lighten-4[value]::-ms-fill {
  background-color: #F0B3B9; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-lighten-4 .progress-bar {
    background-color: #F0B3B9; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F0B3B9 !important; }

.border-danger.border-lighten-4 {
  border: 1px solid #F0B3B9 !important; }

.border-top-danger.border-top-lighten-4 {
  border-top: 1px solid #F0B3B9 !important; }

.border-bottom-danger.border-bottom-lighten-4 {
  border-bottom: 1px solid #F0B3B9 !important; }

.border-left-danger.border-left-lighten-4 {
  border-left: 1px solid #F0B3B9 !important; }

.border-right-danger.border-right-lighten-4 {
  border-right: 1px solid #F0B3B9 !important; }

.overlay-danger.overlay-lighten-4 {
  background: #F0B3B9;
  /* The Fallback */
  background: rgba(240, 179, 185, 0.8); }

.danger.lighten-3 {
  color: #E47782 !important; }

.bg-danger.bg-lighten-3 {
  background-color: #E47782 !important; }

.btn-danger.btn-lighten-3 {
  border-color: #E47782 !important;
  background-color: #E47782 !important; }
  .btn-danger.btn-lighten-3:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-lighten-3 {
  border-color: #E47782 !important;
  color: #E47782 !important; }
  .btn-outline-danger.btn-outline-lighten-3:hover {
    background-color: #E47782 !important; }

.progress-danger.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #E47782; }

.progress-danger.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #E47782; }

.progress-danger.progress-lighten-3[value]::-ms-fill {
  background-color: #E47782; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-lighten-3 .progress-bar {
    background-color: #E47782; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E47782 !important; }

.border-danger.border-lighten-3 {
  border: 1px solid #E47782 !important; }

.border-top-danger.border-top-lighten-3 {
  border-top: 1px solid #E47782 !important; }

.border-bottom-danger.border-bottom-lighten-3 {
  border-bottom: 1px solid #E47782 !important; }

.border-left-danger.border-left-lighten-3 {
  border-left: 1px solid #E47782 !important; }

.border-right-danger.border-right-lighten-3 {
  border-right: 1px solid #E47782 !important; }

.overlay-danger.overlay-lighten-3 {
  background: #E47782;
  /* The Fallback */
  background: rgba(228, 119, 130, 0.8); }

.danger.lighten-2 {
  color: #ED5565 !important; }

.bg-danger.bg-lighten-2 {
  background-color: #ED5565 !important; }

.btn-danger.btn-lighten-2 {
  border-color: #ED5565 !important;
  background-color: #ED5565 !important; }
  .btn-danger.btn-lighten-2:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-lighten-2 {
  border-color: #ED5565 !important;
  color: #ED5565 !important; }
  .btn-outline-danger.btn-outline-lighten-2:hover {
    background-color: #ED5565 !important; }

.progress-danger.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #ED5565; }

.progress-danger.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #ED5565; }

.progress-danger.progress-lighten-2[value]::-ms-fill {
  background-color: #ED5565; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-lighten-2 .progress-bar {
    background-color: #ED5565; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ED5565 !important; }

.border-danger.border-lighten-2 {
  border: 1px solid #ED5565 !important; }

.border-top-danger.border-top-lighten-2 {
  border-top: 1px solid #ED5565 !important; }

.border-bottom-danger.border-bottom-lighten-2 {
  border-bottom: 1px solid #ED5565 !important; }

.border-left-danger.border-left-lighten-2 {
  border-left: 1px solid #ED5565 !important; }

.border-right-danger.border-right-lighten-2 {
  border-right: 1px solid #ED5565 !important; }

.overlay-danger.overlay-lighten-2 {
  background: #ED5565;
  /* The Fallback */
  background: rgba(237, 85, 101, 0.8); }

.danger.lighten-1 {
  color: #DF5E6A !important; }

.bg-danger.bg-lighten-1 {
  background-color: #DF5E6A !important; }

.btn-danger.btn-lighten-1 {
  border-color: #DF5E6A !important;
  background-color: #DF5E6A !important; }
  .btn-danger.btn-lighten-1:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-lighten-1 {
  border-color: #DF5E6A !important;
  color: #DF5E6A !important; }
  .btn-outline-danger.btn-outline-lighten-1:hover {
    background-color: #DF5E6A !important; }

.progress-danger.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #DF5E6A; }

.progress-danger.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #DF5E6A; }

.progress-danger.progress-lighten-1[value]::-ms-fill {
  background-color: #DF5E6A; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-lighten-1 .progress-bar {
    background-color: #DF5E6A; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DF5E6A !important; }

.border-danger.border-lighten-1 {
  border: 1px solid #DF5E6A !important; }

.border-top-danger.border-top-lighten-1 {
  border-top: 1px solid #DF5E6A !important; }

.border-bottom-danger.border-bottom-lighten-1 {
  border-bottom: 1px solid #DF5E6A !important; }

.border-left-danger.border-left-lighten-1 {
  border-left: 1px solid #DF5E6A !important; }

.border-right-danger.border-right-lighten-1 {
  border-right: 1px solid #DF5E6A !important; }

.overlay-danger.overlay-lighten-1 {
  background: #DF5E6A;
  /* The Fallback */
  background: rgba(223, 94, 106, 0.8); }

.danger {
  color: #DA4453 !important; }

.bg-danger {
  background-color: #DA4453 !important; }
  .bg-danger .card-header, .bg-danger .card-footer {
    background-color: transparent; }

.alert-danger {
  border-color: #DA4453 !important;
  background-color: #e6808a !important;
  color: #5a1219 !important; }
  .alert-danger .alert-link {
    color: #380b0f !important; }

.border-danger {
  border-color: #DA4453; }

.bg-danger.tag-glow, .border-danger.tag-glow {
  box-shadow: 0px 0px 10px #DA4453; }

.overlay-danger {
  background: #DA4453;
  /* The Fallback */
  background: rgba(218, 68, 83, 0.8); }

.card-outline-danger {
  border-width: 1px;
  border-style: solid;
  border-color: #DA4453;
  background-color: transparent; }
  .card-outline-danger .card-header, .card-outline-danger .card-footer {
    background-color: transparent; }

.btn-danger {
  border-color: #DA4453;
  background-color: #DA4453;
  color: #FFFFFF; }
  .btn-danger:hover {
    border-color: #ED5565;
    background-color: #ED5565;
    color: #FFF !important; }

.btn-outline-danger {
  border-color: #DA4453;
  background-color: transparent;
  color: #DA4453; }
  .btn-outline-danger:hover {
    background-color: #DA4453;
    color: #FFF !important; }

.progress-danger[value]::-webkit-progress-value {
  background-color: #DA4453; }

.progress-danger[value]::-moz-progress-bar {
  background-color: #DA4453; }

.progress-danger[value]::-ms-fill {
  background-color: #DA4453; }

@media screen and (min-width: 0\0) {
  .progress-danger .progress-bar {
    background-color: #DA4453; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DA4453 !important; }

.border-danger {
  border: 1px solid #DA4453 !important; }

.border-top-danger {
  border-top: 1px solid #DA4453; }

.border-bottom-danger {
  border-bottom: 1px solid #DA4453; }

.border-left-danger {
  border-left: 1px solid #DA4453; }

.border-right-danger {
  border-right: 1px solid #DA4453; }

.danger.darken-1 {
  color: #D52A3B !important; }

.bg-danger.bg-darken-1 {
  background-color: #D52A3B !important; }

.btn-danger.btn-darken-1 {
  border-color: #D52A3B !important;
  background-color: #D52A3B !important; }
  .btn-danger.btn-darken-1:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-darken-1 {
  border-color: #D52A3B !important;
  color: #D52A3B !important; }
  .btn-outline-danger.btn-outline-darken-1:hover {
    background-color: #D52A3B !important; }

.progress-danger.progress-darken-1[value]::-webkit-progress-value {
  background-color: #D52A3B; }

.progress-danger.progress-darken-1[value]::-moz-progress-bar {
  background-color: #D52A3B; }

.progress-danger.progress-darken-1[value]::-ms-fill {
  background-color: #D52A3B; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-darken-1 .progress-bar {
    background-color: #D52A3B; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D52A3B !important; }

.border-danger.border-darken-1 {
  border: 1px solid #D52A3B !important; }

.border-top-danger.border-top-darken-1 {
  border-top: 1px solid #D52A3B !important; }

.border-bottom-danger.border-bottom-darken-1 {
  border-bottom: 1px solid #D52A3B !important; }

.border-left-danger.border-left-darken-1 {
  border-left: 1px solid #D52A3B !important; }

.border-right-danger.border-right-darken-1 {
  border-right: 1px solid #D52A3B !important; }

.overlay-danger.overlay-darken-1 {
  background: #D52A3B;
  /* The Fallback */
  background: rgba(213, 42, 59, 0.8); }

.danger.darken-2 {
  color: #BC2534 !important; }

.bg-danger.bg-darken-2 {
  background-color: #BC2534 !important; }

.btn-danger.btn-darken-2 {
  border-color: #BC2534 !important;
  background-color: #BC2534 !important; }
  .btn-danger.btn-darken-2:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-darken-2 {
  border-color: #BC2534 !important;
  color: #BC2534 !important; }
  .btn-outline-danger.btn-outline-darken-2:hover {
    background-color: #BC2534 !important; }

.progress-danger.progress-darken-2[value]::-webkit-progress-value {
  background-color: #BC2534; }

.progress-danger.progress-darken-2[value]::-moz-progress-bar {
  background-color: #BC2534; }

.progress-danger.progress-darken-2[value]::-ms-fill {
  background-color: #BC2534; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-darken-2 .progress-bar {
    background-color: #BC2534; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BC2534 !important; }

.border-danger.border-darken-2 {
  border: 1px solid #BC2534 !important; }

.border-top-danger.border-top-darken-2 {
  border-top: 1px solid #BC2534 !important; }

.border-bottom-danger.border-bottom-darken-2 {
  border-bottom: 1px solid #BC2534 !important; }

.border-left-danger.border-left-darken-2 {
  border-left: 1px solid #BC2534 !important; }

.border-right-danger.border-right-darken-2 {
  border-right: 1px solid #BC2534 !important; }

.overlay-danger.overlay-darken-2 {
  background: #BC2534;
  /* The Fallback */
  background: rgba(188, 37, 52, 0.8); }

.danger.darken-3 {
  color: #A2202D !important; }

.bg-danger.bg-darken-3 {
  background-color: #A2202D !important; }

.btn-danger.btn-darken-3 {
  border-color: #A2202D !important;
  background-color: #A2202D !important; }
  .btn-danger.btn-darken-3:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-darken-3 {
  border-color: #A2202D !important;
  color: #A2202D !important; }
  .btn-outline-danger.btn-outline-darken-3:hover {
    background-color: #A2202D !important; }

.progress-danger.progress-darken-3[value]::-webkit-progress-value {
  background-color: #A2202D; }

.progress-danger.progress-darken-3[value]::-moz-progress-bar {
  background-color: #A2202D; }

.progress-danger.progress-darken-3[value]::-ms-fill {
  background-color: #A2202D; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-darken-3 .progress-bar {
    background-color: #A2202D; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A2202D !important; }

.border-danger.border-darken-3 {
  border: 1px solid #A2202D !important; }

.border-top-danger.border-top-darken-3 {
  border-top: 1px solid #A2202D !important; }

.border-bottom-danger.border-bottom-darken-3 {
  border-bottom: 1px solid #A2202D !important; }

.border-left-danger.border-left-darken-3 {
  border-left: 1px solid #A2202D !important; }

.border-right-danger.border-right-darken-3 {
  border-right: 1px solid #A2202D !important; }

.overlay-danger.overlay-darken-3 {
  background: #A2202D;
  /* The Fallback */
  background: rgba(162, 32, 45, 0.8); }

.danger.darken-4 {
  color: #891B26 !important; }

.bg-danger.bg-darken-4 {
  background-color: #891B26 !important; }

.btn-danger.btn-darken-4 {
  border-color: #891B26 !important;
  background-color: #891B26 !important; }
  .btn-danger.btn-darken-4:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-darken-4 {
  border-color: #891B26 !important;
  color: #891B26 !important; }
  .btn-outline-danger.btn-outline-darken-4:hover {
    background-color: #891B26 !important; }

.progress-danger.progress-darken-4[value]::-webkit-progress-value {
  background-color: #891B26; }

.progress-danger.progress-darken-4[value]::-moz-progress-bar {
  background-color: #891B26; }

.progress-danger.progress-darken-4[value]::-ms-fill {
  background-color: #891B26; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-darken-4 .progress-bar {
    background-color: #891B26; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #891B26 !important; }

.border-danger.border-darken-4 {
  border: 1px solid #891B26 !important; }

.border-top-danger.border-top-darken-4 {
  border-top: 1px solid #891B26 !important; }

.border-bottom-danger.border-bottom-darken-4 {
  border-bottom: 1px solid #891B26 !important; }

.border-left-danger.border-left-darken-4 {
  border-left: 1px solid #891B26 !important; }

.border-right-danger.border-right-darken-4 {
  border-right: 1px solid #891B26 !important; }

.overlay-danger.overlay-darken-4 {
  background: #891B26;
  /* The Fallback */
  background: rgba(137, 27, 38, 0.8); }

.danger.accent-1 {
  color: #F9E1E4 !important; }

.bg-danger.bg-accent-1 {
  background-color: #F9E1E4 !important; }

.btn-danger.btn-accent-1 {
  border-color: #F9E1E4 !important;
  background-color: #F9E1E4 !important; }
  .btn-danger.btn-accent-1:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-accent-1 {
  border-color: #F9E1E4 !important;
  color: #F9E1E4 !important; }
  .btn-outline-danger.btn-outline-accent-1:hover {
    background-color: #F9E1E4 !important; }

.progress-danger.progress-accent-1[value]::-webkit-progress-value {
  background-color: #F9E1E4; }

.progress-danger.progress-accent-1[value]::-moz-progress-bar {
  background-color: #F9E1E4; }

.progress-danger.progress-accent-1[value]::-ms-fill {
  background-color: #F9E1E4; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-accent-1 .progress-bar {
    background-color: #F9E1E4; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9E1E4 !important; }

.border-danger.border-accent-1 {
  border: 1px solid #F9E1E4 !important; }

.border-top-danger.border-top-accent-1 {
  border-top: 1px solid #F9E1E4 !important; }

.border-bottom-danger.border-bottom-accent-1 {
  border-bottom: 1px solid #F9E1E4 !important; }

.border-left-danger.border-left-accent-1 {
  border-left: 1px solid #F9E1E4 !important; }

.border-right-danger.border-right-accent-1 {
  border-right: 1px solid #F9E1E4 !important; }

.overlay-danger.overlay-accent-1 {
  background: #F9E1E4;
  /* The Fallback */
  background: rgba(249, 225, 228, 0.8); }

.danger.accent-2 {
  color: #EA7983 !important; }

.bg-danger.bg-accent-2 {
  background-color: #EA7983 !important; }

.btn-danger.btn-accent-2 {
  border-color: #EA7983 !important;
  background-color: #EA7983 !important; }
  .btn-danger.btn-accent-2:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-accent-2 {
  border-color: #EA7983 !important;
  color: #EA7983 !important; }
  .btn-outline-danger.btn-outline-accent-2:hover {
    background-color: #EA7983 !important; }

.progress-danger.progress-accent-2[value]::-webkit-progress-value {
  background-color: #EA7983; }

.progress-danger.progress-accent-2[value]::-moz-progress-bar {
  background-color: #EA7983; }

.progress-danger.progress-accent-2[value]::-ms-fill {
  background-color: #EA7983; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-accent-2 .progress-bar {
    background-color: #EA7983; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EA7983 !important; }

.border-danger.border-accent-2 {
  border: 1px solid #EA7983 !important; }

.border-top-danger.border-top-accent-2 {
  border-top: 1px solid #EA7983 !important; }

.border-bottom-danger.border-bottom-accent-2 {
  border-bottom: 1px solid #EA7983 !important; }

.border-left-danger.border-left-accent-2 {
  border-left: 1px solid #EA7983 !important; }

.border-right-danger.border-right-accent-2 {
  border-right: 1px solid #EA7983 !important; }

.overlay-danger.overlay-accent-2 {
  background: #EA7983;
  /* The Fallback */
  background: rgba(234, 121, 131, 0.8); }

.danger.accent-3 {
  color: #DF5E6A !important; }

.bg-danger.bg-accent-3 {
  background-color: #DF5E6A !important; }

.btn-danger.btn-accent-3 {
  border-color: #DF5E6A !important;
  background-color: #DF5E6A !important; }
  .btn-danger.btn-accent-3:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-accent-3 {
  border-color: #DF5E6A !important;
  color: #DF5E6A !important; }
  .btn-outline-danger.btn-outline-accent-3:hover {
    background-color: #DF5E6A !important; }

.progress-danger.progress-accent-3[value]::-webkit-progress-value {
  background-color: #DF5E6A; }

.progress-danger.progress-accent-3[value]::-moz-progress-bar {
  background-color: #DF5E6A; }

.progress-danger.progress-accent-3[value]::-ms-fill {
  background-color: #DF5E6A; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-accent-3 .progress-bar {
    background-color: #DF5E6A; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DF5E6A !important; }

.border-danger.border-accent-3 {
  border: 1px solid #DF5E6A !important; }

.border-top-danger.border-top-accent-3 {
  border-top: 1px solid #DF5E6A !important; }

.border-bottom-danger.border-bottom-accent-3 {
  border-bottom: 1px solid #DF5E6A !important; }

.border-left-danger.border-left-accent-3 {
  border-left: 1px solid #DF5E6A !important; }

.border-right-danger.border-right-accent-3 {
  border-right: 1px solid #DF5E6A !important; }

.overlay-danger.overlay-accent-3 {
  background: #DF5E6A;
  /* The Fallback */
  background: rgba(223, 94, 106, 0.8); }

.danger.accent-4 {
  color: #CC2F3E !important; }

.bg-danger.bg-accent-4 {
  background-color: #CC2F3E !important; }

.btn-danger.btn-accent-4 {
  border-color: #CC2F3E !important;
  background-color: #CC2F3E !important; }
  .btn-danger.btn-accent-4:hover {
    border-color: #ED5565 !important;
    background-color: #ED5565 !important; }

.btn-outline-danger.btn-outline-accent-4 {
  border-color: #CC2F3E !important;
  color: #CC2F3E !important; }
  .btn-outline-danger.btn-outline-accent-4:hover {
    background-color: #CC2F3E !important; }

.progress-danger.progress-accent-4[value]::-webkit-progress-value {
  background-color: #CC2F3E; }

.progress-danger.progress-accent-4[value]::-moz-progress-bar {
  background-color: #CC2F3E; }

.progress-danger.progress-accent-4[value]::-ms-fill {
  background-color: #CC2F3E; }

@media screen and (min-width: 0\0) {
  .progress-danger.progress-accent-4 .progress-bar {
    background-color: #CC2F3E; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CC2F3E !important; }

.border-danger.border-accent-4 {
  border: 1px solid #CC2F3E !important; }

.border-top-danger.border-top-accent-4 {
  border-top: 1px solid #CC2F3E !important; }

.border-bottom-danger.border-bottom-accent-4 {
  border-bottom: 1px solid #CC2F3E !important; }

.border-left-danger.border-left-accent-4 {
  border-left: 1px solid #CC2F3E !important; }

.border-right-danger.border-right-accent-4 {
  border-right: 1px solid #CC2F3E !important; }

.overlay-danger.overlay-accent-4 {
  background: #CC2F3E;
  /* The Fallback */
  background: rgba(204, 47, 62, 0.8); }

.red.lighten-5 {
  color: #FFEBEE !important; }

.bg-red.bg-lighten-5 {
  background-color: #FFEBEE !important; }

.btn-red.btn-lighten-5 {
  border-color: #FFEBEE !important;
  background-color: #FFEBEE !important; }
  .btn-red.btn-lighten-5:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-lighten-5 {
  border-color: #FFEBEE !important;
  color: #FFEBEE !important; }
  .btn-outline-red.btn-outline-lighten-5:hover {
    background-color: #FFEBEE !important; }

.progress-red.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FFEBEE; }

.progress-red.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FFEBEE; }

.progress-red.progress-lighten-5[value]::-ms-fill {
  background-color: #FFEBEE; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-lighten-5 .progress-bar {
    background-color: #FFEBEE; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEBEE !important; }

.border-red.border-lighten-5 {
  border: 1px solid #FFEBEE !important; }

.border-top-red.border-top-lighten-5 {
  border-top: 1px solid #FFEBEE !important; }

.border-bottom-red.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFEBEE !important; }

.border-left-red.border-left-lighten-5 {
  border-left: 1px solid #FFEBEE !important; }

.border-right-red.border-right-lighten-5 {
  border-right: 1px solid #FFEBEE !important; }

.overlay-red.overlay-lighten-5 {
  background: #FFEBEE;
  /* The Fallback */
  background: rgba(255, 235, 238, 0.8); }

.red.lighten-4 {
  color: #FFCDD2 !important; }

.bg-red.bg-lighten-4 {
  background-color: #FFCDD2 !important; }

.btn-red.btn-lighten-4 {
  border-color: #FFCDD2 !important;
  background-color: #FFCDD2 !important; }
  .btn-red.btn-lighten-4:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-lighten-4 {
  border-color: #FFCDD2 !important;
  color: #FFCDD2 !important; }
  .btn-outline-red.btn-outline-lighten-4:hover {
    background-color: #FFCDD2 !important; }

.progress-red.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #FFCDD2; }

.progress-red.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #FFCDD2; }

.progress-red.progress-lighten-4[value]::-ms-fill {
  background-color: #FFCDD2; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-lighten-4 .progress-bar {
    background-color: #FFCDD2; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCDD2 !important; }

.border-red.border-lighten-4 {
  border: 1px solid #FFCDD2 !important; }

.border-top-red.border-top-lighten-4 {
  border-top: 1px solid #FFCDD2 !important; }

.border-bottom-red.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFCDD2 !important; }

.border-left-red.border-left-lighten-4 {
  border-left: 1px solid #FFCDD2 !important; }

.border-right-red.border-right-lighten-4 {
  border-right: 1px solid #FFCDD2 !important; }

.overlay-red.overlay-lighten-4 {
  background: #FFCDD2;
  /* The Fallback */
  background: rgba(255, 205, 210, 0.8); }

.red.lighten-3 {
  color: #EF9A9A !important; }

.bg-red.bg-lighten-3 {
  background-color: #EF9A9A !important; }

.btn-red.btn-lighten-3 {
  border-color: #EF9A9A !important;
  background-color: #EF9A9A !important; }
  .btn-red.btn-lighten-3:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-lighten-3 {
  border-color: #EF9A9A !important;
  color: #EF9A9A !important; }
  .btn-outline-red.btn-outline-lighten-3:hover {
    background-color: #EF9A9A !important; }

.progress-red.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #EF9A9A; }

.progress-red.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #EF9A9A; }

.progress-red.progress-lighten-3[value]::-ms-fill {
  background-color: #EF9A9A; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-lighten-3 .progress-bar {
    background-color: #EF9A9A; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF9A9A !important; }

.border-red.border-lighten-3 {
  border: 1px solid #EF9A9A !important; }

.border-top-red.border-top-lighten-3 {
  border-top: 1px solid #EF9A9A !important; }

.border-bottom-red.border-bottom-lighten-3 {
  border-bottom: 1px solid #EF9A9A !important; }

.border-left-red.border-left-lighten-3 {
  border-left: 1px solid #EF9A9A !important; }

.border-right-red.border-right-lighten-3 {
  border-right: 1px solid #EF9A9A !important; }

.overlay-red.overlay-lighten-3 {
  background: #EF9A9A;
  /* The Fallback */
  background: rgba(239, 154, 154, 0.8); }

.red.lighten-2 {
  color: #E57373 !important; }

.bg-red.bg-lighten-2 {
  background-color: #E57373 !important; }

.btn-red.btn-lighten-2 {
  border-color: #E57373 !important;
  background-color: #E57373 !important; }
  .btn-red.btn-lighten-2:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-lighten-2 {
  border-color: #E57373 !important;
  color: #E57373 !important; }
  .btn-outline-red.btn-outline-lighten-2:hover {
    background-color: #E57373 !important; }

.progress-red.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #E57373; }

.progress-red.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #E57373; }

.progress-red.progress-lighten-2[value]::-ms-fill {
  background-color: #E57373; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-lighten-2 .progress-bar {
    background-color: #E57373; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E57373 !important; }

.border-red.border-lighten-2 {
  border: 1px solid #E57373 !important; }

.border-top-red.border-top-lighten-2 {
  border-top: 1px solid #E57373 !important; }

.border-bottom-red.border-bottom-lighten-2 {
  border-bottom: 1px solid #E57373 !important; }

.border-left-red.border-left-lighten-2 {
  border-left: 1px solid #E57373 !important; }

.border-right-red.border-right-lighten-2 {
  border-right: 1px solid #E57373 !important; }

.overlay-red.overlay-lighten-2 {
  background: #E57373;
  /* The Fallback */
  background: rgba(229, 115, 115, 0.8); }

.red.lighten-1 {
  color: #EF5350 !important; }

.bg-red.bg-lighten-1 {
  background-color: #EF5350 !important; }

.btn-red.btn-lighten-1 {
  border-color: #EF5350 !important;
  background-color: #EF5350 !important; }
  .btn-red.btn-lighten-1:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-lighten-1 {
  border-color: #EF5350 !important;
  color: #EF5350 !important; }
  .btn-outline-red.btn-outline-lighten-1:hover {
    background-color: #EF5350 !important; }

.progress-red.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #EF5350; }

.progress-red.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #EF5350; }

.progress-red.progress-lighten-1[value]::-ms-fill {
  background-color: #EF5350; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-lighten-1 .progress-bar {
    background-color: #EF5350; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF5350 !important; }

.border-red.border-lighten-1 {
  border: 1px solid #EF5350 !important; }

.border-top-red.border-top-lighten-1 {
  border-top: 1px solid #EF5350 !important; }

.border-bottom-red.border-bottom-lighten-1 {
  border-bottom: 1px solid #EF5350 !important; }

.border-left-red.border-left-lighten-1 {
  border-left: 1px solid #EF5350 !important; }

.border-right-red.border-right-lighten-1 {
  border-right: 1px solid #EF5350 !important; }

.overlay-red.overlay-lighten-1 {
  background: #EF5350;
  /* The Fallback */
  background: rgba(239, 83, 80, 0.8); }

.red {
  color: #F44336 !important; }

.bg-red {
  background-color: #F44336 !important; }
  .bg-red .card-header, .bg-red .card-footer {
    background-color: transparent; }

.alert-red {
  border-color: #F44336 !important;
  background-color: #f8827a !important;
  color: #710e06 !important; }
  .alert-red .alert-link {
    color: #4b0904 !important; }

.border-red {
  border-color: #F44336; }

.bg-red.tag-glow, .border-red.tag-glow {
  box-shadow: 0px 0px 10px #F44336; }

.overlay-red {
  background: #F44336;
  /* The Fallback */
  background: rgba(244, 67, 54, 0.8); }

.card-outline-red {
  border-width: 1px;
  border-style: solid;
  border-color: #F44336;
  background-color: transparent; }
  .card-outline-red .card-header, .card-outline-red .card-footer {
    background-color: transparent; }

.btn-red {
  border-color: #F44336;
  background-color: #F44336;
  color: #FFFFFF; }
  .btn-red:hover {
    border-color: #E57373;
    background-color: #E57373;
    color: #FFF !important; }

.btn-outline-red {
  border-color: #F44336;
  background-color: transparent;
  color: #F44336; }
  .btn-outline-red:hover {
    background-color: #F44336;
    color: #FFF !important; }

.progress-red[value]::-webkit-progress-value {
  background-color: #F44336; }

.progress-red[value]::-moz-progress-bar {
  background-color: #F44336; }

.progress-red[value]::-ms-fill {
  background-color: #F44336; }

@media screen and (min-width: 0\0) {
  .progress-red .progress-bar {
    background-color: #F44336; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F44336 !important; }

.border-red {
  border: 1px solid #F44336 !important; }

.border-top-red {
  border-top: 1px solid #F44336; }

.border-bottom-red {
  border-bottom: 1px solid #F44336; }

.border-left-red {
  border-left: 1px solid #F44336; }

.border-right-red {
  border-right: 1px solid #F44336; }

.red.darken-1 {
  color: #E53935 !important; }

.bg-red.bg-darken-1 {
  background-color: #E53935 !important; }

.btn-red.btn-darken-1 {
  border-color: #E53935 !important;
  background-color: #E53935 !important; }
  .btn-red.btn-darken-1:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-darken-1 {
  border-color: #E53935 !important;
  color: #E53935 !important; }
  .btn-outline-red.btn-outline-darken-1:hover {
    background-color: #E53935 !important; }

.progress-red.progress-darken-1[value]::-webkit-progress-value {
  background-color: #E53935; }

.progress-red.progress-darken-1[value]::-moz-progress-bar {
  background-color: #E53935; }

.progress-red.progress-darken-1[value]::-ms-fill {
  background-color: #E53935; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-darken-1 .progress-bar {
    background-color: #E53935; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E53935 !important; }

.border-red.border-darken-1 {
  border: 1px solid #E53935 !important; }

.border-top-red.border-top-darken-1 {
  border-top: 1px solid #E53935 !important; }

.border-bottom-red.border-bottom-darken-1 {
  border-bottom: 1px solid #E53935 !important; }

.border-left-red.border-left-darken-1 {
  border-left: 1px solid #E53935 !important; }

.border-right-red.border-right-darken-1 {
  border-right: 1px solid #E53935 !important; }

.overlay-red.overlay-darken-1 {
  background: #E53935;
  /* The Fallback */
  background: rgba(229, 57, 53, 0.8); }

.red.darken-2 {
  color: #D32F2F !important; }

.bg-red.bg-darken-2 {
  background-color: #D32F2F !important; }

.btn-red.btn-darken-2 {
  border-color: #D32F2F !important;
  background-color: #D32F2F !important; }
  .btn-red.btn-darken-2:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-darken-2 {
  border-color: #D32F2F !important;
  color: #D32F2F !important; }
  .btn-outline-red.btn-outline-darken-2:hover {
    background-color: #D32F2F !important; }

.progress-red.progress-darken-2[value]::-webkit-progress-value {
  background-color: #D32F2F; }

.progress-red.progress-darken-2[value]::-moz-progress-bar {
  background-color: #D32F2F; }

.progress-red.progress-darken-2[value]::-ms-fill {
  background-color: #D32F2F; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-darken-2 .progress-bar {
    background-color: #D32F2F; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D32F2F !important; }

.border-red.border-darken-2 {
  border: 1px solid #D32F2F !important; }

.border-top-red.border-top-darken-2 {
  border-top: 1px solid #D32F2F !important; }

.border-bottom-red.border-bottom-darken-2 {
  border-bottom: 1px solid #D32F2F !important; }

.border-left-red.border-left-darken-2 {
  border-left: 1px solid #D32F2F !important; }

.border-right-red.border-right-darken-2 {
  border-right: 1px solid #D32F2F !important; }

.overlay-red.overlay-darken-2 {
  background: #D32F2F;
  /* The Fallback */
  background: rgba(211, 47, 47, 0.8); }

.red.darken-3 {
  color: #C62828 !important; }

.bg-red.bg-darken-3 {
  background-color: #C62828 !important; }

.btn-red.btn-darken-3 {
  border-color: #C62828 !important;
  background-color: #C62828 !important; }
  .btn-red.btn-darken-3:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-darken-3 {
  border-color: #C62828 !important;
  color: #C62828 !important; }
  .btn-outline-red.btn-outline-darken-3:hover {
    background-color: #C62828 !important; }

.progress-red.progress-darken-3[value]::-webkit-progress-value {
  background-color: #C62828; }

.progress-red.progress-darken-3[value]::-moz-progress-bar {
  background-color: #C62828; }

.progress-red.progress-darken-3[value]::-ms-fill {
  background-color: #C62828; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-darken-3 .progress-bar {
    background-color: #C62828; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C62828 !important; }

.border-red.border-darken-3 {
  border: 1px solid #C62828 !important; }

.border-top-red.border-top-darken-3 {
  border-top: 1px solid #C62828 !important; }

.border-bottom-red.border-bottom-darken-3 {
  border-bottom: 1px solid #C62828 !important; }

.border-left-red.border-left-darken-3 {
  border-left: 1px solid #C62828 !important; }

.border-right-red.border-right-darken-3 {
  border-right: 1px solid #C62828 !important; }

.overlay-red.overlay-darken-3 {
  background: #C62828;
  /* The Fallback */
  background: rgba(198, 40, 40, 0.8); }

.red.darken-4 {
  color: #B71C1C !important; }

.bg-red.bg-darken-4 {
  background-color: #B71C1C !important; }

.btn-red.btn-darken-4 {
  border-color: #B71C1C !important;
  background-color: #B71C1C !important; }
  .btn-red.btn-darken-4:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-darken-4 {
  border-color: #B71C1C !important;
  color: #B71C1C !important; }
  .btn-outline-red.btn-outline-darken-4:hover {
    background-color: #B71C1C !important; }

.progress-red.progress-darken-4[value]::-webkit-progress-value {
  background-color: #B71C1C; }

.progress-red.progress-darken-4[value]::-moz-progress-bar {
  background-color: #B71C1C; }

.progress-red.progress-darken-4[value]::-ms-fill {
  background-color: #B71C1C; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-darken-4 .progress-bar {
    background-color: #B71C1C; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B71C1C !important; }

.border-red.border-darken-4 {
  border: 1px solid #B71C1C !important; }

.border-top-red.border-top-darken-4 {
  border-top: 1px solid #B71C1C !important; }

.border-bottom-red.border-bottom-darken-4 {
  border-bottom: 1px solid #B71C1C !important; }

.border-left-red.border-left-darken-4 {
  border-left: 1px solid #B71C1C !important; }

.border-right-red.border-right-darken-4 {
  border-right: 1px solid #B71C1C !important; }

.overlay-red.overlay-darken-4 {
  background: #B71C1C;
  /* The Fallback */
  background: rgba(183, 28, 28, 0.8); }

.red.accent-1 {
  color: #FF8A80 !important; }

.bg-red.bg-accent-1 {
  background-color: #FF8A80 !important; }

.btn-red.btn-accent-1 {
  border-color: #FF8A80 !important;
  background-color: #FF8A80 !important; }
  .btn-red.btn-accent-1:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-accent-1 {
  border-color: #FF8A80 !important;
  color: #FF8A80 !important; }
  .btn-outline-red.btn-outline-accent-1:hover {
    background-color: #FF8A80 !important; }

.progress-red.progress-accent-1[value]::-webkit-progress-value {
  background-color: #FF8A80; }

.progress-red.progress-accent-1[value]::-moz-progress-bar {
  background-color: #FF8A80; }

.progress-red.progress-accent-1[value]::-ms-fill {
  background-color: #FF8A80; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-accent-1 .progress-bar {
    background-color: #FF8A80; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A80 !important; }

.border-red.border-accent-1 {
  border: 1px solid #FF8A80 !important; }

.border-top-red.border-top-accent-1 {
  border-top: 1px solid #FF8A80 !important; }

.border-bottom-red.border-bottom-accent-1 {
  border-bottom: 1px solid #FF8A80 !important; }

.border-left-red.border-left-accent-1 {
  border-left: 1px solid #FF8A80 !important; }

.border-right-red.border-right-accent-1 {
  border-right: 1px solid #FF8A80 !important; }

.overlay-red.overlay-accent-1 {
  background: #FF8A80;
  /* The Fallback */
  background: rgba(255, 138, 128, 0.8); }

.red.accent-2 {
  color: #FF5252 !important; }

.bg-red.bg-accent-2 {
  background-color: #FF5252 !important; }

.btn-red.btn-accent-2 {
  border-color: #FF5252 !important;
  background-color: #FF5252 !important; }
  .btn-red.btn-accent-2:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-accent-2 {
  border-color: #FF5252 !important;
  color: #FF5252 !important; }
  .btn-outline-red.btn-outline-accent-2:hover {
    background-color: #FF5252 !important; }

.progress-red.progress-accent-2[value]::-webkit-progress-value {
  background-color: #FF5252; }

.progress-red.progress-accent-2[value]::-moz-progress-bar {
  background-color: #FF5252; }

.progress-red.progress-accent-2[value]::-ms-fill {
  background-color: #FF5252; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-accent-2 .progress-bar {
    background-color: #FF5252; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5252 !important; }

.border-red.border-accent-2 {
  border: 1px solid #FF5252 !important; }

.border-top-red.border-top-accent-2 {
  border-top: 1px solid #FF5252 !important; }

.border-bottom-red.border-bottom-accent-2 {
  border-bottom: 1px solid #FF5252 !important; }

.border-left-red.border-left-accent-2 {
  border-left: 1px solid #FF5252 !important; }

.border-right-red.border-right-accent-2 {
  border-right: 1px solid #FF5252 !important; }

.overlay-red.overlay-accent-2 {
  background: #FF5252;
  /* The Fallback */
  background: rgba(255, 82, 82, 0.8); }

.red.accent-3 {
  color: #FF1744 !important; }

.bg-red.bg-accent-3 {
  background-color: #FF1744 !important; }

.btn-red.btn-accent-3 {
  border-color: #FF1744 !important;
  background-color: #FF1744 !important; }
  .btn-red.btn-accent-3:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-accent-3 {
  border-color: #FF1744 !important;
  color: #FF1744 !important; }
  .btn-outline-red.btn-outline-accent-3:hover {
    background-color: #FF1744 !important; }

.progress-red.progress-accent-3[value]::-webkit-progress-value {
  background-color: #FF1744; }

.progress-red.progress-accent-3[value]::-moz-progress-bar {
  background-color: #FF1744; }

.progress-red.progress-accent-3[value]::-ms-fill {
  background-color: #FF1744; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-accent-3 .progress-bar {
    background-color: #FF1744; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF1744 !important; }

.border-red.border-accent-3 {
  border: 1px solid #FF1744 !important; }

.border-top-red.border-top-accent-3 {
  border-top: 1px solid #FF1744 !important; }

.border-bottom-red.border-bottom-accent-3 {
  border-bottom: 1px solid #FF1744 !important; }

.border-left-red.border-left-accent-3 {
  border-left: 1px solid #FF1744 !important; }

.border-right-red.border-right-accent-3 {
  border-right: 1px solid #FF1744 !important; }

.overlay-red.overlay-accent-3 {
  background: #FF1744;
  /* The Fallback */
  background: rgba(255, 23, 68, 0.8); }

.red.accent-4 {
  color: #D50000 !important; }

.bg-red.bg-accent-4 {
  background-color: #D50000 !important; }

.btn-red.btn-accent-4 {
  border-color: #D50000 !important;
  background-color: #D50000 !important; }
  .btn-red.btn-accent-4:hover {
    border-color: #E57373 !important;
    background-color: #E57373 !important; }

.btn-outline-red.btn-outline-accent-4 {
  border-color: #D50000 !important;
  color: #D50000 !important; }
  .btn-outline-red.btn-outline-accent-4:hover {
    background-color: #D50000 !important; }

.progress-red.progress-accent-4[value]::-webkit-progress-value {
  background-color: #D50000; }

.progress-red.progress-accent-4[value]::-moz-progress-bar {
  background-color: #D50000; }

.progress-red.progress-accent-4[value]::-ms-fill {
  background-color: #D50000; }

@media screen and (min-width: 0\0) {
  .progress-red.progress-accent-4 .progress-bar {
    background-color: #D50000; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D50000 !important; }

.border-red.border-accent-4 {
  border: 1px solid #D50000 !important; }

.border-top-red.border-top-accent-4 {
  border-top: 1px solid #D50000 !important; }

.border-bottom-red.border-bottom-accent-4 {
  border-bottom: 1px solid #D50000 !important; }

.border-left-red.border-left-accent-4 {
  border-left: 1px solid #D50000 !important; }

.border-right-red.border-right-accent-4 {
  border-right: 1px solid #D50000 !important; }

.overlay-red.overlay-accent-4 {
  background: #D50000;
  /* The Fallback */
  background: rgba(213, 0, 0, 0.8); }

.pink.lighten-5 {
  color: #FCE4EC !important; }

.bg-pink.bg-lighten-5 {
  background-color: #FCE4EC !important; }

.btn-pink.btn-lighten-5 {
  border-color: #FCE4EC !important;
  background-color: #FCE4EC !important; }
  .btn-pink.btn-lighten-5:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-lighten-5 {
  border-color: #FCE4EC !important;
  color: #FCE4EC !important; }
  .btn-outline-pink.btn-outline-lighten-5:hover {
    background-color: #FCE4EC !important; }

.progress-pink.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FCE4EC; }

.progress-pink.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FCE4EC; }

.progress-pink.progress-lighten-5[value]::-ms-fill {
  background-color: #FCE4EC; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-lighten-5 .progress-bar {
    background-color: #FCE4EC; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FCE4EC !important; }

.border-pink.border-lighten-5 {
  border: 1px solid #FCE4EC !important; }

.border-top-pink.border-top-lighten-5 {
  border-top: 1px solid #FCE4EC !important; }

.border-bottom-pink.border-bottom-lighten-5 {
  border-bottom: 1px solid #FCE4EC !important; }

.border-left-pink.border-left-lighten-5 {
  border-left: 1px solid #FCE4EC !important; }

.border-right-pink.border-right-lighten-5 {
  border-right: 1px solid #FCE4EC !important; }

.overlay-pink.overlay-lighten-5 {
  background: #FCE4EC;
  /* The Fallback */
  background: rgba(252, 228, 236, 0.8); }

.pink.lighten-4 {
  color: #F8BBD0 !important; }

.bg-pink.bg-lighten-4 {
  background-color: #F8BBD0 !important; }

.btn-pink.btn-lighten-4 {
  border-color: #F8BBD0 !important;
  background-color: #F8BBD0 !important; }
  .btn-pink.btn-lighten-4:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-lighten-4 {
  border-color: #F8BBD0 !important;
  color: #F8BBD0 !important; }
  .btn-outline-pink.btn-outline-lighten-4:hover {
    background-color: #F8BBD0 !important; }

.progress-pink.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #F8BBD0; }

.progress-pink.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #F8BBD0; }

.progress-pink.progress-lighten-4[value]::-ms-fill {
  background-color: #F8BBD0; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-lighten-4 .progress-bar {
    background-color: #F8BBD0; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F8BBD0 !important; }

.border-pink.border-lighten-4 {
  border: 1px solid #F8BBD0 !important; }

.border-top-pink.border-top-lighten-4 {
  border-top: 1px solid #F8BBD0 !important; }

.border-bottom-pink.border-bottom-lighten-4 {
  border-bottom: 1px solid #F8BBD0 !important; }

.border-left-pink.border-left-lighten-4 {
  border-left: 1px solid #F8BBD0 !important; }

.border-right-pink.border-right-lighten-4 {
  border-right: 1px solid #F8BBD0 !important; }

.overlay-pink.overlay-lighten-4 {
  background: #F8BBD0;
  /* The Fallback */
  background: rgba(248, 187, 208, 0.8); }

.pink.lighten-3 {
  color: #F48FB1 !important; }

.bg-pink.bg-lighten-3 {
  background-color: #F48FB1 !important; }

.btn-pink.btn-lighten-3 {
  border-color: #F48FB1 !important;
  background-color: #F48FB1 !important; }
  .btn-pink.btn-lighten-3:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-lighten-3 {
  border-color: #F48FB1 !important;
  color: #F48FB1 !important; }
  .btn-outline-pink.btn-outline-lighten-3:hover {
    background-color: #F48FB1 !important; }

.progress-pink.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #F48FB1; }

.progress-pink.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #F48FB1; }

.progress-pink.progress-lighten-3[value]::-ms-fill {
  background-color: #F48FB1; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-lighten-3 .progress-bar {
    background-color: #F48FB1; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F48FB1 !important; }

.border-pink.border-lighten-3 {
  border: 1px solid #F48FB1 !important; }

.border-top-pink.border-top-lighten-3 {
  border-top: 1px solid #F48FB1 !important; }

.border-bottom-pink.border-bottom-lighten-3 {
  border-bottom: 1px solid #F48FB1 !important; }

.border-left-pink.border-left-lighten-3 {
  border-left: 1px solid #F48FB1 !important; }

.border-right-pink.border-right-lighten-3 {
  border-right: 1px solid #F48FB1 !important; }

.overlay-pink.overlay-lighten-3 {
  background: #F48FB1;
  /* The Fallback */
  background: rgba(244, 143, 177, 0.8); }

.pink.lighten-2 {
  color: #F06292 !important; }

.bg-pink.bg-lighten-2 {
  background-color: #F06292 !important; }

.btn-pink.btn-lighten-2 {
  border-color: #F06292 !important;
  background-color: #F06292 !important; }
  .btn-pink.btn-lighten-2:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-lighten-2 {
  border-color: #F06292 !important;
  color: #F06292 !important; }
  .btn-outline-pink.btn-outline-lighten-2:hover {
    background-color: #F06292 !important; }

.progress-pink.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #F06292; }

.progress-pink.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #F06292; }

.progress-pink.progress-lighten-2[value]::-ms-fill {
  background-color: #F06292; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-lighten-2 .progress-bar {
    background-color: #F06292; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F06292 !important; }

.border-pink.border-lighten-2 {
  border: 1px solid #F06292 !important; }

.border-top-pink.border-top-lighten-2 {
  border-top: 1px solid #F06292 !important; }

.border-bottom-pink.border-bottom-lighten-2 {
  border-bottom: 1px solid #F06292 !important; }

.border-left-pink.border-left-lighten-2 {
  border-left: 1px solid #F06292 !important; }

.border-right-pink.border-right-lighten-2 {
  border-right: 1px solid #F06292 !important; }

.overlay-pink.overlay-lighten-2 {
  background: #F06292;
  /* The Fallback */
  background: rgba(240, 98, 146, 0.8); }

.pink.lighten-1 {
  color: #EC407A !important; }

.bg-pink.bg-lighten-1 {
  background-color: #EC407A !important; }

.btn-pink.btn-lighten-1 {
  border-color: #EC407A !important;
  background-color: #EC407A !important; }
  .btn-pink.btn-lighten-1:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-lighten-1 {
  border-color: #EC407A !important;
  color: #EC407A !important; }
  .btn-outline-pink.btn-outline-lighten-1:hover {
    background-color: #EC407A !important; }

.progress-pink.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #EC407A; }

.progress-pink.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #EC407A; }

.progress-pink.progress-lighten-1[value]::-ms-fill {
  background-color: #EC407A; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-lighten-1 .progress-bar {
    background-color: #EC407A; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EC407A !important; }

.border-pink.border-lighten-1 {
  border: 1px solid #EC407A !important; }

.border-top-pink.border-top-lighten-1 {
  border-top: 1px solid #EC407A !important; }

.border-bottom-pink.border-bottom-lighten-1 {
  border-bottom: 1px solid #EC407A !important; }

.border-left-pink.border-left-lighten-1 {
  border-left: 1px solid #EC407A !important; }

.border-right-pink.border-right-lighten-1 {
  border-right: 1px solid #EC407A !important; }

.overlay-pink.overlay-lighten-1 {
  background: #EC407A;
  /* The Fallback */
  background: rgba(236, 64, 122, 0.8); }

.pink {
  color: #E91E63 !important; }

.bg-pink {
  background-color: #E91E63 !important; }
  .bg-pink .card-header, .bg-pink .card-footer {
    background-color: transparent; }

.alert-pink {
  border-color: #E91E63 !important;
  background-color: #ef5f90 !important;
  color: #4d081f !important; }
  .alert-pink .alert-link {
    color: #280410 !important; }

.border-pink {
  border-color: #E91E63; }

.bg-pink.tag-glow, .border-pink.tag-glow {
  box-shadow: 0px 0px 10px #E91E63; }

.overlay-pink {
  background: #E91E63;
  /* The Fallback */
  background: rgba(233, 30, 99, 0.8); }

.card-outline-pink {
  border-width: 1px;
  border-style: solid;
  border-color: #E91E63;
  background-color: transparent; }
  .card-outline-pink .card-header, .card-outline-pink .card-footer {
    background-color: transparent; }

.btn-pink {
  border-color: #E91E63;
  background-color: #E91E63;
  color: #FFFFFF; }
  .btn-pink:hover {
    border-color: #F06292;
    background-color: #F06292;
    color: #FFF !important; }

.btn-outline-pink {
  border-color: #E91E63;
  background-color: transparent;
  color: #E91E63; }
  .btn-outline-pink:hover {
    background-color: #E91E63;
    color: #FFF !important; }

.progress-pink[value]::-webkit-progress-value {
  background-color: #E91E63; }

.progress-pink[value]::-moz-progress-bar {
  background-color: #E91E63; }

.progress-pink[value]::-ms-fill {
  background-color: #E91E63; }

@media screen and (min-width: 0\0) {
  .progress-pink .progress-bar {
    background-color: #E91E63; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E91E63 !important; }

.border-pink {
  border: 1px solid #E91E63 !important; }

.border-top-pink {
  border-top: 1px solid #E91E63; }

.border-bottom-pink {
  border-bottom: 1px solid #E91E63; }

.border-left-pink {
  border-left: 1px solid #E91E63; }

.border-right-pink {
  border-right: 1px solid #E91E63; }

.pink.darken-1 {
  color: #D81B60 !important; }

.bg-pink.bg-darken-1 {
  background-color: #D81B60 !important; }

.btn-pink.btn-darken-1 {
  border-color: #D81B60 !important;
  background-color: #D81B60 !important; }
  .btn-pink.btn-darken-1:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-darken-1 {
  border-color: #D81B60 !important;
  color: #D81B60 !important; }
  .btn-outline-pink.btn-outline-darken-1:hover {
    background-color: #D81B60 !important; }

.progress-pink.progress-darken-1[value]::-webkit-progress-value {
  background-color: #D81B60; }

.progress-pink.progress-darken-1[value]::-moz-progress-bar {
  background-color: #D81B60; }

.progress-pink.progress-darken-1[value]::-ms-fill {
  background-color: #D81B60; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-darken-1 .progress-bar {
    background-color: #D81B60; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D81B60 !important; }

.border-pink.border-darken-1 {
  border: 1px solid #D81B60 !important; }

.border-top-pink.border-top-darken-1 {
  border-top: 1px solid #D81B60 !important; }

.border-bottom-pink.border-bottom-darken-1 {
  border-bottom: 1px solid #D81B60 !important; }

.border-left-pink.border-left-darken-1 {
  border-left: 1px solid #D81B60 !important; }

.border-right-pink.border-right-darken-1 {
  border-right: 1px solid #D81B60 !important; }

.overlay-pink.overlay-darken-1 {
  background: #D81B60;
  /* The Fallback */
  background: rgba(216, 27, 96, 0.8); }

.pink.darken-2 {
  color: #C2185B !important; }

.bg-pink.bg-darken-2 {
  background-color: #C2185B !important; }

.btn-pink.btn-darken-2 {
  border-color: #C2185B !important;
  background-color: #C2185B !important; }
  .btn-pink.btn-darken-2:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-darken-2 {
  border-color: #C2185B !important;
  color: #C2185B !important; }
  .btn-outline-pink.btn-outline-darken-2:hover {
    background-color: #C2185B !important; }

.progress-pink.progress-darken-2[value]::-webkit-progress-value {
  background-color: #C2185B; }

.progress-pink.progress-darken-2[value]::-moz-progress-bar {
  background-color: #C2185B; }

.progress-pink.progress-darken-2[value]::-ms-fill {
  background-color: #C2185B; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-darken-2 .progress-bar {
    background-color: #C2185B; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C2185B !important; }

.border-pink.border-darken-2 {
  border: 1px solid #C2185B !important; }

.border-top-pink.border-top-darken-2 {
  border-top: 1px solid #C2185B !important; }

.border-bottom-pink.border-bottom-darken-2 {
  border-bottom: 1px solid #C2185B !important; }

.border-left-pink.border-left-darken-2 {
  border-left: 1px solid #C2185B !important; }

.border-right-pink.border-right-darken-2 {
  border-right: 1px solid #C2185B !important; }

.overlay-pink.overlay-darken-2 {
  background: #C2185B;
  /* The Fallback */
  background: rgba(194, 24, 91, 0.8); }

.pink.darken-3 {
  color: #AD1457 !important; }

.bg-pink.bg-darken-3 {
  background-color: #AD1457 !important; }

.btn-pink.btn-darken-3 {
  border-color: #AD1457 !important;
  background-color: #AD1457 !important; }
  .btn-pink.btn-darken-3:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-darken-3 {
  border-color: #AD1457 !important;
  color: #AD1457 !important; }
  .btn-outline-pink.btn-outline-darken-3:hover {
    background-color: #AD1457 !important; }

.progress-pink.progress-darken-3[value]::-webkit-progress-value {
  background-color: #AD1457; }

.progress-pink.progress-darken-3[value]::-moz-progress-bar {
  background-color: #AD1457; }

.progress-pink.progress-darken-3[value]::-ms-fill {
  background-color: #AD1457; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-darken-3 .progress-bar {
    background-color: #AD1457; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AD1457 !important; }

.border-pink.border-darken-3 {
  border: 1px solid #AD1457 !important; }

.border-top-pink.border-top-darken-3 {
  border-top: 1px solid #AD1457 !important; }

.border-bottom-pink.border-bottom-darken-3 {
  border-bottom: 1px solid #AD1457 !important; }

.border-left-pink.border-left-darken-3 {
  border-left: 1px solid #AD1457 !important; }

.border-right-pink.border-right-darken-3 {
  border-right: 1px solid #AD1457 !important; }

.overlay-pink.overlay-darken-3 {
  background: #AD1457;
  /* The Fallback */
  background: rgba(173, 20, 87, 0.8); }

.pink.darken-4 {
  color: #880E4F !important; }

.bg-pink.bg-darken-4 {
  background-color: #880E4F !important; }

.btn-pink.btn-darken-4 {
  border-color: #880E4F !important;
  background-color: #880E4F !important; }
  .btn-pink.btn-darken-4:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-darken-4 {
  border-color: #880E4F !important;
  color: #880E4F !important; }
  .btn-outline-pink.btn-outline-darken-4:hover {
    background-color: #880E4F !important; }

.progress-pink.progress-darken-4[value]::-webkit-progress-value {
  background-color: #880E4F; }

.progress-pink.progress-darken-4[value]::-moz-progress-bar {
  background-color: #880E4F; }

.progress-pink.progress-darken-4[value]::-ms-fill {
  background-color: #880E4F; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-darken-4 .progress-bar {
    background-color: #880E4F; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #880E4F !important; }

.border-pink.border-darken-4 {
  border: 1px solid #880E4F !important; }

.border-top-pink.border-top-darken-4 {
  border-top: 1px solid #880E4F !important; }

.border-bottom-pink.border-bottom-darken-4 {
  border-bottom: 1px solid #880E4F !important; }

.border-left-pink.border-left-darken-4 {
  border-left: 1px solid #880E4F !important; }

.border-right-pink.border-right-darken-4 {
  border-right: 1px solid #880E4F !important; }

.overlay-pink.overlay-darken-4 {
  background: #880E4F;
  /* The Fallback */
  background: rgba(136, 14, 79, 0.8); }

.pink.accent-1 {
  color: #FF80AB !important; }

.bg-pink.bg-accent-1 {
  background-color: #FF80AB !important; }

.btn-pink.btn-accent-1 {
  border-color: #FF80AB !important;
  background-color: #FF80AB !important; }
  .btn-pink.btn-accent-1:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-accent-1 {
  border-color: #FF80AB !important;
  color: #FF80AB !important; }
  .btn-outline-pink.btn-outline-accent-1:hover {
    background-color: #FF80AB !important; }

.progress-pink.progress-accent-1[value]::-webkit-progress-value {
  background-color: #FF80AB; }

.progress-pink.progress-accent-1[value]::-moz-progress-bar {
  background-color: #FF80AB; }

.progress-pink.progress-accent-1[value]::-ms-fill {
  background-color: #FF80AB; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-accent-1 .progress-bar {
    background-color: #FF80AB; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF80AB !important; }

.border-pink.border-accent-1 {
  border: 1px solid #FF80AB !important; }

.border-top-pink.border-top-accent-1 {
  border-top: 1px solid #FF80AB !important; }

.border-bottom-pink.border-bottom-accent-1 {
  border-bottom: 1px solid #FF80AB !important; }

.border-left-pink.border-left-accent-1 {
  border-left: 1px solid #FF80AB !important; }

.border-right-pink.border-right-accent-1 {
  border-right: 1px solid #FF80AB !important; }

.overlay-pink.overlay-accent-1 {
  background: #FF80AB;
  /* The Fallback */
  background: rgba(255, 128, 171, 0.8); }

.pink.accent-2 {
  color: #FF4081 !important; }

.bg-pink.bg-accent-2 {
  background-color: #FF4081 !important; }

.btn-pink.btn-accent-2 {
  border-color: #FF4081 !important;
  background-color: #FF4081 !important; }
  .btn-pink.btn-accent-2:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-accent-2 {
  border-color: #FF4081 !important;
  color: #FF4081 !important; }
  .btn-outline-pink.btn-outline-accent-2:hover {
    background-color: #FF4081 !important; }

.progress-pink.progress-accent-2[value]::-webkit-progress-value {
  background-color: #FF4081; }

.progress-pink.progress-accent-2[value]::-moz-progress-bar {
  background-color: #FF4081; }

.progress-pink.progress-accent-2[value]::-ms-fill {
  background-color: #FF4081; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-accent-2 .progress-bar {
    background-color: #FF4081; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4081 !important; }

.border-pink.border-accent-2 {
  border: 1px solid #FF4081 !important; }

.border-top-pink.border-top-accent-2 {
  border-top: 1px solid #FF4081 !important; }

.border-bottom-pink.border-bottom-accent-2 {
  border-bottom: 1px solid #FF4081 !important; }

.border-left-pink.border-left-accent-2 {
  border-left: 1px solid #FF4081 !important; }

.border-right-pink.border-right-accent-2 {
  border-right: 1px solid #FF4081 !important; }

.overlay-pink.overlay-accent-2 {
  background: #FF4081;
  /* The Fallback */
  background: rgba(255, 64, 129, 0.8); }

.pink.accent-3 {
  color: #F50057 !important; }

.bg-pink.bg-accent-3 {
  background-color: #F50057 !important; }

.btn-pink.btn-accent-3 {
  border-color: #F50057 !important;
  background-color: #F50057 !important; }
  .btn-pink.btn-accent-3:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-accent-3 {
  border-color: #F50057 !important;
  color: #F50057 !important; }
  .btn-outline-pink.btn-outline-accent-3:hover {
    background-color: #F50057 !important; }

.progress-pink.progress-accent-3[value]::-webkit-progress-value {
  background-color: #F50057; }

.progress-pink.progress-accent-3[value]::-moz-progress-bar {
  background-color: #F50057; }

.progress-pink.progress-accent-3[value]::-ms-fill {
  background-color: #F50057; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-accent-3 .progress-bar {
    background-color: #F50057; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F50057 !important; }

.border-pink.border-accent-3 {
  border: 1px solid #F50057 !important; }

.border-top-pink.border-top-accent-3 {
  border-top: 1px solid #F50057 !important; }

.border-bottom-pink.border-bottom-accent-3 {
  border-bottom: 1px solid #F50057 !important; }

.border-left-pink.border-left-accent-3 {
  border-left: 1px solid #F50057 !important; }

.border-right-pink.border-right-accent-3 {
  border-right: 1px solid #F50057 !important; }

.overlay-pink.overlay-accent-3 {
  background: #F50057;
  /* The Fallback */
  background: rgba(245, 0, 87, 0.8); }

.pink.accent-4 {
  color: #C51162 !important; }

.bg-pink.bg-accent-4 {
  background-color: #C51162 !important; }

.btn-pink.btn-accent-4 {
  border-color: #C51162 !important;
  background-color: #C51162 !important; }
  .btn-pink.btn-accent-4:hover {
    border-color: #F06292 !important;
    background-color: #F06292 !important; }

.btn-outline-pink.btn-outline-accent-4 {
  border-color: #C51162 !important;
  color: #C51162 !important; }
  .btn-outline-pink.btn-outline-accent-4:hover {
    background-color: #C51162 !important; }

.progress-pink.progress-accent-4[value]::-webkit-progress-value {
  background-color: #C51162; }

.progress-pink.progress-accent-4[value]::-moz-progress-bar {
  background-color: #C51162; }

.progress-pink.progress-accent-4[value]::-ms-fill {
  background-color: #C51162; }

@media screen and (min-width: 0\0) {
  .progress-pink.progress-accent-4 .progress-bar {
    background-color: #C51162; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C51162 !important; }

.border-pink.border-accent-4 {
  border: 1px solid #C51162 !important; }

.border-top-pink.border-top-accent-4 {
  border-top: 1px solid #C51162 !important; }

.border-bottom-pink.border-bottom-accent-4 {
  border-bottom: 1px solid #C51162 !important; }

.border-left-pink.border-left-accent-4 {
  border-left: 1px solid #C51162 !important; }

.border-right-pink.border-right-accent-4 {
  border-right: 1px solid #C51162 !important; }

.overlay-pink.overlay-accent-4 {
  background: #C51162;
  /* The Fallback */
  background: rgba(197, 17, 98, 0.8); }

.purple.lighten-5 {
  color: #F3E5F5 !important; }

.bg-purple.bg-lighten-5 {
  background-color: #F3E5F5 !important; }

.btn-purple.btn-lighten-5 {
  border-color: #F3E5F5 !important;
  background-color: #F3E5F5 !important; }
  .btn-purple.btn-lighten-5:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-lighten-5 {
  border-color: #F3E5F5 !important;
  color: #F3E5F5 !important; }
  .btn-outline-purple.btn-outline-lighten-5:hover {
    background-color: #F3E5F5 !important; }

.progress-purple.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #F3E5F5; }

.progress-purple.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #F3E5F5; }

.progress-purple.progress-lighten-5[value]::-ms-fill {
  background-color: #F3E5F5; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-lighten-5 .progress-bar {
    background-color: #F3E5F5; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F3E5F5 !important; }

.border-purple.border-lighten-5 {
  border: 1px solid #F3E5F5 !important; }

.border-top-purple.border-top-lighten-5 {
  border-top: 1px solid #F3E5F5 !important; }

.border-bottom-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #F3E5F5 !important; }

.border-left-purple.border-left-lighten-5 {
  border-left: 1px solid #F3E5F5 !important; }

.border-right-purple.border-right-lighten-5 {
  border-right: 1px solid #F3E5F5 !important; }

.overlay-purple.overlay-lighten-5 {
  background: #F3E5F5;
  /* The Fallback */
  background: rgba(243, 229, 245, 0.8); }

.purple.lighten-4 {
  color: #E1BEE7 !important; }

.bg-purple.bg-lighten-4 {
  background-color: #E1BEE7 !important; }

.btn-purple.btn-lighten-4 {
  border-color: #E1BEE7 !important;
  background-color: #E1BEE7 !important; }
  .btn-purple.btn-lighten-4:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-lighten-4 {
  border-color: #E1BEE7 !important;
  color: #E1BEE7 !important; }
  .btn-outline-purple.btn-outline-lighten-4:hover {
    background-color: #E1BEE7 !important; }

.progress-purple.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #E1BEE7; }

.progress-purple.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #E1BEE7; }

.progress-purple.progress-lighten-4[value]::-ms-fill {
  background-color: #E1BEE7; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-lighten-4 .progress-bar {
    background-color: #E1BEE7; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1BEE7 !important; }

.border-purple.border-lighten-4 {
  border: 1px solid #E1BEE7 !important; }

.border-top-purple.border-top-lighten-4 {
  border-top: 1px solid #E1BEE7 !important; }

.border-bottom-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #E1BEE7 !important; }

.border-left-purple.border-left-lighten-4 {
  border-left: 1px solid #E1BEE7 !important; }

.border-right-purple.border-right-lighten-4 {
  border-right: 1px solid #E1BEE7 !important; }

.overlay-purple.overlay-lighten-4 {
  background: #E1BEE7;
  /* The Fallback */
  background: rgba(225, 190, 231, 0.8); }

.purple.lighten-3 {
  color: #CE93D8 !important; }

.bg-purple.bg-lighten-3 {
  background-color: #CE93D8 !important; }

.btn-purple.btn-lighten-3 {
  border-color: #CE93D8 !important;
  background-color: #CE93D8 !important; }
  .btn-purple.btn-lighten-3:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-lighten-3 {
  border-color: #CE93D8 !important;
  color: #CE93D8 !important; }
  .btn-outline-purple.btn-outline-lighten-3:hover {
    background-color: #CE93D8 !important; }

.progress-purple.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #CE93D8; }

.progress-purple.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #CE93D8; }

.progress-purple.progress-lighten-3[value]::-ms-fill {
  background-color: #CE93D8; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-lighten-3 .progress-bar {
    background-color: #CE93D8; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CE93D8 !important; }

.border-purple.border-lighten-3 {
  border: 1px solid #CE93D8 !important; }

.border-top-purple.border-top-lighten-3 {
  border-top: 1px solid #CE93D8 !important; }

.border-bottom-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #CE93D8 !important; }

.border-left-purple.border-left-lighten-3 {
  border-left: 1px solid #CE93D8 !important; }

.border-right-purple.border-right-lighten-3 {
  border-right: 1px solid #CE93D8 !important; }

.overlay-purple.overlay-lighten-3 {
  background: #CE93D8;
  /* The Fallback */
  background: rgba(206, 147, 216, 0.8); }

.purple.lighten-2 {
  color: #BA68C8 !important; }

.bg-purple.bg-lighten-2 {
  background-color: #BA68C8 !important; }

.btn-purple.btn-lighten-2 {
  border-color: #BA68C8 !important;
  background-color: #BA68C8 !important; }
  .btn-purple.btn-lighten-2:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-lighten-2 {
  border-color: #BA68C8 !important;
  color: #BA68C8 !important; }
  .btn-outline-purple.btn-outline-lighten-2:hover {
    background-color: #BA68C8 !important; }

.progress-purple.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #BA68C8; }

.progress-purple.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #BA68C8; }

.progress-purple.progress-lighten-2[value]::-ms-fill {
  background-color: #BA68C8; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-lighten-2 .progress-bar {
    background-color: #BA68C8; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BA68C8 !important; }

.border-purple.border-lighten-2 {
  border: 1px solid #BA68C8 !important; }

.border-top-purple.border-top-lighten-2 {
  border-top: 1px solid #BA68C8 !important; }

.border-bottom-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #BA68C8 !important; }

.border-left-purple.border-left-lighten-2 {
  border-left: 1px solid #BA68C8 !important; }

.border-right-purple.border-right-lighten-2 {
  border-right: 1px solid #BA68C8 !important; }

.overlay-purple.overlay-lighten-2 {
  background: #BA68C8;
  /* The Fallback */
  background: rgba(186, 104, 200, 0.8); }

.purple.lighten-1 {
  color: #AB47BC !important; }

.bg-purple.bg-lighten-1 {
  background-color: #AB47BC !important; }

.btn-purple.btn-lighten-1 {
  border-color: #AB47BC !important;
  background-color: #AB47BC !important; }
  .btn-purple.btn-lighten-1:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-lighten-1 {
  border-color: #AB47BC !important;
  color: #AB47BC !important; }
  .btn-outline-purple.btn-outline-lighten-1:hover {
    background-color: #AB47BC !important; }

.progress-purple.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #AB47BC; }

.progress-purple.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #AB47BC; }

.progress-purple.progress-lighten-1[value]::-ms-fill {
  background-color: #AB47BC; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-lighten-1 .progress-bar {
    background-color: #AB47BC; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AB47BC !important; }

.border-purple.border-lighten-1 {
  border: 1px solid #AB47BC !important; }

.border-top-purple.border-top-lighten-1 {
  border-top: 1px solid #AB47BC !important; }

.border-bottom-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #AB47BC !important; }

.border-left-purple.border-left-lighten-1 {
  border-left: 1px solid #AB47BC !important; }

.border-right-purple.border-right-lighten-1 {
  border-right: 1px solid #AB47BC !important; }

.overlay-purple.overlay-lighten-1 {
  background: #AB47BC;
  /* The Fallback */
  background: rgba(171, 71, 188, 0.8); }

.purple {
  color: #9C27B0 !important; }

.bg-purple {
  background-color: #9C27B0 !important; }
  .bg-purple .card-header, .bg-purple .card-footer {
    background-color: transparent; }

.alert-purple {
  border-color: #9C27B0 !important;
  background-color: #c248d6 !important;
  color: #1a071e !important; }
  .alert-purple .alert-link {
    color: black !important; }

.border-purple {
  border-color: #9C27B0; }

.bg-purple.tag-glow, .border-purple.tag-glow {
  box-shadow: 0px 0px 10px #9C27B0; }

.overlay-purple {
  background: #9C27B0;
  /* The Fallback */
  background: rgba(156, 39, 176, 0.8); }

.card-outline-purple {
  border-width: 1px;
  border-style: solid;
  border-color: #9C27B0;
  background-color: transparent; }
  .card-outline-purple .card-header, .card-outline-purple .card-footer {
    background-color: transparent; }

.btn-purple {
  border-color: #9C27B0;
  background-color: #9C27B0;
  color: #FFFFFF; }
  .btn-purple:hover {
    border-color: #BA68C8;
    background-color: #BA68C8;
    color: #FFF !important; }

.btn-outline-purple {
  border-color: #9C27B0;
  background-color: transparent;
  color: #9C27B0; }
  .btn-outline-purple:hover {
    background-color: #9C27B0;
    color: #FFF !important; }

.progress-purple[value]::-webkit-progress-value {
  background-color: #9C27B0; }

.progress-purple[value]::-moz-progress-bar {
  background-color: #9C27B0; }

.progress-purple[value]::-ms-fill {
  background-color: #9C27B0; }

@media screen and (min-width: 0\0) {
  .progress-purple .progress-bar {
    background-color: #9C27B0; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9C27B0 !important; }

.border-purple {
  border: 1px solid #9C27B0 !important; }

.border-top-purple {
  border-top: 1px solid #9C27B0; }

.border-bottom-purple {
  border-bottom: 1px solid #9C27B0; }

.border-left-purple {
  border-left: 1px solid #9C27B0; }

.border-right-purple {
  border-right: 1px solid #9C27B0; }

.purple.darken-1 {
  color: #8E24AA !important; }

.bg-purple.bg-darken-1 {
  background-color: #8E24AA !important; }

.btn-purple.btn-darken-1 {
  border-color: #8E24AA !important;
  background-color: #8E24AA !important; }
  .btn-purple.btn-darken-1:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-darken-1 {
  border-color: #8E24AA !important;
  color: #8E24AA !important; }
  .btn-outline-purple.btn-outline-darken-1:hover {
    background-color: #8E24AA !important; }

.progress-purple.progress-darken-1[value]::-webkit-progress-value {
  background-color: #8E24AA; }

.progress-purple.progress-darken-1[value]::-moz-progress-bar {
  background-color: #8E24AA; }

.progress-purple.progress-darken-1[value]::-ms-fill {
  background-color: #8E24AA; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-darken-1 .progress-bar {
    background-color: #8E24AA; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8E24AA !important; }

.border-purple.border-darken-1 {
  border: 1px solid #8E24AA !important; }

.border-top-purple.border-top-darken-1 {
  border-top: 1px solid #8E24AA !important; }

.border-bottom-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #8E24AA !important; }

.border-left-purple.border-left-darken-1 {
  border-left: 1px solid #8E24AA !important; }

.border-right-purple.border-right-darken-1 {
  border-right: 1px solid #8E24AA !important; }

.overlay-purple.overlay-darken-1 {
  background: #8E24AA;
  /* The Fallback */
  background: rgba(142, 36, 170, 0.8); }

.purple.darken-2 {
  color: #7B1FA2 !important; }

.bg-purple.bg-darken-2 {
  background-color: #7B1FA2 !important; }

.btn-purple.btn-darken-2 {
  border-color: #7B1FA2 !important;
  background-color: #7B1FA2 !important; }
  .btn-purple.btn-darken-2:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-darken-2 {
  border-color: #7B1FA2 !important;
  color: #7B1FA2 !important; }
  .btn-outline-purple.btn-outline-darken-2:hover {
    background-color: #7B1FA2 !important; }

.progress-purple.progress-darken-2[value]::-webkit-progress-value {
  background-color: #7B1FA2; }

.progress-purple.progress-darken-2[value]::-moz-progress-bar {
  background-color: #7B1FA2; }

.progress-purple.progress-darken-2[value]::-ms-fill {
  background-color: #7B1FA2; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-darken-2 .progress-bar {
    background-color: #7B1FA2; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7B1FA2 !important; }

.border-purple.border-darken-2 {
  border: 1px solid #7B1FA2 !important; }

.border-top-purple.border-top-darken-2 {
  border-top: 1px solid #7B1FA2 !important; }

.border-bottom-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #7B1FA2 !important; }

.border-left-purple.border-left-darken-2 {
  border-left: 1px solid #7B1FA2 !important; }

.border-right-purple.border-right-darken-2 {
  border-right: 1px solid #7B1FA2 !important; }

.overlay-purple.overlay-darken-2 {
  background: #7B1FA2;
  /* The Fallback */
  background: rgba(123, 31, 162, 0.8); }

.purple.darken-3 {
  color: #6A1B9A !important; }

.bg-purple.bg-darken-3 {
  background-color: #6A1B9A !important; }

.btn-purple.btn-darken-3 {
  border-color: #6A1B9A !important;
  background-color: #6A1B9A !important; }
  .btn-purple.btn-darken-3:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-darken-3 {
  border-color: #6A1B9A !important;
  color: #6A1B9A !important; }
  .btn-outline-purple.btn-outline-darken-3:hover {
    background-color: #6A1B9A !important; }

.progress-purple.progress-darken-3[value]::-webkit-progress-value {
  background-color: #6A1B9A; }

.progress-purple.progress-darken-3[value]::-moz-progress-bar {
  background-color: #6A1B9A; }

.progress-purple.progress-darken-3[value]::-ms-fill {
  background-color: #6A1B9A; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-darken-3 .progress-bar {
    background-color: #6A1B9A; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6A1B9A !important; }

.border-purple.border-darken-3 {
  border: 1px solid #6A1B9A !important; }

.border-top-purple.border-top-darken-3 {
  border-top: 1px solid #6A1B9A !important; }

.border-bottom-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #6A1B9A !important; }

.border-left-purple.border-left-darken-3 {
  border-left: 1px solid #6A1B9A !important; }

.border-right-purple.border-right-darken-3 {
  border-right: 1px solid #6A1B9A !important; }

.overlay-purple.overlay-darken-3 {
  background: #6A1B9A;
  /* The Fallback */
  background: rgba(106, 27, 154, 0.8); }

.purple.darken-4 {
  color: #4A148C !important; }

.bg-purple.bg-darken-4 {
  background-color: #4A148C !important; }

.btn-purple.btn-darken-4 {
  border-color: #4A148C !important;
  background-color: #4A148C !important; }
  .btn-purple.btn-darken-4:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-darken-4 {
  border-color: #4A148C !important;
  color: #4A148C !important; }
  .btn-outline-purple.btn-outline-darken-4:hover {
    background-color: #4A148C !important; }

.progress-purple.progress-darken-4[value]::-webkit-progress-value {
  background-color: #4A148C; }

.progress-purple.progress-darken-4[value]::-moz-progress-bar {
  background-color: #4A148C; }

.progress-purple.progress-darken-4[value]::-ms-fill {
  background-color: #4A148C; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-darken-4 .progress-bar {
    background-color: #4A148C; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4A148C !important; }

.border-purple.border-darken-4 {
  border: 1px solid #4A148C !important; }

.border-top-purple.border-top-darken-4 {
  border-top: 1px solid #4A148C !important; }

.border-bottom-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #4A148C !important; }

.border-left-purple.border-left-darken-4 {
  border-left: 1px solid #4A148C !important; }

.border-right-purple.border-right-darken-4 {
  border-right: 1px solid #4A148C !important; }

.overlay-purple.overlay-darken-4 {
  background: #4A148C;
  /* The Fallback */
  background: rgba(74, 20, 140, 0.8); }

.purple.accent-1 {
  color: #EA80FC !important; }

.bg-purple.bg-accent-1 {
  background-color: #EA80FC !important; }

.btn-purple.btn-accent-1 {
  border-color: #EA80FC !important;
  background-color: #EA80FC !important; }
  .btn-purple.btn-accent-1:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-accent-1 {
  border-color: #EA80FC !important;
  color: #EA80FC !important; }
  .btn-outline-purple.btn-outline-accent-1:hover {
    background-color: #EA80FC !important; }

.progress-purple.progress-accent-1[value]::-webkit-progress-value {
  background-color: #EA80FC; }

.progress-purple.progress-accent-1[value]::-moz-progress-bar {
  background-color: #EA80FC; }

.progress-purple.progress-accent-1[value]::-ms-fill {
  background-color: #EA80FC; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-accent-1 .progress-bar {
    background-color: #EA80FC; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EA80FC !important; }

.border-purple.border-accent-1 {
  border: 1px solid #EA80FC !important; }

.border-top-purple.border-top-accent-1 {
  border-top: 1px solid #EA80FC !important; }

.border-bottom-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #EA80FC !important; }

.border-left-purple.border-left-accent-1 {
  border-left: 1px solid #EA80FC !important; }

.border-right-purple.border-right-accent-1 {
  border-right: 1px solid #EA80FC !important; }

.overlay-purple.overlay-accent-1 {
  background: #EA80FC;
  /* The Fallback */
  background: rgba(234, 128, 252, 0.8); }

.purple.accent-2 {
  color: #E040FB !important; }

.bg-purple.bg-accent-2 {
  background-color: #E040FB !important; }

.btn-purple.btn-accent-2 {
  border-color: #E040FB !important;
  background-color: #E040FB !important; }
  .btn-purple.btn-accent-2:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-accent-2 {
  border-color: #E040FB !important;
  color: #E040FB !important; }
  .btn-outline-purple.btn-outline-accent-2:hover {
    background-color: #E040FB !important; }

.progress-purple.progress-accent-2[value]::-webkit-progress-value {
  background-color: #E040FB; }

.progress-purple.progress-accent-2[value]::-moz-progress-bar {
  background-color: #E040FB; }

.progress-purple.progress-accent-2[value]::-ms-fill {
  background-color: #E040FB; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-accent-2 .progress-bar {
    background-color: #E040FB; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E040FB !important; }

.border-purple.border-accent-2 {
  border: 1px solid #E040FB !important; }

.border-top-purple.border-top-accent-2 {
  border-top: 1px solid #E040FB !important; }

.border-bottom-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #E040FB !important; }

.border-left-purple.border-left-accent-2 {
  border-left: 1px solid #E040FB !important; }

.border-right-purple.border-right-accent-2 {
  border-right: 1px solid #E040FB !important; }

.overlay-purple.overlay-accent-2 {
  background: #E040FB;
  /* The Fallback */
  background: rgba(224, 64, 251, 0.8); }

.purple.accent-3 {
  color: #D500F9 !important; }

.bg-purple.bg-accent-3 {
  background-color: #D500F9 !important; }

.btn-purple.btn-accent-3 {
  border-color: #D500F9 !important;
  background-color: #D500F9 !important; }
  .btn-purple.btn-accent-3:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-accent-3 {
  border-color: #D500F9 !important;
  color: #D500F9 !important; }
  .btn-outline-purple.btn-outline-accent-3:hover {
    background-color: #D500F9 !important; }

.progress-purple.progress-accent-3[value]::-webkit-progress-value {
  background-color: #D500F9; }

.progress-purple.progress-accent-3[value]::-moz-progress-bar {
  background-color: #D500F9; }

.progress-purple.progress-accent-3[value]::-ms-fill {
  background-color: #D500F9; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-accent-3 .progress-bar {
    background-color: #D500F9; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D500F9 !important; }

.border-purple.border-accent-3 {
  border: 1px solid #D500F9 !important; }

.border-top-purple.border-top-accent-3 {
  border-top: 1px solid #D500F9 !important; }

.border-bottom-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #D500F9 !important; }

.border-left-purple.border-left-accent-3 {
  border-left: 1px solid #D500F9 !important; }

.border-right-purple.border-right-accent-3 {
  border-right: 1px solid #D500F9 !important; }

.overlay-purple.overlay-accent-3 {
  background: #D500F9;
  /* The Fallback */
  background: rgba(213, 0, 249, 0.8); }

.purple.accent-4 {
  color: #DD00FF !important; }

.bg-purple.bg-accent-4 {
  background-color: #DD00FF !important; }

.btn-purple.btn-accent-4 {
  border-color: #DD00FF !important;
  background-color: #DD00FF !important; }
  .btn-purple.btn-accent-4:hover {
    border-color: #BA68C8 !important;
    background-color: #BA68C8 !important; }

.btn-outline-purple.btn-outline-accent-4 {
  border-color: #DD00FF !important;
  color: #DD00FF !important; }
  .btn-outline-purple.btn-outline-accent-4:hover {
    background-color: #DD00FF !important; }

.progress-purple.progress-accent-4[value]::-webkit-progress-value {
  background-color: #DD00FF; }

.progress-purple.progress-accent-4[value]::-moz-progress-bar {
  background-color: #DD00FF; }

.progress-purple.progress-accent-4[value]::-ms-fill {
  background-color: #DD00FF; }

@media screen and (min-width: 0\0) {
  .progress-purple.progress-accent-4 .progress-bar {
    background-color: #DD00FF; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DD00FF !important; }

.border-purple.border-accent-4 {
  border: 1px solid #DD00FF !important; }

.border-top-purple.border-top-accent-4 {
  border-top: 1px solid #DD00FF !important; }

.border-bottom-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #DD00FF !important; }

.border-left-purple.border-left-accent-4 {
  border-left: 1px solid #DD00FF !important; }

.border-right-purple.border-right-accent-4 {
  border-right: 1px solid #DD00FF !important; }

.overlay-purple.overlay-accent-4 {
  background: #DD00FF;
  /* The Fallback */
  background: rgba(221, 0, 255, 0.8); }

.deep-purple.lighten-5 {
  color: #EDE7F6 !important; }

.bg-deep-purple.bg-lighten-5 {
  background-color: #EDE7F6 !important; }

.btn-deep-purple.btn-lighten-5 {
  border-color: #EDE7F6 !important;
  background-color: #EDE7F6 !important; }
  .btn-deep-purple.btn-lighten-5:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-lighten-5 {
  border-color: #EDE7F6 !important;
  color: #EDE7F6 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-5:hover {
    background-color: #EDE7F6 !important; }

.progress-deep-purple.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #EDE7F6; }

.progress-deep-purple.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #EDE7F6; }

.progress-deep-purple.progress-lighten-5[value]::-ms-fill {
  background-color: #EDE7F6; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-lighten-5 .progress-bar {
    background-color: #EDE7F6; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EDE7F6 !important; }

.border-deep-purple.border-lighten-5 {
  border: 1px solid #EDE7F6 !important; }

.border-top-deep-purple.border-top-lighten-5 {
  border-top: 1px solid #EDE7F6 !important; }

.border-bottom-deep-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #EDE7F6 !important; }

.border-left-deep-purple.border-left-lighten-5 {
  border-left: 1px solid #EDE7F6 !important; }

.border-right-deep-purple.border-right-lighten-5 {
  border-right: 1px solid #EDE7F6 !important; }

.overlay-deep-purple.overlay-lighten-5 {
  background: #EDE7F6;
  /* The Fallback */
  background: rgba(237, 231, 246, 0.8); }

.deep-purple.lighten-4 {
  color: #D1C4E9 !important; }

.bg-deep-purple.bg-lighten-4 {
  background-color: #D1C4E9 !important; }

.btn-deep-purple.btn-lighten-4 {
  border-color: #D1C4E9 !important;
  background-color: #D1C4E9 !important; }
  .btn-deep-purple.btn-lighten-4:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-lighten-4 {
  border-color: #D1C4E9 !important;
  color: #D1C4E9 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-4:hover {
    background-color: #D1C4E9 !important; }

.progress-deep-purple.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #D1C4E9; }

.progress-deep-purple.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #D1C4E9; }

.progress-deep-purple.progress-lighten-4[value]::-ms-fill {
  background-color: #D1C4E9; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-lighten-4 .progress-bar {
    background-color: #D1C4E9; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D1C4E9 !important; }

.border-deep-purple.border-lighten-4 {
  border: 1px solid #D1C4E9 !important; }

.border-top-deep-purple.border-top-lighten-4 {
  border-top: 1px solid #D1C4E9 !important; }

.border-bottom-deep-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #D1C4E9 !important; }

.border-left-deep-purple.border-left-lighten-4 {
  border-left: 1px solid #D1C4E9 !important; }

.border-right-deep-purple.border-right-lighten-4 {
  border-right: 1px solid #D1C4E9 !important; }

.overlay-deep-purple.overlay-lighten-4 {
  background: #D1C4E9;
  /* The Fallback */
  background: rgba(209, 196, 233, 0.8); }

.deep-purple.lighten-3 {
  color: #B39DDB !important; }

.bg-deep-purple.bg-lighten-3 {
  background-color: #B39DDB !important; }

.btn-deep-purple.btn-lighten-3 {
  border-color: #B39DDB !important;
  background-color: #B39DDB !important; }
  .btn-deep-purple.btn-lighten-3:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-lighten-3 {
  border-color: #B39DDB !important;
  color: #B39DDB !important; }
  .btn-outline-deep-purple.btn-outline-lighten-3:hover {
    background-color: #B39DDB !important; }

.progress-deep-purple.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #B39DDB; }

.progress-deep-purple.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #B39DDB; }

.progress-deep-purple.progress-lighten-3[value]::-ms-fill {
  background-color: #B39DDB; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-lighten-3 .progress-bar {
    background-color: #B39DDB; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B39DDB !important; }

.border-deep-purple.border-lighten-3 {
  border: 1px solid #B39DDB !important; }

.border-top-deep-purple.border-top-lighten-3 {
  border-top: 1px solid #B39DDB !important; }

.border-bottom-deep-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #B39DDB !important; }

.border-left-deep-purple.border-left-lighten-3 {
  border-left: 1px solid #B39DDB !important; }

.border-right-deep-purple.border-right-lighten-3 {
  border-right: 1px solid #B39DDB !important; }

.overlay-deep-purple.overlay-lighten-3 {
  background: #B39DDB;
  /* The Fallback */
  background: rgba(179, 157, 219, 0.8); }

.deep-purple.lighten-2 {
  color: #9575CD !important; }

.bg-deep-purple.bg-lighten-2 {
  background-color: #9575CD !important; }

.btn-deep-purple.btn-lighten-2 {
  border-color: #9575CD !important;
  background-color: #9575CD !important; }
  .btn-deep-purple.btn-lighten-2:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-lighten-2 {
  border-color: #9575CD !important;
  color: #9575CD !important; }
  .btn-outline-deep-purple.btn-outline-lighten-2:hover {
    background-color: #9575CD !important; }

.progress-deep-purple.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #9575CD; }

.progress-deep-purple.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #9575CD; }

.progress-deep-purple.progress-lighten-2[value]::-ms-fill {
  background-color: #9575CD; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-lighten-2 .progress-bar {
    background-color: #9575CD; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9575CD !important; }

.border-deep-purple.border-lighten-2 {
  border: 1px solid #9575CD !important; }

.border-top-deep-purple.border-top-lighten-2 {
  border-top: 1px solid #9575CD !important; }

.border-bottom-deep-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #9575CD !important; }

.border-left-deep-purple.border-left-lighten-2 {
  border-left: 1px solid #9575CD !important; }

.border-right-deep-purple.border-right-lighten-2 {
  border-right: 1px solid #9575CD !important; }

.overlay-deep-purple.overlay-lighten-2 {
  background: #9575CD;
  /* The Fallback */
  background: rgba(149, 117, 205, 0.8); }

.deep-purple.lighten-1 {
  color: #7E57C2 !important; }

.bg-deep-purple.bg-lighten-1 {
  background-color: #7E57C2 !important; }

.btn-deep-purple.btn-lighten-1 {
  border-color: #7E57C2 !important;
  background-color: #7E57C2 !important; }
  .btn-deep-purple.btn-lighten-1:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-lighten-1 {
  border-color: #7E57C2 !important;
  color: #7E57C2 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-1:hover {
    background-color: #7E57C2 !important; }

.progress-deep-purple.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #7E57C2; }

.progress-deep-purple.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #7E57C2; }

.progress-deep-purple.progress-lighten-1[value]::-ms-fill {
  background-color: #7E57C2; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-lighten-1 .progress-bar {
    background-color: #7E57C2; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7E57C2 !important; }

.border-deep-purple.border-lighten-1 {
  border: 1px solid #7E57C2 !important; }

.border-top-deep-purple.border-top-lighten-1 {
  border-top: 1px solid #7E57C2 !important; }

.border-bottom-deep-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #7E57C2 !important; }

.border-left-deep-purple.border-left-lighten-1 {
  border-left: 1px solid #7E57C2 !important; }

.border-right-deep-purple.border-right-lighten-1 {
  border-right: 1px solid #7E57C2 !important; }

.overlay-deep-purple.overlay-lighten-1 {
  background: #7E57C2;
  /* The Fallback */
  background: rgba(126, 87, 194, 0.8); }

.deep-purple {
  color: #673AB7 !important; }

.bg-deep-purple {
  background-color: #673AB7 !important; }
  .bg-deep-purple .card-header, .bg-deep-purple .card-footer {
    background-color: transparent; }

.alert-deep-purple {
  border-color: #673AB7 !important;
  background-color: #8e69cf !important;
  color: #1b0f2f !important; }
  .alert-deep-purple .alert-link {
    color: #090510 !important; }

.border-deep-purple {
  border-color: #673AB7; }

.bg-deep-purple.tag-glow, .border-deep-purple.tag-glow {
  box-shadow: 0px 0px 10px #673AB7; }

.overlay-deep-purple {
  background: #673AB7;
  /* The Fallback */
  background: rgba(103, 58, 183, 0.8); }

.card-outline-deep-purple {
  border-width: 1px;
  border-style: solid;
  border-color: #673AB7;
  background-color: transparent; }
  .card-outline-deep-purple .card-header, .card-outline-deep-purple .card-footer {
    background-color: transparent; }

.btn-deep-purple {
  border-color: #673AB7;
  background-color: #673AB7;
  color: #FFFFFF; }
  .btn-deep-purple:hover {
    border-color: #9575CD;
    background-color: #9575CD;
    color: #FFF !important; }

.btn-outline-deep-purple {
  border-color: #673AB7;
  background-color: transparent;
  color: #673AB7; }
  .btn-outline-deep-purple:hover {
    background-color: #673AB7;
    color: #FFF !important; }

.progress-deep-purple[value]::-webkit-progress-value {
  background-color: #673AB7; }

.progress-deep-purple[value]::-moz-progress-bar {
  background-color: #673AB7; }

.progress-deep-purple[value]::-ms-fill {
  background-color: #673AB7; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple .progress-bar {
    background-color: #673AB7; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #673AB7 !important; }

.border-deep-purple {
  border: 1px solid #673AB7 !important; }

.border-top-deep-purple {
  border-top: 1px solid #673AB7; }

.border-bottom-deep-purple {
  border-bottom: 1px solid #673AB7; }

.border-left-deep-purple {
  border-left: 1px solid #673AB7; }

.border-right-deep-purple {
  border-right: 1px solid #673AB7; }

.deep-purple.darken-1 {
  color: #5E35B1 !important; }

.bg-deep-purple.bg-darken-1 {
  background-color: #5E35B1 !important; }

.btn-deep-purple.btn-darken-1 {
  border-color: #5E35B1 !important;
  background-color: #5E35B1 !important; }
  .btn-deep-purple.btn-darken-1:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-darken-1 {
  border-color: #5E35B1 !important;
  color: #5E35B1 !important; }
  .btn-outline-deep-purple.btn-outline-darken-1:hover {
    background-color: #5E35B1 !important; }

.progress-deep-purple.progress-darken-1[value]::-webkit-progress-value {
  background-color: #5E35B1; }

.progress-deep-purple.progress-darken-1[value]::-moz-progress-bar {
  background-color: #5E35B1; }

.progress-deep-purple.progress-darken-1[value]::-ms-fill {
  background-color: #5E35B1; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-darken-1 .progress-bar {
    background-color: #5E35B1; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5E35B1 !important; }

.border-deep-purple.border-darken-1 {
  border: 1px solid #5E35B1 !important; }

.border-top-deep-purple.border-top-darken-1 {
  border-top: 1px solid #5E35B1 !important; }

.border-bottom-deep-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #5E35B1 !important; }

.border-left-deep-purple.border-left-darken-1 {
  border-left: 1px solid #5E35B1 !important; }

.border-right-deep-purple.border-right-darken-1 {
  border-right: 1px solid #5E35B1 !important; }

.overlay-deep-purple.overlay-darken-1 {
  background: #5E35B1;
  /* The Fallback */
  background: rgba(94, 53, 177, 0.8); }

.deep-purple.darken-2 {
  color: #512DA8 !important; }

.bg-deep-purple.bg-darken-2 {
  background-color: #512DA8 !important; }

.btn-deep-purple.btn-darken-2 {
  border-color: #512DA8 !important;
  background-color: #512DA8 !important; }
  .btn-deep-purple.btn-darken-2:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-darken-2 {
  border-color: #512DA8 !important;
  color: #512DA8 !important; }
  .btn-outline-deep-purple.btn-outline-darken-2:hover {
    background-color: #512DA8 !important; }

.progress-deep-purple.progress-darken-2[value]::-webkit-progress-value {
  background-color: #512DA8; }

.progress-deep-purple.progress-darken-2[value]::-moz-progress-bar {
  background-color: #512DA8; }

.progress-deep-purple.progress-darken-2[value]::-ms-fill {
  background-color: #512DA8; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-darken-2 .progress-bar {
    background-color: #512DA8; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #512DA8 !important; }

.border-deep-purple.border-darken-2 {
  border: 1px solid #512DA8 !important; }

.border-top-deep-purple.border-top-darken-2 {
  border-top: 1px solid #512DA8 !important; }

.border-bottom-deep-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #512DA8 !important; }

.border-left-deep-purple.border-left-darken-2 {
  border-left: 1px solid #512DA8 !important; }

.border-right-deep-purple.border-right-darken-2 {
  border-right: 1px solid #512DA8 !important; }

.overlay-deep-purple.overlay-darken-2 {
  background: #512DA8;
  /* The Fallback */
  background: rgba(81, 45, 168, 0.8); }

.deep-purple.darken-3 {
  color: #4527A0 !important; }

.bg-deep-purple.bg-darken-3 {
  background-color: #4527A0 !important; }

.btn-deep-purple.btn-darken-3 {
  border-color: #4527A0 !important;
  background-color: #4527A0 !important; }
  .btn-deep-purple.btn-darken-3:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-darken-3 {
  border-color: #4527A0 !important;
  color: #4527A0 !important; }
  .btn-outline-deep-purple.btn-outline-darken-3:hover {
    background-color: #4527A0 !important; }

.progress-deep-purple.progress-darken-3[value]::-webkit-progress-value {
  background-color: #4527A0; }

.progress-deep-purple.progress-darken-3[value]::-moz-progress-bar {
  background-color: #4527A0; }

.progress-deep-purple.progress-darken-3[value]::-ms-fill {
  background-color: #4527A0; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-darken-3 .progress-bar {
    background-color: #4527A0; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4527A0 !important; }

.border-deep-purple.border-darken-3 {
  border: 1px solid #4527A0 !important; }

.border-top-deep-purple.border-top-darken-3 {
  border-top: 1px solid #4527A0 !important; }

.border-bottom-deep-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #4527A0 !important; }

.border-left-deep-purple.border-left-darken-3 {
  border-left: 1px solid #4527A0 !important; }

.border-right-deep-purple.border-right-darken-3 {
  border-right: 1px solid #4527A0 !important; }

.overlay-deep-purple.overlay-darken-3 {
  background: #4527A0;
  /* The Fallback */
  background: rgba(69, 39, 160, 0.8); }

.deep-purple.darken-4 {
  color: #311B92 !important; }

.bg-deep-purple.bg-darken-4 {
  background-color: #311B92 !important; }

.btn-deep-purple.btn-darken-4 {
  border-color: #311B92 !important;
  background-color: #311B92 !important; }
  .btn-deep-purple.btn-darken-4:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-darken-4 {
  border-color: #311B92 !important;
  color: #311B92 !important; }
  .btn-outline-deep-purple.btn-outline-darken-4:hover {
    background-color: #311B92 !important; }

.progress-deep-purple.progress-darken-4[value]::-webkit-progress-value {
  background-color: #311B92; }

.progress-deep-purple.progress-darken-4[value]::-moz-progress-bar {
  background-color: #311B92; }

.progress-deep-purple.progress-darken-4[value]::-ms-fill {
  background-color: #311B92; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-darken-4 .progress-bar {
    background-color: #311B92; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #311B92 !important; }

.border-deep-purple.border-darken-4 {
  border: 1px solid #311B92 !important; }

.border-top-deep-purple.border-top-darken-4 {
  border-top: 1px solid #311B92 !important; }

.border-bottom-deep-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #311B92 !important; }

.border-left-deep-purple.border-left-darken-4 {
  border-left: 1px solid #311B92 !important; }

.border-right-deep-purple.border-right-darken-4 {
  border-right: 1px solid #311B92 !important; }

.overlay-deep-purple.overlay-darken-4 {
  background: #311B92;
  /* The Fallback */
  background: rgba(49, 27, 146, 0.8); }

.deep-purple.accent-1 {
  color: #B388FF !important; }

.bg-deep-purple.bg-accent-1 {
  background-color: #B388FF !important; }

.btn-deep-purple.btn-accent-1 {
  border-color: #B388FF !important;
  background-color: #B388FF !important; }
  .btn-deep-purple.btn-accent-1:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-accent-1 {
  border-color: #B388FF !important;
  color: #B388FF !important; }
  .btn-outline-deep-purple.btn-outline-accent-1:hover {
    background-color: #B388FF !important; }

.progress-deep-purple.progress-accent-1[value]::-webkit-progress-value {
  background-color: #B388FF; }

.progress-deep-purple.progress-accent-1[value]::-moz-progress-bar {
  background-color: #B388FF; }

.progress-deep-purple.progress-accent-1[value]::-ms-fill {
  background-color: #B388FF; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-accent-1 .progress-bar {
    background-color: #B388FF; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B388FF !important; }

.border-deep-purple.border-accent-1 {
  border: 1px solid #B388FF !important; }

.border-top-deep-purple.border-top-accent-1 {
  border-top: 1px solid #B388FF !important; }

.border-bottom-deep-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #B388FF !important; }

.border-left-deep-purple.border-left-accent-1 {
  border-left: 1px solid #B388FF !important; }

.border-right-deep-purple.border-right-accent-1 {
  border-right: 1px solid #B388FF !important; }

.overlay-deep-purple.overlay-accent-1 {
  background: #B388FF;
  /* The Fallback */
  background: rgba(179, 136, 255, 0.8); }

.deep-purple.accent-2 {
  color: #7C4DFF !important; }

.bg-deep-purple.bg-accent-2 {
  background-color: #7C4DFF !important; }

.btn-deep-purple.btn-accent-2 {
  border-color: #7C4DFF !important;
  background-color: #7C4DFF !important; }
  .btn-deep-purple.btn-accent-2:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-accent-2 {
  border-color: #7C4DFF !important;
  color: #7C4DFF !important; }
  .btn-outline-deep-purple.btn-outline-accent-2:hover {
    background-color: #7C4DFF !important; }

.progress-deep-purple.progress-accent-2[value]::-webkit-progress-value {
  background-color: #7C4DFF; }

.progress-deep-purple.progress-accent-2[value]::-moz-progress-bar {
  background-color: #7C4DFF; }

.progress-deep-purple.progress-accent-2[value]::-ms-fill {
  background-color: #7C4DFF; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-accent-2 .progress-bar {
    background-color: #7C4DFF; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7C4DFF !important; }

.border-deep-purple.border-accent-2 {
  border: 1px solid #7C4DFF !important; }

.border-top-deep-purple.border-top-accent-2 {
  border-top: 1px solid #7C4DFF !important; }

.border-bottom-deep-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #7C4DFF !important; }

.border-left-deep-purple.border-left-accent-2 {
  border-left: 1px solid #7C4DFF !important; }

.border-right-deep-purple.border-right-accent-2 {
  border-right: 1px solid #7C4DFF !important; }

.overlay-deep-purple.overlay-accent-2 {
  background: #7C4DFF;
  /* The Fallback */
  background: rgba(124, 77, 255, 0.8); }

.deep-purple.accent-3 {
  color: #651FFF !important; }

.bg-deep-purple.bg-accent-3 {
  background-color: #651FFF !important; }

.btn-deep-purple.btn-accent-3 {
  border-color: #651FFF !important;
  background-color: #651FFF !important; }
  .btn-deep-purple.btn-accent-3:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-accent-3 {
  border-color: #651FFF !important;
  color: #651FFF !important; }
  .btn-outline-deep-purple.btn-outline-accent-3:hover {
    background-color: #651FFF !important; }

.progress-deep-purple.progress-accent-3[value]::-webkit-progress-value {
  background-color: #651FFF; }

.progress-deep-purple.progress-accent-3[value]::-moz-progress-bar {
  background-color: #651FFF; }

.progress-deep-purple.progress-accent-3[value]::-ms-fill {
  background-color: #651FFF; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-accent-3 .progress-bar {
    background-color: #651FFF; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #651FFF !important; }

.border-deep-purple.border-accent-3 {
  border: 1px solid #651FFF !important; }

.border-top-deep-purple.border-top-accent-3 {
  border-top: 1px solid #651FFF !important; }

.border-bottom-deep-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #651FFF !important; }

.border-left-deep-purple.border-left-accent-3 {
  border-left: 1px solid #651FFF !important; }

.border-right-deep-purple.border-right-accent-3 {
  border-right: 1px solid #651FFF !important; }

.overlay-deep-purple.overlay-accent-3 {
  background: #651FFF;
  /* The Fallback */
  background: rgba(101, 31, 255, 0.8); }

.deep-purple.accent-4 {
  color: #6200EA !important; }

.bg-deep-purple.bg-accent-4 {
  background-color: #6200EA !important; }

.btn-deep-purple.btn-accent-4 {
  border-color: #6200EA !important;
  background-color: #6200EA !important; }
  .btn-deep-purple.btn-accent-4:hover {
    border-color: #9575CD !important;
    background-color: #9575CD !important; }

.btn-outline-deep-purple.btn-outline-accent-4 {
  border-color: #6200EA !important;
  color: #6200EA !important; }
  .btn-outline-deep-purple.btn-outline-accent-4:hover {
    background-color: #6200EA !important; }

.progress-deep-purple.progress-accent-4[value]::-webkit-progress-value {
  background-color: #6200EA; }

.progress-deep-purple.progress-accent-4[value]::-moz-progress-bar {
  background-color: #6200EA; }

.progress-deep-purple.progress-accent-4[value]::-ms-fill {
  background-color: #6200EA; }

@media screen and (min-width: 0\0) {
  .progress-deep-purple.progress-accent-4 .progress-bar {
    background-color: #6200EA; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6200EA !important; }

.border-deep-purple.border-accent-4 {
  border: 1px solid #6200EA !important; }

.border-top-deep-purple.border-top-accent-4 {
  border-top: 1px solid #6200EA !important; }

.border-bottom-deep-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #6200EA !important; }

.border-left-deep-purple.border-left-accent-4 {
  border-left: 1px solid #6200EA !important; }

.border-right-deep-purple.border-right-accent-4 {
  border-right: 1px solid #6200EA !important; }

.overlay-deep-purple.overlay-accent-4 {
  background: #6200EA;
  /* The Fallback */
  background: rgba(98, 0, 234, 0.8); }

.indigo.lighten-5 {
  color: #E8EAF6 !important; }

.bg-indigo.bg-lighten-5 {
  background-color: #E8EAF6 !important; }

.btn-indigo.btn-lighten-5 {
  border-color: #E8EAF6 !important;
  background-color: #E8EAF6 !important; }
  .btn-indigo.btn-lighten-5:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-lighten-5 {
  border-color: #E8EAF6 !important;
  color: #E8EAF6 !important; }
  .btn-outline-indigo.btn-outline-lighten-5:hover {
    background-color: #E8EAF6 !important; }

.progress-indigo.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #E8EAF6; }

.progress-indigo.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #E8EAF6; }

.progress-indigo.progress-lighten-5[value]::-ms-fill {
  background-color: #E8EAF6; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-lighten-5 .progress-bar {
    background-color: #E8EAF6; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E8EAF6 !important; }

.border-indigo.border-lighten-5 {
  border: 1px solid #E8EAF6 !important; }

.border-top-indigo.border-top-lighten-5 {
  border-top: 1px solid #E8EAF6 !important; }

.border-bottom-indigo.border-bottom-lighten-5 {
  border-bottom: 1px solid #E8EAF6 !important; }

.border-left-indigo.border-left-lighten-5 {
  border-left: 1px solid #E8EAF6 !important; }

.border-right-indigo.border-right-lighten-5 {
  border-right: 1px solid #E8EAF6 !important; }

.overlay-indigo.overlay-lighten-5 {
  background: #E8EAF6;
  /* The Fallback */
  background: rgba(232, 234, 246, 0.8); }

.indigo.lighten-4 {
  color: #C5CAE9 !important; }

.bg-indigo.bg-lighten-4 {
  background-color: #C5CAE9 !important; }

.btn-indigo.btn-lighten-4 {
  border-color: #C5CAE9 !important;
  background-color: #C5CAE9 !important; }
  .btn-indigo.btn-lighten-4:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-lighten-4 {
  border-color: #C5CAE9 !important;
  color: #C5CAE9 !important; }
  .btn-outline-indigo.btn-outline-lighten-4:hover {
    background-color: #C5CAE9 !important; }

.progress-indigo.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #C5CAE9; }

.progress-indigo.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #C5CAE9; }

.progress-indigo.progress-lighten-4[value]::-ms-fill {
  background-color: #C5CAE9; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-lighten-4 .progress-bar {
    background-color: #C5CAE9; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C5CAE9 !important; }

.border-indigo.border-lighten-4 {
  border: 1px solid #C5CAE9 !important; }

.border-top-indigo.border-top-lighten-4 {
  border-top: 1px solid #C5CAE9 !important; }

.border-bottom-indigo.border-bottom-lighten-4 {
  border-bottom: 1px solid #C5CAE9 !important; }

.border-left-indigo.border-left-lighten-4 {
  border-left: 1px solid #C5CAE9 !important; }

.border-right-indigo.border-right-lighten-4 {
  border-right: 1px solid #C5CAE9 !important; }

.overlay-indigo.overlay-lighten-4 {
  background: #C5CAE9;
  /* The Fallback */
  background: rgba(197, 202, 233, 0.8); }

.indigo.lighten-3 {
  color: #9FA8DA !important; }

.bg-indigo.bg-lighten-3 {
  background-color: #9FA8DA !important; }

.btn-indigo.btn-lighten-3 {
  border-color: #9FA8DA !important;
  background-color: #9FA8DA !important; }
  .btn-indigo.btn-lighten-3:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-lighten-3 {
  border-color: #9FA8DA !important;
  color: #9FA8DA !important; }
  .btn-outline-indigo.btn-outline-lighten-3:hover {
    background-color: #9FA8DA !important; }

.progress-indigo.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #9FA8DA; }

.progress-indigo.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #9FA8DA; }

.progress-indigo.progress-lighten-3[value]::-ms-fill {
  background-color: #9FA8DA; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-lighten-3 .progress-bar {
    background-color: #9FA8DA; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9FA8DA !important; }

.border-indigo.border-lighten-3 {
  border: 1px solid #9FA8DA !important; }

.border-top-indigo.border-top-lighten-3 {
  border-top: 1px solid #9FA8DA !important; }

.border-bottom-indigo.border-bottom-lighten-3 {
  border-bottom: 1px solid #9FA8DA !important; }

.border-left-indigo.border-left-lighten-3 {
  border-left: 1px solid #9FA8DA !important; }

.border-right-indigo.border-right-lighten-3 {
  border-right: 1px solid #9FA8DA !important; }

.overlay-indigo.overlay-lighten-3 {
  background: #9FA8DA;
  /* The Fallback */
  background: rgba(159, 168, 218, 0.8); }

.indigo.lighten-2 {
  color: #7986CB !important; }

.bg-indigo.bg-lighten-2 {
  background-color: #7986CB !important; }

.btn-indigo.btn-lighten-2 {
  border-color: #7986CB !important;
  background-color: #7986CB !important; }
  .btn-indigo.btn-lighten-2:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-lighten-2 {
  border-color: #7986CB !important;
  color: #7986CB !important; }
  .btn-outline-indigo.btn-outline-lighten-2:hover {
    background-color: #7986CB !important; }

.progress-indigo.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #7986CB; }

.progress-indigo.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #7986CB; }

.progress-indigo.progress-lighten-2[value]::-ms-fill {
  background-color: #7986CB; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-lighten-2 .progress-bar {
    background-color: #7986CB; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7986CB !important; }

.border-indigo.border-lighten-2 {
  border: 1px solid #7986CB !important; }

.border-top-indigo.border-top-lighten-2 {
  border-top: 1px solid #7986CB !important; }

.border-bottom-indigo.border-bottom-lighten-2 {
  border-bottom: 1px solid #7986CB !important; }

.border-left-indigo.border-left-lighten-2 {
  border-left: 1px solid #7986CB !important; }

.border-right-indigo.border-right-lighten-2 {
  border-right: 1px solid #7986CB !important; }

.overlay-indigo.overlay-lighten-2 {
  background: #7986CB;
  /* The Fallback */
  background: rgba(121, 134, 203, 0.8); }

.indigo.lighten-1 {
  color: #5C6BC0 !important; }

.bg-indigo.bg-lighten-1 {
  background-color: #5C6BC0 !important; }

.btn-indigo.btn-lighten-1 {
  border-color: #5C6BC0 !important;
  background-color: #5C6BC0 !important; }
  .btn-indigo.btn-lighten-1:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-lighten-1 {
  border-color: #5C6BC0 !important;
  color: #5C6BC0 !important; }
  .btn-outline-indigo.btn-outline-lighten-1:hover {
    background-color: #5C6BC0 !important; }

.progress-indigo.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #5C6BC0; }

.progress-indigo.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #5C6BC0; }

.progress-indigo.progress-lighten-1[value]::-ms-fill {
  background-color: #5C6BC0; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-lighten-1 .progress-bar {
    background-color: #5C6BC0; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5C6BC0 !important; }

.border-indigo.border-lighten-1 {
  border: 1px solid #5C6BC0 !important; }

.border-top-indigo.border-top-lighten-1 {
  border-top: 1px solid #5C6BC0 !important; }

.border-bottom-indigo.border-bottom-lighten-1 {
  border-bottom: 1px solid #5C6BC0 !important; }

.border-left-indigo.border-left-lighten-1 {
  border-left: 1px solid #5C6BC0 !important; }

.border-right-indigo.border-right-lighten-1 {
  border-right: 1px solid #5C6BC0 !important; }

.overlay-indigo.overlay-lighten-1 {
  background: #5C6BC0;
  /* The Fallback */
  background: rgba(92, 107, 192, 0.8); }

.indigo {
  color: #3F51B5 !important; }

.bg-indigo {
  background-color: #3F51B5 !important; }
  .bg-indigo .card-header, .bg-indigo .card-footer {
    background-color: transparent; }

.alert-indigo {
  border-color: #3F51B5 !important;
  background-color: #6f7dcd !important;
  color: #111631 !important; }
  .alert-indigo .alert-link {
    color: #060812 !important; }

.border-indigo {
  border-color: #3F51B5; }

.bg-indigo.tag-glow, .border-indigo.tag-glow {
  box-shadow: 0px 0px 10px #3F51B5; }

.overlay-indigo {
  background: #3F51B5;
  /* The Fallback */
  background: rgba(63, 81, 181, 0.8); }

.card-outline-indigo {
  border-width: 1px;
  border-style: solid;
  border-color: #3F51B5;
  background-color: transparent; }
  .card-outline-indigo .card-header, .card-outline-indigo .card-footer {
    background-color: transparent; }

.btn-indigo {
  border-color: #3F51B5;
  background-color: #3F51B5;
  color: #FFFFFF; }
  .btn-indigo:hover {
    border-color: #7986CB;
    background-color: #7986CB;
    color: #FFF !important; }

.btn-outline-indigo {
  border-color: #3F51B5;
  background-color: transparent;
  color: #3F51B5; }
  .btn-outline-indigo:hover {
    background-color: #3F51B5;
    color: #FFF !important; }

.progress-indigo[value]::-webkit-progress-value {
  background-color: #3F51B5; }

.progress-indigo[value]::-moz-progress-bar {
  background-color: #3F51B5; }

.progress-indigo[value]::-ms-fill {
  background-color: #3F51B5; }

@media screen and (min-width: 0\0) {
  .progress-indigo .progress-bar {
    background-color: #3F51B5; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3F51B5 !important; }

.border-indigo {
  border: 1px solid #3F51B5 !important; }

.border-top-indigo {
  border-top: 1px solid #3F51B5; }

.border-bottom-indigo {
  border-bottom: 1px solid #3F51B5; }

.border-left-indigo {
  border-left: 1px solid #3F51B5; }

.border-right-indigo {
  border-right: 1px solid #3F51B5; }

.indigo.darken-1 {
  color: #3949AB !important; }

.bg-indigo.bg-darken-1 {
  background-color: #3949AB !important; }

.btn-indigo.btn-darken-1 {
  border-color: #3949AB !important;
  background-color: #3949AB !important; }
  .btn-indigo.btn-darken-1:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-darken-1 {
  border-color: #3949AB !important;
  color: #3949AB !important; }
  .btn-outline-indigo.btn-outline-darken-1:hover {
    background-color: #3949AB !important; }

.progress-indigo.progress-darken-1[value]::-webkit-progress-value {
  background-color: #3949AB; }

.progress-indigo.progress-darken-1[value]::-moz-progress-bar {
  background-color: #3949AB; }

.progress-indigo.progress-darken-1[value]::-ms-fill {
  background-color: #3949AB; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-darken-1 .progress-bar {
    background-color: #3949AB; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3949AB !important; }

.border-indigo.border-darken-1 {
  border: 1px solid #3949AB !important; }

.border-top-indigo.border-top-darken-1 {
  border-top: 1px solid #3949AB !important; }

.border-bottom-indigo.border-bottom-darken-1 {
  border-bottom: 1px solid #3949AB !important; }

.border-left-indigo.border-left-darken-1 {
  border-left: 1px solid #3949AB !important; }

.border-right-indigo.border-right-darken-1 {
  border-right: 1px solid #3949AB !important; }

.overlay-indigo.overlay-darken-1 {
  background: #3949AB;
  /* The Fallback */
  background: rgba(57, 73, 171, 0.8); }

.indigo.darken-2 {
  color: #303F9F !important; }

.bg-indigo.bg-darken-2 {
  background-color: #303F9F !important; }

.btn-indigo.btn-darken-2 {
  border-color: #303F9F !important;
  background-color: #303F9F !important; }
  .btn-indigo.btn-darken-2:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-darken-2 {
  border-color: #303F9F !important;
  color: #303F9F !important; }
  .btn-outline-indigo.btn-outline-darken-2:hover {
    background-color: #303F9F !important; }

.progress-indigo.progress-darken-2[value]::-webkit-progress-value {
  background-color: #303F9F; }

.progress-indigo.progress-darken-2[value]::-moz-progress-bar {
  background-color: #303F9F; }

.progress-indigo.progress-darken-2[value]::-ms-fill {
  background-color: #303F9F; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-darken-2 .progress-bar {
    background-color: #303F9F; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #303F9F !important; }

.border-indigo.border-darken-2 {
  border: 1px solid #303F9F !important; }

.border-top-indigo.border-top-darken-2 {
  border-top: 1px solid #303F9F !important; }

.border-bottom-indigo.border-bottom-darken-2 {
  border-bottom: 1px solid #303F9F !important; }

.border-left-indigo.border-left-darken-2 {
  border-left: 1px solid #303F9F !important; }

.border-right-indigo.border-right-darken-2 {
  border-right: 1px solid #303F9F !important; }

.overlay-indigo.overlay-darken-2 {
  background: #303F9F;
  /* The Fallback */
  background: rgba(48, 63, 159, 0.8); }

.indigo.darken-3 {
  color: #283593 !important; }

.bg-indigo.bg-darken-3 {
  background-color: #283593 !important; }

.btn-indigo.btn-darken-3 {
  border-color: #283593 !important;
  background-color: #283593 !important; }
  .btn-indigo.btn-darken-3:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-darken-3 {
  border-color: #283593 !important;
  color: #283593 !important; }
  .btn-outline-indigo.btn-outline-darken-3:hover {
    background-color: #283593 !important; }

.progress-indigo.progress-darken-3[value]::-webkit-progress-value {
  background-color: #283593; }

.progress-indigo.progress-darken-3[value]::-moz-progress-bar {
  background-color: #283593; }

.progress-indigo.progress-darken-3[value]::-ms-fill {
  background-color: #283593; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-darken-3 .progress-bar {
    background-color: #283593; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #283593 !important; }

.border-indigo.border-darken-3 {
  border: 1px solid #283593 !important; }

.border-top-indigo.border-top-darken-3 {
  border-top: 1px solid #283593 !important; }

.border-bottom-indigo.border-bottom-darken-3 {
  border-bottom: 1px solid #283593 !important; }

.border-left-indigo.border-left-darken-3 {
  border-left: 1px solid #283593 !important; }

.border-right-indigo.border-right-darken-3 {
  border-right: 1px solid #283593 !important; }

.overlay-indigo.overlay-darken-3 {
  background: #283593;
  /* The Fallback */
  background: rgba(40, 53, 147, 0.8); }

.indigo.darken-4 {
  color: #1A237E !important; }

.bg-indigo.bg-darken-4 {
  background-color: #1A237E !important; }

.btn-indigo.btn-darken-4 {
  border-color: #1A237E !important;
  background-color: #1A237E !important; }
  .btn-indigo.btn-darken-4:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-darken-4 {
  border-color: #1A237E !important;
  color: #1A237E !important; }
  .btn-outline-indigo.btn-outline-darken-4:hover {
    background-color: #1A237E !important; }

.progress-indigo.progress-darken-4[value]::-webkit-progress-value {
  background-color: #1A237E; }

.progress-indigo.progress-darken-4[value]::-moz-progress-bar {
  background-color: #1A237E; }

.progress-indigo.progress-darken-4[value]::-ms-fill {
  background-color: #1A237E; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-darken-4 .progress-bar {
    background-color: #1A237E; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1A237E !important; }

.border-indigo.border-darken-4 {
  border: 1px solid #1A237E !important; }

.border-top-indigo.border-top-darken-4 {
  border-top: 1px solid #1A237E !important; }

.border-bottom-indigo.border-bottom-darken-4 {
  border-bottom: 1px solid #1A237E !important; }

.border-left-indigo.border-left-darken-4 {
  border-left: 1px solid #1A237E !important; }

.border-right-indigo.border-right-darken-4 {
  border-right: 1px solid #1A237E !important; }

.overlay-indigo.overlay-darken-4 {
  background: #1A237E;
  /* The Fallback */
  background: rgba(26, 35, 126, 0.8); }

.indigo.accent-1 {
  color: #8C9EFF !important; }

.bg-indigo.bg-accent-1 {
  background-color: #8C9EFF !important; }

.btn-indigo.btn-accent-1 {
  border-color: #8C9EFF !important;
  background-color: #8C9EFF !important; }
  .btn-indigo.btn-accent-1:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-accent-1 {
  border-color: #8C9EFF !important;
  color: #8C9EFF !important; }
  .btn-outline-indigo.btn-outline-accent-1:hover {
    background-color: #8C9EFF !important; }

.progress-indigo.progress-accent-1[value]::-webkit-progress-value {
  background-color: #8C9EFF; }

.progress-indigo.progress-accent-1[value]::-moz-progress-bar {
  background-color: #8C9EFF; }

.progress-indigo.progress-accent-1[value]::-ms-fill {
  background-color: #8C9EFF; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-accent-1 .progress-bar {
    background-color: #8C9EFF; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8C9EFF !important; }

.border-indigo.border-accent-1 {
  border: 1px solid #8C9EFF !important; }

.border-top-indigo.border-top-accent-1 {
  border-top: 1px solid #8C9EFF !important; }

.border-bottom-indigo.border-bottom-accent-1 {
  border-bottom: 1px solid #8C9EFF !important; }

.border-left-indigo.border-left-accent-1 {
  border-left: 1px solid #8C9EFF !important; }

.border-right-indigo.border-right-accent-1 {
  border-right: 1px solid #8C9EFF !important; }

.overlay-indigo.overlay-accent-1 {
  background: #8C9EFF;
  /* The Fallback */
  background: rgba(140, 158, 255, 0.8); }

.indigo.accent-2 {
  color: #536DFE !important; }

.bg-indigo.bg-accent-2 {
  background-color: #536DFE !important; }

.btn-indigo.btn-accent-2 {
  border-color: #536DFE !important;
  background-color: #536DFE !important; }
  .btn-indigo.btn-accent-2:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-accent-2 {
  border-color: #536DFE !important;
  color: #536DFE !important; }
  .btn-outline-indigo.btn-outline-accent-2:hover {
    background-color: #536DFE !important; }

.progress-indigo.progress-accent-2[value]::-webkit-progress-value {
  background-color: #536DFE; }

.progress-indigo.progress-accent-2[value]::-moz-progress-bar {
  background-color: #536DFE; }

.progress-indigo.progress-accent-2[value]::-ms-fill {
  background-color: #536DFE; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-accent-2 .progress-bar {
    background-color: #536DFE; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #536DFE !important; }

.border-indigo.border-accent-2 {
  border: 1px solid #536DFE !important; }

.border-top-indigo.border-top-accent-2 {
  border-top: 1px solid #536DFE !important; }

.border-bottom-indigo.border-bottom-accent-2 {
  border-bottom: 1px solid #536DFE !important; }

.border-left-indigo.border-left-accent-2 {
  border-left: 1px solid #536DFE !important; }

.border-right-indigo.border-right-accent-2 {
  border-right: 1px solid #536DFE !important; }

.overlay-indigo.overlay-accent-2 {
  background: #536DFE;
  /* The Fallback */
  background: rgba(83, 109, 254, 0.8); }

.indigo.accent-3 {
  color: #3D5AFE !important; }

.bg-indigo.bg-accent-3 {
  background-color: #3D5AFE !important; }

.btn-indigo.btn-accent-3 {
  border-color: #3D5AFE !important;
  background-color: #3D5AFE !important; }
  .btn-indigo.btn-accent-3:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-accent-3 {
  border-color: #3D5AFE !important;
  color: #3D5AFE !important; }
  .btn-outline-indigo.btn-outline-accent-3:hover {
    background-color: #3D5AFE !important; }

.progress-indigo.progress-accent-3[value]::-webkit-progress-value {
  background-color: #3D5AFE; }

.progress-indigo.progress-accent-3[value]::-moz-progress-bar {
  background-color: #3D5AFE; }

.progress-indigo.progress-accent-3[value]::-ms-fill {
  background-color: #3D5AFE; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-accent-3 .progress-bar {
    background-color: #3D5AFE; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3D5AFE !important; }

.border-indigo.border-accent-3 {
  border: 1px solid #3D5AFE !important; }

.border-top-indigo.border-top-accent-3 {
  border-top: 1px solid #3D5AFE !important; }

.border-bottom-indigo.border-bottom-accent-3 {
  border-bottom: 1px solid #3D5AFE !important; }

.border-left-indigo.border-left-accent-3 {
  border-left: 1px solid #3D5AFE !important; }

.border-right-indigo.border-right-accent-3 {
  border-right: 1px solid #3D5AFE !important; }

.overlay-indigo.overlay-accent-3 {
  background: #3D5AFE;
  /* The Fallback */
  background: rgba(61, 90, 254, 0.8); }

.indigo.accent-4 {
  color: #304FFE !important; }

.bg-indigo.bg-accent-4 {
  background-color: #304FFE !important; }

.btn-indigo.btn-accent-4 {
  border-color: #304FFE !important;
  background-color: #304FFE !important; }
  .btn-indigo.btn-accent-4:hover {
    border-color: #7986CB !important;
    background-color: #7986CB !important; }

.btn-outline-indigo.btn-outline-accent-4 {
  border-color: #304FFE !important;
  color: #304FFE !important; }
  .btn-outline-indigo.btn-outline-accent-4:hover {
    background-color: #304FFE !important; }

.progress-indigo.progress-accent-4[value]::-webkit-progress-value {
  background-color: #304FFE; }

.progress-indigo.progress-accent-4[value]::-moz-progress-bar {
  background-color: #304FFE; }

.progress-indigo.progress-accent-4[value]::-ms-fill {
  background-color: #304FFE; }

@media screen and (min-width: 0\0) {
  .progress-indigo.progress-accent-4 .progress-bar {
    background-color: #304FFE; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #304FFE !important; }

.border-indigo.border-accent-4 {
  border: 1px solid #304FFE !important; }

.border-top-indigo.border-top-accent-4 {
  border-top: 1px solid #304FFE !important; }

.border-bottom-indigo.border-bottom-accent-4 {
  border-bottom: 1px solid #304FFE !important; }

.border-left-indigo.border-left-accent-4 {
  border-left: 1px solid #304FFE !important; }

.border-right-indigo.border-right-accent-4 {
  border-right: 1px solid #304FFE !important; }

.overlay-indigo.overlay-accent-4 {
  background: #304FFE;
  /* The Fallback */
  background: rgba(48, 79, 254, 0.8); }

.blue.lighten-5 {
  color: #E3F2FD !important; }

.bg-blue.bg-lighten-5 {
  background-color: #E3F2FD !important; }

.btn-blue.btn-lighten-5 {
  border-color: #E3F2FD !important;
  background-color: #E3F2FD !important; }
  .btn-blue.btn-lighten-5:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-lighten-5 {
  border-color: #E3F2FD !important;
  color: #E3F2FD !important; }
  .btn-outline-blue.btn-outline-lighten-5:hover {
    background-color: #E3F2FD !important; }

.progress-blue.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #E3F2FD; }

.progress-blue.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #E3F2FD; }

.progress-blue.progress-lighten-5[value]::-ms-fill {
  background-color: #E3F2FD; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-lighten-5 .progress-bar {
    background-color: #E3F2FD; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E3F2FD !important; }

.border-blue.border-lighten-5 {
  border: 1px solid #E3F2FD !important; }

.border-top-blue.border-top-lighten-5 {
  border-top: 1px solid #E3F2FD !important; }

.border-bottom-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #E3F2FD !important; }

.border-left-blue.border-left-lighten-5 {
  border-left: 1px solid #E3F2FD !important; }

.border-right-blue.border-right-lighten-5 {
  border-right: 1px solid #E3F2FD !important; }

.overlay-blue.overlay-lighten-5 {
  background: #E3F2FD;
  /* The Fallback */
  background: rgba(227, 242, 253, 0.8); }

.blue.lighten-4 {
  color: #BBDEFB !important; }

.bg-blue.bg-lighten-4 {
  background-color: #BBDEFB !important; }

.btn-blue.btn-lighten-4 {
  border-color: #BBDEFB !important;
  background-color: #BBDEFB !important; }
  .btn-blue.btn-lighten-4:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-lighten-4 {
  border-color: #BBDEFB !important;
  color: #BBDEFB !important; }
  .btn-outline-blue.btn-outline-lighten-4:hover {
    background-color: #BBDEFB !important; }

.progress-blue.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #BBDEFB; }

.progress-blue.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #BBDEFB; }

.progress-blue.progress-lighten-4[value]::-ms-fill {
  background-color: #BBDEFB; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-lighten-4 .progress-bar {
    background-color: #BBDEFB; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BBDEFB !important; }

.border-blue.border-lighten-4 {
  border: 1px solid #BBDEFB !important; }

.border-top-blue.border-top-lighten-4 {
  border-top: 1px solid #BBDEFB !important; }

.border-bottom-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #BBDEFB !important; }

.border-left-blue.border-left-lighten-4 {
  border-left: 1px solid #BBDEFB !important; }

.border-right-blue.border-right-lighten-4 {
  border-right: 1px solid #BBDEFB !important; }

.overlay-blue.overlay-lighten-4 {
  background: #BBDEFB;
  /* The Fallback */
  background: rgba(187, 222, 251, 0.8); }

.blue.lighten-3 {
  color: #90CAF9 !important; }

.bg-blue.bg-lighten-3 {
  background-color: #90CAF9 !important; }

.btn-blue.btn-lighten-3 {
  border-color: #90CAF9 !important;
  background-color: #90CAF9 !important; }
  .btn-blue.btn-lighten-3:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-lighten-3 {
  border-color: #90CAF9 !important;
  color: #90CAF9 !important; }
  .btn-outline-blue.btn-outline-lighten-3:hover {
    background-color: #90CAF9 !important; }

.progress-blue.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #90CAF9; }

.progress-blue.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #90CAF9; }

.progress-blue.progress-lighten-3[value]::-ms-fill {
  background-color: #90CAF9; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-lighten-3 .progress-bar {
    background-color: #90CAF9; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90CAF9 !important; }

.border-blue.border-lighten-3 {
  border: 1px solid #90CAF9 !important; }

.border-top-blue.border-top-lighten-3 {
  border-top: 1px solid #90CAF9 !important; }

.border-bottom-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #90CAF9 !important; }

.border-left-blue.border-left-lighten-3 {
  border-left: 1px solid #90CAF9 !important; }

.border-right-blue.border-right-lighten-3 {
  border-right: 1px solid #90CAF9 !important; }

.overlay-blue.overlay-lighten-3 {
  background: #90CAF9;
  /* The Fallback */
  background: rgba(144, 202, 249, 0.8); }

.blue.lighten-2 {
  color: #64B5F6 !important; }

.bg-blue.bg-lighten-2 {
  background-color: #64B5F6 !important; }

.btn-blue.btn-lighten-2 {
  border-color: #64B5F6 !important;
  background-color: #64B5F6 !important; }
  .btn-blue.btn-lighten-2:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-lighten-2 {
  border-color: #64B5F6 !important;
  color: #64B5F6 !important; }
  .btn-outline-blue.btn-outline-lighten-2:hover {
    background-color: #64B5F6 !important; }

.progress-blue.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #64B5F6; }

.progress-blue.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #64B5F6; }

.progress-blue.progress-lighten-2[value]::-ms-fill {
  background-color: #64B5F6; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-lighten-2 .progress-bar {
    background-color: #64B5F6; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64B5F6 !important; }

.border-blue.border-lighten-2 {
  border: 1px solid #64B5F6 !important; }

.border-top-blue.border-top-lighten-2 {
  border-top: 1px solid #64B5F6 !important; }

.border-bottom-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #64B5F6 !important; }

.border-left-blue.border-left-lighten-2 {
  border-left: 1px solid #64B5F6 !important; }

.border-right-blue.border-right-lighten-2 {
  border-right: 1px solid #64B5F6 !important; }

.overlay-blue.overlay-lighten-2 {
  background: #64B5F6;
  /* The Fallback */
  background: rgba(100, 181, 246, 0.8); }

.blue.lighten-1 {
  color: #42A5F5 !important; }

.bg-blue.bg-lighten-1 {
  background-color: #42A5F5 !important; }

.btn-blue.btn-lighten-1 {
  border-color: #42A5F5 !important;
  background-color: #42A5F5 !important; }
  .btn-blue.btn-lighten-1:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-lighten-1 {
  border-color: #42A5F5 !important;
  color: #42A5F5 !important; }
  .btn-outline-blue.btn-outline-lighten-1:hover {
    background-color: #42A5F5 !important; }

.progress-blue.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #42A5F5; }

.progress-blue.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #42A5F5; }

.progress-blue.progress-lighten-1[value]::-ms-fill {
  background-color: #42A5F5; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-lighten-1 .progress-bar {
    background-color: #42A5F5; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #42A5F5 !important; }

.border-blue.border-lighten-1 {
  border: 1px solid #42A5F5 !important; }

.border-top-blue.border-top-lighten-1 {
  border-top: 1px solid #42A5F5 !important; }

.border-bottom-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #42A5F5 !important; }

.border-left-blue.border-left-lighten-1 {
  border-left: 1px solid #42A5F5 !important; }

.border-right-blue.border-right-lighten-1 {
  border-right: 1px solid #42A5F5 !important; }

.overlay-blue.overlay-lighten-1 {
  background: #42A5F5;
  /* The Fallback */
  background: rgba(66, 165, 245, 0.8); }

.blue {
  color: #2196F3 !important; }

.bg-blue {
  background-color: #2196F3 !important; }
  .bg-blue .card-header, .bg-blue .card-footer {
    background-color: transparent; }

.alert-blue {
  border-color: #2196F3 !important;
  background-color: #65b6f7 !important;
  color: #05365d !important; }
  .alert-blue .alert-link {
    color: #031f36 !important; }

.border-blue {
  border-color: #2196F3; }

.bg-blue.tag-glow, .border-blue.tag-glow {
  box-shadow: 0px 0px 10px #2196F3; }

.overlay-blue {
  background: #2196F3;
  /* The Fallback */
  background: rgba(33, 150, 243, 0.8); }

.card-outline-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #2196F3;
  background-color: transparent; }
  .card-outline-blue .card-header, .card-outline-blue .card-footer {
    background-color: transparent; }

.btn-blue {
  border-color: #2196F3;
  background-color: #2196F3;
  color: #FFFFFF; }
  .btn-blue:hover {
    border-color: #64B5F6;
    background-color: #64B5F6;
    color: #FFF !important; }

.btn-outline-blue {
  border-color: #2196F3;
  background-color: transparent;
  color: #2196F3; }
  .btn-outline-blue:hover {
    background-color: #2196F3;
    color: #FFF !important; }

.progress-blue[value]::-webkit-progress-value {
  background-color: #2196F3; }

.progress-blue[value]::-moz-progress-bar {
  background-color: #2196F3; }

.progress-blue[value]::-ms-fill {
  background-color: #2196F3; }

@media screen and (min-width: 0\0) {
  .progress-blue .progress-bar {
    background-color: #2196F3; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2196F3 !important; }

.border-blue {
  border: 1px solid #2196F3 !important; }

.border-top-blue {
  border-top: 1px solid #2196F3; }

.border-bottom-blue {
  border-bottom: 1px solid #2196F3; }

.border-left-blue {
  border-left: 1px solid #2196F3; }

.border-right-blue {
  border-right: 1px solid #2196F3; }

.blue.darken-1 {
  color: #1E88E5 !important; }

.bg-blue.bg-darken-1 {
  background-color: #1E88E5 !important; }

.btn-blue.btn-darken-1 {
  border-color: #1E88E5 !important;
  background-color: #1E88E5 !important; }
  .btn-blue.btn-darken-1:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-darken-1 {
  border-color: #1E88E5 !important;
  color: #1E88E5 !important; }
  .btn-outline-blue.btn-outline-darken-1:hover {
    background-color: #1E88E5 !important; }

.progress-blue.progress-darken-1[value]::-webkit-progress-value {
  background-color: #1E88E5; }

.progress-blue.progress-darken-1[value]::-moz-progress-bar {
  background-color: #1E88E5; }

.progress-blue.progress-darken-1[value]::-ms-fill {
  background-color: #1E88E5; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-darken-1 .progress-bar {
    background-color: #1E88E5; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1E88E5 !important; }

.border-blue.border-darken-1 {
  border: 1px solid #1E88E5 !important; }

.border-top-blue.border-top-darken-1 {
  border-top: 1px solid #1E88E5 !important; }

.border-bottom-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #1E88E5 !important; }

.border-left-blue.border-left-darken-1 {
  border-left: 1px solid #1E88E5 !important; }

.border-right-blue.border-right-darken-1 {
  border-right: 1px solid #1E88E5 !important; }

.overlay-blue.overlay-darken-1 {
  background: #1E88E5;
  /* The Fallback */
  background: rgba(30, 136, 229, 0.8); }

.blue.darken-2 {
  color: #1976D2 !important; }

.bg-blue.bg-darken-2 {
  background-color: #1976D2 !important; }

.btn-blue.btn-darken-2 {
  border-color: #1976D2 !important;
  background-color: #1976D2 !important; }
  .btn-blue.btn-darken-2:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-darken-2 {
  border-color: #1976D2 !important;
  color: #1976D2 !important; }
  .btn-outline-blue.btn-outline-darken-2:hover {
    background-color: #1976D2 !important; }

.progress-blue.progress-darken-2[value]::-webkit-progress-value {
  background-color: #1976D2; }

.progress-blue.progress-darken-2[value]::-moz-progress-bar {
  background-color: #1976D2; }

.progress-blue.progress-darken-2[value]::-ms-fill {
  background-color: #1976D2; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-darken-2 .progress-bar {
    background-color: #1976D2; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1976D2 !important; }

.border-blue.border-darken-2 {
  border: 1px solid #1976D2 !important; }

.border-top-blue.border-top-darken-2 {
  border-top: 1px solid #1976D2 !important; }

.border-bottom-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #1976D2 !important; }

.border-left-blue.border-left-darken-2 {
  border-left: 1px solid #1976D2 !important; }

.border-right-blue.border-right-darken-2 {
  border-right: 1px solid #1976D2 !important; }

.overlay-blue.overlay-darken-2 {
  background: #1976D2;
  /* The Fallback */
  background: rgba(25, 118, 210, 0.8); }

.blue.darken-3 {
  color: #1565C0 !important; }

.bg-blue.bg-darken-3 {
  background-color: #1565C0 !important; }

.btn-blue.btn-darken-3 {
  border-color: #1565C0 !important;
  background-color: #1565C0 !important; }
  .btn-blue.btn-darken-3:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-darken-3 {
  border-color: #1565C0 !important;
  color: #1565C0 !important; }
  .btn-outline-blue.btn-outline-darken-3:hover {
    background-color: #1565C0 !important; }

.progress-blue.progress-darken-3[value]::-webkit-progress-value {
  background-color: #1565C0; }

.progress-blue.progress-darken-3[value]::-moz-progress-bar {
  background-color: #1565C0; }

.progress-blue.progress-darken-3[value]::-ms-fill {
  background-color: #1565C0; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-darken-3 .progress-bar {
    background-color: #1565C0; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1565C0 !important; }

.border-blue.border-darken-3 {
  border: 1px solid #1565C0 !important; }

.border-top-blue.border-top-darken-3 {
  border-top: 1px solid #1565C0 !important; }

.border-bottom-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #1565C0 !important; }

.border-left-blue.border-left-darken-3 {
  border-left: 1px solid #1565C0 !important; }

.border-right-blue.border-right-darken-3 {
  border-right: 1px solid #1565C0 !important; }

.overlay-blue.overlay-darken-3 {
  background: #1565C0;
  /* The Fallback */
  background: rgba(21, 101, 192, 0.8); }

.blue.darken-4 {
  color: #0D47A1 !important; }

.bg-blue.bg-darken-4 {
  background-color: #0D47A1 !important; }

.btn-blue.btn-darken-4 {
  border-color: #0D47A1 !important;
  background-color: #0D47A1 !important; }
  .btn-blue.btn-darken-4:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-darken-4 {
  border-color: #0D47A1 !important;
  color: #0D47A1 !important; }
  .btn-outline-blue.btn-outline-darken-4:hover {
    background-color: #0D47A1 !important; }

.progress-blue.progress-darken-4[value]::-webkit-progress-value {
  background-color: #0D47A1; }

.progress-blue.progress-darken-4[value]::-moz-progress-bar {
  background-color: #0D47A1; }

.progress-blue.progress-darken-4[value]::-ms-fill {
  background-color: #0D47A1; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-darken-4 .progress-bar {
    background-color: #0D47A1; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0D47A1 !important; }

.border-blue.border-darken-4 {
  border: 1px solid #0D47A1 !important; }

.border-top-blue.border-top-darken-4 {
  border-top: 1px solid #0D47A1 !important; }

.border-bottom-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #0D47A1 !important; }

.border-left-blue.border-left-darken-4 {
  border-left: 1px solid #0D47A1 !important; }

.border-right-blue.border-right-darken-4 {
  border-right: 1px solid #0D47A1 !important; }

.overlay-blue.overlay-darken-4 {
  background: #0D47A1;
  /* The Fallback */
  background: rgba(13, 71, 161, 0.8); }

.blue.accent-1 {
  color: #82B1FF !important; }

.bg-blue.bg-accent-1 {
  background-color: #82B1FF !important; }

.btn-blue.btn-accent-1 {
  border-color: #82B1FF !important;
  background-color: #82B1FF !important; }
  .btn-blue.btn-accent-1:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-accent-1 {
  border-color: #82B1FF !important;
  color: #82B1FF !important; }
  .btn-outline-blue.btn-outline-accent-1:hover {
    background-color: #82B1FF !important; }

.progress-blue.progress-accent-1[value]::-webkit-progress-value {
  background-color: #82B1FF; }

.progress-blue.progress-accent-1[value]::-moz-progress-bar {
  background-color: #82B1FF; }

.progress-blue.progress-accent-1[value]::-ms-fill {
  background-color: #82B1FF; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-accent-1 .progress-bar {
    background-color: #82B1FF; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82B1FF !important; }

.border-blue.border-accent-1 {
  border: 1px solid #82B1FF !important; }

.border-top-blue.border-top-accent-1 {
  border-top: 1px solid #82B1FF !important; }

.border-bottom-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #82B1FF !important; }

.border-left-blue.border-left-accent-1 {
  border-left: 1px solid #82B1FF !important; }

.border-right-blue.border-right-accent-1 {
  border-right: 1px solid #82B1FF !important; }

.overlay-blue.overlay-accent-1 {
  background: #82B1FF;
  /* The Fallback */
  background: rgba(130, 177, 255, 0.8); }

.blue.accent-2 {
  color: #448AFF !important; }

.bg-blue.bg-accent-2 {
  background-color: #448AFF !important; }

.btn-blue.btn-accent-2 {
  border-color: #448AFF !important;
  background-color: #448AFF !important; }
  .btn-blue.btn-accent-2:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-accent-2 {
  border-color: #448AFF !important;
  color: #448AFF !important; }
  .btn-outline-blue.btn-outline-accent-2:hover {
    background-color: #448AFF !important; }

.progress-blue.progress-accent-2[value]::-webkit-progress-value {
  background-color: #448AFF; }

.progress-blue.progress-accent-2[value]::-moz-progress-bar {
  background-color: #448AFF; }

.progress-blue.progress-accent-2[value]::-ms-fill {
  background-color: #448AFF; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-accent-2 .progress-bar {
    background-color: #448AFF; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #448AFF !important; }

.border-blue.border-accent-2 {
  border: 1px solid #448AFF !important; }

.border-top-blue.border-top-accent-2 {
  border-top: 1px solid #448AFF !important; }

.border-bottom-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #448AFF !important; }

.border-left-blue.border-left-accent-2 {
  border-left: 1px solid #448AFF !important; }

.border-right-blue.border-right-accent-2 {
  border-right: 1px solid #448AFF !important; }

.overlay-blue.overlay-accent-2 {
  background: #448AFF;
  /* The Fallback */
  background: rgba(68, 138, 255, 0.8); }

.blue.accent-3 {
  color: #2979FF !important; }

.bg-blue.bg-accent-3 {
  background-color: #2979FF !important; }

.btn-blue.btn-accent-3 {
  border-color: #2979FF !important;
  background-color: #2979FF !important; }
  .btn-blue.btn-accent-3:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-accent-3 {
  border-color: #2979FF !important;
  color: #2979FF !important; }
  .btn-outline-blue.btn-outline-accent-3:hover {
    background-color: #2979FF !important; }

.progress-blue.progress-accent-3[value]::-webkit-progress-value {
  background-color: #2979FF; }

.progress-blue.progress-accent-3[value]::-moz-progress-bar {
  background-color: #2979FF; }

.progress-blue.progress-accent-3[value]::-ms-fill {
  background-color: #2979FF; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-accent-3 .progress-bar {
    background-color: #2979FF; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2979FF !important; }

.border-blue.border-accent-3 {
  border: 1px solid #2979FF !important; }

.border-top-blue.border-top-accent-3 {
  border-top: 1px solid #2979FF !important; }

.border-bottom-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #2979FF !important; }

.border-left-blue.border-left-accent-3 {
  border-left: 1px solid #2979FF !important; }

.border-right-blue.border-right-accent-3 {
  border-right: 1px solid #2979FF !important; }

.overlay-blue.overlay-accent-3 {
  background: #2979FF;
  /* The Fallback */
  background: rgba(41, 121, 255, 0.8); }

.blue.accent-4 {
  color: #2962FF !important; }

.bg-blue.bg-accent-4 {
  background-color: #2962FF !important; }

.btn-blue.btn-accent-4 {
  border-color: #2962FF !important;
  background-color: #2962FF !important; }
  .btn-blue.btn-accent-4:hover {
    border-color: #64B5F6 !important;
    background-color: #64B5F6 !important; }

.btn-outline-blue.btn-outline-accent-4 {
  border-color: #2962FF !important;
  color: #2962FF !important; }
  .btn-outline-blue.btn-outline-accent-4:hover {
    background-color: #2962FF !important; }

.progress-blue.progress-accent-4[value]::-webkit-progress-value {
  background-color: #2962FF; }

.progress-blue.progress-accent-4[value]::-moz-progress-bar {
  background-color: #2962FF; }

.progress-blue.progress-accent-4[value]::-ms-fill {
  background-color: #2962FF; }

@media screen and (min-width: 0\0) {
  .progress-blue.progress-accent-4 .progress-bar {
    background-color: #2962FF; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2962FF !important; }

.border-blue.border-accent-4 {
  border: 1px solid #2962FF !important; }

.border-top-blue.border-top-accent-4 {
  border-top: 1px solid #2962FF !important; }

.border-bottom-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #2962FF !important; }

.border-left-blue.border-left-accent-4 {
  border-left: 1px solid #2962FF !important; }

.border-right-blue.border-right-accent-4 {
  border-right: 1px solid #2962FF !important; }

.overlay-blue.overlay-accent-4 {
  background: #2962FF;
  /* The Fallback */
  background: rgba(41, 98, 255, 0.8); }

.light-blue.lighten-5 {
  color: #E1F5FE !important; }

.bg-light-blue.bg-lighten-5 {
  background-color: #E1F5FE !important; }

.btn-light-blue.btn-lighten-5 {
  border-color: #E1F5FE !important;
  background-color: #E1F5FE !important; }
  .btn-light-blue.btn-lighten-5:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-lighten-5 {
  border-color: #E1F5FE !important;
  color: #E1F5FE !important; }
  .btn-outline-light-blue.btn-outline-lighten-5:hover {
    background-color: #E1F5FE !important; }

.progress-light-blue.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #E1F5FE; }

.progress-light-blue.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #E1F5FE; }

.progress-light-blue.progress-lighten-5[value]::-ms-fill {
  background-color: #E1F5FE; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-lighten-5 .progress-bar {
    background-color: #E1F5FE; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1F5FE !important; }

.border-light-blue.border-lighten-5 {
  border: 1px solid #E1F5FE !important; }

.border-top-light-blue.border-top-lighten-5 {
  border-top: 1px solid #E1F5FE !important; }

.border-bottom-light-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #E1F5FE !important; }

.border-left-light-blue.border-left-lighten-5 {
  border-left: 1px solid #E1F5FE !important; }

.border-right-light-blue.border-right-lighten-5 {
  border-right: 1px solid #E1F5FE !important; }

.overlay-light-blue.overlay-lighten-5 {
  background: #E1F5FE;
  /* The Fallback */
  background: rgba(225, 245, 254, 0.8); }

.light-blue.lighten-4 {
  color: #B3E5FC !important; }

.bg-light-blue.bg-lighten-4 {
  background-color: #B3E5FC !important; }

.btn-light-blue.btn-lighten-4 {
  border-color: #B3E5FC !important;
  background-color: #B3E5FC !important; }
  .btn-light-blue.btn-lighten-4:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-lighten-4 {
  border-color: #B3E5FC !important;
  color: #B3E5FC !important; }
  .btn-outline-light-blue.btn-outline-lighten-4:hover {
    background-color: #B3E5FC !important; }

.progress-light-blue.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #B3E5FC; }

.progress-light-blue.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #B3E5FC; }

.progress-light-blue.progress-lighten-4[value]::-ms-fill {
  background-color: #B3E5FC; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-lighten-4 .progress-bar {
    background-color: #B3E5FC; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B3E5FC !important; }

.border-light-blue.border-lighten-4 {
  border: 1px solid #B3E5FC !important; }

.border-top-light-blue.border-top-lighten-4 {
  border-top: 1px solid #B3E5FC !important; }

.border-bottom-light-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #B3E5FC !important; }

.border-left-light-blue.border-left-lighten-4 {
  border-left: 1px solid #B3E5FC !important; }

.border-right-light-blue.border-right-lighten-4 {
  border-right: 1px solid #B3E5FC !important; }

.overlay-light-blue.overlay-lighten-4 {
  background: #B3E5FC;
  /* The Fallback */
  background: rgba(179, 229, 252, 0.8); }

.light-blue.lighten-3 {
  color: #81D4FA !important; }

.bg-light-blue.bg-lighten-3 {
  background-color: #81D4FA !important; }

.btn-light-blue.btn-lighten-3 {
  border-color: #81D4FA !important;
  background-color: #81D4FA !important; }
  .btn-light-blue.btn-lighten-3:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-lighten-3 {
  border-color: #81D4FA !important;
  color: #81D4FA !important; }
  .btn-outline-light-blue.btn-outline-lighten-3:hover {
    background-color: #81D4FA !important; }

.progress-light-blue.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #81D4FA; }

.progress-light-blue.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #81D4FA; }

.progress-light-blue.progress-lighten-3[value]::-ms-fill {
  background-color: #81D4FA; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-lighten-3 .progress-bar {
    background-color: #81D4FA; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81D4FA !important; }

.border-light-blue.border-lighten-3 {
  border: 1px solid #81D4FA !important; }

.border-top-light-blue.border-top-lighten-3 {
  border-top: 1px solid #81D4FA !important; }

.border-bottom-light-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #81D4FA !important; }

.border-left-light-blue.border-left-lighten-3 {
  border-left: 1px solid #81D4FA !important; }

.border-right-light-blue.border-right-lighten-3 {
  border-right: 1px solid #81D4FA !important; }

.overlay-light-blue.overlay-lighten-3 {
  background: #81D4FA;
  /* The Fallback */
  background: rgba(129, 212, 250, 0.8); }

.light-blue.lighten-2 {
  color: #4FC3F7 !important; }

.bg-light-blue.bg-lighten-2 {
  background-color: #4FC3F7 !important; }

.btn-light-blue.btn-lighten-2 {
  border-color: #4FC3F7 !important;
  background-color: #4FC3F7 !important; }
  .btn-light-blue.btn-lighten-2:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-lighten-2 {
  border-color: #4FC3F7 !important;
  color: #4FC3F7 !important; }
  .btn-outline-light-blue.btn-outline-lighten-2:hover {
    background-color: #4FC3F7 !important; }

.progress-light-blue.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #4FC3F7; }

.progress-light-blue.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #4FC3F7; }

.progress-light-blue.progress-lighten-2[value]::-ms-fill {
  background-color: #4FC3F7; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-lighten-2 .progress-bar {
    background-color: #4FC3F7; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4FC3F7 !important; }

.border-light-blue.border-lighten-2 {
  border: 1px solid #4FC3F7 !important; }

.border-top-light-blue.border-top-lighten-2 {
  border-top: 1px solid #4FC3F7 !important; }

.border-bottom-light-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #4FC3F7 !important; }

.border-left-light-blue.border-left-lighten-2 {
  border-left: 1px solid #4FC3F7 !important; }

.border-right-light-blue.border-right-lighten-2 {
  border-right: 1px solid #4FC3F7 !important; }

.overlay-light-blue.overlay-lighten-2 {
  background: #4FC3F7;
  /* The Fallback */
  background: rgba(79, 195, 247, 0.8); }

.light-blue.lighten-1 {
  color: #29B6F6 !important; }

.bg-light-blue.bg-lighten-1 {
  background-color: #29B6F6 !important; }

.btn-light-blue.btn-lighten-1 {
  border-color: #29B6F6 !important;
  background-color: #29B6F6 !important; }
  .btn-light-blue.btn-lighten-1:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-lighten-1 {
  border-color: #29B6F6 !important;
  color: #29B6F6 !important; }
  .btn-outline-light-blue.btn-outline-lighten-1:hover {
    background-color: #29B6F6 !important; }

.progress-light-blue.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #29B6F6; }

.progress-light-blue.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #29B6F6; }

.progress-light-blue.progress-lighten-1[value]::-ms-fill {
  background-color: #29B6F6; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-lighten-1 .progress-bar {
    background-color: #29B6F6; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #29B6F6 !important; }

.border-light-blue.border-lighten-1 {
  border: 1px solid #29B6F6 !important; }

.border-top-light-blue.border-top-lighten-1 {
  border-top: 1px solid #29B6F6 !important; }

.border-bottom-light-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #29B6F6 !important; }

.border-left-light-blue.border-left-lighten-1 {
  border-left: 1px solid #29B6F6 !important; }

.border-right-light-blue.border-right-lighten-1 {
  border-right: 1px solid #29B6F6 !important; }

.overlay-light-blue.overlay-lighten-1 {
  background: #29B6F6;
  /* The Fallback */
  background: rgba(41, 182, 246, 0.8); }

.light-blue {
  color: #03A9F4 !important; }

.bg-light-blue {
  background-color: #03A9F4 !important; }
  .bg-light-blue .card-header, .bg-light-blue .card-footer {
    background-color: transparent; }

.alert-light-blue {
  border-color: #03A9F4 !important;
  background-color: #42c2fd !important;
  color: #012f44 !important; }
  .alert-light-blue .alert-link {
    color: #00131b !important; }

.border-light-blue {
  border-color: #03A9F4; }

.bg-light-blue.tag-glow, .border-light-blue.tag-glow {
  box-shadow: 0px 0px 10px #03A9F4; }

.overlay-light-blue {
  background: #03A9F4;
  /* The Fallback */
  background: rgba(3, 169, 244, 0.8); }

.card-outline-light-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #03A9F4;
  background-color: transparent; }
  .card-outline-light-blue .card-header, .card-outline-light-blue .card-footer {
    background-color: transparent; }

.btn-light-blue {
  border-color: #03A9F4;
  background-color: #03A9F4;
  color: #FFFFFF; }
  .btn-light-blue:hover {
    border-color: #4FC3F7;
    background-color: #4FC3F7;
    color: #FFF !important; }

.btn-outline-light-blue {
  border-color: #03A9F4;
  background-color: transparent;
  color: #03A9F4; }
  .btn-outline-light-blue:hover {
    background-color: #03A9F4;
    color: #FFF !important; }

.progress-light-blue[value]::-webkit-progress-value {
  background-color: #03A9F4; }

.progress-light-blue[value]::-moz-progress-bar {
  background-color: #03A9F4; }

.progress-light-blue[value]::-ms-fill {
  background-color: #03A9F4; }

@media screen and (min-width: 0\0) {
  .progress-light-blue .progress-bar {
    background-color: #03A9F4; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #03A9F4 !important; }

.border-light-blue {
  border: 1px solid #03A9F4 !important; }

.border-top-light-blue {
  border-top: 1px solid #03A9F4; }

.border-bottom-light-blue {
  border-bottom: 1px solid #03A9F4; }

.border-left-light-blue {
  border-left: 1px solid #03A9F4; }

.border-right-light-blue {
  border-right: 1px solid #03A9F4; }

.light-blue.darken-1 {
  color: #039BE5 !important; }

.bg-light-blue.bg-darken-1 {
  background-color: #039BE5 !important; }

.btn-light-blue.btn-darken-1 {
  border-color: #039BE5 !important;
  background-color: #039BE5 !important; }
  .btn-light-blue.btn-darken-1:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-darken-1 {
  border-color: #039BE5 !important;
  color: #039BE5 !important; }
  .btn-outline-light-blue.btn-outline-darken-1:hover {
    background-color: #039BE5 !important; }

.progress-light-blue.progress-darken-1[value]::-webkit-progress-value {
  background-color: #039BE5; }

.progress-light-blue.progress-darken-1[value]::-moz-progress-bar {
  background-color: #039BE5; }

.progress-light-blue.progress-darken-1[value]::-ms-fill {
  background-color: #039BE5; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-darken-1 .progress-bar {
    background-color: #039BE5; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039BE5 !important; }

.border-light-blue.border-darken-1 {
  border: 1px solid #039BE5 !important; }

.border-top-light-blue.border-top-darken-1 {
  border-top: 1px solid #039BE5 !important; }

.border-bottom-light-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #039BE5 !important; }

.border-left-light-blue.border-left-darken-1 {
  border-left: 1px solid #039BE5 !important; }

.border-right-light-blue.border-right-darken-1 {
  border-right: 1px solid #039BE5 !important; }

.overlay-light-blue.overlay-darken-1 {
  background: #039BE5;
  /* The Fallback */
  background: rgba(3, 155, 229, 0.8); }

.light-blue.darken-2 {
  color: #0288D1 !important; }

.bg-light-blue.bg-darken-2 {
  background-color: #0288D1 !important; }

.btn-light-blue.btn-darken-2 {
  border-color: #0288D1 !important;
  background-color: #0288D1 !important; }
  .btn-light-blue.btn-darken-2:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-darken-2 {
  border-color: #0288D1 !important;
  color: #0288D1 !important; }
  .btn-outline-light-blue.btn-outline-darken-2:hover {
    background-color: #0288D1 !important; }

.progress-light-blue.progress-darken-2[value]::-webkit-progress-value {
  background-color: #0288D1; }

.progress-light-blue.progress-darken-2[value]::-moz-progress-bar {
  background-color: #0288D1; }

.progress-light-blue.progress-darken-2[value]::-ms-fill {
  background-color: #0288D1; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-darken-2 .progress-bar {
    background-color: #0288D1; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0288D1 !important; }

.border-light-blue.border-darken-2 {
  border: 1px solid #0288D1 !important; }

.border-top-light-blue.border-top-darken-2 {
  border-top: 1px solid #0288D1 !important; }

.border-bottom-light-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #0288D1 !important; }

.border-left-light-blue.border-left-darken-2 {
  border-left: 1px solid #0288D1 !important; }

.border-right-light-blue.border-right-darken-2 {
  border-right: 1px solid #0288D1 !important; }

.overlay-light-blue.overlay-darken-2 {
  background: #0288D1;
  /* The Fallback */
  background: rgba(2, 136, 209, 0.8); }

.light-blue.darken-3 {
  color: #0277BD !important; }

.bg-light-blue.bg-darken-3 {
  background-color: #0277BD !important; }

.btn-light-blue.btn-darken-3 {
  border-color: #0277BD !important;
  background-color: #0277BD !important; }
  .btn-light-blue.btn-darken-3:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-darken-3 {
  border-color: #0277BD !important;
  color: #0277BD !important; }
  .btn-outline-light-blue.btn-outline-darken-3:hover {
    background-color: #0277BD !important; }

.progress-light-blue.progress-darken-3[value]::-webkit-progress-value {
  background-color: #0277BD; }

.progress-light-blue.progress-darken-3[value]::-moz-progress-bar {
  background-color: #0277BD; }

.progress-light-blue.progress-darken-3[value]::-ms-fill {
  background-color: #0277BD; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-darken-3 .progress-bar {
    background-color: #0277BD; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0277BD !important; }

.border-light-blue.border-darken-3 {
  border: 1px solid #0277BD !important; }

.border-top-light-blue.border-top-darken-3 {
  border-top: 1px solid #0277BD !important; }

.border-bottom-light-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #0277BD !important; }

.border-left-light-blue.border-left-darken-3 {
  border-left: 1px solid #0277BD !important; }

.border-right-light-blue.border-right-darken-3 {
  border-right: 1px solid #0277BD !important; }

.overlay-light-blue.overlay-darken-3 {
  background: #0277BD;
  /* The Fallback */
  background: rgba(2, 119, 189, 0.8); }

.light-blue.darken-4 {
  color: #01579B !important; }

.bg-light-blue.bg-darken-4 {
  background-color: #01579B !important; }

.btn-light-blue.btn-darken-4 {
  border-color: #01579B !important;
  background-color: #01579B !important; }
  .btn-light-blue.btn-darken-4:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-darken-4 {
  border-color: #01579B !important;
  color: #01579B !important; }
  .btn-outline-light-blue.btn-outline-darken-4:hover {
    background-color: #01579B !important; }

.progress-light-blue.progress-darken-4[value]::-webkit-progress-value {
  background-color: #01579B; }

.progress-light-blue.progress-darken-4[value]::-moz-progress-bar {
  background-color: #01579B; }

.progress-light-blue.progress-darken-4[value]::-ms-fill {
  background-color: #01579B; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-darken-4 .progress-bar {
    background-color: #01579B; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #01579B !important; }

.border-light-blue.border-darken-4 {
  border: 1px solid #01579B !important; }

.border-top-light-blue.border-top-darken-4 {
  border-top: 1px solid #01579B !important; }

.border-bottom-light-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #01579B !important; }

.border-left-light-blue.border-left-darken-4 {
  border-left: 1px solid #01579B !important; }

.border-right-light-blue.border-right-darken-4 {
  border-right: 1px solid #01579B !important; }

.overlay-light-blue.overlay-darken-4 {
  background: #01579B;
  /* The Fallback */
  background: rgba(1, 87, 155, 0.8); }

.light-blue.accent-1 {
  color: #80D8FF !important; }

.bg-light-blue.bg-accent-1 {
  background-color: #80D8FF !important; }

.btn-light-blue.btn-accent-1 {
  border-color: #80D8FF !important;
  background-color: #80D8FF !important; }
  .btn-light-blue.btn-accent-1:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-accent-1 {
  border-color: #80D8FF !important;
  color: #80D8FF !important; }
  .btn-outline-light-blue.btn-outline-accent-1:hover {
    background-color: #80D8FF !important; }

.progress-light-blue.progress-accent-1[value]::-webkit-progress-value {
  background-color: #80D8FF; }

.progress-light-blue.progress-accent-1[value]::-moz-progress-bar {
  background-color: #80D8FF; }

.progress-light-blue.progress-accent-1[value]::-ms-fill {
  background-color: #80D8FF; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-accent-1 .progress-bar {
    background-color: #80D8FF; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80D8FF !important; }

.border-light-blue.border-accent-1 {
  border: 1px solid #80D8FF !important; }

.border-top-light-blue.border-top-accent-1 {
  border-top: 1px solid #80D8FF !important; }

.border-bottom-light-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #80D8FF !important; }

.border-left-light-blue.border-left-accent-1 {
  border-left: 1px solid #80D8FF !important; }

.border-right-light-blue.border-right-accent-1 {
  border-right: 1px solid #80D8FF !important; }

.overlay-light-blue.overlay-accent-1 {
  background: #80D8FF;
  /* The Fallback */
  background: rgba(128, 216, 255, 0.8); }

.light-blue.accent-2 {
  color: #40C4FF !important; }

.bg-light-blue.bg-accent-2 {
  background-color: #40C4FF !important; }

.btn-light-blue.btn-accent-2 {
  border-color: #40C4FF !important;
  background-color: #40C4FF !important; }
  .btn-light-blue.btn-accent-2:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-accent-2 {
  border-color: #40C4FF !important;
  color: #40C4FF !important; }
  .btn-outline-light-blue.btn-outline-accent-2:hover {
    background-color: #40C4FF !important; }

.progress-light-blue.progress-accent-2[value]::-webkit-progress-value {
  background-color: #40C4FF; }

.progress-light-blue.progress-accent-2[value]::-moz-progress-bar {
  background-color: #40C4FF; }

.progress-light-blue.progress-accent-2[value]::-ms-fill {
  background-color: #40C4FF; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-accent-2 .progress-bar {
    background-color: #40C4FF; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #40C4FF !important; }

.border-light-blue.border-accent-2 {
  border: 1px solid #40C4FF !important; }

.border-top-light-blue.border-top-accent-2 {
  border-top: 1px solid #40C4FF !important; }

.border-bottom-light-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #40C4FF !important; }

.border-left-light-blue.border-left-accent-2 {
  border-left: 1px solid #40C4FF !important; }

.border-right-light-blue.border-right-accent-2 {
  border-right: 1px solid #40C4FF !important; }

.overlay-light-blue.overlay-accent-2 {
  background: #40C4FF;
  /* The Fallback */
  background: rgba(64, 196, 255, 0.8); }

.light-blue.accent-3 {
  color: #00B0FF !important; }

.bg-light-blue.bg-accent-3 {
  background-color: #00B0FF !important; }

.btn-light-blue.btn-accent-3 {
  border-color: #00B0FF !important;
  background-color: #00B0FF !important; }
  .btn-light-blue.btn-accent-3:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-accent-3 {
  border-color: #00B0FF !important;
  color: #00B0FF !important; }
  .btn-outline-light-blue.btn-outline-accent-3:hover {
    background-color: #00B0FF !important; }

.progress-light-blue.progress-accent-3[value]::-webkit-progress-value {
  background-color: #00B0FF; }

.progress-light-blue.progress-accent-3[value]::-moz-progress-bar {
  background-color: #00B0FF; }

.progress-light-blue.progress-accent-3[value]::-ms-fill {
  background-color: #00B0FF; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-accent-3 .progress-bar {
    background-color: #00B0FF; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00B0FF !important; }

.border-light-blue.border-accent-3 {
  border: 1px solid #00B0FF !important; }

.border-top-light-blue.border-top-accent-3 {
  border-top: 1px solid #00B0FF !important; }

.border-bottom-light-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #00B0FF !important; }

.border-left-light-blue.border-left-accent-3 {
  border-left: 1px solid #00B0FF !important; }

.border-right-light-blue.border-right-accent-3 {
  border-right: 1px solid #00B0FF !important; }

.overlay-light-blue.overlay-accent-3 {
  background: #00B0FF;
  /* The Fallback */
  background: rgba(0, 176, 255, 0.8); }

.light-blue.accent-4 {
  color: #0091EA !important; }

.bg-light-blue.bg-accent-4 {
  background-color: #0091EA !important; }

.btn-light-blue.btn-accent-4 {
  border-color: #0091EA !important;
  background-color: #0091EA !important; }
  .btn-light-blue.btn-accent-4:hover {
    border-color: #4FC3F7 !important;
    background-color: #4FC3F7 !important; }

.btn-outline-light-blue.btn-outline-accent-4 {
  border-color: #0091EA !important;
  color: #0091EA !important; }
  .btn-outline-light-blue.btn-outline-accent-4:hover {
    background-color: #0091EA !important; }

.progress-light-blue.progress-accent-4[value]::-webkit-progress-value {
  background-color: #0091EA; }

.progress-light-blue.progress-accent-4[value]::-moz-progress-bar {
  background-color: #0091EA; }

.progress-light-blue.progress-accent-4[value]::-ms-fill {
  background-color: #0091EA; }

@media screen and (min-width: 0\0) {
  .progress-light-blue.progress-accent-4 .progress-bar {
    background-color: #0091EA; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0091EA !important; }

.border-light-blue.border-accent-4 {
  border: 1px solid #0091EA !important; }

.border-top-light-blue.border-top-accent-4 {
  border-top: 1px solid #0091EA !important; }

.border-bottom-light-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #0091EA !important; }

.border-left-light-blue.border-left-accent-4 {
  border-left: 1px solid #0091EA !important; }

.border-right-light-blue.border-right-accent-4 {
  border-right: 1px solid #0091EA !important; }

.overlay-light-blue.overlay-accent-4 {
  background: #0091EA;
  /* The Fallback */
  background: rgba(0, 145, 234, 0.8); }

.cyan.lighten-5 {
  color: #E0F7FA !important; }

.bg-cyan.bg-lighten-5 {
  background-color: #E0F7FA !important; }

.btn-cyan.btn-lighten-5 {
  border-color: #E0F7FA !important;
  background-color: #E0F7FA !important; }
  .btn-cyan.btn-lighten-5:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-lighten-5 {
  border-color: #E0F7FA !important;
  color: #E0F7FA !important; }
  .btn-outline-cyan.btn-outline-lighten-5:hover {
    background-color: #E0F7FA !important; }

.progress-cyan.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #E0F7FA; }

.progress-cyan.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #E0F7FA; }

.progress-cyan.progress-lighten-5[value]::-ms-fill {
  background-color: #E0F7FA; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-lighten-5 .progress-bar {
    background-color: #E0F7FA; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F7FA !important; }

.border-cyan.border-lighten-5 {
  border: 1px solid #E0F7FA !important; }

.border-top-cyan.border-top-lighten-5 {
  border-top: 1px solid #E0F7FA !important; }

.border-bottom-cyan.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F7FA !important; }

.border-left-cyan.border-left-lighten-5 {
  border-left: 1px solid #E0F7FA !important; }

.border-right-cyan.border-right-lighten-5 {
  border-right: 1px solid #E0F7FA !important; }

.overlay-cyan.overlay-lighten-5 {
  background: #E0F7FA;
  /* The Fallback */
  background: rgba(224, 247, 250, 0.8); }

.cyan.lighten-4 {
  color: #B2EBF2 !important; }

.bg-cyan.bg-lighten-4 {
  background-color: #B2EBF2 !important; }

.btn-cyan.btn-lighten-4 {
  border-color: #B2EBF2 !important;
  background-color: #B2EBF2 !important; }
  .btn-cyan.btn-lighten-4:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-lighten-4 {
  border-color: #B2EBF2 !important;
  color: #B2EBF2 !important; }
  .btn-outline-cyan.btn-outline-lighten-4:hover {
    background-color: #B2EBF2 !important; }

.progress-cyan.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #B2EBF2; }

.progress-cyan.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #B2EBF2; }

.progress-cyan.progress-lighten-4[value]::-ms-fill {
  background-color: #B2EBF2; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-lighten-4 .progress-bar {
    background-color: #B2EBF2; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2EBF2 !important; }

.border-cyan.border-lighten-4 {
  border: 1px solid #B2EBF2 !important; }

.border-top-cyan.border-top-lighten-4 {
  border-top: 1px solid #B2EBF2 !important; }

.border-bottom-cyan.border-bottom-lighten-4 {
  border-bottom: 1px solid #B2EBF2 !important; }

.border-left-cyan.border-left-lighten-4 {
  border-left: 1px solid #B2EBF2 !important; }

.border-right-cyan.border-right-lighten-4 {
  border-right: 1px solid #B2EBF2 !important; }

.overlay-cyan.overlay-lighten-4 {
  background: #B2EBF2;
  /* The Fallback */
  background: rgba(178, 235, 242, 0.8); }

.cyan.lighten-3 {
  color: #80DEEA !important; }

.bg-cyan.bg-lighten-3 {
  background-color: #80DEEA !important; }

.btn-cyan.btn-lighten-3 {
  border-color: #80DEEA !important;
  background-color: #80DEEA !important; }
  .btn-cyan.btn-lighten-3:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-lighten-3 {
  border-color: #80DEEA !important;
  color: #80DEEA !important; }
  .btn-outline-cyan.btn-outline-lighten-3:hover {
    background-color: #80DEEA !important; }

.progress-cyan.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #80DEEA; }

.progress-cyan.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #80DEEA; }

.progress-cyan.progress-lighten-3[value]::-ms-fill {
  background-color: #80DEEA; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-lighten-3 .progress-bar {
    background-color: #80DEEA; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80DEEA !important; }

.border-cyan.border-lighten-3 {
  border: 1px solid #80DEEA !important; }

.border-top-cyan.border-top-lighten-3 {
  border-top: 1px solid #80DEEA !important; }

.border-bottom-cyan.border-bottom-lighten-3 {
  border-bottom: 1px solid #80DEEA !important; }

.border-left-cyan.border-left-lighten-3 {
  border-left: 1px solid #80DEEA !important; }

.border-right-cyan.border-right-lighten-3 {
  border-right: 1px solid #80DEEA !important; }

.overlay-cyan.overlay-lighten-3 {
  background: #80DEEA;
  /* The Fallback */
  background: rgba(128, 222, 234, 0.8); }

.cyan.lighten-2 {
  color: #4DD0E1 !important; }

.bg-cyan.bg-lighten-2 {
  background-color: #4DD0E1 !important; }

.btn-cyan.btn-lighten-2 {
  border-color: #4DD0E1 !important;
  background-color: #4DD0E1 !important; }
  .btn-cyan.btn-lighten-2:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-lighten-2 {
  border-color: #4DD0E1 !important;
  color: #4DD0E1 !important; }
  .btn-outline-cyan.btn-outline-lighten-2:hover {
    background-color: #4DD0E1 !important; }

.progress-cyan.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #4DD0E1; }

.progress-cyan.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #4DD0E1; }

.progress-cyan.progress-lighten-2[value]::-ms-fill {
  background-color: #4DD0E1; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-lighten-2 .progress-bar {
    background-color: #4DD0E1; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DD0E1 !important; }

.border-cyan.border-lighten-2 {
  border: 1px solid #4DD0E1 !important; }

.border-top-cyan.border-top-lighten-2 {
  border-top: 1px solid #4DD0E1 !important; }

.border-bottom-cyan.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DD0E1 !important; }

.border-left-cyan.border-left-lighten-2 {
  border-left: 1px solid #4DD0E1 !important; }

.border-right-cyan.border-right-lighten-2 {
  border-right: 1px solid #4DD0E1 !important; }

.overlay-cyan.overlay-lighten-2 {
  background: #4DD0E1;
  /* The Fallback */
  background: rgba(77, 208, 225, 0.8); }

.cyan.lighten-1 {
  color: #26C6DA !important; }

.bg-cyan.bg-lighten-1 {
  background-color: #26C6DA !important; }

.btn-cyan.btn-lighten-1 {
  border-color: #26C6DA !important;
  background-color: #26C6DA !important; }
  .btn-cyan.btn-lighten-1:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-lighten-1 {
  border-color: #26C6DA !important;
  color: #26C6DA !important; }
  .btn-outline-cyan.btn-outline-lighten-1:hover {
    background-color: #26C6DA !important; }

.progress-cyan.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #26C6DA; }

.progress-cyan.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #26C6DA; }

.progress-cyan.progress-lighten-1[value]::-ms-fill {
  background-color: #26C6DA; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-lighten-1 .progress-bar {
    background-color: #26C6DA; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26C6DA !important; }

.border-cyan.border-lighten-1 {
  border: 1px solid #26C6DA !important; }

.border-top-cyan.border-top-lighten-1 {
  border-top: 1px solid #26C6DA !important; }

.border-bottom-cyan.border-bottom-lighten-1 {
  border-bottom: 1px solid #26C6DA !important; }

.border-left-cyan.border-left-lighten-1 {
  border-left: 1px solid #26C6DA !important; }

.border-right-cyan.border-right-lighten-1 {
  border-right: 1px solid #26C6DA !important; }

.overlay-cyan.overlay-lighten-1 {
  background: #26C6DA;
  /* The Fallback */
  background: rgba(38, 198, 218, 0.8); }

.cyan {
  color: #00BCD4 !important; }

.bg-cyan {
  background-color: #00BCD4 !important; }
  .bg-cyan .card-header, .bg-cyan .card-footer {
    background-color: transparent; }

.alert-cyan {
  border-color: #00BCD4 !important;
  background-color: #1ce5ff !important;
  color: #001e22 !important; }
  .alert-cyan .alert-link {
    color: black !important; }

.border-cyan {
  border-color: #00BCD4; }

.bg-cyan.tag-glow, .border-cyan.tag-glow {
  box-shadow: 0px 0px 10px #00BCD4; }

.overlay-cyan {
  background: #00BCD4;
  /* The Fallback */
  background: rgba(0, 188, 212, 0.8); }

.card-outline-cyan {
  border-width: 1px;
  border-style: solid;
  border-color: #00BCD4;
  background-color: transparent; }
  .card-outline-cyan .card-header, .card-outline-cyan .card-footer {
    background-color: transparent; }

.btn-cyan {
  border-color: #00BCD4;
  background-color: #00BCD4;
  color: #FFFFFF; }
  .btn-cyan:hover {
    border-color: #4DD0E1;
    background-color: #4DD0E1;
    color: #FFF !important; }

.btn-outline-cyan {
  border-color: #00BCD4;
  background-color: transparent;
  color: #00BCD4; }
  .btn-outline-cyan:hover {
    background-color: #00BCD4;
    color: #FFF !important; }

.progress-cyan[value]::-webkit-progress-value {
  background-color: #00BCD4; }

.progress-cyan[value]::-moz-progress-bar {
  background-color: #00BCD4; }

.progress-cyan[value]::-ms-fill {
  background-color: #00BCD4; }

@media screen and (min-width: 0\0) {
  .progress-cyan .progress-bar {
    background-color: #00BCD4; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00BCD4 !important; }

.border-cyan {
  border: 1px solid #00BCD4 !important; }

.border-top-cyan {
  border-top: 1px solid #00BCD4; }

.border-bottom-cyan {
  border-bottom: 1px solid #00BCD4; }

.border-left-cyan {
  border-left: 1px solid #00BCD4; }

.border-right-cyan {
  border-right: 1px solid #00BCD4; }

.cyan.darken-1 {
  color: #00ACC1 !important; }

.bg-cyan.bg-darken-1 {
  background-color: #00ACC1 !important; }

.btn-cyan.btn-darken-1 {
  border-color: #00ACC1 !important;
  background-color: #00ACC1 !important; }
  .btn-cyan.btn-darken-1:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-darken-1 {
  border-color: #00ACC1 !important;
  color: #00ACC1 !important; }
  .btn-outline-cyan.btn-outline-darken-1:hover {
    background-color: #00ACC1 !important; }

.progress-cyan.progress-darken-1[value]::-webkit-progress-value {
  background-color: #00ACC1; }

.progress-cyan.progress-darken-1[value]::-moz-progress-bar {
  background-color: #00ACC1; }

.progress-cyan.progress-darken-1[value]::-ms-fill {
  background-color: #00ACC1; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-darken-1 .progress-bar {
    background-color: #00ACC1; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00ACC1 !important; }

.border-cyan.border-darken-1 {
  border: 1px solid #00ACC1 !important; }

.border-top-cyan.border-top-darken-1 {
  border-top: 1px solid #00ACC1 !important; }

.border-bottom-cyan.border-bottom-darken-1 {
  border-bottom: 1px solid #00ACC1 !important; }

.border-left-cyan.border-left-darken-1 {
  border-left: 1px solid #00ACC1 !important; }

.border-right-cyan.border-right-darken-1 {
  border-right: 1px solid #00ACC1 !important; }

.overlay-cyan.overlay-darken-1 {
  background: #00ACC1;
  /* The Fallback */
  background: rgba(0, 172, 193, 0.8); }

.cyan.darken-2 {
  color: #0097A7 !important; }

.bg-cyan.bg-darken-2 {
  background-color: #0097A7 !important; }

.btn-cyan.btn-darken-2 {
  border-color: #0097A7 !important;
  background-color: #0097A7 !important; }
  .btn-cyan.btn-darken-2:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-darken-2 {
  border-color: #0097A7 !important;
  color: #0097A7 !important; }
  .btn-outline-cyan.btn-outline-darken-2:hover {
    background-color: #0097A7 !important; }

.progress-cyan.progress-darken-2[value]::-webkit-progress-value {
  background-color: #0097A7; }

.progress-cyan.progress-darken-2[value]::-moz-progress-bar {
  background-color: #0097A7; }

.progress-cyan.progress-darken-2[value]::-ms-fill {
  background-color: #0097A7; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-darken-2 .progress-bar {
    background-color: #0097A7; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0097A7 !important; }

.border-cyan.border-darken-2 {
  border: 1px solid #0097A7 !important; }

.border-top-cyan.border-top-darken-2 {
  border-top: 1px solid #0097A7 !important; }

.border-bottom-cyan.border-bottom-darken-2 {
  border-bottom: 1px solid #0097A7 !important; }

.border-left-cyan.border-left-darken-2 {
  border-left: 1px solid #0097A7 !important; }

.border-right-cyan.border-right-darken-2 {
  border-right: 1px solid #0097A7 !important; }

.overlay-cyan.overlay-darken-2 {
  background: #0097A7;
  /* The Fallback */
  background: rgba(0, 151, 167, 0.8); }

.cyan.darken-3 {
  color: #00838F !important; }

.bg-cyan.bg-darken-3 {
  background-color: #00838F !important; }

.btn-cyan.btn-darken-3 {
  border-color: #00838F !important;
  background-color: #00838F !important; }
  .btn-cyan.btn-darken-3:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-darken-3 {
  border-color: #00838F !important;
  color: #00838F !important; }
  .btn-outline-cyan.btn-outline-darken-3:hover {
    background-color: #00838F !important; }

.progress-cyan.progress-darken-3[value]::-webkit-progress-value {
  background-color: #00838F; }

.progress-cyan.progress-darken-3[value]::-moz-progress-bar {
  background-color: #00838F; }

.progress-cyan.progress-darken-3[value]::-ms-fill {
  background-color: #00838F; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-darken-3 .progress-bar {
    background-color: #00838F; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00838F !important; }

.border-cyan.border-darken-3 {
  border: 1px solid #00838F !important; }

.border-top-cyan.border-top-darken-3 {
  border-top: 1px solid #00838F !important; }

.border-bottom-cyan.border-bottom-darken-3 {
  border-bottom: 1px solid #00838F !important; }

.border-left-cyan.border-left-darken-3 {
  border-left: 1px solid #00838F !important; }

.border-right-cyan.border-right-darken-3 {
  border-right: 1px solid #00838F !important; }

.overlay-cyan.overlay-darken-3 {
  background: #00838F;
  /* The Fallback */
  background: rgba(0, 131, 143, 0.8); }

.cyan.darken-4 {
  color: #006064 !important; }

.bg-cyan.bg-darken-4 {
  background-color: #006064 !important; }

.btn-cyan.btn-darken-4 {
  border-color: #006064 !important;
  background-color: #006064 !important; }
  .btn-cyan.btn-darken-4:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-darken-4 {
  border-color: #006064 !important;
  color: #006064 !important; }
  .btn-outline-cyan.btn-outline-darken-4:hover {
    background-color: #006064 !important; }

.progress-cyan.progress-darken-4[value]::-webkit-progress-value {
  background-color: #006064; }

.progress-cyan.progress-darken-4[value]::-moz-progress-bar {
  background-color: #006064; }

.progress-cyan.progress-darken-4[value]::-ms-fill {
  background-color: #006064; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-darken-4 .progress-bar {
    background-color: #006064; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006064 !important; }

.border-cyan.border-darken-4 {
  border: 1px solid #006064 !important; }

.border-top-cyan.border-top-darken-4 {
  border-top: 1px solid #006064 !important; }

.border-bottom-cyan.border-bottom-darken-4 {
  border-bottom: 1px solid #006064 !important; }

.border-left-cyan.border-left-darken-4 {
  border-left: 1px solid #006064 !important; }

.border-right-cyan.border-right-darken-4 {
  border-right: 1px solid #006064 !important; }

.overlay-cyan.overlay-darken-4 {
  background: #006064;
  /* The Fallback */
  background: rgba(0, 96, 100, 0.8); }

.cyan.accent-1 {
  color: #84FFFF !important; }

.bg-cyan.bg-accent-1 {
  background-color: #84FFFF !important; }

.btn-cyan.btn-accent-1 {
  border-color: #84FFFF !important;
  background-color: #84FFFF !important; }
  .btn-cyan.btn-accent-1:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-accent-1 {
  border-color: #84FFFF !important;
  color: #84FFFF !important; }
  .btn-outline-cyan.btn-outline-accent-1:hover {
    background-color: #84FFFF !important; }

.progress-cyan.progress-accent-1[value]::-webkit-progress-value {
  background-color: #84FFFF; }

.progress-cyan.progress-accent-1[value]::-moz-progress-bar {
  background-color: #84FFFF; }

.progress-cyan.progress-accent-1[value]::-ms-fill {
  background-color: #84FFFF; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-accent-1 .progress-bar {
    background-color: #84FFFF; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84FFFF !important; }

.border-cyan.border-accent-1 {
  border: 1px solid #84FFFF !important; }

.border-top-cyan.border-top-accent-1 {
  border-top: 1px solid #84FFFF !important; }

.border-bottom-cyan.border-bottom-accent-1 {
  border-bottom: 1px solid #84FFFF !important; }

.border-left-cyan.border-left-accent-1 {
  border-left: 1px solid #84FFFF !important; }

.border-right-cyan.border-right-accent-1 {
  border-right: 1px solid #84FFFF !important; }

.overlay-cyan.overlay-accent-1 {
  background: #84FFFF;
  /* The Fallback */
  background: rgba(132, 255, 255, 0.8); }

.cyan.accent-2 {
  color: #18FFFF !important; }

.bg-cyan.bg-accent-2 {
  background-color: #18FFFF !important; }

.btn-cyan.btn-accent-2 {
  border-color: #18FFFF !important;
  background-color: #18FFFF !important; }
  .btn-cyan.btn-accent-2:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-accent-2 {
  border-color: #18FFFF !important;
  color: #18FFFF !important; }
  .btn-outline-cyan.btn-outline-accent-2:hover {
    background-color: #18FFFF !important; }

.progress-cyan.progress-accent-2[value]::-webkit-progress-value {
  background-color: #18FFFF; }

.progress-cyan.progress-accent-2[value]::-moz-progress-bar {
  background-color: #18FFFF; }

.progress-cyan.progress-accent-2[value]::-ms-fill {
  background-color: #18FFFF; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-accent-2 .progress-bar {
    background-color: #18FFFF; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #18FFFF !important; }

.border-cyan.border-accent-2 {
  border: 1px solid #18FFFF !important; }

.border-top-cyan.border-top-accent-2 {
  border-top: 1px solid #18FFFF !important; }

.border-bottom-cyan.border-bottom-accent-2 {
  border-bottom: 1px solid #18FFFF !important; }

.border-left-cyan.border-left-accent-2 {
  border-left: 1px solid #18FFFF !important; }

.border-right-cyan.border-right-accent-2 {
  border-right: 1px solid #18FFFF !important; }

.overlay-cyan.overlay-accent-2 {
  background: #18FFFF;
  /* The Fallback */
  background: rgba(24, 255, 255, 0.8); }

.cyan.accent-3 {
  color: #00E5FF !important; }

.bg-cyan.bg-accent-3 {
  background-color: #00E5FF !important; }

.btn-cyan.btn-accent-3 {
  border-color: #00E5FF !important;
  background-color: #00E5FF !important; }
  .btn-cyan.btn-accent-3:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-accent-3 {
  border-color: #00E5FF !important;
  color: #00E5FF !important; }
  .btn-outline-cyan.btn-outline-accent-3:hover {
    background-color: #00E5FF !important; }

.progress-cyan.progress-accent-3[value]::-webkit-progress-value {
  background-color: #00E5FF; }

.progress-cyan.progress-accent-3[value]::-moz-progress-bar {
  background-color: #00E5FF; }

.progress-cyan.progress-accent-3[value]::-ms-fill {
  background-color: #00E5FF; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-accent-3 .progress-bar {
    background-color: #00E5FF; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00E5FF !important; }

.border-cyan.border-accent-3 {
  border: 1px solid #00E5FF !important; }

.border-top-cyan.border-top-accent-3 {
  border-top: 1px solid #00E5FF !important; }

.border-bottom-cyan.border-bottom-accent-3 {
  border-bottom: 1px solid #00E5FF !important; }

.border-left-cyan.border-left-accent-3 {
  border-left: 1px solid #00E5FF !important; }

.border-right-cyan.border-right-accent-3 {
  border-right: 1px solid #00E5FF !important; }

.overlay-cyan.overlay-accent-3 {
  background: #00E5FF;
  /* The Fallback */
  background: rgba(0, 229, 255, 0.8); }

.cyan.accent-4 {
  color: #00B8D4 !important; }

.bg-cyan.bg-accent-4 {
  background-color: #00B8D4 !important; }

.btn-cyan.btn-accent-4 {
  border-color: #00B8D4 !important;
  background-color: #00B8D4 !important; }
  .btn-cyan.btn-accent-4:hover {
    border-color: #4DD0E1 !important;
    background-color: #4DD0E1 !important; }

.btn-outline-cyan.btn-outline-accent-4 {
  border-color: #00B8D4 !important;
  color: #00B8D4 !important; }
  .btn-outline-cyan.btn-outline-accent-4:hover {
    background-color: #00B8D4 !important; }

.progress-cyan.progress-accent-4[value]::-webkit-progress-value {
  background-color: #00B8D4; }

.progress-cyan.progress-accent-4[value]::-moz-progress-bar {
  background-color: #00B8D4; }

.progress-cyan.progress-accent-4[value]::-ms-fill {
  background-color: #00B8D4; }

@media screen and (min-width: 0\0) {
  .progress-cyan.progress-accent-4 .progress-bar {
    background-color: #00B8D4; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00B8D4 !important; }

.border-cyan.border-accent-4 {
  border: 1px solid #00B8D4 !important; }

.border-top-cyan.border-top-accent-4 {
  border-top: 1px solid #00B8D4 !important; }

.border-bottom-cyan.border-bottom-accent-4 {
  border-bottom: 1px solid #00B8D4 !important; }

.border-left-cyan.border-left-accent-4 {
  border-left: 1px solid #00B8D4 !important; }

.border-right-cyan.border-right-accent-4 {
  border-right: 1px solid #00B8D4 !important; }

.overlay-cyan.overlay-accent-4 {
  background: #00B8D4;
  /* The Fallback */
  background: rgba(0, 184, 212, 0.8); }

.teal.lighten-5 {
  color: #E0F2F1 !important; }

.bg-teal.bg-lighten-5 {
  background-color: #E0F2F1 !important; }

.btn-teal.btn-lighten-5 {
  border-color: #E0F2F1 !important;
  background-color: #E0F2F1 !important; }
  .btn-teal.btn-lighten-5:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-lighten-5 {
  border-color: #E0F2F1 !important;
  color: #E0F2F1 !important; }
  .btn-outline-teal.btn-outline-lighten-5:hover {
    background-color: #E0F2F1 !important; }

.progress-teal.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #E0F2F1; }

.progress-teal.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #E0F2F1; }

.progress-teal.progress-lighten-5[value]::-ms-fill {
  background-color: #E0F2F1; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-lighten-5 .progress-bar {
    background-color: #E0F2F1; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F2F1 !important; }

.border-teal.border-lighten-5 {
  border: 1px solid #E0F2F1 !important; }

.border-top-teal.border-top-lighten-5 {
  border-top: 1px solid #E0F2F1 !important; }

.border-bottom-teal.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F2F1 !important; }

.border-left-teal.border-left-lighten-5 {
  border-left: 1px solid #E0F2F1 !important; }

.border-right-teal.border-right-lighten-5 {
  border-right: 1px solid #E0F2F1 !important; }

.overlay-teal.overlay-lighten-5 {
  background: #E0F2F1;
  /* The Fallback */
  background: rgba(224, 242, 241, 0.8); }

.teal.lighten-4 {
  color: #B2DFDB !important; }

.bg-teal.bg-lighten-4 {
  background-color: #B2DFDB !important; }

.btn-teal.btn-lighten-4 {
  border-color: #B2DFDB !important;
  background-color: #B2DFDB !important; }
  .btn-teal.btn-lighten-4:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-lighten-4 {
  border-color: #B2DFDB !important;
  color: #B2DFDB !important; }
  .btn-outline-teal.btn-outline-lighten-4:hover {
    background-color: #B2DFDB !important; }

.progress-teal.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #B2DFDB; }

.progress-teal.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #B2DFDB; }

.progress-teal.progress-lighten-4[value]::-ms-fill {
  background-color: #B2DFDB; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-lighten-4 .progress-bar {
    background-color: #B2DFDB; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2DFDB !important; }

.border-teal.border-lighten-4 {
  border: 1px solid #B2DFDB !important; }

.border-top-teal.border-top-lighten-4 {
  border-top: 1px solid #B2DFDB !important; }

.border-bottom-teal.border-bottom-lighten-4 {
  border-bottom: 1px solid #B2DFDB !important; }

.border-left-teal.border-left-lighten-4 {
  border-left: 1px solid #B2DFDB !important; }

.border-right-teal.border-right-lighten-4 {
  border-right: 1px solid #B2DFDB !important; }

.overlay-teal.overlay-lighten-4 {
  background: #B2DFDB;
  /* The Fallback */
  background: rgba(178, 223, 219, 0.8); }

.teal.lighten-3 {
  color: #80CBC4 !important; }

.bg-teal.bg-lighten-3 {
  background-color: #80CBC4 !important; }

.btn-teal.btn-lighten-3 {
  border-color: #80CBC4 !important;
  background-color: #80CBC4 !important; }
  .btn-teal.btn-lighten-3:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-lighten-3 {
  border-color: #80CBC4 !important;
  color: #80CBC4 !important; }
  .btn-outline-teal.btn-outline-lighten-3:hover {
    background-color: #80CBC4 !important; }

.progress-teal.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #80CBC4; }

.progress-teal.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #80CBC4; }

.progress-teal.progress-lighten-3[value]::-ms-fill {
  background-color: #80CBC4; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-lighten-3 .progress-bar {
    background-color: #80CBC4; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80CBC4 !important; }

.border-teal.border-lighten-3 {
  border: 1px solid #80CBC4 !important; }

.border-top-teal.border-top-lighten-3 {
  border-top: 1px solid #80CBC4 !important; }

.border-bottom-teal.border-bottom-lighten-3 {
  border-bottom: 1px solid #80CBC4 !important; }

.border-left-teal.border-left-lighten-3 {
  border-left: 1px solid #80CBC4 !important; }

.border-right-teal.border-right-lighten-3 {
  border-right: 1px solid #80CBC4 !important; }

.overlay-teal.overlay-lighten-3 {
  background: #80CBC4;
  /* The Fallback */
  background: rgba(128, 203, 196, 0.8); }

.teal.lighten-2 {
  color: #4DB6AC !important; }

.bg-teal.bg-lighten-2 {
  background-color: #4DB6AC !important; }

.btn-teal.btn-lighten-2 {
  border-color: #4DB6AC !important;
  background-color: #4DB6AC !important; }
  .btn-teal.btn-lighten-2:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-lighten-2 {
  border-color: #4DB6AC !important;
  color: #4DB6AC !important; }
  .btn-outline-teal.btn-outline-lighten-2:hover {
    background-color: #4DB6AC !important; }

.progress-teal.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #4DB6AC; }

.progress-teal.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #4DB6AC; }

.progress-teal.progress-lighten-2[value]::-ms-fill {
  background-color: #4DB6AC; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-lighten-2 .progress-bar {
    background-color: #4DB6AC; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DB6AC !important; }

.border-teal.border-lighten-2 {
  border: 1px solid #4DB6AC !important; }

.border-top-teal.border-top-lighten-2 {
  border-top: 1px solid #4DB6AC !important; }

.border-bottom-teal.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DB6AC !important; }

.border-left-teal.border-left-lighten-2 {
  border-left: 1px solid #4DB6AC !important; }

.border-right-teal.border-right-lighten-2 {
  border-right: 1px solid #4DB6AC !important; }

.overlay-teal.overlay-lighten-2 {
  background: #4DB6AC;
  /* The Fallback */
  background: rgba(77, 182, 172, 0.8); }

.teal.lighten-1 {
  color: #26A69A !important; }

.bg-teal.bg-lighten-1 {
  background-color: #26A69A !important; }

.btn-teal.btn-lighten-1 {
  border-color: #26A69A !important;
  background-color: #26A69A !important; }
  .btn-teal.btn-lighten-1:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-lighten-1 {
  border-color: #26A69A !important;
  color: #26A69A !important; }
  .btn-outline-teal.btn-outline-lighten-1:hover {
    background-color: #26A69A !important; }

.progress-teal.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #26A69A; }

.progress-teal.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #26A69A; }

.progress-teal.progress-lighten-1[value]::-ms-fill {
  background-color: #26A69A; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-lighten-1 .progress-bar {
    background-color: #26A69A; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26A69A !important; }

.border-teal.border-lighten-1 {
  border: 1px solid #26A69A !important; }

.border-top-teal.border-top-lighten-1 {
  border-top: 1px solid #26A69A !important; }

.border-bottom-teal.border-bottom-lighten-1 {
  border-bottom: 1px solid #26A69A !important; }

.border-left-teal.border-left-lighten-1 {
  border-left: 1px solid #26A69A !important; }

.border-right-teal.border-right-lighten-1 {
  border-right: 1px solid #26A69A !important; }

.overlay-teal.overlay-lighten-1 {
  background: #26A69A;
  /* The Fallback */
  background: rgba(38, 166, 154, 0.8); }

.teal {
  color: #009688 !important; }

.bg-teal {
  background-color: #009688 !important; }
  .bg-teal .card-header, .bg-teal .card-footer {
    background-color: transparent; }

.alert-teal {
  border-color: #009688 !important;
  background-color: #00ddc9 !important;
  color: black !important; }
  .alert-teal .alert-link {
    color: black !important; }

.border-teal {
  border-color: #009688; }

.bg-teal.tag-glow, .border-teal.tag-glow {
  box-shadow: 0px 0px 10px #009688; }

.overlay-teal {
  background: #009688;
  /* The Fallback */
  background: rgba(0, 150, 136, 0.8); }

.card-outline-teal {
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  background-color: transparent; }
  .card-outline-teal .card-header, .card-outline-teal .card-footer {
    background-color: transparent; }

.btn-teal {
  border-color: #009688;
  background-color: #009688;
  color: #FFFFFF; }
  .btn-teal:hover {
    border-color: #4DB6AC;
    background-color: #4DB6AC;
    color: #FFF !important; }

.btn-outline-teal {
  border-color: #009688;
  background-color: transparent;
  color: #009688; }
  .btn-outline-teal:hover {
    background-color: #009688;
    color: #FFF !important; }

.progress-teal[value]::-webkit-progress-value {
  background-color: #009688; }

.progress-teal[value]::-moz-progress-bar {
  background-color: #009688; }

.progress-teal[value]::-ms-fill {
  background-color: #009688; }

@media screen and (min-width: 0\0) {
  .progress-teal .progress-bar {
    background-color: #009688; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009688 !important; }

.border-teal {
  border: 1px solid #009688 !important; }

.border-top-teal {
  border-top: 1px solid #009688; }

.border-bottom-teal {
  border-bottom: 1px solid #009688; }

.border-left-teal {
  border-left: 1px solid #009688; }

.border-right-teal {
  border-right: 1px solid #009688; }

.teal.darken-1 {
  color: #00897B !important; }

.bg-teal.bg-darken-1 {
  background-color: #00897B !important; }

.btn-teal.btn-darken-1 {
  border-color: #00897B !important;
  background-color: #00897B !important; }
  .btn-teal.btn-darken-1:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-darken-1 {
  border-color: #00897B !important;
  color: #00897B !important; }
  .btn-outline-teal.btn-outline-darken-1:hover {
    background-color: #00897B !important; }

.progress-teal.progress-darken-1[value]::-webkit-progress-value {
  background-color: #00897B; }

.progress-teal.progress-darken-1[value]::-moz-progress-bar {
  background-color: #00897B; }

.progress-teal.progress-darken-1[value]::-ms-fill {
  background-color: #00897B; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-darken-1 .progress-bar {
    background-color: #00897B; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00897B !important; }

.border-teal.border-darken-1 {
  border: 1px solid #00897B !important; }

.border-top-teal.border-top-darken-1 {
  border-top: 1px solid #00897B !important; }

.border-bottom-teal.border-bottom-darken-1 {
  border-bottom: 1px solid #00897B !important; }

.border-left-teal.border-left-darken-1 {
  border-left: 1px solid #00897B !important; }

.border-right-teal.border-right-darken-1 {
  border-right: 1px solid #00897B !important; }

.overlay-teal.overlay-darken-1 {
  background: #00897B;
  /* The Fallback */
  background: rgba(0, 137, 123, 0.8); }

.teal.darken-2 {
  color: #00796B !important; }

.bg-teal.bg-darken-2 {
  background-color: #00796B !important; }

.btn-teal.btn-darken-2 {
  border-color: #00796B !important;
  background-color: #00796B !important; }
  .btn-teal.btn-darken-2:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-darken-2 {
  border-color: #00796B !important;
  color: #00796B !important; }
  .btn-outline-teal.btn-outline-darken-2:hover {
    background-color: #00796B !important; }

.progress-teal.progress-darken-2[value]::-webkit-progress-value {
  background-color: #00796B; }

.progress-teal.progress-darken-2[value]::-moz-progress-bar {
  background-color: #00796B; }

.progress-teal.progress-darken-2[value]::-ms-fill {
  background-color: #00796B; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-darken-2 .progress-bar {
    background-color: #00796B; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00796B !important; }

.border-teal.border-darken-2 {
  border: 1px solid #00796B !important; }

.border-top-teal.border-top-darken-2 {
  border-top: 1px solid #00796B !important; }

.border-bottom-teal.border-bottom-darken-2 {
  border-bottom: 1px solid #00796B !important; }

.border-left-teal.border-left-darken-2 {
  border-left: 1px solid #00796B !important; }

.border-right-teal.border-right-darken-2 {
  border-right: 1px solid #00796B !important; }

.overlay-teal.overlay-darken-2 {
  background: #00796B;
  /* The Fallback */
  background: rgba(0, 121, 107, 0.8); }

.teal.darken-3 {
  color: #00695C !important; }

.bg-teal.bg-darken-3 {
  background-color: #00695C !important; }

.btn-teal.btn-darken-3 {
  border-color: #00695C !important;
  background-color: #00695C !important; }
  .btn-teal.btn-darken-3:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-darken-3 {
  border-color: #00695C !important;
  color: #00695C !important; }
  .btn-outline-teal.btn-outline-darken-3:hover {
    background-color: #00695C !important; }

.progress-teal.progress-darken-3[value]::-webkit-progress-value {
  background-color: #00695C; }

.progress-teal.progress-darken-3[value]::-moz-progress-bar {
  background-color: #00695C; }

.progress-teal.progress-darken-3[value]::-ms-fill {
  background-color: #00695C; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-darken-3 .progress-bar {
    background-color: #00695C; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00695C !important; }

.border-teal.border-darken-3 {
  border: 1px solid #00695C !important; }

.border-top-teal.border-top-darken-3 {
  border-top: 1px solid #00695C !important; }

.border-bottom-teal.border-bottom-darken-3 {
  border-bottom: 1px solid #00695C !important; }

.border-left-teal.border-left-darken-3 {
  border-left: 1px solid #00695C !important; }

.border-right-teal.border-right-darken-3 {
  border-right: 1px solid #00695C !important; }

.overlay-teal.overlay-darken-3 {
  background: #00695C;
  /* The Fallback */
  background: rgba(0, 105, 92, 0.8); }

.teal.darken-4 {
  color: #004D40 !important; }

.bg-teal.bg-darken-4 {
  background-color: #004D40 !important; }

.btn-teal.btn-darken-4 {
  border-color: #004D40 !important;
  background-color: #004D40 !important; }
  .btn-teal.btn-darken-4:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-darken-4 {
  border-color: #004D40 !important;
  color: #004D40 !important; }
  .btn-outline-teal.btn-outline-darken-4:hover {
    background-color: #004D40 !important; }

.progress-teal.progress-darken-4[value]::-webkit-progress-value {
  background-color: #004D40; }

.progress-teal.progress-darken-4[value]::-moz-progress-bar {
  background-color: #004D40; }

.progress-teal.progress-darken-4[value]::-ms-fill {
  background-color: #004D40; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-darken-4 .progress-bar {
    background-color: #004D40; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #004D40 !important; }

.border-teal.border-darken-4 {
  border: 1px solid #004D40 !important; }

.border-top-teal.border-top-darken-4 {
  border-top: 1px solid #004D40 !important; }

.border-bottom-teal.border-bottom-darken-4 {
  border-bottom: 1px solid #004D40 !important; }

.border-left-teal.border-left-darken-4 {
  border-left: 1px solid #004D40 !important; }

.border-right-teal.border-right-darken-4 {
  border-right: 1px solid #004D40 !important; }

.overlay-teal.overlay-darken-4 {
  background: #004D40;
  /* The Fallback */
  background: rgba(0, 77, 64, 0.8); }

.teal.accent-1 {
  color: #A7FFEB !important; }

.bg-teal.bg-accent-1 {
  background-color: #A7FFEB !important; }

.btn-teal.btn-accent-1 {
  border-color: #A7FFEB !important;
  background-color: #A7FFEB !important; }
  .btn-teal.btn-accent-1:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-accent-1 {
  border-color: #A7FFEB !important;
  color: #A7FFEB !important; }
  .btn-outline-teal.btn-outline-accent-1:hover {
    background-color: #A7FFEB !important; }

.progress-teal.progress-accent-1[value]::-webkit-progress-value {
  background-color: #A7FFEB; }

.progress-teal.progress-accent-1[value]::-moz-progress-bar {
  background-color: #A7FFEB; }

.progress-teal.progress-accent-1[value]::-ms-fill {
  background-color: #A7FFEB; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-accent-1 .progress-bar {
    background-color: #A7FFEB; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A7FFEB !important; }

.border-teal.border-accent-1 {
  border: 1px solid #A7FFEB !important; }

.border-top-teal.border-top-accent-1 {
  border-top: 1px solid #A7FFEB !important; }

.border-bottom-teal.border-bottom-accent-1 {
  border-bottom: 1px solid #A7FFEB !important; }

.border-left-teal.border-left-accent-1 {
  border-left: 1px solid #A7FFEB !important; }

.border-right-teal.border-right-accent-1 {
  border-right: 1px solid #A7FFEB !important; }

.overlay-teal.overlay-accent-1 {
  background: #A7FFEB;
  /* The Fallback */
  background: rgba(167, 255, 235, 0.8); }

.teal.accent-2 {
  color: #64FFDA !important; }

.bg-teal.bg-accent-2 {
  background-color: #64FFDA !important; }

.btn-teal.btn-accent-2 {
  border-color: #64FFDA !important;
  background-color: #64FFDA !important; }
  .btn-teal.btn-accent-2:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-accent-2 {
  border-color: #64FFDA !important;
  color: #64FFDA !important; }
  .btn-outline-teal.btn-outline-accent-2:hover {
    background-color: #64FFDA !important; }

.progress-teal.progress-accent-2[value]::-webkit-progress-value {
  background-color: #64FFDA; }

.progress-teal.progress-accent-2[value]::-moz-progress-bar {
  background-color: #64FFDA; }

.progress-teal.progress-accent-2[value]::-ms-fill {
  background-color: #64FFDA; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-accent-2 .progress-bar {
    background-color: #64FFDA; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64FFDA !important; }

.border-teal.border-accent-2 {
  border: 1px solid #64FFDA !important; }

.border-top-teal.border-top-accent-2 {
  border-top: 1px solid #64FFDA !important; }

.border-bottom-teal.border-bottom-accent-2 {
  border-bottom: 1px solid #64FFDA !important; }

.border-left-teal.border-left-accent-2 {
  border-left: 1px solid #64FFDA !important; }

.border-right-teal.border-right-accent-2 {
  border-right: 1px solid #64FFDA !important; }

.overlay-teal.overlay-accent-2 {
  background: #64FFDA;
  /* The Fallback */
  background: rgba(100, 255, 218, 0.8); }

.teal.accent-3 {
  color: #1DE9B6 !important; }

.bg-teal.bg-accent-3 {
  background-color: #1DE9B6 !important; }

.btn-teal.btn-accent-3 {
  border-color: #1DE9B6 !important;
  background-color: #1DE9B6 !important; }
  .btn-teal.btn-accent-3:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-accent-3 {
  border-color: #1DE9B6 !important;
  color: #1DE9B6 !important; }
  .btn-outline-teal.btn-outline-accent-3:hover {
    background-color: #1DE9B6 !important; }

.progress-teal.progress-accent-3[value]::-webkit-progress-value {
  background-color: #1DE9B6; }

.progress-teal.progress-accent-3[value]::-moz-progress-bar {
  background-color: #1DE9B6; }

.progress-teal.progress-accent-3[value]::-ms-fill {
  background-color: #1DE9B6; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-accent-3 .progress-bar {
    background-color: #1DE9B6; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1DE9B6 !important; }

.border-teal.border-accent-3 {
  border: 1px solid #1DE9B6 !important; }

.border-top-teal.border-top-accent-3 {
  border-top: 1px solid #1DE9B6 !important; }

.border-bottom-teal.border-bottom-accent-3 {
  border-bottom: 1px solid #1DE9B6 !important; }

.border-left-teal.border-left-accent-3 {
  border-left: 1px solid #1DE9B6 !important; }

.border-right-teal.border-right-accent-3 {
  border-right: 1px solid #1DE9B6 !important; }

.overlay-teal.overlay-accent-3 {
  background: #1DE9B6;
  /* The Fallback */
  background: rgba(29, 233, 182, 0.8); }

.teal.accent-4 {
  color: #00BFA5 !important; }

.bg-teal.bg-accent-4 {
  background-color: #00BFA5 !important; }

.btn-teal.btn-accent-4 {
  border-color: #00BFA5 !important;
  background-color: #00BFA5 !important; }
  .btn-teal.btn-accent-4:hover {
    border-color: #4DB6AC !important;
    background-color: #4DB6AC !important; }

.btn-outline-teal.btn-outline-accent-4 {
  border-color: #00BFA5 !important;
  color: #00BFA5 !important; }
  .btn-outline-teal.btn-outline-accent-4:hover {
    background-color: #00BFA5 !important; }

.progress-teal.progress-accent-4[value]::-webkit-progress-value {
  background-color: #00BFA5; }

.progress-teal.progress-accent-4[value]::-moz-progress-bar {
  background-color: #00BFA5; }

.progress-teal.progress-accent-4[value]::-ms-fill {
  background-color: #00BFA5; }

@media screen and (min-width: 0\0) {
  .progress-teal.progress-accent-4 .progress-bar {
    background-color: #00BFA5; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00BFA5 !important; }

.border-teal.border-accent-4 {
  border: 1px solid #00BFA5 !important; }

.border-top-teal.border-top-accent-4 {
  border-top: 1px solid #00BFA5 !important; }

.border-bottom-teal.border-bottom-accent-4 {
  border-bottom: 1px solid #00BFA5 !important; }

.border-left-teal.border-left-accent-4 {
  border-left: 1px solid #00BFA5 !important; }

.border-right-teal.border-right-accent-4 {
  border-right: 1px solid #00BFA5 !important; }

.overlay-teal.overlay-accent-4 {
  background: #00BFA5;
  /* The Fallback */
  background: rgba(0, 191, 165, 0.8); }

.green.lighten-5 {
  color: #E8F5E9 !important; }

.bg-green.bg-lighten-5 {
  background-color: #E8F5E9 !important; }

.btn-green.btn-lighten-5 {
  border-color: #E8F5E9 !important;
  background-color: #E8F5E9 !important; }
  .btn-green.btn-lighten-5:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-lighten-5 {
  border-color: #E8F5E9 !important;
  color: #E8F5E9 !important; }
  .btn-outline-green.btn-outline-lighten-5:hover {
    background-color: #E8F5E9 !important; }

.progress-green.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #E8F5E9; }

.progress-green.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #E8F5E9; }

.progress-green.progress-lighten-5[value]::-ms-fill {
  background-color: #E8F5E9; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-lighten-5 .progress-bar {
    background-color: #E8F5E9; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E8F5E9 !important; }

.border-green.border-lighten-5 {
  border: 1px solid #E8F5E9 !important; }

.border-top-green.border-top-lighten-5 {
  border-top: 1px solid #E8F5E9 !important; }

.border-bottom-green.border-bottom-lighten-5 {
  border-bottom: 1px solid #E8F5E9 !important; }

.border-left-green.border-left-lighten-5 {
  border-left: 1px solid #E8F5E9 !important; }

.border-right-green.border-right-lighten-5 {
  border-right: 1px solid #E8F5E9 !important; }

.overlay-green.overlay-lighten-5 {
  background: #E8F5E9;
  /* The Fallback */
  background: rgba(232, 245, 233, 0.8); }

.green.lighten-4 {
  color: #C8E6C9 !important; }

.bg-green.bg-lighten-4 {
  background-color: #C8E6C9 !important; }

.btn-green.btn-lighten-4 {
  border-color: #C8E6C9 !important;
  background-color: #C8E6C9 !important; }
  .btn-green.btn-lighten-4:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-lighten-4 {
  border-color: #C8E6C9 !important;
  color: #C8E6C9 !important; }
  .btn-outline-green.btn-outline-lighten-4:hover {
    background-color: #C8E6C9 !important; }

.progress-green.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #C8E6C9; }

.progress-green.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #C8E6C9; }

.progress-green.progress-lighten-4[value]::-ms-fill {
  background-color: #C8E6C9; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-lighten-4 .progress-bar {
    background-color: #C8E6C9; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C8E6C9 !important; }

.border-green.border-lighten-4 {
  border: 1px solid #C8E6C9 !important; }

.border-top-green.border-top-lighten-4 {
  border-top: 1px solid #C8E6C9 !important; }

.border-bottom-green.border-bottom-lighten-4 {
  border-bottom: 1px solid #C8E6C9 !important; }

.border-left-green.border-left-lighten-4 {
  border-left: 1px solid #C8E6C9 !important; }

.border-right-green.border-right-lighten-4 {
  border-right: 1px solid #C8E6C9 !important; }

.overlay-green.overlay-lighten-4 {
  background: #C8E6C9;
  /* The Fallback */
  background: rgba(200, 230, 201, 0.8); }

.green.lighten-3 {
  color: #A5D6A7 !important; }

.bg-green.bg-lighten-3 {
  background-color: #A5D6A7 !important; }

.btn-green.btn-lighten-3 {
  border-color: #A5D6A7 !important;
  background-color: #A5D6A7 !important; }
  .btn-green.btn-lighten-3:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-lighten-3 {
  border-color: #A5D6A7 !important;
  color: #A5D6A7 !important; }
  .btn-outline-green.btn-outline-lighten-3:hover {
    background-color: #A5D6A7 !important; }

.progress-green.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #A5D6A7; }

.progress-green.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #A5D6A7; }

.progress-green.progress-lighten-3[value]::-ms-fill {
  background-color: #A5D6A7; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-lighten-3 .progress-bar {
    background-color: #A5D6A7; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A5D6A7 !important; }

.border-green.border-lighten-3 {
  border: 1px solid #A5D6A7 !important; }

.border-top-green.border-top-lighten-3 {
  border-top: 1px solid #A5D6A7 !important; }

.border-bottom-green.border-bottom-lighten-3 {
  border-bottom: 1px solid #A5D6A7 !important; }

.border-left-green.border-left-lighten-3 {
  border-left: 1px solid #A5D6A7 !important; }

.border-right-green.border-right-lighten-3 {
  border-right: 1px solid #A5D6A7 !important; }

.overlay-green.overlay-lighten-3 {
  background: #A5D6A7;
  /* The Fallback */
  background: rgba(165, 214, 167, 0.8); }

.green.lighten-2 {
  color: #81C784 !important; }

.bg-green.bg-lighten-2 {
  background-color: #81C784 !important; }

.btn-green.btn-lighten-2 {
  border-color: #81C784 !important;
  background-color: #81C784 !important; }
  .btn-green.btn-lighten-2:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-lighten-2 {
  border-color: #81C784 !important;
  color: #81C784 !important; }
  .btn-outline-green.btn-outline-lighten-2:hover {
    background-color: #81C784 !important; }

.progress-green.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #81C784; }

.progress-green.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #81C784; }

.progress-green.progress-lighten-2[value]::-ms-fill {
  background-color: #81C784; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-lighten-2 .progress-bar {
    background-color: #81C784; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81C784 !important; }

.border-green.border-lighten-2 {
  border: 1px solid #81C784 !important; }

.border-top-green.border-top-lighten-2 {
  border-top: 1px solid #81C784 !important; }

.border-bottom-green.border-bottom-lighten-2 {
  border-bottom: 1px solid #81C784 !important; }

.border-left-green.border-left-lighten-2 {
  border-left: 1px solid #81C784 !important; }

.border-right-green.border-right-lighten-2 {
  border-right: 1px solid #81C784 !important; }

.overlay-green.overlay-lighten-2 {
  background: #81C784;
  /* The Fallback */
  background: rgba(129, 199, 132, 0.8); }

.green.lighten-1 {
  color: #66BB6A !important; }

.bg-green.bg-lighten-1 {
  background-color: #66BB6A !important; }

.btn-green.btn-lighten-1 {
  border-color: #66BB6A !important;
  background-color: #66BB6A !important; }
  .btn-green.btn-lighten-1:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-lighten-1 {
  border-color: #66BB6A !important;
  color: #66BB6A !important; }
  .btn-outline-green.btn-outline-lighten-1:hover {
    background-color: #66BB6A !important; }

.progress-green.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #66BB6A; }

.progress-green.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #66BB6A; }

.progress-green.progress-lighten-1[value]::-ms-fill {
  background-color: #66BB6A; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-lighten-1 .progress-bar {
    background-color: #66BB6A; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #66BB6A !important; }

.border-green.border-lighten-1 {
  border: 1px solid #66BB6A !important; }

.border-top-green.border-top-lighten-1 {
  border-top: 1px solid #66BB6A !important; }

.border-bottom-green.border-bottom-lighten-1 {
  border-bottom: 1px solid #66BB6A !important; }

.border-left-green.border-left-lighten-1 {
  border-left: 1px solid #66BB6A !important; }

.border-right-green.border-right-lighten-1 {
  border-right: 1px solid #66BB6A !important; }

.overlay-green.overlay-lighten-1 {
  background: #66BB6A;
  /* The Fallback */
  background: rgba(102, 187, 106, 0.8); }

.green {
  color: #4CAF50 !important; }

.bg-green {
  background-color: #4CAF50 !important; }
  .bg-green .card-header, .bg-green .card-footer {
    background-color: transparent; }

.alert-green {
  border-color: #4CAF50 !important;
  background-color: #7cc67f !important;
  color: #163317 !important; }
  .alert-green .alert-link {
    color: #0a160a !important; }

.border-green {
  border-color: #4CAF50; }

.bg-green.tag-glow, .border-green.tag-glow {
  box-shadow: 0px 0px 10px #4CAF50; }

.overlay-green {
  background: #4CAF50;
  /* The Fallback */
  background: rgba(76, 175, 80, 0.8); }

.card-outline-green {
  border-width: 1px;
  border-style: solid;
  border-color: #4CAF50;
  background-color: transparent; }
  .card-outline-green .card-header, .card-outline-green .card-footer {
    background-color: transparent; }

.btn-green {
  border-color: #4CAF50;
  background-color: #4CAF50;
  color: #FFFFFF; }
  .btn-green:hover {
    border-color: #81C784;
    background-color: #81C784;
    color: #FFF !important; }

.btn-outline-green {
  border-color: #4CAF50;
  background-color: transparent;
  color: #4CAF50; }
  .btn-outline-green:hover {
    background-color: #4CAF50;
    color: #FFF !important; }

.progress-green[value]::-webkit-progress-value {
  background-color: #4CAF50; }

.progress-green[value]::-moz-progress-bar {
  background-color: #4CAF50; }

.progress-green[value]::-ms-fill {
  background-color: #4CAF50; }

@media screen and (min-width: 0\0) {
  .progress-green .progress-bar {
    background-color: #4CAF50; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4CAF50 !important; }

.border-green {
  border: 1px solid #4CAF50 !important; }

.border-top-green {
  border-top: 1px solid #4CAF50; }

.border-bottom-green {
  border-bottom: 1px solid #4CAF50; }

.border-left-green {
  border-left: 1px solid #4CAF50; }

.border-right-green {
  border-right: 1px solid #4CAF50; }

.green.darken-1 {
  color: #43A047 !important; }

.bg-green.bg-darken-1 {
  background-color: #43A047 !important; }

.btn-green.btn-darken-1 {
  border-color: #43A047 !important;
  background-color: #43A047 !important; }
  .btn-green.btn-darken-1:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-darken-1 {
  border-color: #43A047 !important;
  color: #43A047 !important; }
  .btn-outline-green.btn-outline-darken-1:hover {
    background-color: #43A047 !important; }

.progress-green.progress-darken-1[value]::-webkit-progress-value {
  background-color: #43A047; }

.progress-green.progress-darken-1[value]::-moz-progress-bar {
  background-color: #43A047; }

.progress-green.progress-darken-1[value]::-ms-fill {
  background-color: #43A047; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-darken-1 .progress-bar {
    background-color: #43A047; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #43A047 !important; }

.border-green.border-darken-1 {
  border: 1px solid #43A047 !important; }

.border-top-green.border-top-darken-1 {
  border-top: 1px solid #43A047 !important; }

.border-bottom-green.border-bottom-darken-1 {
  border-bottom: 1px solid #43A047 !important; }

.border-left-green.border-left-darken-1 {
  border-left: 1px solid #43A047 !important; }

.border-right-green.border-right-darken-1 {
  border-right: 1px solid #43A047 !important; }

.overlay-green.overlay-darken-1 {
  background: #43A047;
  /* The Fallback */
  background: rgba(67, 160, 71, 0.8); }

.green.darken-2 {
  color: #388E3C !important; }

.bg-green.bg-darken-2 {
  background-color: #388E3C !important; }

.btn-green.btn-darken-2 {
  border-color: #388E3C !important;
  background-color: #388E3C !important; }
  .btn-green.btn-darken-2:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-darken-2 {
  border-color: #388E3C !important;
  color: #388E3C !important; }
  .btn-outline-green.btn-outline-darken-2:hover {
    background-color: #388E3C !important; }

.progress-green.progress-darken-2[value]::-webkit-progress-value {
  background-color: #388E3C; }

.progress-green.progress-darken-2[value]::-moz-progress-bar {
  background-color: #388E3C; }

.progress-green.progress-darken-2[value]::-ms-fill {
  background-color: #388E3C; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-darken-2 .progress-bar {
    background-color: #388E3C; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #388E3C !important; }

.border-green.border-darken-2 {
  border: 1px solid #388E3C !important; }

.border-top-green.border-top-darken-2 {
  border-top: 1px solid #388E3C !important; }

.border-bottom-green.border-bottom-darken-2 {
  border-bottom: 1px solid #388E3C !important; }

.border-left-green.border-left-darken-2 {
  border-left: 1px solid #388E3C !important; }

.border-right-green.border-right-darken-2 {
  border-right: 1px solid #388E3C !important; }

.overlay-green.overlay-darken-2 {
  background: #388E3C;
  /* The Fallback */
  background: rgba(56, 142, 60, 0.8); }

.green.darken-3 {
  color: #2E7D32 !important; }

.bg-green.bg-darken-3 {
  background-color: #2E7D32 !important; }

.btn-green.btn-darken-3 {
  border-color: #2E7D32 !important;
  background-color: #2E7D32 !important; }
  .btn-green.btn-darken-3:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-darken-3 {
  border-color: #2E7D32 !important;
  color: #2E7D32 !important; }
  .btn-outline-green.btn-outline-darken-3:hover {
    background-color: #2E7D32 !important; }

.progress-green.progress-darken-3[value]::-webkit-progress-value {
  background-color: #2E7D32; }

.progress-green.progress-darken-3[value]::-moz-progress-bar {
  background-color: #2E7D32; }

.progress-green.progress-darken-3[value]::-ms-fill {
  background-color: #2E7D32; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-darken-3 .progress-bar {
    background-color: #2E7D32; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2E7D32 !important; }

.border-green.border-darken-3 {
  border: 1px solid #2E7D32 !important; }

.border-top-green.border-top-darken-3 {
  border-top: 1px solid #2E7D32 !important; }

.border-bottom-green.border-bottom-darken-3 {
  border-bottom: 1px solid #2E7D32 !important; }

.border-left-green.border-left-darken-3 {
  border-left: 1px solid #2E7D32 !important; }

.border-right-green.border-right-darken-3 {
  border-right: 1px solid #2E7D32 !important; }

.overlay-green.overlay-darken-3 {
  background: #2E7D32;
  /* The Fallback */
  background: rgba(46, 125, 50, 0.8); }

.green.darken-4 {
  color: #1B5E20 !important; }

.bg-green.bg-darken-4 {
  background-color: #1B5E20 !important; }

.btn-green.btn-darken-4 {
  border-color: #1B5E20 !important;
  background-color: #1B5E20 !important; }
  .btn-green.btn-darken-4:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-darken-4 {
  border-color: #1B5E20 !important;
  color: #1B5E20 !important; }
  .btn-outline-green.btn-outline-darken-4:hover {
    background-color: #1B5E20 !important; }

.progress-green.progress-darken-4[value]::-webkit-progress-value {
  background-color: #1B5E20; }

.progress-green.progress-darken-4[value]::-moz-progress-bar {
  background-color: #1B5E20; }

.progress-green.progress-darken-4[value]::-ms-fill {
  background-color: #1B5E20; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-darken-4 .progress-bar {
    background-color: #1B5E20; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1B5E20 !important; }

.border-green.border-darken-4 {
  border: 1px solid #1B5E20 !important; }

.border-top-green.border-top-darken-4 {
  border-top: 1px solid #1B5E20 !important; }

.border-bottom-green.border-bottom-darken-4 {
  border-bottom: 1px solid #1B5E20 !important; }

.border-left-green.border-left-darken-4 {
  border-left: 1px solid #1B5E20 !important; }

.border-right-green.border-right-darken-4 {
  border-right: 1px solid #1B5E20 !important; }

.overlay-green.overlay-darken-4 {
  background: #1B5E20;
  /* The Fallback */
  background: rgba(27, 94, 32, 0.8); }

.green.accent-1 {
  color: #B9F6CA !important; }

.bg-green.bg-accent-1 {
  background-color: #B9F6CA !important; }

.btn-green.btn-accent-1 {
  border-color: #B9F6CA !important;
  background-color: #B9F6CA !important; }
  .btn-green.btn-accent-1:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-accent-1 {
  border-color: #B9F6CA !important;
  color: #B9F6CA !important; }
  .btn-outline-green.btn-outline-accent-1:hover {
    background-color: #B9F6CA !important; }

.progress-green.progress-accent-1[value]::-webkit-progress-value {
  background-color: #B9F6CA; }

.progress-green.progress-accent-1[value]::-moz-progress-bar {
  background-color: #B9F6CA; }

.progress-green.progress-accent-1[value]::-ms-fill {
  background-color: #B9F6CA; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-accent-1 .progress-bar {
    background-color: #B9F6CA; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B9F6CA !important; }

.border-green.border-accent-1 {
  border: 1px solid #B9F6CA !important; }

.border-top-green.border-top-accent-1 {
  border-top: 1px solid #B9F6CA !important; }

.border-bottom-green.border-bottom-accent-1 {
  border-bottom: 1px solid #B9F6CA !important; }

.border-left-green.border-left-accent-1 {
  border-left: 1px solid #B9F6CA !important; }

.border-right-green.border-right-accent-1 {
  border-right: 1px solid #B9F6CA !important; }

.overlay-green.overlay-accent-1 {
  background: #B9F6CA;
  /* The Fallback */
  background: rgba(185, 246, 202, 0.8); }

.green.accent-2 {
  color: #69F0AE !important; }

.bg-green.bg-accent-2 {
  background-color: #69F0AE !important; }

.btn-green.btn-accent-2 {
  border-color: #69F0AE !important;
  background-color: #69F0AE !important; }
  .btn-green.btn-accent-2:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-accent-2 {
  border-color: #69F0AE !important;
  color: #69F0AE !important; }
  .btn-outline-green.btn-outline-accent-2:hover {
    background-color: #69F0AE !important; }

.progress-green.progress-accent-2[value]::-webkit-progress-value {
  background-color: #69F0AE; }

.progress-green.progress-accent-2[value]::-moz-progress-bar {
  background-color: #69F0AE; }

.progress-green.progress-accent-2[value]::-ms-fill {
  background-color: #69F0AE; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-accent-2 .progress-bar {
    background-color: #69F0AE; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #69F0AE !important; }

.border-green.border-accent-2 {
  border: 1px solid #69F0AE !important; }

.border-top-green.border-top-accent-2 {
  border-top: 1px solid #69F0AE !important; }

.border-bottom-green.border-bottom-accent-2 {
  border-bottom: 1px solid #69F0AE !important; }

.border-left-green.border-left-accent-2 {
  border-left: 1px solid #69F0AE !important; }

.border-right-green.border-right-accent-2 {
  border-right: 1px solid #69F0AE !important; }

.overlay-green.overlay-accent-2 {
  background: #69F0AE;
  /* The Fallback */
  background: rgba(105, 240, 174, 0.8); }

.green.accent-3 {
  color: #00E676 !important; }

.bg-green.bg-accent-3 {
  background-color: #00E676 !important; }

.btn-green.btn-accent-3 {
  border-color: #00E676 !important;
  background-color: #00E676 !important; }
  .btn-green.btn-accent-3:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-accent-3 {
  border-color: #00E676 !important;
  color: #00E676 !important; }
  .btn-outline-green.btn-outline-accent-3:hover {
    background-color: #00E676 !important; }

.progress-green.progress-accent-3[value]::-webkit-progress-value {
  background-color: #00E676; }

.progress-green.progress-accent-3[value]::-moz-progress-bar {
  background-color: #00E676; }

.progress-green.progress-accent-3[value]::-ms-fill {
  background-color: #00E676; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-accent-3 .progress-bar {
    background-color: #00E676; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00E676 !important; }

.border-green.border-accent-3 {
  border: 1px solid #00E676 !important; }

.border-top-green.border-top-accent-3 {
  border-top: 1px solid #00E676 !important; }

.border-bottom-green.border-bottom-accent-3 {
  border-bottom: 1px solid #00E676 !important; }

.border-left-green.border-left-accent-3 {
  border-left: 1px solid #00E676 !important; }

.border-right-green.border-right-accent-3 {
  border-right: 1px solid #00E676 !important; }

.overlay-green.overlay-accent-3 {
  background: #00E676;
  /* The Fallback */
  background: rgba(0, 230, 118, 0.8); }

.green.accent-4 {
  color: #00C853 !important; }

.bg-green.bg-accent-4 {
  background-color: #00C853 !important; }

.btn-green.btn-accent-4 {
  border-color: #00C853 !important;
  background-color: #00C853 !important; }
  .btn-green.btn-accent-4:hover {
    border-color: #81C784 !important;
    background-color: #81C784 !important; }

.btn-outline-green.btn-outline-accent-4 {
  border-color: #00C853 !important;
  color: #00C853 !important; }
  .btn-outline-green.btn-outline-accent-4:hover {
    background-color: #00C853 !important; }

.progress-green.progress-accent-4[value]::-webkit-progress-value {
  background-color: #00C853; }

.progress-green.progress-accent-4[value]::-moz-progress-bar {
  background-color: #00C853; }

.progress-green.progress-accent-4[value]::-ms-fill {
  background-color: #00C853; }

@media screen and (min-width: 0\0) {
  .progress-green.progress-accent-4 .progress-bar {
    background-color: #00C853; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00C853 !important; }

.border-green.border-accent-4 {
  border: 1px solid #00C853 !important; }

.border-top-green.border-top-accent-4 {
  border-top: 1px solid #00C853 !important; }

.border-bottom-green.border-bottom-accent-4 {
  border-bottom: 1px solid #00C853 !important; }

.border-left-green.border-left-accent-4 {
  border-left: 1px solid #00C853 !important; }

.border-right-green.border-right-accent-4 {
  border-right: 1px solid #00C853 !important; }

.overlay-green.overlay-accent-4 {
  background: #00C853;
  /* The Fallback */
  background: rgba(0, 200, 83, 0.8); }

.light-green.lighten-5 {
  color: #F1F8A9 !important; }

.bg-light-green.bg-lighten-5 {
  background-color: #F1F8A9 !important; }

.btn-light-green.btn-lighten-5 {
  border-color: #F1F8A9 !important;
  background-color: #F1F8A9 !important; }
  .btn-light-green.btn-lighten-5:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-lighten-5 {
  border-color: #F1F8A9 !important;
  color: #F1F8A9 !important; }
  .btn-outline-light-green.btn-outline-lighten-5:hover {
    background-color: #F1F8A9 !important; }

.progress-light-green.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #F1F8A9; }

.progress-light-green.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #F1F8A9; }

.progress-light-green.progress-lighten-5[value]::-ms-fill {
  background-color: #F1F8A9; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-lighten-5 .progress-bar {
    background-color: #F1F8A9; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F1F8A9 !important; }

.border-light-green.border-lighten-5 {
  border: 1px solid #F1F8A9 !important; }

.border-top-light-green.border-top-lighten-5 {
  border-top: 1px solid #F1F8A9 !important; }

.border-bottom-light-green.border-bottom-lighten-5 {
  border-bottom: 1px solid #F1F8A9 !important; }

.border-left-light-green.border-left-lighten-5 {
  border-left: 1px solid #F1F8A9 !important; }

.border-right-light-green.border-right-lighten-5 {
  border-right: 1px solid #F1F8A9 !important; }

.overlay-light-green.overlay-lighten-5 {
  background: #F1F8A9;
  /* The Fallback */
  background: rgba(241, 248, 169, 0.8); }

.light-green.lighten-4 {
  color: #DCEDC8 !important; }

.bg-light-green.bg-lighten-4 {
  background-color: #DCEDC8 !important; }

.btn-light-green.btn-lighten-4 {
  border-color: #DCEDC8 !important;
  background-color: #DCEDC8 !important; }
  .btn-light-green.btn-lighten-4:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-lighten-4 {
  border-color: #DCEDC8 !important;
  color: #DCEDC8 !important; }
  .btn-outline-light-green.btn-outline-lighten-4:hover {
    background-color: #DCEDC8 !important; }

.progress-light-green.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #DCEDC8; }

.progress-light-green.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #DCEDC8; }

.progress-light-green.progress-lighten-4[value]::-ms-fill {
  background-color: #DCEDC8; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-lighten-4 .progress-bar {
    background-color: #DCEDC8; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DCEDC8 !important; }

.border-light-green.border-lighten-4 {
  border: 1px solid #DCEDC8 !important; }

.border-top-light-green.border-top-lighten-4 {
  border-top: 1px solid #DCEDC8 !important; }

.border-bottom-light-green.border-bottom-lighten-4 {
  border-bottom: 1px solid #DCEDC8 !important; }

.border-left-light-green.border-left-lighten-4 {
  border-left: 1px solid #DCEDC8 !important; }

.border-right-light-green.border-right-lighten-4 {
  border-right: 1px solid #DCEDC8 !important; }

.overlay-light-green.overlay-lighten-4 {
  background: #DCEDC8;
  /* The Fallback */
  background: rgba(220, 237, 200, 0.8); }

.light-green.lighten-3 {
  color: #C5E1A5 !important; }

.bg-light-green.bg-lighten-3 {
  background-color: #C5E1A5 !important; }

.btn-light-green.btn-lighten-3 {
  border-color: #C5E1A5 !important;
  background-color: #C5E1A5 !important; }
  .btn-light-green.btn-lighten-3:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-lighten-3 {
  border-color: #C5E1A5 !important;
  color: #C5E1A5 !important; }
  .btn-outline-light-green.btn-outline-lighten-3:hover {
    background-color: #C5E1A5 !important; }

.progress-light-green.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #C5E1A5; }

.progress-light-green.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #C5E1A5; }

.progress-light-green.progress-lighten-3[value]::-ms-fill {
  background-color: #C5E1A5; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-lighten-3 .progress-bar {
    background-color: #C5E1A5; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C5E1A5 !important; }

.border-light-green.border-lighten-3 {
  border: 1px solid #C5E1A5 !important; }

.border-top-light-green.border-top-lighten-3 {
  border-top: 1px solid #C5E1A5 !important; }

.border-bottom-light-green.border-bottom-lighten-3 {
  border-bottom: 1px solid #C5E1A5 !important; }

.border-left-light-green.border-left-lighten-3 {
  border-left: 1px solid #C5E1A5 !important; }

.border-right-light-green.border-right-lighten-3 {
  border-right: 1px solid #C5E1A5 !important; }

.overlay-light-green.overlay-lighten-3 {
  background: #C5E1A5;
  /* The Fallback */
  background: rgba(197, 225, 165, 0.8); }

.light-green.lighten-2 {
  color: #AED581 !important; }

.bg-light-green.bg-lighten-2 {
  background-color: #AED581 !important; }

.btn-light-green.btn-lighten-2 {
  border-color: #AED581 !important;
  background-color: #AED581 !important; }
  .btn-light-green.btn-lighten-2:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-lighten-2 {
  border-color: #AED581 !important;
  color: #AED581 !important; }
  .btn-outline-light-green.btn-outline-lighten-2:hover {
    background-color: #AED581 !important; }

.progress-light-green.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #AED581; }

.progress-light-green.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #AED581; }

.progress-light-green.progress-lighten-2[value]::-ms-fill {
  background-color: #AED581; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-lighten-2 .progress-bar {
    background-color: #AED581; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AED581 !important; }

.border-light-green.border-lighten-2 {
  border: 1px solid #AED581 !important; }

.border-top-light-green.border-top-lighten-2 {
  border-top: 1px solid #AED581 !important; }

.border-bottom-light-green.border-bottom-lighten-2 {
  border-bottom: 1px solid #AED581 !important; }

.border-left-light-green.border-left-lighten-2 {
  border-left: 1px solid #AED581 !important; }

.border-right-light-green.border-right-lighten-2 {
  border-right: 1px solid #AED581 !important; }

.overlay-light-green.overlay-lighten-2 {
  background: #AED581;
  /* The Fallback */
  background: rgba(174, 213, 129, 0.8); }

.light-green.lighten-1 {
  color: #9CCC65 !important; }

.bg-light-green.bg-lighten-1 {
  background-color: #9CCC65 !important; }

.btn-light-green.btn-lighten-1 {
  border-color: #9CCC65 !important;
  background-color: #9CCC65 !important; }
  .btn-light-green.btn-lighten-1:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-lighten-1 {
  border-color: #9CCC65 !important;
  color: #9CCC65 !important; }
  .btn-outline-light-green.btn-outline-lighten-1:hover {
    background-color: #9CCC65 !important; }

.progress-light-green.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #9CCC65; }

.progress-light-green.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #9CCC65; }

.progress-light-green.progress-lighten-1[value]::-ms-fill {
  background-color: #9CCC65; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-lighten-1 .progress-bar {
    background-color: #9CCC65; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9CCC65 !important; }

.border-light-green.border-lighten-1 {
  border: 1px solid #9CCC65 !important; }

.border-top-light-green.border-top-lighten-1 {
  border-top: 1px solid #9CCC65 !important; }

.border-bottom-light-green.border-bottom-lighten-1 {
  border-bottom: 1px solid #9CCC65 !important; }

.border-left-light-green.border-left-lighten-1 {
  border-left: 1px solid #9CCC65 !important; }

.border-right-light-green.border-right-lighten-1 {
  border-right: 1px solid #9CCC65 !important; }

.overlay-light-green.overlay-lighten-1 {
  background: #9CCC65;
  /* The Fallback */
  background: rgba(156, 204, 101, 0.8); }

.light-green {
  color: #8BC34A !important; }

.bg-light-green {
  background-color: #8BC34A !important; }
  .bg-light-green .card-header, .bg-light-green .card-footer {
    background-color: transparent; }

.alert-light-green {
  border-color: #8BC34A !important;
  background-color: #add580 !important;
  color: #2f4417 !important; }
  .alert-light-green .alert-link {
    color: #1a250c !important; }

.border-light-green {
  border-color: #8BC34A; }

.bg-light-green.tag-glow, .border-light-green.tag-glow {
  box-shadow: 0px 0px 10px #8BC34A; }

.overlay-light-green {
  background: #8BC34A;
  /* The Fallback */
  background: rgba(139, 195, 74, 0.8); }

.card-outline-light-green {
  border-width: 1px;
  border-style: solid;
  border-color: #8BC34A;
  background-color: transparent; }
  .card-outline-light-green .card-header, .card-outline-light-green .card-footer {
    background-color: transparent; }

.btn-light-green {
  border-color: #8BC34A;
  background-color: #8BC34A;
  color: #FFFFFF; }
  .btn-light-green:hover {
    border-color: #AED581;
    background-color: #AED581;
    color: #FFF !important; }

.btn-outline-light-green {
  border-color: #8BC34A;
  background-color: transparent;
  color: #8BC34A; }
  .btn-outline-light-green:hover {
    background-color: #8BC34A;
    color: #FFF !important; }

.progress-light-green[value]::-webkit-progress-value {
  background-color: #8BC34A; }

.progress-light-green[value]::-moz-progress-bar {
  background-color: #8BC34A; }

.progress-light-green[value]::-ms-fill {
  background-color: #8BC34A; }

@media screen and (min-width: 0\0) {
  .progress-light-green .progress-bar {
    background-color: #8BC34A; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8BC34A !important; }

.border-light-green {
  border: 1px solid #8BC34A !important; }

.border-top-light-green {
  border-top: 1px solid #8BC34A; }

.border-bottom-light-green {
  border-bottom: 1px solid #8BC34A; }

.border-left-light-green {
  border-left: 1px solid #8BC34A; }

.border-right-light-green {
  border-right: 1px solid #8BC34A; }

.light-green.darken-1 {
  color: #7CB342 !important; }

.bg-light-green.bg-darken-1 {
  background-color: #7CB342 !important; }

.btn-light-green.btn-darken-1 {
  border-color: #7CB342 !important;
  background-color: #7CB342 !important; }
  .btn-light-green.btn-darken-1:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-darken-1 {
  border-color: #7CB342 !important;
  color: #7CB342 !important; }
  .btn-outline-light-green.btn-outline-darken-1:hover {
    background-color: #7CB342 !important; }

.progress-light-green.progress-darken-1[value]::-webkit-progress-value {
  background-color: #7CB342; }

.progress-light-green.progress-darken-1[value]::-moz-progress-bar {
  background-color: #7CB342; }

.progress-light-green.progress-darken-1[value]::-ms-fill {
  background-color: #7CB342; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-darken-1 .progress-bar {
    background-color: #7CB342; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7CB342 !important; }

.border-light-green.border-darken-1 {
  border: 1px solid #7CB342 !important; }

.border-top-light-green.border-top-darken-1 {
  border-top: 1px solid #7CB342 !important; }

.border-bottom-light-green.border-bottom-darken-1 {
  border-bottom: 1px solid #7CB342 !important; }

.border-left-light-green.border-left-darken-1 {
  border-left: 1px solid #7CB342 !important; }

.border-right-light-green.border-right-darken-1 {
  border-right: 1px solid #7CB342 !important; }

.overlay-light-green.overlay-darken-1 {
  background: #7CB342;
  /* The Fallback */
  background: rgba(124, 179, 66, 0.8); }

.light-green.darken-2 {
  color: #689F38 !important; }

.bg-light-green.bg-darken-2 {
  background-color: #689F38 !important; }

.btn-light-green.btn-darken-2 {
  border-color: #689F38 !important;
  background-color: #689F38 !important; }
  .btn-light-green.btn-darken-2:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-darken-2 {
  border-color: #689F38 !important;
  color: #689F38 !important; }
  .btn-outline-light-green.btn-outline-darken-2:hover {
    background-color: #689F38 !important; }

.progress-light-green.progress-darken-2[value]::-webkit-progress-value {
  background-color: #689F38; }

.progress-light-green.progress-darken-2[value]::-moz-progress-bar {
  background-color: #689F38; }

.progress-light-green.progress-darken-2[value]::-ms-fill {
  background-color: #689F38; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-darken-2 .progress-bar {
    background-color: #689F38; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #689F38 !important; }

.border-light-green.border-darken-2 {
  border: 1px solid #689F38 !important; }

.border-top-light-green.border-top-darken-2 {
  border-top: 1px solid #689F38 !important; }

.border-bottom-light-green.border-bottom-darken-2 {
  border-bottom: 1px solid #689F38 !important; }

.border-left-light-green.border-left-darken-2 {
  border-left: 1px solid #689F38 !important; }

.border-right-light-green.border-right-darken-2 {
  border-right: 1px solid #689F38 !important; }

.overlay-light-green.overlay-darken-2 {
  background: #689F38;
  /* The Fallback */
  background: rgba(104, 159, 56, 0.8); }

.light-green.darken-3 {
  color: #558B2F !important; }

.bg-light-green.bg-darken-3 {
  background-color: #558B2F !important; }

.btn-light-green.btn-darken-3 {
  border-color: #558B2F !important;
  background-color: #558B2F !important; }
  .btn-light-green.btn-darken-3:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-darken-3 {
  border-color: #558B2F !important;
  color: #558B2F !important; }
  .btn-outline-light-green.btn-outline-darken-3:hover {
    background-color: #558B2F !important; }

.progress-light-green.progress-darken-3[value]::-webkit-progress-value {
  background-color: #558B2F; }

.progress-light-green.progress-darken-3[value]::-moz-progress-bar {
  background-color: #558B2F; }

.progress-light-green.progress-darken-3[value]::-ms-fill {
  background-color: #558B2F; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-darken-3 .progress-bar {
    background-color: #558B2F; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #558B2F !important; }

.border-light-green.border-darken-3 {
  border: 1px solid #558B2F !important; }

.border-top-light-green.border-top-darken-3 {
  border-top: 1px solid #558B2F !important; }

.border-bottom-light-green.border-bottom-darken-3 {
  border-bottom: 1px solid #558B2F !important; }

.border-left-light-green.border-left-darken-3 {
  border-left: 1px solid #558B2F !important; }

.border-right-light-green.border-right-darken-3 {
  border-right: 1px solid #558B2F !important; }

.overlay-light-green.overlay-darken-3 {
  background: #558B2F;
  /* The Fallback */
  background: rgba(85, 139, 47, 0.8); }

.light-green.darken-4 {
  color: #33691E !important; }

.bg-light-green.bg-darken-4 {
  background-color: #33691E !important; }

.btn-light-green.btn-darken-4 {
  border-color: #33691E !important;
  background-color: #33691E !important; }
  .btn-light-green.btn-darken-4:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-darken-4 {
  border-color: #33691E !important;
  color: #33691E !important; }
  .btn-outline-light-green.btn-outline-darken-4:hover {
    background-color: #33691E !important; }

.progress-light-green.progress-darken-4[value]::-webkit-progress-value {
  background-color: #33691E; }

.progress-light-green.progress-darken-4[value]::-moz-progress-bar {
  background-color: #33691E; }

.progress-light-green.progress-darken-4[value]::-ms-fill {
  background-color: #33691E; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-darken-4 .progress-bar {
    background-color: #33691E; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #33691E !important; }

.border-light-green.border-darken-4 {
  border: 1px solid #33691E !important; }

.border-top-light-green.border-top-darken-4 {
  border-top: 1px solid #33691E !important; }

.border-bottom-light-green.border-bottom-darken-4 {
  border-bottom: 1px solid #33691E !important; }

.border-left-light-green.border-left-darken-4 {
  border-left: 1px solid #33691E !important; }

.border-right-light-green.border-right-darken-4 {
  border-right: 1px solid #33691E !important; }

.overlay-light-green.overlay-darken-4 {
  background: #33691E;
  /* The Fallback */
  background: rgba(51, 105, 30, 0.8); }

.light-green.accent-1 {
  color: #CCFF90 !important; }

.bg-light-green.bg-accent-1 {
  background-color: #CCFF90 !important; }

.btn-light-green.btn-accent-1 {
  border-color: #CCFF90 !important;
  background-color: #CCFF90 !important; }
  .btn-light-green.btn-accent-1:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-accent-1 {
  border-color: #CCFF90 !important;
  color: #CCFF90 !important; }
  .btn-outline-light-green.btn-outline-accent-1:hover {
    background-color: #CCFF90 !important; }

.progress-light-green.progress-accent-1[value]::-webkit-progress-value {
  background-color: #CCFF90; }

.progress-light-green.progress-accent-1[value]::-moz-progress-bar {
  background-color: #CCFF90; }

.progress-light-green.progress-accent-1[value]::-ms-fill {
  background-color: #CCFF90; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-accent-1 .progress-bar {
    background-color: #CCFF90; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CCFF90 !important; }

.border-light-green.border-accent-1 {
  border: 1px solid #CCFF90 !important; }

.border-top-light-green.border-top-accent-1 {
  border-top: 1px solid #CCFF90 !important; }

.border-bottom-light-green.border-bottom-accent-1 {
  border-bottom: 1px solid #CCFF90 !important; }

.border-left-light-green.border-left-accent-1 {
  border-left: 1px solid #CCFF90 !important; }

.border-right-light-green.border-right-accent-1 {
  border-right: 1px solid #CCFF90 !important; }

.overlay-light-green.overlay-accent-1 {
  background: #CCFF90;
  /* The Fallback */
  background: rgba(204, 255, 144, 0.8); }

.light-green.accent-2 {
  color: #B2FF59 !important; }

.bg-light-green.bg-accent-2 {
  background-color: #B2FF59 !important; }

.btn-light-green.btn-accent-2 {
  border-color: #B2FF59 !important;
  background-color: #B2FF59 !important; }
  .btn-light-green.btn-accent-2:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-accent-2 {
  border-color: #B2FF59 !important;
  color: #B2FF59 !important; }
  .btn-outline-light-green.btn-outline-accent-2:hover {
    background-color: #B2FF59 !important; }

.progress-light-green.progress-accent-2[value]::-webkit-progress-value {
  background-color: #B2FF59; }

.progress-light-green.progress-accent-2[value]::-moz-progress-bar {
  background-color: #B2FF59; }

.progress-light-green.progress-accent-2[value]::-ms-fill {
  background-color: #B2FF59; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-accent-2 .progress-bar {
    background-color: #B2FF59; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2FF59 !important; }

.border-light-green.border-accent-2 {
  border: 1px solid #B2FF59 !important; }

.border-top-light-green.border-top-accent-2 {
  border-top: 1px solid #B2FF59 !important; }

.border-bottom-light-green.border-bottom-accent-2 {
  border-bottom: 1px solid #B2FF59 !important; }

.border-left-light-green.border-left-accent-2 {
  border-left: 1px solid #B2FF59 !important; }

.border-right-light-green.border-right-accent-2 {
  border-right: 1px solid #B2FF59 !important; }

.overlay-light-green.overlay-accent-2 {
  background: #B2FF59;
  /* The Fallback */
  background: rgba(178, 255, 89, 0.8); }

.light-green.accent-3 {
  color: #76FF03 !important; }

.bg-light-green.bg-accent-3 {
  background-color: #76FF03 !important; }

.btn-light-green.btn-accent-3 {
  border-color: #76FF03 !important;
  background-color: #76FF03 !important; }
  .btn-light-green.btn-accent-3:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-accent-3 {
  border-color: #76FF03 !important;
  color: #76FF03 !important; }
  .btn-outline-light-green.btn-outline-accent-3:hover {
    background-color: #76FF03 !important; }

.progress-light-green.progress-accent-3[value]::-webkit-progress-value {
  background-color: #76FF03; }

.progress-light-green.progress-accent-3[value]::-moz-progress-bar {
  background-color: #76FF03; }

.progress-light-green.progress-accent-3[value]::-ms-fill {
  background-color: #76FF03; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-accent-3 .progress-bar {
    background-color: #76FF03; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #76FF03 !important; }

.border-light-green.border-accent-3 {
  border: 1px solid #76FF03 !important; }

.border-top-light-green.border-top-accent-3 {
  border-top: 1px solid #76FF03 !important; }

.border-bottom-light-green.border-bottom-accent-3 {
  border-bottom: 1px solid #76FF03 !important; }

.border-left-light-green.border-left-accent-3 {
  border-left: 1px solid #76FF03 !important; }

.border-right-light-green.border-right-accent-3 {
  border-right: 1px solid #76FF03 !important; }

.overlay-light-green.overlay-accent-3 {
  background: #76FF03;
  /* The Fallback */
  background: rgba(118, 255, 3, 0.8); }

.light-green.accent-4 {
  color: #64DD17 !important; }

.bg-light-green.bg-accent-4 {
  background-color: #64DD17 !important; }

.btn-light-green.btn-accent-4 {
  border-color: #64DD17 !important;
  background-color: #64DD17 !important; }
  .btn-light-green.btn-accent-4:hover {
    border-color: #AED581 !important;
    background-color: #AED581 !important; }

.btn-outline-light-green.btn-outline-accent-4 {
  border-color: #64DD17 !important;
  color: #64DD17 !important; }
  .btn-outline-light-green.btn-outline-accent-4:hover {
    background-color: #64DD17 !important; }

.progress-light-green.progress-accent-4[value]::-webkit-progress-value {
  background-color: #64DD17; }

.progress-light-green.progress-accent-4[value]::-moz-progress-bar {
  background-color: #64DD17; }

.progress-light-green.progress-accent-4[value]::-ms-fill {
  background-color: #64DD17; }

@media screen and (min-width: 0\0) {
  .progress-light-green.progress-accent-4 .progress-bar {
    background-color: #64DD17; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64DD17 !important; }

.border-light-green.border-accent-4 {
  border: 1px solid #64DD17 !important; }

.border-top-light-green.border-top-accent-4 {
  border-top: 1px solid #64DD17 !important; }

.border-bottom-light-green.border-bottom-accent-4 {
  border-bottom: 1px solid #64DD17 !important; }

.border-left-light-green.border-left-accent-4 {
  border-left: 1px solid #64DD17 !important; }

.border-right-light-green.border-right-accent-4 {
  border-right: 1px solid #64DD17 !important; }

.overlay-light-green.overlay-accent-4 {
  background: #64DD17;
  /* The Fallback */
  background: rgba(100, 221, 23, 0.8); }

.lime.lighten-5 {
  color: #F9FBE7 !important; }

.bg-lime.bg-lighten-5 {
  background-color: #F9FBE7 !important; }

.btn-lime.btn-lighten-5 {
  border-color: #F9FBE7 !important;
  background-color: #F9FBE7 !important; }
  .btn-lime.btn-lighten-5:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-lighten-5 {
  border-color: #F9FBE7 !important;
  color: #F9FBE7 !important; }
  .btn-outline-lime.btn-outline-lighten-5:hover {
    background-color: #F9FBE7 !important; }

.progress-lime.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #F9FBE7; }

.progress-lime.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #F9FBE7; }

.progress-lime.progress-lighten-5[value]::-ms-fill {
  background-color: #F9FBE7; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-lighten-5 .progress-bar {
    background-color: #F9FBE7; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9FBE7 !important; }

.border-lime.border-lighten-5 {
  border: 1px solid #F9FBE7 !important; }

.border-top-lime.border-top-lighten-5 {
  border-top: 1px solid #F9FBE7 !important; }

.border-bottom-lime.border-bottom-lighten-5 {
  border-bottom: 1px solid #F9FBE7 !important; }

.border-left-lime.border-left-lighten-5 {
  border-left: 1px solid #F9FBE7 !important; }

.border-right-lime.border-right-lighten-5 {
  border-right: 1px solid #F9FBE7 !important; }

.overlay-lime.overlay-lighten-5 {
  background: #F9FBE7;
  /* The Fallback */
  background: rgba(249, 251, 231, 0.8); }

.lime.lighten-4 {
  color: #F0F4C3 !important; }

.bg-lime.bg-lighten-4 {
  background-color: #F0F4C3 !important; }

.btn-lime.btn-lighten-4 {
  border-color: #F0F4C3 !important;
  background-color: #F0F4C3 !important; }
  .btn-lime.btn-lighten-4:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-lighten-4 {
  border-color: #F0F4C3 !important;
  color: #F0F4C3 !important; }
  .btn-outline-lime.btn-outline-lighten-4:hover {
    background-color: #F0F4C3 !important; }

.progress-lime.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #F0F4C3; }

.progress-lime.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #F0F4C3; }

.progress-lime.progress-lighten-4[value]::-ms-fill {
  background-color: #F0F4C3; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-lighten-4 .progress-bar {
    background-color: #F0F4C3; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F0F4C3 !important; }

.border-lime.border-lighten-4 {
  border: 1px solid #F0F4C3 !important; }

.border-top-lime.border-top-lighten-4 {
  border-top: 1px solid #F0F4C3 !important; }

.border-bottom-lime.border-bottom-lighten-4 {
  border-bottom: 1px solid #F0F4C3 !important; }

.border-left-lime.border-left-lighten-4 {
  border-left: 1px solid #F0F4C3 !important; }

.border-right-lime.border-right-lighten-4 {
  border-right: 1px solid #F0F4C3 !important; }

.overlay-lime.overlay-lighten-4 {
  background: #F0F4C3;
  /* The Fallback */
  background: rgba(240, 244, 195, 0.8); }

.lime.lighten-3 {
  color: #E6EE9C !important; }

.bg-lime.bg-lighten-3 {
  background-color: #E6EE9C !important; }

.btn-lime.btn-lighten-3 {
  border-color: #E6EE9C !important;
  background-color: #E6EE9C !important; }
  .btn-lime.btn-lighten-3:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-lighten-3 {
  border-color: #E6EE9C !important;
  color: #E6EE9C !important; }
  .btn-outline-lime.btn-outline-lighten-3:hover {
    background-color: #E6EE9C !important; }

.progress-lime.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #E6EE9C; }

.progress-lime.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #E6EE9C; }

.progress-lime.progress-lighten-3[value]::-ms-fill {
  background-color: #E6EE9C; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-lighten-3 .progress-bar {
    background-color: #E6EE9C; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E6EE9C !important; }

.border-lime.border-lighten-3 {
  border: 1px solid #E6EE9C !important; }

.border-top-lime.border-top-lighten-3 {
  border-top: 1px solid #E6EE9C !important; }

.border-bottom-lime.border-bottom-lighten-3 {
  border-bottom: 1px solid #E6EE9C !important; }

.border-left-lime.border-left-lighten-3 {
  border-left: 1px solid #E6EE9C !important; }

.border-right-lime.border-right-lighten-3 {
  border-right: 1px solid #E6EE9C !important; }

.overlay-lime.overlay-lighten-3 {
  background: #E6EE9C;
  /* The Fallback */
  background: rgba(230, 238, 156, 0.8); }

.lime.lighten-2 {
  color: #DCE775 !important; }

.bg-lime.bg-lighten-2 {
  background-color: #DCE775 !important; }

.btn-lime.btn-lighten-2 {
  border-color: #DCE775 !important;
  background-color: #DCE775 !important; }
  .btn-lime.btn-lighten-2:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-lighten-2 {
  border-color: #DCE775 !important;
  color: #DCE775 !important; }
  .btn-outline-lime.btn-outline-lighten-2:hover {
    background-color: #DCE775 !important; }

.progress-lime.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #DCE775; }

.progress-lime.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #DCE775; }

.progress-lime.progress-lighten-2[value]::-ms-fill {
  background-color: #DCE775; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-lighten-2 .progress-bar {
    background-color: #DCE775; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DCE775 !important; }

.border-lime.border-lighten-2 {
  border: 1px solid #DCE775 !important; }

.border-top-lime.border-top-lighten-2 {
  border-top: 1px solid #DCE775 !important; }

.border-bottom-lime.border-bottom-lighten-2 {
  border-bottom: 1px solid #DCE775 !important; }

.border-left-lime.border-left-lighten-2 {
  border-left: 1px solid #DCE775 !important; }

.border-right-lime.border-right-lighten-2 {
  border-right: 1px solid #DCE775 !important; }

.overlay-lime.overlay-lighten-2 {
  background: #DCE775;
  /* The Fallback */
  background: rgba(220, 231, 117, 0.8); }

.lime.lighten-1 {
  color: #D4E157 !important; }

.bg-lime.bg-lighten-1 {
  background-color: #D4E157 !important; }

.btn-lime.btn-lighten-1 {
  border-color: #D4E157 !important;
  background-color: #D4E157 !important; }
  .btn-lime.btn-lighten-1:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-lighten-1 {
  border-color: #D4E157 !important;
  color: #D4E157 !important; }
  .btn-outline-lime.btn-outline-lighten-1:hover {
    background-color: #D4E157 !important; }

.progress-lime.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #D4E157; }

.progress-lime.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #D4E157; }

.progress-lime.progress-lighten-1[value]::-ms-fill {
  background-color: #D4E157; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-lighten-1 .progress-bar {
    background-color: #D4E157; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D4E157 !important; }

.border-lime.border-lighten-1 {
  border: 1px solid #D4E157 !important; }

.border-top-lime.border-top-lighten-1 {
  border-top: 1px solid #D4E157 !important; }

.border-bottom-lime.border-bottom-lighten-1 {
  border-bottom: 1px solid #D4E157 !important; }

.border-left-lime.border-left-lighten-1 {
  border-left: 1px solid #D4E157 !important; }

.border-right-lime.border-right-lighten-1 {
  border-right: 1px solid #D4E157 !important; }

.overlay-lime.overlay-lighten-1 {
  background: #D4E157;
  /* The Fallback */
  background: rgba(212, 225, 87, 0.8); }

.lime {
  color: #CDDC39 !important; }

.bg-lime {
  background-color: #CDDC39 !important; }
  .bg-lime .card-header, .bg-lime .card-footer {
    background-color: transparent; }

.alert-lime {
  border-color: #CDDC39 !important;
  background-color: #dce776 !important;
  color: #4d540f !important; }
  .alert-lime .alert-link {
    color: #2d3109 !important; }

.border-lime {
  border-color: #CDDC39; }

.bg-lime.tag-glow, .border-lime.tag-glow {
  box-shadow: 0px 0px 10px #CDDC39; }

.overlay-lime {
  background: #CDDC39;
  /* The Fallback */
  background: rgba(205, 220, 57, 0.8); }

.card-outline-lime {
  border-width: 1px;
  border-style: solid;
  border-color: #CDDC39;
  background-color: transparent; }
  .card-outline-lime .card-header, .card-outline-lime .card-footer {
    background-color: transparent; }

.btn-lime {
  border-color: #CDDC39;
  background-color: #CDDC39;
  color: #FFFFFF; }
  .btn-lime:hover {
    border-color: #DCE775;
    background-color: #DCE775;
    color: #FFF !important; }

.btn-outline-lime {
  border-color: #CDDC39;
  background-color: transparent;
  color: #CDDC39; }
  .btn-outline-lime:hover {
    background-color: #CDDC39;
    color: #FFF !important; }

.progress-lime[value]::-webkit-progress-value {
  background-color: #CDDC39; }

.progress-lime[value]::-moz-progress-bar {
  background-color: #CDDC39; }

.progress-lime[value]::-ms-fill {
  background-color: #CDDC39; }

@media screen and (min-width: 0\0) {
  .progress-lime .progress-bar {
    background-color: #CDDC39; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CDDC39 !important; }

.border-lime {
  border: 1px solid #CDDC39 !important; }

.border-top-lime {
  border-top: 1px solid #CDDC39; }

.border-bottom-lime {
  border-bottom: 1px solid #CDDC39; }

.border-left-lime {
  border-left: 1px solid #CDDC39; }

.border-right-lime {
  border-right: 1px solid #CDDC39; }

.lime.darken-1 {
  color: #C0CA33 !important; }

.bg-lime.bg-darken-1 {
  background-color: #C0CA33 !important; }

.btn-lime.btn-darken-1 {
  border-color: #C0CA33 !important;
  background-color: #C0CA33 !important; }
  .btn-lime.btn-darken-1:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-darken-1 {
  border-color: #C0CA33 !important;
  color: #C0CA33 !important; }
  .btn-outline-lime.btn-outline-darken-1:hover {
    background-color: #C0CA33 !important; }

.progress-lime.progress-darken-1[value]::-webkit-progress-value {
  background-color: #C0CA33; }

.progress-lime.progress-darken-1[value]::-moz-progress-bar {
  background-color: #C0CA33; }

.progress-lime.progress-darken-1[value]::-ms-fill {
  background-color: #C0CA33; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-darken-1 .progress-bar {
    background-color: #C0CA33; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C0CA33 !important; }

.border-lime.border-darken-1 {
  border: 1px solid #C0CA33 !important; }

.border-top-lime.border-top-darken-1 {
  border-top: 1px solid #C0CA33 !important; }

.border-bottom-lime.border-bottom-darken-1 {
  border-bottom: 1px solid #C0CA33 !important; }

.border-left-lime.border-left-darken-1 {
  border-left: 1px solid #C0CA33 !important; }

.border-right-lime.border-right-darken-1 {
  border-right: 1px solid #C0CA33 !important; }

.overlay-lime.overlay-darken-1 {
  background: #C0CA33;
  /* The Fallback */
  background: rgba(192, 202, 51, 0.8); }

.lime.darken-2 {
  color: #AFB42B !important; }

.bg-lime.bg-darken-2 {
  background-color: #AFB42B !important; }

.btn-lime.btn-darken-2 {
  border-color: #AFB42B !important;
  background-color: #AFB42B !important; }
  .btn-lime.btn-darken-2:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-darken-2 {
  border-color: #AFB42B !important;
  color: #AFB42B !important; }
  .btn-outline-lime.btn-outline-darken-2:hover {
    background-color: #AFB42B !important; }

.progress-lime.progress-darken-2[value]::-webkit-progress-value {
  background-color: #AFB42B; }

.progress-lime.progress-darken-2[value]::-moz-progress-bar {
  background-color: #AFB42B; }

.progress-lime.progress-darken-2[value]::-ms-fill {
  background-color: #AFB42B; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-darken-2 .progress-bar {
    background-color: #AFB42B; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AFB42B !important; }

.border-lime.border-darken-2 {
  border: 1px solid #AFB42B !important; }

.border-top-lime.border-top-darken-2 {
  border-top: 1px solid #AFB42B !important; }

.border-bottom-lime.border-bottom-darken-2 {
  border-bottom: 1px solid #AFB42B !important; }

.border-left-lime.border-left-darken-2 {
  border-left: 1px solid #AFB42B !important; }

.border-right-lime.border-right-darken-2 {
  border-right: 1px solid #AFB42B !important; }

.overlay-lime.overlay-darken-2 {
  background: #AFB42B;
  /* The Fallback */
  background: rgba(175, 180, 43, 0.8); }

.lime.darken-3 {
  color: #9E9D24 !important; }

.bg-lime.bg-darken-3 {
  background-color: #9E9D24 !important; }

.btn-lime.btn-darken-3 {
  border-color: #9E9D24 !important;
  background-color: #9E9D24 !important; }
  .btn-lime.btn-darken-3:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-darken-3 {
  border-color: #9E9D24 !important;
  color: #9E9D24 !important; }
  .btn-outline-lime.btn-outline-darken-3:hover {
    background-color: #9E9D24 !important; }

.progress-lime.progress-darken-3[value]::-webkit-progress-value {
  background-color: #9E9D24; }

.progress-lime.progress-darken-3[value]::-moz-progress-bar {
  background-color: #9E9D24; }

.progress-lime.progress-darken-3[value]::-ms-fill {
  background-color: #9E9D24; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-darken-3 .progress-bar {
    background-color: #9E9D24; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9E9D24 !important; }

.border-lime.border-darken-3 {
  border: 1px solid #9E9D24 !important; }

.border-top-lime.border-top-darken-3 {
  border-top: 1px solid #9E9D24 !important; }

.border-bottom-lime.border-bottom-darken-3 {
  border-bottom: 1px solid #9E9D24 !important; }

.border-left-lime.border-left-darken-3 {
  border-left: 1px solid #9E9D24 !important; }

.border-right-lime.border-right-darken-3 {
  border-right: 1px solid #9E9D24 !important; }

.overlay-lime.overlay-darken-3 {
  background: #9E9D24;
  /* The Fallback */
  background: rgba(158, 157, 36, 0.8); }

.lime.darken-4 {
  color: #827717 !important; }

.bg-lime.bg-darken-4 {
  background-color: #827717 !important; }

.btn-lime.btn-darken-4 {
  border-color: #827717 !important;
  background-color: #827717 !important; }
  .btn-lime.btn-darken-4:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-darken-4 {
  border-color: #827717 !important;
  color: #827717 !important; }
  .btn-outline-lime.btn-outline-darken-4:hover {
    background-color: #827717 !important; }

.progress-lime.progress-darken-4[value]::-webkit-progress-value {
  background-color: #827717; }

.progress-lime.progress-darken-4[value]::-moz-progress-bar {
  background-color: #827717; }

.progress-lime.progress-darken-4[value]::-ms-fill {
  background-color: #827717; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-darken-4 .progress-bar {
    background-color: #827717; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #827717 !important; }

.border-lime.border-darken-4 {
  border: 1px solid #827717 !important; }

.border-top-lime.border-top-darken-4 {
  border-top: 1px solid #827717 !important; }

.border-bottom-lime.border-bottom-darken-4 {
  border-bottom: 1px solid #827717 !important; }

.border-left-lime.border-left-darken-4 {
  border-left: 1px solid #827717 !important; }

.border-right-lime.border-right-darken-4 {
  border-right: 1px solid #827717 !important; }

.overlay-lime.overlay-darken-4 {
  background: #827717;
  /* The Fallback */
  background: rgba(130, 119, 23, 0.8); }

.lime.accent-1 {
  color: #F4FF81 !important; }

.bg-lime.bg-accent-1 {
  background-color: #F4FF81 !important; }

.btn-lime.btn-accent-1 {
  border-color: #F4FF81 !important;
  background-color: #F4FF81 !important; }
  .btn-lime.btn-accent-1:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-accent-1 {
  border-color: #F4FF81 !important;
  color: #F4FF81 !important; }
  .btn-outline-lime.btn-outline-accent-1:hover {
    background-color: #F4FF81 !important; }

.progress-lime.progress-accent-1[value]::-webkit-progress-value {
  background-color: #F4FF81; }

.progress-lime.progress-accent-1[value]::-moz-progress-bar {
  background-color: #F4FF81; }

.progress-lime.progress-accent-1[value]::-ms-fill {
  background-color: #F4FF81; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-accent-1 .progress-bar {
    background-color: #F4FF81; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F4FF81 !important; }

.border-lime.border-accent-1 {
  border: 1px solid #F4FF81 !important; }

.border-top-lime.border-top-accent-1 {
  border-top: 1px solid #F4FF81 !important; }

.border-bottom-lime.border-bottom-accent-1 {
  border-bottom: 1px solid #F4FF81 !important; }

.border-left-lime.border-left-accent-1 {
  border-left: 1px solid #F4FF81 !important; }

.border-right-lime.border-right-accent-1 {
  border-right: 1px solid #F4FF81 !important; }

.overlay-lime.overlay-accent-1 {
  background: #F4FF81;
  /* The Fallback */
  background: rgba(244, 255, 129, 0.8); }

.lime.accent-2 {
  color: #EEFF41 !important; }

.bg-lime.bg-accent-2 {
  background-color: #EEFF41 !important; }

.btn-lime.btn-accent-2 {
  border-color: #EEFF41 !important;
  background-color: #EEFF41 !important; }
  .btn-lime.btn-accent-2:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-accent-2 {
  border-color: #EEFF41 !important;
  color: #EEFF41 !important; }
  .btn-outline-lime.btn-outline-accent-2:hover {
    background-color: #EEFF41 !important; }

.progress-lime.progress-accent-2[value]::-webkit-progress-value {
  background-color: #EEFF41; }

.progress-lime.progress-accent-2[value]::-moz-progress-bar {
  background-color: #EEFF41; }

.progress-lime.progress-accent-2[value]::-ms-fill {
  background-color: #EEFF41; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-accent-2 .progress-bar {
    background-color: #EEFF41; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EEFF41 !important; }

.border-lime.border-accent-2 {
  border: 1px solid #EEFF41 !important; }

.border-top-lime.border-top-accent-2 {
  border-top: 1px solid #EEFF41 !important; }

.border-bottom-lime.border-bottom-accent-2 {
  border-bottom: 1px solid #EEFF41 !important; }

.border-left-lime.border-left-accent-2 {
  border-left: 1px solid #EEFF41 !important; }

.border-right-lime.border-right-accent-2 {
  border-right: 1px solid #EEFF41 !important; }

.overlay-lime.overlay-accent-2 {
  background: #EEFF41;
  /* The Fallback */
  background: rgba(238, 255, 65, 0.8); }

.lime.accent-3 {
  color: #C6FF00 !important; }

.bg-lime.bg-accent-3 {
  background-color: #C6FF00 !important; }

.btn-lime.btn-accent-3 {
  border-color: #C6FF00 !important;
  background-color: #C6FF00 !important; }
  .btn-lime.btn-accent-3:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-accent-3 {
  border-color: #C6FF00 !important;
  color: #C6FF00 !important; }
  .btn-outline-lime.btn-outline-accent-3:hover {
    background-color: #C6FF00 !important; }

.progress-lime.progress-accent-3[value]::-webkit-progress-value {
  background-color: #C6FF00; }

.progress-lime.progress-accent-3[value]::-moz-progress-bar {
  background-color: #C6FF00; }

.progress-lime.progress-accent-3[value]::-ms-fill {
  background-color: #C6FF00; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-accent-3 .progress-bar {
    background-color: #C6FF00; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C6FF00 !important; }

.border-lime.border-accent-3 {
  border: 1px solid #C6FF00 !important; }

.border-top-lime.border-top-accent-3 {
  border-top: 1px solid #C6FF00 !important; }

.border-bottom-lime.border-bottom-accent-3 {
  border-bottom: 1px solid #C6FF00 !important; }

.border-left-lime.border-left-accent-3 {
  border-left: 1px solid #C6FF00 !important; }

.border-right-lime.border-right-accent-3 {
  border-right: 1px solid #C6FF00 !important; }

.overlay-lime.overlay-accent-3 {
  background: #C6FF00;
  /* The Fallback */
  background: rgba(198, 255, 0, 0.8); }

.lime.accent-4 {
  color: #AEEA00 !important; }

.bg-lime.bg-accent-4 {
  background-color: #AEEA00 !important; }

.btn-lime.btn-accent-4 {
  border-color: #AEEA00 !important;
  background-color: #AEEA00 !important; }
  .btn-lime.btn-accent-4:hover {
    border-color: #DCE775 !important;
    background-color: #DCE775 !important; }

.btn-outline-lime.btn-outline-accent-4 {
  border-color: #AEEA00 !important;
  color: #AEEA00 !important; }
  .btn-outline-lime.btn-outline-accent-4:hover {
    background-color: #AEEA00 !important; }

.progress-lime.progress-accent-4[value]::-webkit-progress-value {
  background-color: #AEEA00; }

.progress-lime.progress-accent-4[value]::-moz-progress-bar {
  background-color: #AEEA00; }

.progress-lime.progress-accent-4[value]::-ms-fill {
  background-color: #AEEA00; }

@media screen and (min-width: 0\0) {
  .progress-lime.progress-accent-4 .progress-bar {
    background-color: #AEEA00; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AEEA00 !important; }

.border-lime.border-accent-4 {
  border: 1px solid #AEEA00 !important; }

.border-top-lime.border-top-accent-4 {
  border-top: 1px solid #AEEA00 !important; }

.border-bottom-lime.border-bottom-accent-4 {
  border-bottom: 1px solid #AEEA00 !important; }

.border-left-lime.border-left-accent-4 {
  border-left: 1px solid #AEEA00 !important; }

.border-right-lime.border-right-accent-4 {
  border-right: 1px solid #AEEA00 !important; }

.overlay-lime.overlay-accent-4 {
  background: #AEEA00;
  /* The Fallback */
  background: rgba(174, 234, 0, 0.8); }

.yellow.lighten-5 {
  color: #FFFDE7 !important; }

.bg-yellow.bg-lighten-5 {
  background-color: #FFFDE7 !important; }

.btn-yellow.btn-lighten-5 {
  border-color: #FFFDE7 !important;
  background-color: #FFFDE7 !important; }
  .btn-yellow.btn-lighten-5:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-lighten-5 {
  border-color: #FFFDE7 !important;
  color: #FFFDE7 !important; }
  .btn-outline-yellow.btn-outline-lighten-5:hover {
    background-color: #FFFDE7 !important; }

.progress-yellow.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FFFDE7; }

.progress-yellow.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FFFDE7; }

.progress-yellow.progress-lighten-5[value]::-ms-fill {
  background-color: #FFFDE7; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-lighten-5 .progress-bar {
    background-color: #FFFDE7; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFDE7 !important; }

.border-yellow.border-lighten-5 {
  border: 1px solid #FFFDE7 !important; }

.border-top-yellow.border-top-lighten-5 {
  border-top: 1px solid #FFFDE7 !important; }

.border-bottom-yellow.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFFDE7 !important; }

.border-left-yellow.border-left-lighten-5 {
  border-left: 1px solid #FFFDE7 !important; }

.border-right-yellow.border-right-lighten-5 {
  border-right: 1px solid #FFFDE7 !important; }

.overlay-yellow.overlay-lighten-5 {
  background: #FFFDE7;
  /* The Fallback */
  background: rgba(255, 253, 231, 0.8); }

.yellow.lighten-4 {
  color: #FFF9C4 !important; }

.bg-yellow.bg-lighten-4 {
  background-color: #FFF9C4 !important; }

.btn-yellow.btn-lighten-4 {
  border-color: #FFF9C4 !important;
  background-color: #FFF9C4 !important; }
  .btn-yellow.btn-lighten-4:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-lighten-4 {
  border-color: #FFF9C4 !important;
  color: #FFF9C4 !important; }
  .btn-outline-yellow.btn-outline-lighten-4:hover {
    background-color: #FFF9C4 !important; }

.progress-yellow.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #FFF9C4; }

.progress-yellow.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #FFF9C4; }

.progress-yellow.progress-lighten-4[value]::-ms-fill {
  background-color: #FFF9C4; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-lighten-4 .progress-bar {
    background-color: #FFF9C4; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF9C4 !important; }

.border-yellow.border-lighten-4 {
  border: 1px solid #FFF9C4 !important; }

.border-top-yellow.border-top-lighten-4 {
  border-top: 1px solid #FFF9C4 !important; }

.border-bottom-yellow.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFF9C4 !important; }

.border-left-yellow.border-left-lighten-4 {
  border-left: 1px solid #FFF9C4 !important; }

.border-right-yellow.border-right-lighten-4 {
  border-right: 1px solid #FFF9C4 !important; }

.overlay-yellow.overlay-lighten-4 {
  background: #FFF9C4;
  /* The Fallback */
  background: rgba(255, 249, 196, 0.8); }

.yellow.lighten-3 {
  color: #FFF59D !important; }

.bg-yellow.bg-lighten-3 {
  background-color: #FFF59D !important; }

.btn-yellow.btn-lighten-3 {
  border-color: #FFF59D !important;
  background-color: #FFF59D !important; }
  .btn-yellow.btn-lighten-3:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-lighten-3 {
  border-color: #FFF59D !important;
  color: #FFF59D !important; }
  .btn-outline-yellow.btn-outline-lighten-3:hover {
    background-color: #FFF59D !important; }

.progress-yellow.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #FFF59D; }

.progress-yellow.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #FFF59D; }

.progress-yellow.progress-lighten-3[value]::-ms-fill {
  background-color: #FFF59D; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-lighten-3 .progress-bar {
    background-color: #FFF59D; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF59D !important; }

.border-yellow.border-lighten-3 {
  border: 1px solid #FFF59D !important; }

.border-top-yellow.border-top-lighten-3 {
  border-top: 1px solid #FFF59D !important; }

.border-bottom-yellow.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFF59D !important; }

.border-left-yellow.border-left-lighten-3 {
  border-left: 1px solid #FFF59D !important; }

.border-right-yellow.border-right-lighten-3 {
  border-right: 1px solid #FFF59D !important; }

.overlay-yellow.overlay-lighten-3 {
  background: #FFF59D;
  /* The Fallback */
  background: rgba(255, 245, 157, 0.8); }

.yellow.lighten-2 {
  color: #FFF176 !important; }

.bg-yellow.bg-lighten-2 {
  background-color: #FFF176 !important; }

.btn-yellow.btn-lighten-2 {
  border-color: #FFF176 !important;
  background-color: #FFF176 !important; }
  .btn-yellow.btn-lighten-2:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-lighten-2 {
  border-color: #FFF176 !important;
  color: #FFF176 !important; }
  .btn-outline-yellow.btn-outline-lighten-2:hover {
    background-color: #FFF176 !important; }

.progress-yellow.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #FFF176; }

.progress-yellow.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #FFF176; }

.progress-yellow.progress-lighten-2[value]::-ms-fill {
  background-color: #FFF176; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-lighten-2 .progress-bar {
    background-color: #FFF176; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF176 !important; }

.border-yellow.border-lighten-2 {
  border: 1px solid #FFF176 !important; }

.border-top-yellow.border-top-lighten-2 {
  border-top: 1px solid #FFF176 !important; }

.border-bottom-yellow.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFF176 !important; }

.border-left-yellow.border-left-lighten-2 {
  border-left: 1px solid #FFF176 !important; }

.border-right-yellow.border-right-lighten-2 {
  border-right: 1px solid #FFF176 !important; }

.overlay-yellow.overlay-lighten-2 {
  background: #FFF176;
  /* The Fallback */
  background: rgba(255, 241, 118, 0.8); }

.yellow.lighten-1 {
  color: #FFEE58 !important; }

.bg-yellow.bg-lighten-1 {
  background-color: #FFEE58 !important; }

.btn-yellow.btn-lighten-1 {
  border-color: #FFEE58 !important;
  background-color: #FFEE58 !important; }
  .btn-yellow.btn-lighten-1:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-lighten-1 {
  border-color: #FFEE58 !important;
  color: #FFEE58 !important; }
  .btn-outline-yellow.btn-outline-lighten-1:hover {
    background-color: #FFEE58 !important; }

.progress-yellow.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #FFEE58; }

.progress-yellow.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #FFEE58; }

.progress-yellow.progress-lighten-1[value]::-ms-fill {
  background-color: #FFEE58; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-lighten-1 .progress-bar {
    background-color: #FFEE58; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEE58 !important; }

.border-yellow.border-lighten-1 {
  border: 1px solid #FFEE58 !important; }

.border-top-yellow.border-top-lighten-1 {
  border-top: 1px solid #FFEE58 !important; }

.border-bottom-yellow.border-bottom-lighten-1 {
  border-bottom: 1px solid #FFEE58 !important; }

.border-left-yellow.border-left-lighten-1 {
  border-left: 1px solid #FFEE58 !important; }

.border-right-yellow.border-right-lighten-1 {
  border-right: 1px solid #FFEE58 !important; }

.overlay-yellow.overlay-lighten-1 {
  background: #FFEE58;
  /* The Fallback */
  background: rgba(255, 238, 88, 0.8); }

.yellow {
  color: #FFEB3B !important; }

.bg-yellow {
  background-color: #FFEB3B !important; }
  .bg-yellow .card-header, .bg-yellow .card-footer {
    background-color: transparent; }

.alert-yellow {
  border-color: #FFEB3B !important;
  background-color: #fff282 !important;
  color: #887a00 !important; }
  .alert-yellow .alert-link {
    color: #5f5500 !important; }

.border-yellow {
  border-color: #FFEB3B; }

.bg-yellow.tag-glow, .border-yellow.tag-glow {
  box-shadow: 0px 0px 10px #FFEB3B; }

.overlay-yellow {
  background: #FFEB3B;
  /* The Fallback */
  background: rgba(255, 235, 59, 0.8); }

.card-outline-yellow {
  border-width: 1px;
  border-style: solid;
  border-color: #FFEB3B;
  background-color: transparent; }
  .card-outline-yellow .card-header, .card-outline-yellow .card-footer {
    background-color: transparent; }

.btn-yellow {
  border-color: #FFEB3B;
  background-color: #FFEB3B;
  color: #FFFFFF; }
  .btn-yellow:hover {
    border-color: #FFF176;
    background-color: #FFF176;
    color: #FFF !important; }

.btn-outline-yellow {
  border-color: #FFEB3B;
  background-color: transparent;
  color: #FFEB3B; }
  .btn-outline-yellow:hover {
    background-color: #FFEB3B;
    color: #FFF !important; }

.progress-yellow[value]::-webkit-progress-value {
  background-color: #FFEB3B; }

.progress-yellow[value]::-moz-progress-bar {
  background-color: #FFEB3B; }

.progress-yellow[value]::-ms-fill {
  background-color: #FFEB3B; }

@media screen and (min-width: 0\0) {
  .progress-yellow .progress-bar {
    background-color: #FFEB3B; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEB3B !important; }

.border-yellow {
  border: 1px solid #FFEB3B !important; }

.border-top-yellow {
  border-top: 1px solid #FFEB3B; }

.border-bottom-yellow {
  border-bottom: 1px solid #FFEB3B; }

.border-left-yellow {
  border-left: 1px solid #FFEB3B; }

.border-right-yellow {
  border-right: 1px solid #FFEB3B; }

.yellow.darken-1 {
  color: #FDD835 !important; }

.bg-yellow.bg-darken-1 {
  background-color: #FDD835 !important; }

.btn-yellow.btn-darken-1 {
  border-color: #FDD835 !important;
  background-color: #FDD835 !important; }
  .btn-yellow.btn-darken-1:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-darken-1 {
  border-color: #FDD835 !important;
  color: #FDD835 !important; }
  .btn-outline-yellow.btn-outline-darken-1:hover {
    background-color: #FDD835 !important; }

.progress-yellow.progress-darken-1[value]::-webkit-progress-value {
  background-color: #FDD835; }

.progress-yellow.progress-darken-1[value]::-moz-progress-bar {
  background-color: #FDD835; }

.progress-yellow.progress-darken-1[value]::-ms-fill {
  background-color: #FDD835; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-darken-1 .progress-bar {
    background-color: #FDD835; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FDD835 !important; }

.border-yellow.border-darken-1 {
  border: 1px solid #FDD835 !important; }

.border-top-yellow.border-top-darken-1 {
  border-top: 1px solid #FDD835 !important; }

.border-bottom-yellow.border-bottom-darken-1 {
  border-bottom: 1px solid #FDD835 !important; }

.border-left-yellow.border-left-darken-1 {
  border-left: 1px solid #FDD835 !important; }

.border-right-yellow.border-right-darken-1 {
  border-right: 1px solid #FDD835 !important; }

.overlay-yellow.overlay-darken-1 {
  background: #FDD835;
  /* The Fallback */
  background: rgba(253, 216, 53, 0.8); }

.yellow.darken-2 {
  color: #FBC02D !important; }

.bg-yellow.bg-darken-2 {
  background-color: #FBC02D !important; }

.btn-yellow.btn-darken-2 {
  border-color: #FBC02D !important;
  background-color: #FBC02D !important; }
  .btn-yellow.btn-darken-2:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-darken-2 {
  border-color: #FBC02D !important;
  color: #FBC02D !important; }
  .btn-outline-yellow.btn-outline-darken-2:hover {
    background-color: #FBC02D !important; }

.progress-yellow.progress-darken-2[value]::-webkit-progress-value {
  background-color: #FBC02D; }

.progress-yellow.progress-darken-2[value]::-moz-progress-bar {
  background-color: #FBC02D; }

.progress-yellow.progress-darken-2[value]::-ms-fill {
  background-color: #FBC02D; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-darken-2 .progress-bar {
    background-color: #FBC02D; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FBC02D !important; }

.border-yellow.border-darken-2 {
  border: 1px solid #FBC02D !important; }

.border-top-yellow.border-top-darken-2 {
  border-top: 1px solid #FBC02D !important; }

.border-bottom-yellow.border-bottom-darken-2 {
  border-bottom: 1px solid #FBC02D !important; }

.border-left-yellow.border-left-darken-2 {
  border-left: 1px solid #FBC02D !important; }

.border-right-yellow.border-right-darken-2 {
  border-right: 1px solid #FBC02D !important; }

.overlay-yellow.overlay-darken-2 {
  background: #FBC02D;
  /* The Fallback */
  background: rgba(251, 192, 45, 0.8); }

.yellow.darken-3 {
  color: #F9A825 !important; }

.bg-yellow.bg-darken-3 {
  background-color: #F9A825 !important; }

.btn-yellow.btn-darken-3 {
  border-color: #F9A825 !important;
  background-color: #F9A825 !important; }
  .btn-yellow.btn-darken-3:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-darken-3 {
  border-color: #F9A825 !important;
  color: #F9A825 !important; }
  .btn-outline-yellow.btn-outline-darken-3:hover {
    background-color: #F9A825 !important; }

.progress-yellow.progress-darken-3[value]::-webkit-progress-value {
  background-color: #F9A825; }

.progress-yellow.progress-darken-3[value]::-moz-progress-bar {
  background-color: #F9A825; }

.progress-yellow.progress-darken-3[value]::-ms-fill {
  background-color: #F9A825; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-darken-3 .progress-bar {
    background-color: #F9A825; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9A825 !important; }

.border-yellow.border-darken-3 {
  border: 1px solid #F9A825 !important; }

.border-top-yellow.border-top-darken-3 {
  border-top: 1px solid #F9A825 !important; }

.border-bottom-yellow.border-bottom-darken-3 {
  border-bottom: 1px solid #F9A825 !important; }

.border-left-yellow.border-left-darken-3 {
  border-left: 1px solid #F9A825 !important; }

.border-right-yellow.border-right-darken-3 {
  border-right: 1px solid #F9A825 !important; }

.overlay-yellow.overlay-darken-3 {
  background: #F9A825;
  /* The Fallback */
  background: rgba(249, 168, 37, 0.8); }

.yellow.darken-4 {
  color: #F57F17 !important; }

.bg-yellow.bg-darken-4 {
  background-color: #F57F17 !important; }

.btn-yellow.btn-darken-4 {
  border-color: #F57F17 !important;
  background-color: #F57F17 !important; }
  .btn-yellow.btn-darken-4:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-darken-4 {
  border-color: #F57F17 !important;
  color: #F57F17 !important; }
  .btn-outline-yellow.btn-outline-darken-4:hover {
    background-color: #F57F17 !important; }

.progress-yellow.progress-darken-4[value]::-webkit-progress-value {
  background-color: #F57F17; }

.progress-yellow.progress-darken-4[value]::-moz-progress-bar {
  background-color: #F57F17; }

.progress-yellow.progress-darken-4[value]::-ms-fill {
  background-color: #F57F17; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-darken-4 .progress-bar {
    background-color: #F57F17; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F57F17 !important; }

.border-yellow.border-darken-4 {
  border: 1px solid #F57F17 !important; }

.border-top-yellow.border-top-darken-4 {
  border-top: 1px solid #F57F17 !important; }

.border-bottom-yellow.border-bottom-darken-4 {
  border-bottom: 1px solid #F57F17 !important; }

.border-left-yellow.border-left-darken-4 {
  border-left: 1px solid #F57F17 !important; }

.border-right-yellow.border-right-darken-4 {
  border-right: 1px solid #F57F17 !important; }

.overlay-yellow.overlay-darken-4 {
  background: #F57F17;
  /* The Fallback */
  background: rgba(245, 127, 23, 0.8); }

.yellow.accent-1 {
  color: #FFFF8D !important; }

.bg-yellow.bg-accent-1 {
  background-color: #FFFF8D !important; }

.btn-yellow.btn-accent-1 {
  border-color: #FFFF8D !important;
  background-color: #FFFF8D !important; }
  .btn-yellow.btn-accent-1:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-accent-1 {
  border-color: #FFFF8D !important;
  color: #FFFF8D !important; }
  .btn-outline-yellow.btn-outline-accent-1:hover {
    background-color: #FFFF8D !important; }

.progress-yellow.progress-accent-1[value]::-webkit-progress-value {
  background-color: #FFFF8D; }

.progress-yellow.progress-accent-1[value]::-moz-progress-bar {
  background-color: #FFFF8D; }

.progress-yellow.progress-accent-1[value]::-ms-fill {
  background-color: #FFFF8D; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-accent-1 .progress-bar {
    background-color: #FFFF8D; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFF8D !important; }

.border-yellow.border-accent-1 {
  border: 1px solid #FFFF8D !important; }

.border-top-yellow.border-top-accent-1 {
  border-top: 1px solid #FFFF8D !important; }

.border-bottom-yellow.border-bottom-accent-1 {
  border-bottom: 1px solid #FFFF8D !important; }

.border-left-yellow.border-left-accent-1 {
  border-left: 1px solid #FFFF8D !important; }

.border-right-yellow.border-right-accent-1 {
  border-right: 1px solid #FFFF8D !important; }

.overlay-yellow.overlay-accent-1 {
  background: #FFFF8D;
  /* The Fallback */
  background: rgba(255, 255, 141, 0.8); }

.yellow.accent-2 {
  color: #FFFF00 !important; }

.bg-yellow.bg-accent-2 {
  background-color: #FFFF00 !important; }

.btn-yellow.btn-accent-2 {
  border-color: #FFFF00 !important;
  background-color: #FFFF00 !important; }
  .btn-yellow.btn-accent-2:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-accent-2 {
  border-color: #FFFF00 !important;
  color: #FFFF00 !important; }
  .btn-outline-yellow.btn-outline-accent-2:hover {
    background-color: #FFFF00 !important; }

.progress-yellow.progress-accent-2[value]::-webkit-progress-value {
  background-color: #FFFF00; }

.progress-yellow.progress-accent-2[value]::-moz-progress-bar {
  background-color: #FFFF00; }

.progress-yellow.progress-accent-2[value]::-ms-fill {
  background-color: #FFFF00; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-accent-2 .progress-bar {
    background-color: #FFFF00; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFF00 !important; }

.border-yellow.border-accent-2 {
  border: 1px solid #FFFF00 !important; }

.border-top-yellow.border-top-accent-2 {
  border-top: 1px solid #FFFF00 !important; }

.border-bottom-yellow.border-bottom-accent-2 {
  border-bottom: 1px solid #FFFF00 !important; }

.border-left-yellow.border-left-accent-2 {
  border-left: 1px solid #FFFF00 !important; }

.border-right-yellow.border-right-accent-2 {
  border-right: 1px solid #FFFF00 !important; }

.overlay-yellow.overlay-accent-2 {
  background: #FFFF00;
  /* The Fallback */
  background: rgba(255, 255, 0, 0.8); }

.yellow.accent-3 {
  color: #FFEA00 !important; }

.bg-yellow.bg-accent-3 {
  background-color: #FFEA00 !important; }

.btn-yellow.btn-accent-3 {
  border-color: #FFEA00 !important;
  background-color: #FFEA00 !important; }
  .btn-yellow.btn-accent-3:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-accent-3 {
  border-color: #FFEA00 !important;
  color: #FFEA00 !important; }
  .btn-outline-yellow.btn-outline-accent-3:hover {
    background-color: #FFEA00 !important; }

.progress-yellow.progress-accent-3[value]::-webkit-progress-value {
  background-color: #FFEA00; }

.progress-yellow.progress-accent-3[value]::-moz-progress-bar {
  background-color: #FFEA00; }

.progress-yellow.progress-accent-3[value]::-ms-fill {
  background-color: #FFEA00; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-accent-3 .progress-bar {
    background-color: #FFEA00; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEA00 !important; }

.border-yellow.border-accent-3 {
  border: 1px solid #FFEA00 !important; }

.border-top-yellow.border-top-accent-3 {
  border-top: 1px solid #FFEA00 !important; }

.border-bottom-yellow.border-bottom-accent-3 {
  border-bottom: 1px solid #FFEA00 !important; }

.border-left-yellow.border-left-accent-3 {
  border-left: 1px solid #FFEA00 !important; }

.border-right-yellow.border-right-accent-3 {
  border-right: 1px solid #FFEA00 !important; }

.overlay-yellow.overlay-accent-3 {
  background: #FFEA00;
  /* The Fallback */
  background: rgba(255, 234, 0, 0.8); }

.yellow.accent-4 {
  color: #FFD600 !important; }

.bg-yellow.bg-accent-4 {
  background-color: #FFD600 !important; }

.btn-yellow.btn-accent-4 {
  border-color: #FFD600 !important;
  background-color: #FFD600 !important; }
  .btn-yellow.btn-accent-4:hover {
    border-color: #FFF176 !important;
    background-color: #FFF176 !important; }

.btn-outline-yellow.btn-outline-accent-4 {
  border-color: #FFD600 !important;
  color: #FFD600 !important; }
  .btn-outline-yellow.btn-outline-accent-4:hover {
    background-color: #FFD600 !important; }

.progress-yellow.progress-accent-4[value]::-webkit-progress-value {
  background-color: #FFD600; }

.progress-yellow.progress-accent-4[value]::-moz-progress-bar {
  background-color: #FFD600; }

.progress-yellow.progress-accent-4[value]::-ms-fill {
  background-color: #FFD600; }

@media screen and (min-width: 0\0) {
  .progress-yellow.progress-accent-4 .progress-bar {
    background-color: #FFD600; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD600 !important; }

.border-yellow.border-accent-4 {
  border: 1px solid #FFD600 !important; }

.border-top-yellow.border-top-accent-4 {
  border-top: 1px solid #FFD600 !important; }

.border-bottom-yellow.border-bottom-accent-4 {
  border-bottom: 1px solid #FFD600 !important; }

.border-left-yellow.border-left-accent-4 {
  border-left: 1px solid #FFD600 !important; }

.border-right-yellow.border-right-accent-4 {
  border-right: 1px solid #FFD600 !important; }

.overlay-yellow.overlay-accent-4 {
  background: #FFD600;
  /* The Fallback */
  background: rgba(255, 214, 0, 0.8); }

.amber.lighten-5 {
  color: #FFF8E1 !important; }

.bg-amber.bg-lighten-5 {
  background-color: #FFF8E1 !important; }

.btn-amber.btn-lighten-5 {
  border-color: #FFF8E1 !important;
  background-color: #FFF8E1 !important; }
  .btn-amber.btn-lighten-5:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-lighten-5 {
  border-color: #FFF8E1 !important;
  color: #FFF8E1 !important; }
  .btn-outline-amber.btn-outline-lighten-5:hover {
    background-color: #FFF8E1 !important; }

.progress-amber.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FFF8E1; }

.progress-amber.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FFF8E1; }

.progress-amber.progress-lighten-5[value]::-ms-fill {
  background-color: #FFF8E1; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-lighten-5 .progress-bar {
    background-color: #FFF8E1; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF8E1 !important; }

.border-amber.border-lighten-5 {
  border: 1px solid #FFF8E1 !important; }

.border-top-amber.border-top-lighten-5 {
  border-top: 1px solid #FFF8E1 !important; }

.border-bottom-amber.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFF8E1 !important; }

.border-left-amber.border-left-lighten-5 {
  border-left: 1px solid #FFF8E1 !important; }

.border-right-amber.border-right-lighten-5 {
  border-right: 1px solid #FFF8E1 !important; }

.overlay-amber.overlay-lighten-5 {
  background: #FFF8E1;
  /* The Fallback */
  background: rgba(255, 248, 225, 0.8); }

.amber.lighten-4 {
  color: #FFECB3 !important; }

.bg-amber.bg-lighten-4 {
  background-color: #FFECB3 !important; }

.btn-amber.btn-lighten-4 {
  border-color: #FFECB3 !important;
  background-color: #FFECB3 !important; }
  .btn-amber.btn-lighten-4:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-lighten-4 {
  border-color: #FFECB3 !important;
  color: #FFECB3 !important; }
  .btn-outline-amber.btn-outline-lighten-4:hover {
    background-color: #FFECB3 !important; }

.progress-amber.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #FFECB3; }

.progress-amber.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #FFECB3; }

.progress-amber.progress-lighten-4[value]::-ms-fill {
  background-color: #FFECB3; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-lighten-4 .progress-bar {
    background-color: #FFECB3; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFECB3 !important; }

.border-amber.border-lighten-4 {
  border: 1px solid #FFECB3 !important; }

.border-top-amber.border-top-lighten-4 {
  border-top: 1px solid #FFECB3 !important; }

.border-bottom-amber.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFECB3 !important; }

.border-left-amber.border-left-lighten-4 {
  border-left: 1px solid #FFECB3 !important; }

.border-right-amber.border-right-lighten-4 {
  border-right: 1px solid #FFECB3 !important; }

.overlay-amber.overlay-lighten-4 {
  background: #FFECB3;
  /* The Fallback */
  background: rgba(255, 236, 179, 0.8); }

.amber.lighten-3 {
  color: #FFE082 !important; }

.bg-amber.bg-lighten-3 {
  background-color: #FFE082 !important; }

.btn-amber.btn-lighten-3 {
  border-color: #FFE082 !important;
  background-color: #FFE082 !important; }
  .btn-amber.btn-lighten-3:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-lighten-3 {
  border-color: #FFE082 !important;
  color: #FFE082 !important; }
  .btn-outline-amber.btn-outline-lighten-3:hover {
    background-color: #FFE082 !important; }

.progress-amber.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #FFE082; }

.progress-amber.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #FFE082; }

.progress-amber.progress-lighten-3[value]::-ms-fill {
  background-color: #FFE082; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-lighten-3 .progress-bar {
    background-color: #FFE082; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE082 !important; }

.border-amber.border-lighten-3 {
  border: 1px solid #FFE082 !important; }

.border-top-amber.border-top-lighten-3 {
  border-top: 1px solid #FFE082 !important; }

.border-bottom-amber.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFE082 !important; }

.border-left-amber.border-left-lighten-3 {
  border-left: 1px solid #FFE082 !important; }

.border-right-amber.border-right-lighten-3 {
  border-right: 1px solid #FFE082 !important; }

.overlay-amber.overlay-lighten-3 {
  background: #FFE082;
  /* The Fallback */
  background: rgba(255, 224, 130, 0.8); }

.amber.lighten-2 {
  color: #FFD54F !important; }

.bg-amber.bg-lighten-2 {
  background-color: #FFD54F !important; }

.btn-amber.btn-lighten-2 {
  border-color: #FFD54F !important;
  background-color: #FFD54F !important; }
  .btn-amber.btn-lighten-2:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-lighten-2 {
  border-color: #FFD54F !important;
  color: #FFD54F !important; }
  .btn-outline-amber.btn-outline-lighten-2:hover {
    background-color: #FFD54F !important; }

.progress-amber.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #FFD54F; }

.progress-amber.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #FFD54F; }

.progress-amber.progress-lighten-2[value]::-ms-fill {
  background-color: #FFD54F; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-lighten-2 .progress-bar {
    background-color: #FFD54F; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD54F !important; }

.border-amber.border-lighten-2 {
  border: 1px solid #FFD54F !important; }

.border-top-amber.border-top-lighten-2 {
  border-top: 1px solid #FFD54F !important; }

.border-bottom-amber.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFD54F !important; }

.border-left-amber.border-left-lighten-2 {
  border-left: 1px solid #FFD54F !important; }

.border-right-amber.border-right-lighten-2 {
  border-right: 1px solid #FFD54F !important; }

.overlay-amber.overlay-lighten-2 {
  background: #FFD54F;
  /* The Fallback */
  background: rgba(255, 213, 79, 0.8); }

.amber.lighten-1 {
  color: #FFCA28 !important; }

.bg-amber.bg-lighten-1 {
  background-color: #FFCA28 !important; }

.btn-amber.btn-lighten-1 {
  border-color: #FFCA28 !important;
  background-color: #FFCA28 !important; }
  .btn-amber.btn-lighten-1:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-lighten-1 {
  border-color: #FFCA28 !important;
  color: #FFCA28 !important; }
  .btn-outline-amber.btn-outline-lighten-1:hover {
    background-color: #FFCA28 !important; }

.progress-amber.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #FFCA28; }

.progress-amber.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #FFCA28; }

.progress-amber.progress-lighten-1[value]::-ms-fill {
  background-color: #FFCA28; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-lighten-1 .progress-bar {
    background-color: #FFCA28; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCA28 !important; }

.border-amber.border-lighten-1 {
  border: 1px solid #FFCA28 !important; }

.border-top-amber.border-top-lighten-1 {
  border-top: 1px solid #FFCA28 !important; }

.border-bottom-amber.border-bottom-lighten-1 {
  border-bottom: 1px solid #FFCA28 !important; }

.border-left-amber.border-left-lighten-1 {
  border-left: 1px solid #FFCA28 !important; }

.border-right-amber.border-right-lighten-1 {
  border-right: 1px solid #FFCA28 !important; }

.overlay-amber.overlay-lighten-1 {
  background: #FFCA28;
  /* The Fallback */
  background: rgba(255, 202, 40, 0.8); }

.amber {
  color: #FFC107 !important; }

.bg-amber {
  background-color: #FFC107 !important; }
  .bg-amber .card-header, .bg-amber .card-footer {
    background-color: transparent; }

.alert-amber {
  border-color: #FFC107 !important;
  background-color: #ffd34e !important;
  color: #543f00 !important; }
  .alert-amber .alert-link {
    color: #2b2000 !important; }

.border-amber {
  border-color: #FFC107; }

.bg-amber.tag-glow, .border-amber.tag-glow {
  box-shadow: 0px 0px 10px #FFC107; }

.overlay-amber {
  background: #FFC107;
  /* The Fallback */
  background: rgba(255, 193, 7, 0.8); }

.card-outline-amber {
  border-width: 1px;
  border-style: solid;
  border-color: #FFC107;
  background-color: transparent; }
  .card-outline-amber .card-header, .card-outline-amber .card-footer {
    background-color: transparent; }

.btn-amber {
  border-color: #FFC107;
  background-color: #FFC107;
  color: #FFFFFF; }
  .btn-amber:hover {
    border-color: #FFD54F;
    background-color: #FFD54F;
    color: #FFF !important; }

.btn-outline-amber {
  border-color: #FFC107;
  background-color: transparent;
  color: #FFC107; }
  .btn-outline-amber:hover {
    background-color: #FFC107;
    color: #FFF !important; }

.progress-amber[value]::-webkit-progress-value {
  background-color: #FFC107; }

.progress-amber[value]::-moz-progress-bar {
  background-color: #FFC107; }

.progress-amber[value]::-ms-fill {
  background-color: #FFC107; }

@media screen and (min-width: 0\0) {
  .progress-amber .progress-bar {
    background-color: #FFC107; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC107 !important; }

.border-amber {
  border: 1px solid #FFC107 !important; }

.border-top-amber {
  border-top: 1px solid #FFC107; }

.border-bottom-amber {
  border-bottom: 1px solid #FFC107; }

.border-left-amber {
  border-left: 1px solid #FFC107; }

.border-right-amber {
  border-right: 1px solid #FFC107; }

.amber.darken-1 {
  color: #FFB300 !important; }

.bg-amber.bg-darken-1 {
  background-color: #FFB300 !important; }

.btn-amber.btn-darken-1 {
  border-color: #FFB300 !important;
  background-color: #FFB300 !important; }
  .btn-amber.btn-darken-1:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-darken-1 {
  border-color: #FFB300 !important;
  color: #FFB300 !important; }
  .btn-outline-amber.btn-outline-darken-1:hover {
    background-color: #FFB300 !important; }

.progress-amber.progress-darken-1[value]::-webkit-progress-value {
  background-color: #FFB300; }

.progress-amber.progress-darken-1[value]::-moz-progress-bar {
  background-color: #FFB300; }

.progress-amber.progress-darken-1[value]::-ms-fill {
  background-color: #FFB300; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-darken-1 .progress-bar {
    background-color: #FFB300; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFB300 !important; }

.border-amber.border-darken-1 {
  border: 1px solid #FFB300 !important; }

.border-top-amber.border-top-darken-1 {
  border-top: 1px solid #FFB300 !important; }

.border-bottom-amber.border-bottom-darken-1 {
  border-bottom: 1px solid #FFB300 !important; }

.border-left-amber.border-left-darken-1 {
  border-left: 1px solid #FFB300 !important; }

.border-right-amber.border-right-darken-1 {
  border-right: 1px solid #FFB300 !important; }

.overlay-amber.overlay-darken-1 {
  background: #FFB300;
  /* The Fallback */
  background: rgba(255, 179, 0, 0.8); }

.amber.darken-2 {
  color: #FFA000 !important; }

.bg-amber.bg-darken-2 {
  background-color: #FFA000 !important; }

.btn-amber.btn-darken-2 {
  border-color: #FFA000 !important;
  background-color: #FFA000 !important; }
  .btn-amber.btn-darken-2:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-darken-2 {
  border-color: #FFA000 !important;
  color: #FFA000 !important; }
  .btn-outline-amber.btn-outline-darken-2:hover {
    background-color: #FFA000 !important; }

.progress-amber.progress-darken-2[value]::-webkit-progress-value {
  background-color: #FFA000; }

.progress-amber.progress-darken-2[value]::-moz-progress-bar {
  background-color: #FFA000; }

.progress-amber.progress-darken-2[value]::-ms-fill {
  background-color: #FFA000; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-darken-2 .progress-bar {
    background-color: #FFA000; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA000 !important; }

.border-amber.border-darken-2 {
  border: 1px solid #FFA000 !important; }

.border-top-amber.border-top-darken-2 {
  border-top: 1px solid #FFA000 !important; }

.border-bottom-amber.border-bottom-darken-2 {
  border-bottom: 1px solid #FFA000 !important; }

.border-left-amber.border-left-darken-2 {
  border-left: 1px solid #FFA000 !important; }

.border-right-amber.border-right-darken-2 {
  border-right: 1px solid #FFA000 !important; }

.overlay-amber.overlay-darken-2 {
  background: #FFA000;
  /* The Fallback */
  background: rgba(255, 160, 0, 0.8); }

.amber.darken-3 {
  color: #FF8F00 !important; }

.bg-amber.bg-darken-3 {
  background-color: #FF8F00 !important; }

.btn-amber.btn-darken-3 {
  border-color: #FF8F00 !important;
  background-color: #FF8F00 !important; }
  .btn-amber.btn-darken-3:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-darken-3 {
  border-color: #FF8F00 !important;
  color: #FF8F00 !important; }
  .btn-outline-amber.btn-outline-darken-3:hover {
    background-color: #FF8F00 !important; }

.progress-amber.progress-darken-3[value]::-webkit-progress-value {
  background-color: #FF8F00; }

.progress-amber.progress-darken-3[value]::-moz-progress-bar {
  background-color: #FF8F00; }

.progress-amber.progress-darken-3[value]::-ms-fill {
  background-color: #FF8F00; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-darken-3 .progress-bar {
    background-color: #FF8F00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8F00 !important; }

.border-amber.border-darken-3 {
  border: 1px solid #FF8F00 !important; }

.border-top-amber.border-top-darken-3 {
  border-top: 1px solid #FF8F00 !important; }

.border-bottom-amber.border-bottom-darken-3 {
  border-bottom: 1px solid #FF8F00 !important; }

.border-left-amber.border-left-darken-3 {
  border-left: 1px solid #FF8F00 !important; }

.border-right-amber.border-right-darken-3 {
  border-right: 1px solid #FF8F00 !important; }

.overlay-amber.overlay-darken-3 {
  background: #FF8F00;
  /* The Fallback */
  background: rgba(255, 143, 0, 0.8); }

.amber.darken-4 {
  color: #FF6F00 !important; }

.bg-amber.bg-darken-4 {
  background-color: #FF6F00 !important; }

.btn-amber.btn-darken-4 {
  border-color: #FF6F00 !important;
  background-color: #FF6F00 !important; }
  .btn-amber.btn-darken-4:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-darken-4 {
  border-color: #FF6F00 !important;
  color: #FF6F00 !important; }
  .btn-outline-amber.btn-outline-darken-4:hover {
    background-color: #FF6F00 !important; }

.progress-amber.progress-darken-4[value]::-webkit-progress-value {
  background-color: #FF6F00; }

.progress-amber.progress-darken-4[value]::-moz-progress-bar {
  background-color: #FF6F00; }

.progress-amber.progress-darken-4[value]::-ms-fill {
  background-color: #FF6F00; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-darken-4 .progress-bar {
    background-color: #FF6F00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6F00 !important; }

.border-amber.border-darken-4 {
  border: 1px solid #FF6F00 !important; }

.border-top-amber.border-top-darken-4 {
  border-top: 1px solid #FF6F00 !important; }

.border-bottom-amber.border-bottom-darken-4 {
  border-bottom: 1px solid #FF6F00 !important; }

.border-left-amber.border-left-darken-4 {
  border-left: 1px solid #FF6F00 !important; }

.border-right-amber.border-right-darken-4 {
  border-right: 1px solid #FF6F00 !important; }

.overlay-amber.overlay-darken-4 {
  background: #FF6F00;
  /* The Fallback */
  background: rgba(255, 111, 0, 0.8); }

.amber.accent-1 {
  color: #FFE57F !important; }

.bg-amber.bg-accent-1 {
  background-color: #FFE57F !important; }

.btn-amber.btn-accent-1 {
  border-color: #FFE57F !important;
  background-color: #FFE57F !important; }
  .btn-amber.btn-accent-1:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-accent-1 {
  border-color: #FFE57F !important;
  color: #FFE57F !important; }
  .btn-outline-amber.btn-outline-accent-1:hover {
    background-color: #FFE57F !important; }

.progress-amber.progress-accent-1[value]::-webkit-progress-value {
  background-color: #FFE57F; }

.progress-amber.progress-accent-1[value]::-moz-progress-bar {
  background-color: #FFE57F; }

.progress-amber.progress-accent-1[value]::-ms-fill {
  background-color: #FFE57F; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-accent-1 .progress-bar {
    background-color: #FFE57F; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE57F !important; }

.border-amber.border-accent-1 {
  border: 1px solid #FFE57F !important; }

.border-top-amber.border-top-accent-1 {
  border-top: 1px solid #FFE57F !important; }

.border-bottom-amber.border-bottom-accent-1 {
  border-bottom: 1px solid #FFE57F !important; }

.border-left-amber.border-left-accent-1 {
  border-left: 1px solid #FFE57F !important; }

.border-right-amber.border-right-accent-1 {
  border-right: 1px solid #FFE57F !important; }

.overlay-amber.overlay-accent-1 {
  background: #FFE57F;
  /* The Fallback */
  background: rgba(255, 229, 127, 0.8); }

.amber.accent-2 {
  color: #FFD740 !important; }

.bg-amber.bg-accent-2 {
  background-color: #FFD740 !important; }

.btn-amber.btn-accent-2 {
  border-color: #FFD740 !important;
  background-color: #FFD740 !important; }
  .btn-amber.btn-accent-2:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-accent-2 {
  border-color: #FFD740 !important;
  color: #FFD740 !important; }
  .btn-outline-amber.btn-outline-accent-2:hover {
    background-color: #FFD740 !important; }

.progress-amber.progress-accent-2[value]::-webkit-progress-value {
  background-color: #FFD740; }

.progress-amber.progress-accent-2[value]::-moz-progress-bar {
  background-color: #FFD740; }

.progress-amber.progress-accent-2[value]::-ms-fill {
  background-color: #FFD740; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-accent-2 .progress-bar {
    background-color: #FFD740; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD740 !important; }

.border-amber.border-accent-2 {
  border: 1px solid #FFD740 !important; }

.border-top-amber.border-top-accent-2 {
  border-top: 1px solid #FFD740 !important; }

.border-bottom-amber.border-bottom-accent-2 {
  border-bottom: 1px solid #FFD740 !important; }

.border-left-amber.border-left-accent-2 {
  border-left: 1px solid #FFD740 !important; }

.border-right-amber.border-right-accent-2 {
  border-right: 1px solid #FFD740 !important; }

.overlay-amber.overlay-accent-2 {
  background: #FFD740;
  /* The Fallback */
  background: rgba(255, 215, 64, 0.8); }

.amber.accent-3 {
  color: #FFC400 !important; }

.bg-amber.bg-accent-3 {
  background-color: #FFC400 !important; }

.btn-amber.btn-accent-3 {
  border-color: #FFC400 !important;
  background-color: #FFC400 !important; }
  .btn-amber.btn-accent-3:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-accent-3 {
  border-color: #FFC400 !important;
  color: #FFC400 !important; }
  .btn-outline-amber.btn-outline-accent-3:hover {
    background-color: #FFC400 !important; }

.progress-amber.progress-accent-3[value]::-webkit-progress-value {
  background-color: #FFC400; }

.progress-amber.progress-accent-3[value]::-moz-progress-bar {
  background-color: #FFC400; }

.progress-amber.progress-accent-3[value]::-ms-fill {
  background-color: #FFC400; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-accent-3 .progress-bar {
    background-color: #FFC400; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC400 !important; }

.border-amber.border-accent-3 {
  border: 1px solid #FFC400 !important; }

.border-top-amber.border-top-accent-3 {
  border-top: 1px solid #FFC400 !important; }

.border-bottom-amber.border-bottom-accent-3 {
  border-bottom: 1px solid #FFC400 !important; }

.border-left-amber.border-left-accent-3 {
  border-left: 1px solid #FFC400 !important; }

.border-right-amber.border-right-accent-3 {
  border-right: 1px solid #FFC400 !important; }

.overlay-amber.overlay-accent-3 {
  background: #FFC400;
  /* The Fallback */
  background: rgba(255, 196, 0, 0.8); }

.amber.accent-4 {
  color: #FFAB00 !important; }

.bg-amber.bg-accent-4 {
  background-color: #FFAB00 !important; }

.btn-amber.btn-accent-4 {
  border-color: #FFAB00 !important;
  background-color: #FFAB00 !important; }
  .btn-amber.btn-accent-4:hover {
    border-color: #FFD54F !important;
    background-color: #FFD54F !important; }

.btn-outline-amber.btn-outline-accent-4 {
  border-color: #FFAB00 !important;
  color: #FFAB00 !important; }
  .btn-outline-amber.btn-outline-accent-4:hover {
    background-color: #FFAB00 !important; }

.progress-amber.progress-accent-4[value]::-webkit-progress-value {
  background-color: #FFAB00; }

.progress-amber.progress-accent-4[value]::-moz-progress-bar {
  background-color: #FFAB00; }

.progress-amber.progress-accent-4[value]::-ms-fill {
  background-color: #FFAB00; }

@media screen and (min-width: 0\0) {
  .progress-amber.progress-accent-4 .progress-bar {
    background-color: #FFAB00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAB00 !important; }

.border-amber.border-accent-4 {
  border: 1px solid #FFAB00 !important; }

.border-top-amber.border-top-accent-4 {
  border-top: 1px solid #FFAB00 !important; }

.border-bottom-amber.border-bottom-accent-4 {
  border-bottom: 1px solid #FFAB00 !important; }

.border-left-amber.border-left-accent-4 {
  border-left: 1px solid #FFAB00 !important; }

.border-right-amber.border-right-accent-4 {
  border-right: 1px solid #FFAB00 !important; }

.overlay-amber.overlay-accent-4 {
  background: #FFAB00;
  /* The Fallback */
  background: rgba(255, 171, 0, 0.8); }

.orange.lighten-5 {
  color: #FFF3E0 !important; }

.bg-orange.bg-lighten-5 {
  background-color: #FFF3E0 !important; }

.btn-orange.btn-lighten-5 {
  border-color: #FFF3E0 !important;
  background-color: #FFF3E0 !important; }
  .btn-orange.btn-lighten-5:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-lighten-5 {
  border-color: #FFF3E0 !important;
  color: #FFF3E0 !important; }
  .btn-outline-orange.btn-outline-lighten-5:hover {
    background-color: #FFF3E0 !important; }

.progress-orange.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FFF3E0; }

.progress-orange.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FFF3E0; }

.progress-orange.progress-lighten-5[value]::-ms-fill {
  background-color: #FFF3E0; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-lighten-5 .progress-bar {
    background-color: #FFF3E0; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF3E0 !important; }

.border-orange.border-lighten-5 {
  border: 1px solid #FFF3E0 !important; }

.border-top-orange.border-top-lighten-5 {
  border-top: 1px solid #FFF3E0 !important; }

.border-bottom-orange.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFF3E0 !important; }

.border-left-orange.border-left-lighten-5 {
  border-left: 1px solid #FFF3E0 !important; }

.border-right-orange.border-right-lighten-5 {
  border-right: 1px solid #FFF3E0 !important; }

.overlay-orange.overlay-lighten-5 {
  background: #FFF3E0;
  /* The Fallback */
  background: rgba(255, 243, 224, 0.8); }

.orange.lighten-4 {
  color: #FFE0B2 !important; }

.bg-orange.bg-lighten-4 {
  background-color: #FFE0B2 !important; }

.btn-orange.btn-lighten-4 {
  border-color: #FFE0B2 !important;
  background-color: #FFE0B2 !important; }
  .btn-orange.btn-lighten-4:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-lighten-4 {
  border-color: #FFE0B2 !important;
  color: #FFE0B2 !important; }
  .btn-outline-orange.btn-outline-lighten-4:hover {
    background-color: #FFE0B2 !important; }

.progress-orange.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #FFE0B2; }

.progress-orange.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #FFE0B2; }

.progress-orange.progress-lighten-4[value]::-ms-fill {
  background-color: #FFE0B2; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-lighten-4 .progress-bar {
    background-color: #FFE0B2; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE0B2 !important; }

.border-orange.border-lighten-4 {
  border: 1px solid #FFE0B2 !important; }

.border-top-orange.border-top-lighten-4 {
  border-top: 1px solid #FFE0B2 !important; }

.border-bottom-orange.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFE0B2 !important; }

.border-left-orange.border-left-lighten-4 {
  border-left: 1px solid #FFE0B2 !important; }

.border-right-orange.border-right-lighten-4 {
  border-right: 1px solid #FFE0B2 !important; }

.overlay-orange.overlay-lighten-4 {
  background: #FFE0B2;
  /* The Fallback */
  background: rgba(255, 224, 178, 0.8); }

.orange.lighten-3 {
  color: #FFCC80 !important; }

.bg-orange.bg-lighten-3 {
  background-color: #FFCC80 !important; }

.btn-orange.btn-lighten-3 {
  border-color: #FFCC80 !important;
  background-color: #FFCC80 !important; }
  .btn-orange.btn-lighten-3:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-lighten-3 {
  border-color: #FFCC80 !important;
  color: #FFCC80 !important; }
  .btn-outline-orange.btn-outline-lighten-3:hover {
    background-color: #FFCC80 !important; }

.progress-orange.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #FFCC80; }

.progress-orange.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #FFCC80; }

.progress-orange.progress-lighten-3[value]::-ms-fill {
  background-color: #FFCC80; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-lighten-3 .progress-bar {
    background-color: #FFCC80; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCC80 !important; }

.border-orange.border-lighten-3 {
  border: 1px solid #FFCC80 !important; }

.border-top-orange.border-top-lighten-3 {
  border-top: 1px solid #FFCC80 !important; }

.border-bottom-orange.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFCC80 !important; }

.border-left-orange.border-left-lighten-3 {
  border-left: 1px solid #FFCC80 !important; }

.border-right-orange.border-right-lighten-3 {
  border-right: 1px solid #FFCC80 !important; }

.overlay-orange.overlay-lighten-3 {
  background: #FFCC80;
  /* The Fallback */
  background: rgba(255, 204, 128, 0.8); }

.orange.lighten-2 {
  color: #FFB74D !important; }

.bg-orange.bg-lighten-2 {
  background-color: #FFB74D !important; }

.btn-orange.btn-lighten-2 {
  border-color: #FFB74D !important;
  background-color: #FFB74D !important; }
  .btn-orange.btn-lighten-2:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-lighten-2 {
  border-color: #FFB74D !important;
  color: #FFB74D !important; }
  .btn-outline-orange.btn-outline-lighten-2:hover {
    background-color: #FFB74D !important; }

.progress-orange.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #FFB74D; }

.progress-orange.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #FFB74D; }

.progress-orange.progress-lighten-2[value]::-ms-fill {
  background-color: #FFB74D; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-lighten-2 .progress-bar {
    background-color: #FFB74D; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFB74D !important; }

.border-orange.border-lighten-2 {
  border: 1px solid #FFB74D !important; }

.border-top-orange.border-top-lighten-2 {
  border-top: 1px solid #FFB74D !important; }

.border-bottom-orange.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFB74D !important; }

.border-left-orange.border-left-lighten-2 {
  border-left: 1px solid #FFB74D !important; }

.border-right-orange.border-right-lighten-2 {
  border-right: 1px solid #FFB74D !important; }

.overlay-orange.overlay-lighten-2 {
  background: #FFB74D;
  /* The Fallback */
  background: rgba(255, 183, 77, 0.8); }

.orange.lighten-1 {
  color: #FFA726 !important; }

.bg-orange.bg-lighten-1 {
  background-color: #FFA726 !important; }

.btn-orange.btn-lighten-1 {
  border-color: #FFA726 !important;
  background-color: #FFA726 !important; }
  .btn-orange.btn-lighten-1:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-lighten-1 {
  border-color: #FFA726 !important;
  color: #FFA726 !important; }
  .btn-outline-orange.btn-outline-lighten-1:hover {
    background-color: #FFA726 !important; }

.progress-orange.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #FFA726; }

.progress-orange.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #FFA726; }

.progress-orange.progress-lighten-1[value]::-ms-fill {
  background-color: #FFA726; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-lighten-1 .progress-bar {
    background-color: #FFA726; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA726 !important; }

.border-orange.border-lighten-1 {
  border: 1px solid #FFA726 !important; }

.border-top-orange.border-top-lighten-1 {
  border-top: 1px solid #FFA726 !important; }

.border-bottom-orange.border-bottom-lighten-1 {
  border-bottom: 1px solid #FFA726 !important; }

.border-left-orange.border-left-lighten-1 {
  border-left: 1px solid #FFA726 !important; }

.border-right-orange.border-right-lighten-1 {
  border-right: 1px solid #FFA726 !important; }

.overlay-orange.overlay-lighten-1 {
  background: #FFA726;
  /* The Fallback */
  background: rgba(255, 167, 38, 0.8); }

.orange {
  color: #FF9800 !important; }

.bg-orange {
  background-color: #FF9800 !important; }
  .bg-orange .card-header, .bg-orange .card-footer {
    background-color: transparent; }

.alert-orange {
  border-color: #FF9800 !important;
  background-color: #ffb547 !important;
  color: #4d2e00 !important; }
  .alert-orange .alert-link {
    color: #241500 !important; }

.border-orange {
  border-color: #FF9800; }

.bg-orange.tag-glow, .border-orange.tag-glow {
  box-shadow: 0px 0px 10px #FF9800; }

.overlay-orange {
  background: #FF9800;
  /* The Fallback */
  background: rgba(255, 152, 0, 0.8); }

.card-outline-orange {
  border-width: 1px;
  border-style: solid;
  border-color: #FF9800;
  background-color: transparent; }
  .card-outline-orange .card-header, .card-outline-orange .card-footer {
    background-color: transparent; }

.btn-orange {
  border-color: #FF9800;
  background-color: #FF9800;
  color: #FFFFFF; }
  .btn-orange:hover {
    border-color: #FFB74D;
    background-color: #FFB74D;
    color: #FFF !important; }

.btn-outline-orange {
  border-color: #FF9800;
  background-color: transparent;
  color: #FF9800; }
  .btn-outline-orange:hover {
    background-color: #FF9800;
    color: #FFF !important; }

.progress-orange[value]::-webkit-progress-value {
  background-color: #FF9800; }

.progress-orange[value]::-moz-progress-bar {
  background-color: #FF9800; }

.progress-orange[value]::-ms-fill {
  background-color: #FF9800; }

@media screen and (min-width: 0\0) {
  .progress-orange .progress-bar {
    background-color: #FF9800; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9800 !important; }

.border-orange {
  border: 1px solid #FF9800 !important; }

.border-top-orange {
  border-top: 1px solid #FF9800; }

.border-bottom-orange {
  border-bottom: 1px solid #FF9800; }

.border-left-orange {
  border-left: 1px solid #FF9800; }

.border-right-orange {
  border-right: 1px solid #FF9800; }

.orange.darken-1 {
  color: #FB8C00 !important; }

.bg-orange.bg-darken-1 {
  background-color: #FB8C00 !important; }

.btn-orange.btn-darken-1 {
  border-color: #FB8C00 !important;
  background-color: #FB8C00 !important; }
  .btn-orange.btn-darken-1:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-darken-1 {
  border-color: #FB8C00 !important;
  color: #FB8C00 !important; }
  .btn-outline-orange.btn-outline-darken-1:hover {
    background-color: #FB8C00 !important; }

.progress-orange.progress-darken-1[value]::-webkit-progress-value {
  background-color: #FB8C00; }

.progress-orange.progress-darken-1[value]::-moz-progress-bar {
  background-color: #FB8C00; }

.progress-orange.progress-darken-1[value]::-ms-fill {
  background-color: #FB8C00; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-darken-1 .progress-bar {
    background-color: #FB8C00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FB8C00 !important; }

.border-orange.border-darken-1 {
  border: 1px solid #FB8C00 !important; }

.border-top-orange.border-top-darken-1 {
  border-top: 1px solid #FB8C00 !important; }

.border-bottom-orange.border-bottom-darken-1 {
  border-bottom: 1px solid #FB8C00 !important; }

.border-left-orange.border-left-darken-1 {
  border-left: 1px solid #FB8C00 !important; }

.border-right-orange.border-right-darken-1 {
  border-right: 1px solid #FB8C00 !important; }

.overlay-orange.overlay-darken-1 {
  background: #FB8C00;
  /* The Fallback */
  background: rgba(251, 140, 0, 0.8); }

.orange.darken-2 {
  color: #F57C00 !important; }

.bg-orange.bg-darken-2 {
  background-color: #F57C00 !important; }

.btn-orange.btn-darken-2 {
  border-color: #F57C00 !important;
  background-color: #F57C00 !important; }
  .btn-orange.btn-darken-2:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-darken-2 {
  border-color: #F57C00 !important;
  color: #F57C00 !important; }
  .btn-outline-orange.btn-outline-darken-2:hover {
    background-color: #F57C00 !important; }

.progress-orange.progress-darken-2[value]::-webkit-progress-value {
  background-color: #F57C00; }

.progress-orange.progress-darken-2[value]::-moz-progress-bar {
  background-color: #F57C00; }

.progress-orange.progress-darken-2[value]::-ms-fill {
  background-color: #F57C00; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-darken-2 .progress-bar {
    background-color: #F57C00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F57C00 !important; }

.border-orange.border-darken-2 {
  border: 1px solid #F57C00 !important; }

.border-top-orange.border-top-darken-2 {
  border-top: 1px solid #F57C00 !important; }

.border-bottom-orange.border-bottom-darken-2 {
  border-bottom: 1px solid #F57C00 !important; }

.border-left-orange.border-left-darken-2 {
  border-left: 1px solid #F57C00 !important; }

.border-right-orange.border-right-darken-2 {
  border-right: 1px solid #F57C00 !important; }

.overlay-orange.overlay-darken-2 {
  background: #F57C00;
  /* The Fallback */
  background: rgba(245, 124, 0, 0.8); }

.orange.darken-3 {
  color: #EF6C00 !important; }

.bg-orange.bg-darken-3 {
  background-color: #EF6C00 !important; }

.btn-orange.btn-darken-3 {
  border-color: #EF6C00 !important;
  background-color: #EF6C00 !important; }
  .btn-orange.btn-darken-3:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-darken-3 {
  border-color: #EF6C00 !important;
  color: #EF6C00 !important; }
  .btn-outline-orange.btn-outline-darken-3:hover {
    background-color: #EF6C00 !important; }

.progress-orange.progress-darken-3[value]::-webkit-progress-value {
  background-color: #EF6C00; }

.progress-orange.progress-darken-3[value]::-moz-progress-bar {
  background-color: #EF6C00; }

.progress-orange.progress-darken-3[value]::-ms-fill {
  background-color: #EF6C00; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-darken-3 .progress-bar {
    background-color: #EF6C00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF6C00 !important; }

.border-orange.border-darken-3 {
  border: 1px solid #EF6C00 !important; }

.border-top-orange.border-top-darken-3 {
  border-top: 1px solid #EF6C00 !important; }

.border-bottom-orange.border-bottom-darken-3 {
  border-bottom: 1px solid #EF6C00 !important; }

.border-left-orange.border-left-darken-3 {
  border-left: 1px solid #EF6C00 !important; }

.border-right-orange.border-right-darken-3 {
  border-right: 1px solid #EF6C00 !important; }

.overlay-orange.overlay-darken-3 {
  background: #EF6C00;
  /* The Fallback */
  background: rgba(239, 108, 0, 0.8); }

.orange.darken-4 {
  color: #E65100 !important; }

.bg-orange.bg-darken-4 {
  background-color: #E65100 !important; }

.btn-orange.btn-darken-4 {
  border-color: #E65100 !important;
  background-color: #E65100 !important; }
  .btn-orange.btn-darken-4:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-darken-4 {
  border-color: #E65100 !important;
  color: #E65100 !important; }
  .btn-outline-orange.btn-outline-darken-4:hover {
    background-color: #E65100 !important; }

.progress-orange.progress-darken-4[value]::-webkit-progress-value {
  background-color: #E65100; }

.progress-orange.progress-darken-4[value]::-moz-progress-bar {
  background-color: #E65100; }

.progress-orange.progress-darken-4[value]::-ms-fill {
  background-color: #E65100; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-darken-4 .progress-bar {
    background-color: #E65100; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E65100 !important; }

.border-orange.border-darken-4 {
  border: 1px solid #E65100 !important; }

.border-top-orange.border-top-darken-4 {
  border-top: 1px solid #E65100 !important; }

.border-bottom-orange.border-bottom-darken-4 {
  border-bottom: 1px solid #E65100 !important; }

.border-left-orange.border-left-darken-4 {
  border-left: 1px solid #E65100 !important; }

.border-right-orange.border-right-darken-4 {
  border-right: 1px solid #E65100 !important; }

.overlay-orange.overlay-darken-4 {
  background: #E65100;
  /* The Fallback */
  background: rgba(230, 81, 0, 0.8); }

.orange.accent-1 {
  color: #FFD180 !important; }

.bg-orange.bg-accent-1 {
  background-color: #FFD180 !important; }

.btn-orange.btn-accent-1 {
  border-color: #FFD180 !important;
  background-color: #FFD180 !important; }
  .btn-orange.btn-accent-1:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-accent-1 {
  border-color: #FFD180 !important;
  color: #FFD180 !important; }
  .btn-outline-orange.btn-outline-accent-1:hover {
    background-color: #FFD180 !important; }

.progress-orange.progress-accent-1[value]::-webkit-progress-value {
  background-color: #FFD180; }

.progress-orange.progress-accent-1[value]::-moz-progress-bar {
  background-color: #FFD180; }

.progress-orange.progress-accent-1[value]::-ms-fill {
  background-color: #FFD180; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-accent-1 .progress-bar {
    background-color: #FFD180; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD180 !important; }

.border-orange.border-accent-1 {
  border: 1px solid #FFD180 !important; }

.border-top-orange.border-top-accent-1 {
  border-top: 1px solid #FFD180 !important; }

.border-bottom-orange.border-bottom-accent-1 {
  border-bottom: 1px solid #FFD180 !important; }

.border-left-orange.border-left-accent-1 {
  border-left: 1px solid #FFD180 !important; }

.border-right-orange.border-right-accent-1 {
  border-right: 1px solid #FFD180 !important; }

.overlay-orange.overlay-accent-1 {
  background: #FFD180;
  /* The Fallback */
  background: rgba(255, 209, 128, 0.8); }

.orange.accent-2 {
  color: #FFAB40 !important; }

.bg-orange.bg-accent-2 {
  background-color: #FFAB40 !important; }

.btn-orange.btn-accent-2 {
  border-color: #FFAB40 !important;
  background-color: #FFAB40 !important; }
  .btn-orange.btn-accent-2:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-accent-2 {
  border-color: #FFAB40 !important;
  color: #FFAB40 !important; }
  .btn-outline-orange.btn-outline-accent-2:hover {
    background-color: #FFAB40 !important; }

.progress-orange.progress-accent-2[value]::-webkit-progress-value {
  background-color: #FFAB40; }

.progress-orange.progress-accent-2[value]::-moz-progress-bar {
  background-color: #FFAB40; }

.progress-orange.progress-accent-2[value]::-ms-fill {
  background-color: #FFAB40; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-accent-2 .progress-bar {
    background-color: #FFAB40; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAB40 !important; }

.border-orange.border-accent-2 {
  border: 1px solid #FFAB40 !important; }

.border-top-orange.border-top-accent-2 {
  border-top: 1px solid #FFAB40 !important; }

.border-bottom-orange.border-bottom-accent-2 {
  border-bottom: 1px solid #FFAB40 !important; }

.border-left-orange.border-left-accent-2 {
  border-left: 1px solid #FFAB40 !important; }

.border-right-orange.border-right-accent-2 {
  border-right: 1px solid #FFAB40 !important; }

.overlay-orange.overlay-accent-2 {
  background: #FFAB40;
  /* The Fallback */
  background: rgba(255, 171, 64, 0.8); }

.orange.accent-3 {
  color: #FF9100 !important; }

.bg-orange.bg-accent-3 {
  background-color: #FF9100 !important; }

.btn-orange.btn-accent-3 {
  border-color: #FF9100 !important;
  background-color: #FF9100 !important; }
  .btn-orange.btn-accent-3:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-accent-3 {
  border-color: #FF9100 !important;
  color: #FF9100 !important; }
  .btn-outline-orange.btn-outline-accent-3:hover {
    background-color: #FF9100 !important; }

.progress-orange.progress-accent-3[value]::-webkit-progress-value {
  background-color: #FF9100; }

.progress-orange.progress-accent-3[value]::-moz-progress-bar {
  background-color: #FF9100; }

.progress-orange.progress-accent-3[value]::-ms-fill {
  background-color: #FF9100; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-accent-3 .progress-bar {
    background-color: #FF9100; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9100 !important; }

.border-orange.border-accent-3 {
  border: 1px solid #FF9100 !important; }

.border-top-orange.border-top-accent-3 {
  border-top: 1px solid #FF9100 !important; }

.border-bottom-orange.border-bottom-accent-3 {
  border-bottom: 1px solid #FF9100 !important; }

.border-left-orange.border-left-accent-3 {
  border-left: 1px solid #FF9100 !important; }

.border-right-orange.border-right-accent-3 {
  border-right: 1px solid #FF9100 !important; }

.overlay-orange.overlay-accent-3 {
  background: #FF9100;
  /* The Fallback */
  background: rgba(255, 145, 0, 0.8); }

.orange.accent-4 {
  color: #FF6D00 !important; }

.bg-orange.bg-accent-4 {
  background-color: #FF6D00 !important; }

.btn-orange.btn-accent-4 {
  border-color: #FF6D00 !important;
  background-color: #FF6D00 !important; }
  .btn-orange.btn-accent-4:hover {
    border-color: #FFB74D !important;
    background-color: #FFB74D !important; }

.btn-outline-orange.btn-outline-accent-4 {
  border-color: #FF6D00 !important;
  color: #FF6D00 !important; }
  .btn-outline-orange.btn-outline-accent-4:hover {
    background-color: #FF6D00 !important; }

.progress-orange.progress-accent-4[value]::-webkit-progress-value {
  background-color: #FF6D00; }

.progress-orange.progress-accent-4[value]::-moz-progress-bar {
  background-color: #FF6D00; }

.progress-orange.progress-accent-4[value]::-ms-fill {
  background-color: #FF6D00; }

@media screen and (min-width: 0\0) {
  .progress-orange.progress-accent-4 .progress-bar {
    background-color: #FF6D00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6D00 !important; }

.border-orange.border-accent-4 {
  border: 1px solid #FF6D00 !important; }

.border-top-orange.border-top-accent-4 {
  border-top: 1px solid #FF6D00 !important; }

.border-bottom-orange.border-bottom-accent-4 {
  border-bottom: 1px solid #FF6D00 !important; }

.border-left-orange.border-left-accent-4 {
  border-left: 1px solid #FF6D00 !important; }

.border-right-orange.border-right-accent-4 {
  border-right: 1px solid #FF6D00 !important; }

.overlay-orange.overlay-accent-4 {
  background: #FF6D00;
  /* The Fallback */
  background: rgba(255, 109, 0, 0.8); }

.deep-orange.lighten-5 {
  color: #FbE9E7 !important; }

.bg-deep-orange.bg-lighten-5 {
  background-color: #FbE9E7 !important; }

.btn-deep-orange.btn-lighten-5 {
  border-color: #FbE9E7 !important;
  background-color: #FbE9E7 !important; }
  .btn-deep-orange.btn-lighten-5:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-lighten-5 {
  border-color: #FbE9E7 !important;
  color: #FbE9E7 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-5:hover {
    background-color: #FbE9E7 !important; }

.progress-deep-orange.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FbE9E7; }

.progress-deep-orange.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FbE9E7; }

.progress-deep-orange.progress-lighten-5[value]::-ms-fill {
  background-color: #FbE9E7; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-lighten-5 .progress-bar {
    background-color: #FbE9E7; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FbE9E7 !important; }

.border-deep-orange.border-lighten-5 {
  border: 1px solid #FbE9E7 !important; }

.border-top-deep-orange.border-top-lighten-5 {
  border-top: 1px solid #FbE9E7 !important; }

.border-bottom-deep-orange.border-bottom-lighten-5 {
  border-bottom: 1px solid #FbE9E7 !important; }

.border-left-deep-orange.border-left-lighten-5 {
  border-left: 1px solid #FbE9E7 !important; }

.border-right-deep-orange.border-right-lighten-5 {
  border-right: 1px solid #FbE9E7 !important; }

.overlay-deep-orange.overlay-lighten-5 {
  background: #FbE9E7;
  /* The Fallback */
  background: rgba(251, 233, 231, 0.8); }

.deep-orange.lighten-4 {
  color: #FFCCBC !important; }

.bg-deep-orange.bg-lighten-4 {
  background-color: #FFCCBC !important; }

.btn-deep-orange.btn-lighten-4 {
  border-color: #FFCCBC !important;
  background-color: #FFCCBC !important; }
  .btn-deep-orange.btn-lighten-4:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-lighten-4 {
  border-color: #FFCCBC !important;
  color: #FFCCBC !important; }
  .btn-outline-deep-orange.btn-outline-lighten-4:hover {
    background-color: #FFCCBC !important; }

.progress-deep-orange.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #FFCCBC; }

.progress-deep-orange.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #FFCCBC; }

.progress-deep-orange.progress-lighten-4[value]::-ms-fill {
  background-color: #FFCCBC; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-lighten-4 .progress-bar {
    background-color: #FFCCBC; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCCBC !important; }

.border-deep-orange.border-lighten-4 {
  border: 1px solid #FFCCBC !important; }

.border-top-deep-orange.border-top-lighten-4 {
  border-top: 1px solid #FFCCBC !important; }

.border-bottom-deep-orange.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFCCBC !important; }

.border-left-deep-orange.border-left-lighten-4 {
  border-left: 1px solid #FFCCBC !important; }

.border-right-deep-orange.border-right-lighten-4 {
  border-right: 1px solid #FFCCBC !important; }

.overlay-deep-orange.overlay-lighten-4 {
  background: #FFCCBC;
  /* The Fallback */
  background: rgba(255, 204, 188, 0.8); }

.deep-orange.lighten-3 {
  color: #FFAB91 !important; }

.bg-deep-orange.bg-lighten-3 {
  background-color: #FFAB91 !important; }

.btn-deep-orange.btn-lighten-3 {
  border-color: #FFAB91 !important;
  background-color: #FFAB91 !important; }
  .btn-deep-orange.btn-lighten-3:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-lighten-3 {
  border-color: #FFAB91 !important;
  color: #FFAB91 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-3:hover {
    background-color: #FFAB91 !important; }

.progress-deep-orange.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #FFAB91; }

.progress-deep-orange.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #FFAB91; }

.progress-deep-orange.progress-lighten-3[value]::-ms-fill {
  background-color: #FFAB91; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-lighten-3 .progress-bar {
    background-color: #FFAB91; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAB91 !important; }

.border-deep-orange.border-lighten-3 {
  border: 1px solid #FFAB91 !important; }

.border-top-deep-orange.border-top-lighten-3 {
  border-top: 1px solid #FFAB91 !important; }

.border-bottom-deep-orange.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFAB91 !important; }

.border-left-deep-orange.border-left-lighten-3 {
  border-left: 1px solid #FFAB91 !important; }

.border-right-deep-orange.border-right-lighten-3 {
  border-right: 1px solid #FFAB91 !important; }

.overlay-deep-orange.overlay-lighten-3 {
  background: #FFAB91;
  /* The Fallback */
  background: rgba(255, 171, 145, 0.8); }

.deep-orange.lighten-2 {
  color: #FF8A65 !important; }

.bg-deep-orange.bg-lighten-2 {
  background-color: #FF8A65 !important; }

.btn-deep-orange.btn-lighten-2 {
  border-color: #FF8A65 !important;
  background-color: #FF8A65 !important; }
  .btn-deep-orange.btn-lighten-2:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-lighten-2 {
  border-color: #FF8A65 !important;
  color: #FF8A65 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-2:hover {
    background-color: #FF8A65 !important; }

.progress-deep-orange.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #FF8A65; }

.progress-deep-orange.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #FF8A65; }

.progress-deep-orange.progress-lighten-2[value]::-ms-fill {
  background-color: #FF8A65; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-lighten-2 .progress-bar {
    background-color: #FF8A65; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A65 !important; }

.border-deep-orange.border-lighten-2 {
  border: 1px solid #FF8A65 !important; }

.border-top-deep-orange.border-top-lighten-2 {
  border-top: 1px solid #FF8A65 !important; }

.border-bottom-deep-orange.border-bottom-lighten-2 {
  border-bottom: 1px solid #FF8A65 !important; }

.border-left-deep-orange.border-left-lighten-2 {
  border-left: 1px solid #FF8A65 !important; }

.border-right-deep-orange.border-right-lighten-2 {
  border-right: 1px solid #FF8A65 !important; }

.overlay-deep-orange.overlay-lighten-2 {
  background: #FF8A65;
  /* The Fallback */
  background: rgba(255, 138, 101, 0.8); }

.deep-orange.lighten-1 {
  color: #FF7043 !important; }

.bg-deep-orange.bg-lighten-1 {
  background-color: #FF7043 !important; }

.btn-deep-orange.btn-lighten-1 {
  border-color: #FF7043 !important;
  background-color: #FF7043 !important; }
  .btn-deep-orange.btn-lighten-1:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-lighten-1 {
  border-color: #FF7043 !important;
  color: #FF7043 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-1:hover {
    background-color: #FF7043 !important; }

.progress-deep-orange.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #FF7043; }

.progress-deep-orange.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #FF7043; }

.progress-deep-orange.progress-lighten-1[value]::-ms-fill {
  background-color: #FF7043; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-lighten-1 .progress-bar {
    background-color: #FF7043; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7043 !important; }

.border-deep-orange.border-lighten-1 {
  border: 1px solid #FF7043 !important; }

.border-top-deep-orange.border-top-lighten-1 {
  border-top: 1px solid #FF7043 !important; }

.border-bottom-deep-orange.border-bottom-lighten-1 {
  border-bottom: 1px solid #FF7043 !important; }

.border-left-deep-orange.border-left-lighten-1 {
  border-left: 1px solid #FF7043 !important; }

.border-right-deep-orange.border-right-lighten-1 {
  border-right: 1px solid #FF7043 !important; }

.overlay-deep-orange.overlay-lighten-1 {
  background: #FF7043;
  /* The Fallback */
  background: rgba(255, 112, 67, 0.8); }

.deep-orange {
  color: #FF5722 !important; }

.bg-deep-orange {
  background-color: #FF5722 !important; }
  .bg-deep-orange .card-header, .bg-deep-orange .card-footer {
    background-color: transparent; }

.alert-deep-orange {
  border-color: #FF5722 !important;
  background-color: #ff8d69 !important;
  color: #6f1b00 !important; }
  .alert-deep-orange .alert-link {
    color: #461100 !important; }

.border-deep-orange {
  border-color: #FF5722; }

.bg-deep-orange.tag-glow, .border-deep-orange.tag-glow {
  box-shadow: 0px 0px 10px #FF5722; }

.overlay-deep-orange {
  background: #FF5722;
  /* The Fallback */
  background: rgba(255, 87, 34, 0.8); }

.card-outline-deep-orange {
  border-width: 1px;
  border-style: solid;
  border-color: #FF5722;
  background-color: transparent; }
  .card-outline-deep-orange .card-header, .card-outline-deep-orange .card-footer {
    background-color: transparent; }

.btn-deep-orange {
  border-color: #FF5722;
  background-color: #FF5722;
  color: #FFFFFF; }
  .btn-deep-orange:hover {
    border-color: #FF8A65;
    background-color: #FF8A65;
    color: #FFF !important; }

.btn-outline-deep-orange {
  border-color: #FF5722;
  background-color: transparent;
  color: #FF5722; }
  .btn-outline-deep-orange:hover {
    background-color: #FF5722;
    color: #FFF !important; }

.progress-deep-orange[value]::-webkit-progress-value {
  background-color: #FF5722; }

.progress-deep-orange[value]::-moz-progress-bar {
  background-color: #FF5722; }

.progress-deep-orange[value]::-ms-fill {
  background-color: #FF5722; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange .progress-bar {
    background-color: #FF5722; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5722 !important; }

.border-deep-orange {
  border: 1px solid #FF5722 !important; }

.border-top-deep-orange {
  border-top: 1px solid #FF5722; }

.border-bottom-deep-orange {
  border-bottom: 1px solid #FF5722; }

.border-left-deep-orange {
  border-left: 1px solid #FF5722; }

.border-right-deep-orange {
  border-right: 1px solid #FF5722; }

.deep-orange.darken-1 {
  color: #F4511E !important; }

.bg-deep-orange.bg-darken-1 {
  background-color: #F4511E !important; }

.btn-deep-orange.btn-darken-1 {
  border-color: #F4511E !important;
  background-color: #F4511E !important; }
  .btn-deep-orange.btn-darken-1:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-darken-1 {
  border-color: #F4511E !important;
  color: #F4511E !important; }
  .btn-outline-deep-orange.btn-outline-darken-1:hover {
    background-color: #F4511E !important; }

.progress-deep-orange.progress-darken-1[value]::-webkit-progress-value {
  background-color: #F4511E; }

.progress-deep-orange.progress-darken-1[value]::-moz-progress-bar {
  background-color: #F4511E; }

.progress-deep-orange.progress-darken-1[value]::-ms-fill {
  background-color: #F4511E; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-darken-1 .progress-bar {
    background-color: #F4511E; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F4511E !important; }

.border-deep-orange.border-darken-1 {
  border: 1px solid #F4511E !important; }

.border-top-deep-orange.border-top-darken-1 {
  border-top: 1px solid #F4511E !important; }

.border-bottom-deep-orange.border-bottom-darken-1 {
  border-bottom: 1px solid #F4511E !important; }

.border-left-deep-orange.border-left-darken-1 {
  border-left: 1px solid #F4511E !important; }

.border-right-deep-orange.border-right-darken-1 {
  border-right: 1px solid #F4511E !important; }

.overlay-deep-orange.overlay-darken-1 {
  background: #F4511E;
  /* The Fallback */
  background: rgba(244, 81, 30, 0.8); }

.deep-orange.darken-2 {
  color: #E64A19 !important; }

.bg-deep-orange.bg-darken-2 {
  background-color: #E64A19 !important; }

.btn-deep-orange.btn-darken-2 {
  border-color: #E64A19 !important;
  background-color: #E64A19 !important; }
  .btn-deep-orange.btn-darken-2:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-darken-2 {
  border-color: #E64A19 !important;
  color: #E64A19 !important; }
  .btn-outline-deep-orange.btn-outline-darken-2:hover {
    background-color: #E64A19 !important; }

.progress-deep-orange.progress-darken-2[value]::-webkit-progress-value {
  background-color: #E64A19; }

.progress-deep-orange.progress-darken-2[value]::-moz-progress-bar {
  background-color: #E64A19; }

.progress-deep-orange.progress-darken-2[value]::-ms-fill {
  background-color: #E64A19; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-darken-2 .progress-bar {
    background-color: #E64A19; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E64A19 !important; }

.border-deep-orange.border-darken-2 {
  border: 1px solid #E64A19 !important; }

.border-top-deep-orange.border-top-darken-2 {
  border-top: 1px solid #E64A19 !important; }

.border-bottom-deep-orange.border-bottom-darken-2 {
  border-bottom: 1px solid #E64A19 !important; }

.border-left-deep-orange.border-left-darken-2 {
  border-left: 1px solid #E64A19 !important; }

.border-right-deep-orange.border-right-darken-2 {
  border-right: 1px solid #E64A19 !important; }

.overlay-deep-orange.overlay-darken-2 {
  background: #E64A19;
  /* The Fallback */
  background: rgba(230, 74, 25, 0.8); }

.deep-orange.darken-3 {
  color: #D84315 !important; }

.bg-deep-orange.bg-darken-3 {
  background-color: #D84315 !important; }

.btn-deep-orange.btn-darken-3 {
  border-color: #D84315 !important;
  background-color: #D84315 !important; }
  .btn-deep-orange.btn-darken-3:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-darken-3 {
  border-color: #D84315 !important;
  color: #D84315 !important; }
  .btn-outline-deep-orange.btn-outline-darken-3:hover {
    background-color: #D84315 !important; }

.progress-deep-orange.progress-darken-3[value]::-webkit-progress-value {
  background-color: #D84315; }

.progress-deep-orange.progress-darken-3[value]::-moz-progress-bar {
  background-color: #D84315; }

.progress-deep-orange.progress-darken-3[value]::-ms-fill {
  background-color: #D84315; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-darken-3 .progress-bar {
    background-color: #D84315; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D84315 !important; }

.border-deep-orange.border-darken-3 {
  border: 1px solid #D84315 !important; }

.border-top-deep-orange.border-top-darken-3 {
  border-top: 1px solid #D84315 !important; }

.border-bottom-deep-orange.border-bottom-darken-3 {
  border-bottom: 1px solid #D84315 !important; }

.border-left-deep-orange.border-left-darken-3 {
  border-left: 1px solid #D84315 !important; }

.border-right-deep-orange.border-right-darken-3 {
  border-right: 1px solid #D84315 !important; }

.overlay-deep-orange.overlay-darken-3 {
  background: #D84315;
  /* The Fallback */
  background: rgba(216, 67, 21, 0.8); }

.deep-orange.darken-4 {
  color: #BF360C !important; }

.bg-deep-orange.bg-darken-4 {
  background-color: #BF360C !important; }

.btn-deep-orange.btn-darken-4 {
  border-color: #BF360C !important;
  background-color: #BF360C !important; }
  .btn-deep-orange.btn-darken-4:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-darken-4 {
  border-color: #BF360C !important;
  color: #BF360C !important; }
  .btn-outline-deep-orange.btn-outline-darken-4:hover {
    background-color: #BF360C !important; }

.progress-deep-orange.progress-darken-4[value]::-webkit-progress-value {
  background-color: #BF360C; }

.progress-deep-orange.progress-darken-4[value]::-moz-progress-bar {
  background-color: #BF360C; }

.progress-deep-orange.progress-darken-4[value]::-ms-fill {
  background-color: #BF360C; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-darken-4 .progress-bar {
    background-color: #BF360C; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BF360C !important; }

.border-deep-orange.border-darken-4 {
  border: 1px solid #BF360C !important; }

.border-top-deep-orange.border-top-darken-4 {
  border-top: 1px solid #BF360C !important; }

.border-bottom-deep-orange.border-bottom-darken-4 {
  border-bottom: 1px solid #BF360C !important; }

.border-left-deep-orange.border-left-darken-4 {
  border-left: 1px solid #BF360C !important; }

.border-right-deep-orange.border-right-darken-4 {
  border-right: 1px solid #BF360C !important; }

.overlay-deep-orange.overlay-darken-4 {
  background: #BF360C;
  /* The Fallback */
  background: rgba(191, 54, 12, 0.8); }

.deep-orange.accent-1 {
  color: #FF9E80 !important; }

.bg-deep-orange.bg-accent-1 {
  background-color: #FF9E80 !important; }

.btn-deep-orange.btn-accent-1 {
  border-color: #FF9E80 !important;
  background-color: #FF9E80 !important; }
  .btn-deep-orange.btn-accent-1:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-accent-1 {
  border-color: #FF9E80 !important;
  color: #FF9E80 !important; }
  .btn-outline-deep-orange.btn-outline-accent-1:hover {
    background-color: #FF9E80 !important; }

.progress-deep-orange.progress-accent-1[value]::-webkit-progress-value {
  background-color: #FF9E80; }

.progress-deep-orange.progress-accent-1[value]::-moz-progress-bar {
  background-color: #FF9E80; }

.progress-deep-orange.progress-accent-1[value]::-ms-fill {
  background-color: #FF9E80; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-accent-1 .progress-bar {
    background-color: #FF9E80; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9E80 !important; }

.border-deep-orange.border-accent-1 {
  border: 1px solid #FF9E80 !important; }

.border-top-deep-orange.border-top-accent-1 {
  border-top: 1px solid #FF9E80 !important; }

.border-bottom-deep-orange.border-bottom-accent-1 {
  border-bottom: 1px solid #FF9E80 !important; }

.border-left-deep-orange.border-left-accent-1 {
  border-left: 1px solid #FF9E80 !important; }

.border-right-deep-orange.border-right-accent-1 {
  border-right: 1px solid #FF9E80 !important; }

.overlay-deep-orange.overlay-accent-1 {
  background: #FF9E80;
  /* The Fallback */
  background: rgba(255, 158, 128, 0.8); }

.deep-orange.accent-2 {
  color: #FF6E40 !important; }

.bg-deep-orange.bg-accent-2 {
  background-color: #FF6E40 !important; }

.btn-deep-orange.btn-accent-2 {
  border-color: #FF6E40 !important;
  background-color: #FF6E40 !important; }
  .btn-deep-orange.btn-accent-2:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-accent-2 {
  border-color: #FF6E40 !important;
  color: #FF6E40 !important; }
  .btn-outline-deep-orange.btn-outline-accent-2:hover {
    background-color: #FF6E40 !important; }

.progress-deep-orange.progress-accent-2[value]::-webkit-progress-value {
  background-color: #FF6E40; }

.progress-deep-orange.progress-accent-2[value]::-moz-progress-bar {
  background-color: #FF6E40; }

.progress-deep-orange.progress-accent-2[value]::-ms-fill {
  background-color: #FF6E40; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-accent-2 .progress-bar {
    background-color: #FF6E40; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6E40 !important; }

.border-deep-orange.border-accent-2 {
  border: 1px solid #FF6E40 !important; }

.border-top-deep-orange.border-top-accent-2 {
  border-top: 1px solid #FF6E40 !important; }

.border-bottom-deep-orange.border-bottom-accent-2 {
  border-bottom: 1px solid #FF6E40 !important; }

.border-left-deep-orange.border-left-accent-2 {
  border-left: 1px solid #FF6E40 !important; }

.border-right-deep-orange.border-right-accent-2 {
  border-right: 1px solid #FF6E40 !important; }

.overlay-deep-orange.overlay-accent-2 {
  background: #FF6E40;
  /* The Fallback */
  background: rgba(255, 110, 64, 0.8); }

.deep-orange.accent-3 {
  color: #FF3D00 !important; }

.bg-deep-orange.bg-accent-3 {
  background-color: #FF3D00 !important; }

.btn-deep-orange.btn-accent-3 {
  border-color: #FF3D00 !important;
  background-color: #FF3D00 !important; }
  .btn-deep-orange.btn-accent-3:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-accent-3 {
  border-color: #FF3D00 !important;
  color: #FF3D00 !important; }
  .btn-outline-deep-orange.btn-outline-accent-3:hover {
    background-color: #FF3D00 !important; }

.progress-deep-orange.progress-accent-3[value]::-webkit-progress-value {
  background-color: #FF3D00; }

.progress-deep-orange.progress-accent-3[value]::-moz-progress-bar {
  background-color: #FF3D00; }

.progress-deep-orange.progress-accent-3[value]::-ms-fill {
  background-color: #FF3D00; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-accent-3 .progress-bar {
    background-color: #FF3D00; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF3D00 !important; }

.border-deep-orange.border-accent-3 {
  border: 1px solid #FF3D00 !important; }

.border-top-deep-orange.border-top-accent-3 {
  border-top: 1px solid #FF3D00 !important; }

.border-bottom-deep-orange.border-bottom-accent-3 {
  border-bottom: 1px solid #FF3D00 !important; }

.border-left-deep-orange.border-left-accent-3 {
  border-left: 1px solid #FF3D00 !important; }

.border-right-deep-orange.border-right-accent-3 {
  border-right: 1px solid #FF3D00 !important; }

.overlay-deep-orange.overlay-accent-3 {
  background: #FF3D00;
  /* The Fallback */
  background: rgba(255, 61, 0, 0.8); }

.deep-orange.accent-4 {
  color: #DD2C00 !important; }

.bg-deep-orange.bg-accent-4 {
  background-color: #DD2C00 !important; }

.btn-deep-orange.btn-accent-4 {
  border-color: #DD2C00 !important;
  background-color: #DD2C00 !important; }
  .btn-deep-orange.btn-accent-4:hover {
    border-color: #FF8A65 !important;
    background-color: #FF8A65 !important; }

.btn-outline-deep-orange.btn-outline-accent-4 {
  border-color: #DD2C00 !important;
  color: #DD2C00 !important; }
  .btn-outline-deep-orange.btn-outline-accent-4:hover {
    background-color: #DD2C00 !important; }

.progress-deep-orange.progress-accent-4[value]::-webkit-progress-value {
  background-color: #DD2C00; }

.progress-deep-orange.progress-accent-4[value]::-moz-progress-bar {
  background-color: #DD2C00; }

.progress-deep-orange.progress-accent-4[value]::-ms-fill {
  background-color: #DD2C00; }

@media screen and (min-width: 0\0) {
  .progress-deep-orange.progress-accent-4 .progress-bar {
    background-color: #DD2C00; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DD2C00 !important; }

.border-deep-orange.border-accent-4 {
  border: 1px solid #DD2C00 !important; }

.border-top-deep-orange.border-top-accent-4 {
  border-top: 1px solid #DD2C00 !important; }

.border-bottom-deep-orange.border-bottom-accent-4 {
  border-bottom: 1px solid #DD2C00 !important; }

.border-left-deep-orange.border-left-accent-4 {
  border-left: 1px solid #DD2C00 !important; }

.border-right-deep-orange.border-right-accent-4 {
  border-right: 1px solid #DD2C00 !important; }

.overlay-deep-orange.overlay-accent-4 {
  background: #DD2C00;
  /* The Fallback */
  background: rgba(221, 44, 0, 0.8); }

.brown.lighten-5 {
  color: #EFEBE9 !important; }

.bg-brown.bg-lighten-5 {
  background-color: #EFEBE9 !important; }

.btn-brown.btn-lighten-5 {
  border-color: #EFEBE9 !important;
  background-color: #EFEBE9 !important; }
  .btn-brown.btn-lighten-5:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-lighten-5 {
  border-color: #EFEBE9 !important;
  color: #EFEBE9 !important; }
  .btn-outline-brown.btn-outline-lighten-5:hover {
    background-color: #EFEBE9 !important; }

.progress-brown.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #EFEBE9; }

.progress-brown.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #EFEBE9; }

.progress-brown.progress-lighten-5[value]::-ms-fill {
  background-color: #EFEBE9; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-lighten-5 .progress-bar {
    background-color: #EFEBE9; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EFEBE9 !important; }

.border-brown.border-lighten-5 {
  border: 1px solid #EFEBE9 !important; }

.border-top-brown.border-top-lighten-5 {
  border-top: 1px solid #EFEBE9 !important; }

.border-bottom-brown.border-bottom-lighten-5 {
  border-bottom: 1px solid #EFEBE9 !important; }

.border-left-brown.border-left-lighten-5 {
  border-left: 1px solid #EFEBE9 !important; }

.border-right-brown.border-right-lighten-5 {
  border-right: 1px solid #EFEBE9 !important; }

.overlay-brown.overlay-lighten-5 {
  background: #EFEBE9;
  /* The Fallback */
  background: rgba(239, 235, 233, 0.8); }

.brown.lighten-4 {
  color: #D7CCC8 !important; }

.bg-brown.bg-lighten-4 {
  background-color: #D7CCC8 !important; }

.btn-brown.btn-lighten-4 {
  border-color: #D7CCC8 !important;
  background-color: #D7CCC8 !important; }
  .btn-brown.btn-lighten-4:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-lighten-4 {
  border-color: #D7CCC8 !important;
  color: #D7CCC8 !important; }
  .btn-outline-brown.btn-outline-lighten-4:hover {
    background-color: #D7CCC8 !important; }

.progress-brown.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #D7CCC8; }

.progress-brown.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #D7CCC8; }

.progress-brown.progress-lighten-4[value]::-ms-fill {
  background-color: #D7CCC8; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-lighten-4 .progress-bar {
    background-color: #D7CCC8; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D7CCC8 !important; }

.border-brown.border-lighten-4 {
  border: 1px solid #D7CCC8 !important; }

.border-top-brown.border-top-lighten-4 {
  border-top: 1px solid #D7CCC8 !important; }

.border-bottom-brown.border-bottom-lighten-4 {
  border-bottom: 1px solid #D7CCC8 !important; }

.border-left-brown.border-left-lighten-4 {
  border-left: 1px solid #D7CCC8 !important; }

.border-right-brown.border-right-lighten-4 {
  border-right: 1px solid #D7CCC8 !important; }

.overlay-brown.overlay-lighten-4 {
  background: #D7CCC8;
  /* The Fallback */
  background: rgba(215, 204, 200, 0.8); }

.brown.lighten-3 {
  color: #BCAAA4 !important; }

.bg-brown.bg-lighten-3 {
  background-color: #BCAAA4 !important; }

.btn-brown.btn-lighten-3 {
  border-color: #BCAAA4 !important;
  background-color: #BCAAA4 !important; }
  .btn-brown.btn-lighten-3:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-lighten-3 {
  border-color: #BCAAA4 !important;
  color: #BCAAA4 !important; }
  .btn-outline-brown.btn-outline-lighten-3:hover {
    background-color: #BCAAA4 !important; }

.progress-brown.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #BCAAA4; }

.progress-brown.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #BCAAA4; }

.progress-brown.progress-lighten-3[value]::-ms-fill {
  background-color: #BCAAA4; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-lighten-3 .progress-bar {
    background-color: #BCAAA4; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BCAAA4 !important; }

.border-brown.border-lighten-3 {
  border: 1px solid #BCAAA4 !important; }

.border-top-brown.border-top-lighten-3 {
  border-top: 1px solid #BCAAA4 !important; }

.border-bottom-brown.border-bottom-lighten-3 {
  border-bottom: 1px solid #BCAAA4 !important; }

.border-left-brown.border-left-lighten-3 {
  border-left: 1px solid #BCAAA4 !important; }

.border-right-brown.border-right-lighten-3 {
  border-right: 1px solid #BCAAA4 !important; }

.overlay-brown.overlay-lighten-3 {
  background: #BCAAA4;
  /* The Fallback */
  background: rgba(188, 170, 164, 0.8); }

.brown.lighten-2 {
  color: #A1887F !important; }

.bg-brown.bg-lighten-2 {
  background-color: #A1887F !important; }

.btn-brown.btn-lighten-2 {
  border-color: #A1887F !important;
  background-color: #A1887F !important; }
  .btn-brown.btn-lighten-2:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-lighten-2 {
  border-color: #A1887F !important;
  color: #A1887F !important; }
  .btn-outline-brown.btn-outline-lighten-2:hover {
    background-color: #A1887F !important; }

.progress-brown.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #A1887F; }

.progress-brown.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #A1887F; }

.progress-brown.progress-lighten-2[value]::-ms-fill {
  background-color: #A1887F; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-lighten-2 .progress-bar {
    background-color: #A1887F; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A1887F !important; }

.border-brown.border-lighten-2 {
  border: 1px solid #A1887F !important; }

.border-top-brown.border-top-lighten-2 {
  border-top: 1px solid #A1887F !important; }

.border-bottom-brown.border-bottom-lighten-2 {
  border-bottom: 1px solid #A1887F !important; }

.border-left-brown.border-left-lighten-2 {
  border-left: 1px solid #A1887F !important; }

.border-right-brown.border-right-lighten-2 {
  border-right: 1px solid #A1887F !important; }

.overlay-brown.overlay-lighten-2 {
  background: #A1887F;
  /* The Fallback */
  background: rgba(161, 136, 127, 0.8); }

.brown.lighten-1 {
  color: #8D6E63 !important; }

.bg-brown.bg-lighten-1 {
  background-color: #8D6E63 !important; }

.btn-brown.btn-lighten-1 {
  border-color: #8D6E63 !important;
  background-color: #8D6E63 !important; }
  .btn-brown.btn-lighten-1:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-lighten-1 {
  border-color: #8D6E63 !important;
  color: #8D6E63 !important; }
  .btn-outline-brown.btn-outline-lighten-1:hover {
    background-color: #8D6E63 !important; }

.progress-brown.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #8D6E63; }

.progress-brown.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #8D6E63; }

.progress-brown.progress-lighten-1[value]::-ms-fill {
  background-color: #8D6E63; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-lighten-1 .progress-bar {
    background-color: #8D6E63; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8D6E63 !important; }

.border-brown.border-lighten-1 {
  border: 1px solid #8D6E63 !important; }

.border-top-brown.border-top-lighten-1 {
  border-top: 1px solid #8D6E63 !important; }

.border-bottom-brown.border-bottom-lighten-1 {
  border-bottom: 1px solid #8D6E63 !important; }

.border-left-brown.border-left-lighten-1 {
  border-left: 1px solid #8D6E63 !important; }

.border-right-brown.border-right-lighten-1 {
  border-right: 1px solid #8D6E63 !important; }

.overlay-brown.overlay-lighten-1 {
  background: #8D6E63;
  /* The Fallback */
  background: rgba(141, 110, 99, 0.8); }

.brown {
  color: #795548 !important; }

.bg-brown {
  background-color: #795548 !important; }
  .bg-brown .card-header, .bg-brown .card-footer {
    background-color: transparent; }

.alert-brown {
  border-color: #795548 !important;
  background-color: #a37665 !important;
  color: #090605 !important; }
  .alert-brown .alert-link {
    color: black !important; }

.border-brown {
  border-color: #795548; }

.bg-brown.tag-glow, .border-brown.tag-glow {
  box-shadow: 0px 0px 10px #795548; }

.overlay-brown {
  background: #795548;
  /* The Fallback */
  background: rgba(121, 85, 72, 0.8); }

.card-outline-brown {
  border-width: 1px;
  border-style: solid;
  border-color: #795548;
  background-color: transparent; }
  .card-outline-brown .card-header, .card-outline-brown .card-footer {
    background-color: transparent; }

.btn-brown {
  border-color: #795548;
  background-color: #795548;
  color: #FFFFFF; }
  .btn-brown:hover {
    border-color: #A1887F;
    background-color: #A1887F;
    color: #FFF !important; }

.btn-outline-brown {
  border-color: #795548;
  background-color: transparent;
  color: #795548; }
  .btn-outline-brown:hover {
    background-color: #795548;
    color: #FFF !important; }

.progress-brown[value]::-webkit-progress-value {
  background-color: #795548; }

.progress-brown[value]::-moz-progress-bar {
  background-color: #795548; }

.progress-brown[value]::-ms-fill {
  background-color: #795548; }

@media screen and (min-width: 0\0) {
  .progress-brown .progress-bar {
    background-color: #795548; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #795548 !important; }

.border-brown {
  border: 1px solid #795548 !important; }

.border-top-brown {
  border-top: 1px solid #795548; }

.border-bottom-brown {
  border-bottom: 1px solid #795548; }

.border-left-brown {
  border-left: 1px solid #795548; }

.border-right-brown {
  border-right: 1px solid #795548; }

.brown.darken-1 {
  color: #6D4C41 !important; }

.bg-brown.bg-darken-1 {
  background-color: #6D4C41 !important; }

.btn-brown.btn-darken-1 {
  border-color: #6D4C41 !important;
  background-color: #6D4C41 !important; }
  .btn-brown.btn-darken-1:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-darken-1 {
  border-color: #6D4C41 !important;
  color: #6D4C41 !important; }
  .btn-outline-brown.btn-outline-darken-1:hover {
    background-color: #6D4C41 !important; }

.progress-brown.progress-darken-1[value]::-webkit-progress-value {
  background-color: #6D4C41; }

.progress-brown.progress-darken-1[value]::-moz-progress-bar {
  background-color: #6D4C41; }

.progress-brown.progress-darken-1[value]::-ms-fill {
  background-color: #6D4C41; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-darken-1 .progress-bar {
    background-color: #6D4C41; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6D4C41 !important; }

.border-brown.border-darken-1 {
  border: 1px solid #6D4C41 !important; }

.border-top-brown.border-top-darken-1 {
  border-top: 1px solid #6D4C41 !important; }

.border-bottom-brown.border-bottom-darken-1 {
  border-bottom: 1px solid #6D4C41 !important; }

.border-left-brown.border-left-darken-1 {
  border-left: 1px solid #6D4C41 !important; }

.border-right-brown.border-right-darken-1 {
  border-right: 1px solid #6D4C41 !important; }

.overlay-brown.overlay-darken-1 {
  background: #6D4C41;
  /* The Fallback */
  background: rgba(109, 76, 65, 0.8); }

.brown.darken-2 {
  color: #5D4037 !important; }

.bg-brown.bg-darken-2 {
  background-color: #5D4037 !important; }

.btn-brown.btn-darken-2 {
  border-color: #5D4037 !important;
  background-color: #5D4037 !important; }
  .btn-brown.btn-darken-2:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-darken-2 {
  border-color: #5D4037 !important;
  color: #5D4037 !important; }
  .btn-outline-brown.btn-outline-darken-2:hover {
    background-color: #5D4037 !important; }

.progress-brown.progress-darken-2[value]::-webkit-progress-value {
  background-color: #5D4037; }

.progress-brown.progress-darken-2[value]::-moz-progress-bar {
  background-color: #5D4037; }

.progress-brown.progress-darken-2[value]::-ms-fill {
  background-color: #5D4037; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-darken-2 .progress-bar {
    background-color: #5D4037; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5D4037 !important; }

.border-brown.border-darken-2 {
  border: 1px solid #5D4037 !important; }

.border-top-brown.border-top-darken-2 {
  border-top: 1px solid #5D4037 !important; }

.border-bottom-brown.border-bottom-darken-2 {
  border-bottom: 1px solid #5D4037 !important; }

.border-left-brown.border-left-darken-2 {
  border-left: 1px solid #5D4037 !important; }

.border-right-brown.border-right-darken-2 {
  border-right: 1px solid #5D4037 !important; }

.overlay-brown.overlay-darken-2 {
  background: #5D4037;
  /* The Fallback */
  background: rgba(93, 64, 55, 0.8); }

.brown.darken-3 {
  color: #4E342E !important; }

.bg-brown.bg-darken-3 {
  background-color: #4E342E !important; }

.btn-brown.btn-darken-3 {
  border-color: #4E342E !important;
  background-color: #4E342E !important; }
  .btn-brown.btn-darken-3:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-darken-3 {
  border-color: #4E342E !important;
  color: #4E342E !important; }
  .btn-outline-brown.btn-outline-darken-3:hover {
    background-color: #4E342E !important; }

.progress-brown.progress-darken-3[value]::-webkit-progress-value {
  background-color: #4E342E; }

.progress-brown.progress-darken-3[value]::-moz-progress-bar {
  background-color: #4E342E; }

.progress-brown.progress-darken-3[value]::-ms-fill {
  background-color: #4E342E; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-darken-3 .progress-bar {
    background-color: #4E342E; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4E342E !important; }

.border-brown.border-darken-3 {
  border: 1px solid #4E342E !important; }

.border-top-brown.border-top-darken-3 {
  border-top: 1px solid #4E342E !important; }

.border-bottom-brown.border-bottom-darken-3 {
  border-bottom: 1px solid #4E342E !important; }

.border-left-brown.border-left-darken-3 {
  border-left: 1px solid #4E342E !important; }

.border-right-brown.border-right-darken-3 {
  border-right: 1px solid #4E342E !important; }

.overlay-brown.overlay-darken-3 {
  background: #4E342E;
  /* The Fallback */
  background: rgba(78, 52, 46, 0.8); }

.brown.darken-4 {
  color: #3E2723 !important; }

.bg-brown.bg-darken-4 {
  background-color: #3E2723 !important; }

.btn-brown.btn-darken-4 {
  border-color: #3E2723 !important;
  background-color: #3E2723 !important; }
  .btn-brown.btn-darken-4:hover {
    border-color: #A1887F !important;
    background-color: #A1887F !important; }

.btn-outline-brown.btn-outline-darken-4 {
  border-color: #3E2723 !important;
  color: #3E2723 !important; }
  .btn-outline-brown.btn-outline-darken-4:hover {
    background-color: #3E2723 !important; }

.progress-brown.progress-darken-4[value]::-webkit-progress-value {
  background-color: #3E2723; }

.progress-brown.progress-darken-4[value]::-moz-progress-bar {
  background-color: #3E2723; }

.progress-brown.progress-darken-4[value]::-ms-fill {
  background-color: #3E2723; }

@media screen and (min-width: 0\0) {
  .progress-brown.progress-darken-4 .progress-bar {
    background-color: #3E2723; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3E2723 !important; }

.border-brown.border-darken-4 {
  border: 1px solid #3E2723 !important; }

.border-top-brown.border-top-darken-4 {
  border-top: 1px solid #3E2723 !important; }

.border-bottom-brown.border-bottom-darken-4 {
  border-bottom: 1px solid #3E2723 !important; }

.border-left-brown.border-left-darken-4 {
  border-left: 1px solid #3E2723 !important; }

.border-right-brown.border-right-darken-4 {
  border-right: 1px solid #3E2723 !important; }

.overlay-brown.overlay-darken-4 {
  background: #3E2723;
  /* The Fallback */
  background: rgba(62, 39, 35, 0.8); }

.blue-grey.lighten-5 {
  color: #ECEFF1 !important; }

.bg-blue-grey.bg-lighten-5 {
  background-color: #ECEFF1 !important; }

.btn-blue-grey.btn-lighten-5 {
  border-color: #ECEFF1 !important;
  background-color: #ECEFF1 !important; }
  .btn-blue-grey.btn-lighten-5:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-lighten-5 {
  border-color: #ECEFF1 !important;
  color: #ECEFF1 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-5:hover {
    background-color: #ECEFF1 !important; }

.progress-blue-grey.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #ECEFF1; }

.progress-blue-grey.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #ECEFF1; }

.progress-blue-grey.progress-lighten-5[value]::-ms-fill {
  background-color: #ECEFF1; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-lighten-5 .progress-bar {
    background-color: #ECEFF1; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ECEFF1 !important; }

.border-blue-grey.border-lighten-5 {
  border: 1px solid #ECEFF1 !important; }

.border-top-blue-grey.border-top-lighten-5 {
  border-top: 1px solid #ECEFF1 !important; }

.border-bottom-blue-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #ECEFF1 !important; }

.border-left-blue-grey.border-left-lighten-5 {
  border-left: 1px solid #ECEFF1 !important; }

.border-right-blue-grey.border-right-lighten-5 {
  border-right: 1px solid #ECEFF1 !important; }

.overlay-blue-grey.overlay-lighten-5 {
  background: #ECEFF1;
  /* The Fallback */
  background: rgba(236, 239, 241, 0.8); }

.blue-grey.lighten-4 {
  color: #CFD8DC !important; }

.bg-blue-grey.bg-lighten-4 {
  background-color: #CFD8DC !important; }

.btn-blue-grey.btn-lighten-4 {
  border-color: #CFD8DC !important;
  background-color: #CFD8DC !important; }
  .btn-blue-grey.btn-lighten-4:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-lighten-4 {
  border-color: #CFD8DC !important;
  color: #CFD8DC !important; }
  .btn-outline-blue-grey.btn-outline-lighten-4:hover {
    background-color: #CFD8DC !important; }

.progress-blue-grey.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #CFD8DC; }

.progress-blue-grey.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #CFD8DC; }

.progress-blue-grey.progress-lighten-4[value]::-ms-fill {
  background-color: #CFD8DC; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-lighten-4 .progress-bar {
    background-color: #CFD8DC; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CFD8DC !important; }

.border-blue-grey.border-lighten-4 {
  border: 1px solid #CFD8DC !important; }

.border-top-blue-grey.border-top-lighten-4 {
  border-top: 1px solid #CFD8DC !important; }

.border-bottom-blue-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #CFD8DC !important; }

.border-left-blue-grey.border-left-lighten-4 {
  border-left: 1px solid #CFD8DC !important; }

.border-right-blue-grey.border-right-lighten-4 {
  border-right: 1px solid #CFD8DC !important; }

.overlay-blue-grey.overlay-lighten-4 {
  background: #CFD8DC;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.blue-grey.lighten-3 {
  color: #B0BEC5 !important; }

.bg-blue-grey.bg-lighten-3 {
  background-color: #B0BEC5 !important; }

.btn-blue-grey.btn-lighten-3 {
  border-color: #B0BEC5 !important;
  background-color: #B0BEC5 !important; }
  .btn-blue-grey.btn-lighten-3:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-lighten-3 {
  border-color: #B0BEC5 !important;
  color: #B0BEC5 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-3:hover {
    background-color: #B0BEC5 !important; }

.progress-blue-grey.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #B0BEC5; }

.progress-blue-grey.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #B0BEC5; }

.progress-blue-grey.progress-lighten-3[value]::-ms-fill {
  background-color: #B0BEC5; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-lighten-3 .progress-bar {
    background-color: #B0BEC5; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B0BEC5 !important; }

.border-blue-grey.border-lighten-3 {
  border: 1px solid #B0BEC5 !important; }

.border-top-blue-grey.border-top-lighten-3 {
  border-top: 1px solid #B0BEC5 !important; }

.border-bottom-blue-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #B0BEC5 !important; }

.border-left-blue-grey.border-left-lighten-3 {
  border-left: 1px solid #B0BEC5 !important; }

.border-right-blue-grey.border-right-lighten-3 {
  border-right: 1px solid #B0BEC5 !important; }

.overlay-blue-grey.overlay-lighten-3 {
  background: #B0BEC5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.blue-grey.lighten-2 {
  color: #90A4AE !important; }

.bg-blue-grey.bg-lighten-2 {
  background-color: #90A4AE !important; }

.btn-blue-grey.btn-lighten-2 {
  border-color: #90A4AE !important;
  background-color: #90A4AE !important; }
  .btn-blue-grey.btn-lighten-2:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-lighten-2 {
  border-color: #90A4AE !important;
  color: #90A4AE !important; }
  .btn-outline-blue-grey.btn-outline-lighten-2:hover {
    background-color: #90A4AE !important; }

.progress-blue-grey.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #90A4AE; }

.progress-blue-grey.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #90A4AE; }

.progress-blue-grey.progress-lighten-2[value]::-ms-fill {
  background-color: #90A4AE; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-lighten-2 .progress-bar {
    background-color: #90A4AE; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90A4AE !important; }

.border-blue-grey.border-lighten-2 {
  border: 1px solid #90A4AE !important; }

.border-top-blue-grey.border-top-lighten-2 {
  border-top: 1px solid #90A4AE !important; }

.border-bottom-blue-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #90A4AE !important; }

.border-left-blue-grey.border-left-lighten-2 {
  border-left: 1px solid #90A4AE !important; }

.border-right-blue-grey.border-right-lighten-2 {
  border-right: 1px solid #90A4AE !important; }

.overlay-blue-grey.overlay-lighten-2 {
  background: #90A4AE;
  /* The Fallback */
  background: rgba(144, 164, 174, 0.8); }

.blue-grey.lighten-1 {
  color: #78909C !important; }

.bg-blue-grey.bg-lighten-1 {
  background-color: #78909C !important; }

.btn-blue-grey.btn-lighten-1 {
  border-color: #78909C !important;
  background-color: #78909C !important; }
  .btn-blue-grey.btn-lighten-1:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-lighten-1 {
  border-color: #78909C !important;
  color: #78909C !important; }
  .btn-outline-blue-grey.btn-outline-lighten-1:hover {
    background-color: #78909C !important; }

.progress-blue-grey.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #78909C; }

.progress-blue-grey.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #78909C; }

.progress-blue-grey.progress-lighten-1[value]::-ms-fill {
  background-color: #78909C; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-lighten-1 .progress-bar {
    background-color: #78909C; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909C !important; }

.border-blue-grey.border-lighten-1 {
  border: 1px solid #78909C !important; }

.border-top-blue-grey.border-top-lighten-1 {
  border-top: 1px solid #78909C !important; }

.border-bottom-blue-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #78909C !important; }

.border-left-blue-grey.border-left-lighten-1 {
  border-left: 1px solid #78909C !important; }

.border-right-blue-grey.border-right-lighten-1 {
  border-right: 1px solid #78909C !important; }

.overlay-blue-grey.overlay-lighten-1 {
  background: #78909C;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.blue-grey {
  color: #607D8B !important; }

.bg-blue-grey {
  background-color: #607D8B !important; }
  .bg-blue-grey .card-header, .bg-blue-grey .card-footer {
    background-color: transparent; }

.alert-blue-grey {
  border-color: #607D8B !important;
  background-color: #87a0ac !important;
  color: #171e21 !important; }
  .alert-blue-grey .alert-link {
    color: #060809 !important; }

.border-blue-grey {
  border-color: #607D8B; }

.bg-blue-grey.tag-glow, .border-blue-grey.tag-glow {
  box-shadow: 0px 0px 10px #607D8B; }

.overlay-blue-grey {
  background: #607D8B;
  /* The Fallback */
  background: rgba(96, 125, 139, 0.8); }

.card-outline-blue-grey {
  border-width: 1px;
  border-style: solid;
  border-color: #607D8B;
  background-color: transparent; }
  .card-outline-blue-grey .card-header, .card-outline-blue-grey .card-footer {
    background-color: transparent; }

.btn-blue-grey {
  border-color: #607D8B;
  background-color: #607D8B;
  color: #FFFFFF; }
  .btn-blue-grey:hover {
    border-color: #90A4AE;
    background-color: #90A4AE;
    color: #FFF !important; }

.btn-outline-blue-grey {
  border-color: #607D8B;
  background-color: transparent;
  color: #607D8B; }
  .btn-outline-blue-grey:hover {
    background-color: #607D8B;
    color: #FFF !important; }

.progress-blue-grey[value]::-webkit-progress-value {
  background-color: #607D8B; }

.progress-blue-grey[value]::-moz-progress-bar {
  background-color: #607D8B; }

.progress-blue-grey[value]::-ms-fill {
  background-color: #607D8B; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey .progress-bar {
    background-color: #607D8B; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #607D8B !important; }

.border-blue-grey {
  border: 1px solid #607D8B !important; }

.border-top-blue-grey {
  border-top: 1px solid #607D8B; }

.border-bottom-blue-grey {
  border-bottom: 1px solid #607D8B; }

.border-left-blue-grey {
  border-left: 1px solid #607D8B; }

.border-right-blue-grey {
  border-right: 1px solid #607D8B; }

.blue-grey.darken-1 {
  color: #546E7A !important; }

.bg-blue-grey.bg-darken-1 {
  background-color: #546E7A !important; }

.btn-blue-grey.btn-darken-1 {
  border-color: #546E7A !important;
  background-color: #546E7A !important; }
  .btn-blue-grey.btn-darken-1:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-darken-1 {
  border-color: #546E7A !important;
  color: #546E7A !important; }
  .btn-outline-blue-grey.btn-outline-darken-1:hover {
    background-color: #546E7A !important; }

.progress-blue-grey.progress-darken-1[value]::-webkit-progress-value {
  background-color: #546E7A; }

.progress-blue-grey.progress-darken-1[value]::-moz-progress-bar {
  background-color: #546E7A; }

.progress-blue-grey.progress-darken-1[value]::-ms-fill {
  background-color: #546E7A; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-darken-1 .progress-bar {
    background-color: #546E7A; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546E7A !important; }

.border-blue-grey.border-darken-1 {
  border: 1px solid #546E7A !important; }

.border-top-blue-grey.border-top-darken-1 {
  border-top: 1px solid #546E7A !important; }

.border-bottom-blue-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #546E7A !important; }

.border-left-blue-grey.border-left-darken-1 {
  border-left: 1px solid #546E7A !important; }

.border-right-blue-grey.border-right-darken-1 {
  border-right: 1px solid #546E7A !important; }

.overlay-blue-grey.overlay-darken-1 {
  background: #546E7A;
  /* The Fallback */
  background: rgba(84, 110, 122, 0.8); }

.blue-grey.darken-2 {
  color: #455A64 !important; }

.bg-blue-grey.bg-darken-2 {
  background-color: #455A64 !important; }

.btn-blue-grey.btn-darken-2 {
  border-color: #455A64 !important;
  background-color: #455A64 !important; }
  .btn-blue-grey.btn-darken-2:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-darken-2 {
  border-color: #455A64 !important;
  color: #455A64 !important; }
  .btn-outline-blue-grey.btn-outline-darken-2:hover {
    background-color: #455A64 !important; }

.progress-blue-grey.progress-darken-2[value]::-webkit-progress-value {
  background-color: #455A64; }

.progress-blue-grey.progress-darken-2[value]::-moz-progress-bar {
  background-color: #455A64; }

.progress-blue-grey.progress-darken-2[value]::-ms-fill {
  background-color: #455A64; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-darken-2 .progress-bar {
    background-color: #455A64; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455A64 !important; }

.border-blue-grey.border-darken-2 {
  border: 1px solid #455A64 !important; }

.border-top-blue-grey.border-top-darken-2 {
  border-top: 1px solid #455A64 !important; }

.border-bottom-blue-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #455A64 !important; }

.border-left-blue-grey.border-left-darken-2 {
  border-left: 1px solid #455A64 !important; }

.border-right-blue-grey.border-right-darken-2 {
  border-right: 1px solid #455A64 !important; }

.overlay-blue-grey.overlay-darken-2 {
  background: #455A64;
  /* The Fallback */
  background: rgba(69, 90, 100, 0.8); }

.blue-grey.darken-3 {
  color: #37474F !important; }

.bg-blue-grey.bg-darken-3 {
  background-color: #37474F !important; }

.btn-blue-grey.btn-darken-3 {
  border-color: #37474F !important;
  background-color: #37474F !important; }
  .btn-blue-grey.btn-darken-3:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-darken-3 {
  border-color: #37474F !important;
  color: #37474F !important; }
  .btn-outline-blue-grey.btn-outline-darken-3:hover {
    background-color: #37474F !important; }

.progress-blue-grey.progress-darken-3[value]::-webkit-progress-value {
  background-color: #37474F; }

.progress-blue-grey.progress-darken-3[value]::-moz-progress-bar {
  background-color: #37474F; }

.progress-blue-grey.progress-darken-3[value]::-ms-fill {
  background-color: #37474F; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-darken-3 .progress-bar {
    background-color: #37474F; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474F !important; }

.border-blue-grey.border-darken-3 {
  border: 1px solid #37474F !important; }

.border-top-blue-grey.border-top-darken-3 {
  border-top: 1px solid #37474F !important; }

.border-bottom-blue-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #37474F !important; }

.border-left-blue-grey.border-left-darken-3 {
  border-left: 1px solid #37474F !important; }

.border-right-blue-grey.border-right-darken-3 {
  border-right: 1px solid #37474F !important; }

.overlay-blue-grey.overlay-darken-3 {
  background: #37474F;
  /* The Fallback */
  background: rgba(55, 71, 79, 0.8); }

.blue-grey.darken-4 {
  color: #263238 !important; }

.bg-blue-grey.bg-darken-4 {
  background-color: #263238 !important; }

.btn-blue-grey.btn-darken-4 {
  border-color: #263238 !important;
  background-color: #263238 !important; }
  .btn-blue-grey.btn-darken-4:hover {
    border-color: #90A4AE !important;
    background-color: #90A4AE !important; }

.btn-outline-blue-grey.btn-outline-darken-4 {
  border-color: #263238 !important;
  color: #263238 !important; }
  .btn-outline-blue-grey.btn-outline-darken-4:hover {
    background-color: #263238 !important; }

.progress-blue-grey.progress-darken-4[value]::-webkit-progress-value {
  background-color: #263238; }

.progress-blue-grey.progress-darken-4[value]::-moz-progress-bar {
  background-color: #263238; }

.progress-blue-grey.progress-darken-4[value]::-ms-fill {
  background-color: #263238; }

@media screen and (min-width: 0\0) {
  .progress-blue-grey.progress-darken-4 .progress-bar {
    background-color: #263238; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important; }

.border-blue-grey.border-darken-4 {
  border: 1px solid #263238 !important; }

.border-top-blue-grey.border-top-darken-4 {
  border-top: 1px solid #263238 !important; }

.border-bottom-blue-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #263238 !important; }

.border-left-blue-grey.border-left-darken-4 {
  border-left: 1px solid #263238 !important; }

.border-right-blue-grey.border-right-darken-4 {
  border-right: 1px solid #263238 !important; }

.overlay-blue-grey.overlay-darken-4 {
  background: #263238;
  /* The Fallback */
  background: rgba(38, 50, 56, 0.8); }

.grey.lighten-5 {
  color: #FAFAFA !important; }

.bg-grey.bg-lighten-5 {
  background-color: #FAFAFA !important; }

.btn-grey.btn-lighten-5 {
  border-color: #FAFAFA !important;
  background-color: #FAFAFA !important; }
  .btn-grey.btn-lighten-5:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-lighten-5 {
  border-color: #FAFAFA !important;
  color: #FAFAFA !important; }
  .btn-outline-grey.btn-outline-lighten-5:hover {
    background-color: #FAFAFA !important; }

.progress-grey.progress-lighten-5[value]::-webkit-progress-value {
  background-color: #FAFAFA; }

.progress-grey.progress-lighten-5[value]::-moz-progress-bar {
  background-color: #FAFAFA; }

.progress-grey.progress-lighten-5[value]::-ms-fill {
  background-color: #FAFAFA; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-lighten-5 .progress-bar {
    background-color: #FAFAFA; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FAFAFA !important; }

.border-grey.border-lighten-5 {
  border: 1px solid #FAFAFA !important; }

.border-top-grey.border-top-lighten-5 {
  border-top: 1px solid #FAFAFA !important; }

.border-bottom-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #FAFAFA !important; }

.border-left-grey.border-left-lighten-5 {
  border-left: 1px solid #FAFAFA !important; }

.border-right-grey.border-right-lighten-5 {
  border-right: 1px solid #FAFAFA !important; }

.overlay-grey.overlay-lighten-5 {
  background: #FAFAFA;
  /* The Fallback */
  background: rgba(250, 250, 250, 0.8); }

.grey.lighten-4 {
  color: #F5F5F5 !important; }

.bg-grey.bg-lighten-4 {
  background-color: #F5F5F5 !important; }

.btn-grey.btn-lighten-4 {
  border-color: #F5F5F5 !important;
  background-color: #F5F5F5 !important; }
  .btn-grey.btn-lighten-4:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-lighten-4 {
  border-color: #F5F5F5 !important;
  color: #F5F5F5 !important; }
  .btn-outline-grey.btn-outline-lighten-4:hover {
    background-color: #F5F5F5 !important; }

.progress-grey.progress-lighten-4[value]::-webkit-progress-value {
  background-color: #F5F5F5; }

.progress-grey.progress-lighten-4[value]::-moz-progress-bar {
  background-color: #F5F5F5; }

.progress-grey.progress-lighten-4[value]::-ms-fill {
  background-color: #F5F5F5; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-lighten-4 .progress-bar {
    background-color: #F5F5F5; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F5F5F5 !important; }

.border-grey.border-lighten-4 {
  border: 1px solid #F5F5F5 !important; }

.border-top-grey.border-top-lighten-4 {
  border-top: 1px solid #F5F5F5 !important; }

.border-bottom-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #F5F5F5 !important; }

.border-left-grey.border-left-lighten-4 {
  border-left: 1px solid #F5F5F5 !important; }

.border-right-grey.border-right-lighten-4 {
  border-right: 1px solid #F5F5F5 !important; }

.overlay-grey.overlay-lighten-4 {
  background: #F5F5F5;
  /* The Fallback */
  background: rgba(245, 245, 245, 0.8); }

.grey.lighten-3 {
  color: #EEEEEE !important; }

.bg-grey.bg-lighten-3 {
  background-color: #EEEEEE !important; }

.btn-grey.btn-lighten-3 {
  border-color: #EEEEEE !important;
  background-color: #EEEEEE !important; }
  .btn-grey.btn-lighten-3:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-lighten-3 {
  border-color: #EEEEEE !important;
  color: #EEEEEE !important; }
  .btn-outline-grey.btn-outline-lighten-3:hover {
    background-color: #EEEEEE !important; }

.progress-grey.progress-lighten-3[value]::-webkit-progress-value {
  background-color: #EEEEEE; }

.progress-grey.progress-lighten-3[value]::-moz-progress-bar {
  background-color: #EEEEEE; }

.progress-grey.progress-lighten-3[value]::-ms-fill {
  background-color: #EEEEEE; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-lighten-3 .progress-bar {
    background-color: #EEEEEE; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EEEEEE !important; }

.border-grey.border-lighten-3 {
  border: 1px solid #EEEEEE !important; }

.border-top-grey.border-top-lighten-3 {
  border-top: 1px solid #EEEEEE !important; }

.border-bottom-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #EEEEEE !important; }

.border-left-grey.border-left-lighten-3 {
  border-left: 1px solid #EEEEEE !important; }

.border-right-grey.border-right-lighten-3 {
  border-right: 1px solid #EEEEEE !important; }

.overlay-grey.overlay-lighten-3 {
  background: #EEEEEE;
  /* The Fallback */
  background: rgba(238, 238, 238, 0.8); }

.grey.lighten-2 {
  color: #E0E0E0 !important; }

.bg-grey.bg-lighten-2 {
  background-color: #E0E0E0 !important; }

.btn-grey.btn-lighten-2 {
  border-color: #E0E0E0 !important;
  background-color: #E0E0E0 !important; }
  .btn-grey.btn-lighten-2:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-lighten-2 {
  border-color: #E0E0E0 !important;
  color: #E0E0E0 !important; }
  .btn-outline-grey.btn-outline-lighten-2:hover {
    background-color: #E0E0E0 !important; }

.progress-grey.progress-lighten-2[value]::-webkit-progress-value {
  background-color: #E0E0E0; }

.progress-grey.progress-lighten-2[value]::-moz-progress-bar {
  background-color: #E0E0E0; }

.progress-grey.progress-lighten-2[value]::-ms-fill {
  background-color: #E0E0E0; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-lighten-2 .progress-bar {
    background-color: #E0E0E0; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0E0E0 !important; }

.border-grey.border-lighten-2 {
  border: 1px solid #E0E0E0 !important; }

.border-top-grey.border-top-lighten-2 {
  border-top: 1px solid #E0E0E0 !important; }

.border-bottom-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #E0E0E0 !important; }

.border-left-grey.border-left-lighten-2 {
  border-left: 1px solid #E0E0E0 !important; }

.border-right-grey.border-right-lighten-2 {
  border-right: 1px solid #E0E0E0 !important; }

.overlay-grey.overlay-lighten-2 {
  background: #E0E0E0;
  /* The Fallback */
  background: rgba(224, 224, 224, 0.8); }

.grey.lighten-1 {
  color: #BDBDBD !important; }

.bg-grey.bg-lighten-1 {
  background-color: #BDBDBD !important; }

.btn-grey.btn-lighten-1 {
  border-color: #BDBDBD !important;
  background-color: #BDBDBD !important; }
  .btn-grey.btn-lighten-1:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-lighten-1 {
  border-color: #BDBDBD !important;
  color: #BDBDBD !important; }
  .btn-outline-grey.btn-outline-lighten-1:hover {
    background-color: #BDBDBD !important; }

.progress-grey.progress-lighten-1[value]::-webkit-progress-value {
  background-color: #BDBDBD; }

.progress-grey.progress-lighten-1[value]::-moz-progress-bar {
  background-color: #BDBDBD; }

.progress-grey.progress-lighten-1[value]::-ms-fill {
  background-color: #BDBDBD; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-lighten-1 .progress-bar {
    background-color: #BDBDBD; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BDBDBD !important; }

.border-grey.border-lighten-1 {
  border: 1px solid #BDBDBD !important; }

.border-top-grey.border-top-lighten-1 {
  border-top: 1px solid #BDBDBD !important; }

.border-bottom-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #BDBDBD !important; }

.border-left-grey.border-left-lighten-1 {
  border-left: 1px solid #BDBDBD !important; }

.border-right-grey.border-right-lighten-1 {
  border-right: 1px solid #BDBDBD !important; }

.overlay-grey.overlay-lighten-1 {
  background: #BDBDBD;
  /* The Fallback */
  background: rgba(189, 189, 189, 0.8); }

.grey {
  color: #9E9E9E !important; }

.bg-grey {
  background-color: #9E9E9E !important; }
  .bg-grey .card-header, .bg-grey .card-footer {
    background-color: transparent; }

.alert-grey {
  border-color: #9E9E9E !important;
  background-color: #c2c2c2 !important;
  color: #454545 !important; }
  .alert-grey .alert-link {
    color: #303030 !important; }

.border-grey {
  border-color: #9E9E9E; }

.bg-grey.tag-glow, .border-grey.tag-glow {
  box-shadow: 0px 0px 10px #9E9E9E; }

.overlay-grey {
  background: #9E9E9E;
  /* The Fallback */
  background: rgba(158, 158, 158, 0.8); }

.card-outline-grey {
  border-width: 1px;
  border-style: solid;
  border-color: #9E9E9E;
  background-color: transparent; }
  .card-outline-grey .card-header, .card-outline-grey .card-footer {
    background-color: transparent; }

.btn-grey {
  border-color: #9E9E9E;
  background-color: #9E9E9E;
  color: #FFFFFF; }
  .btn-grey:hover {
    border-color: #E0E0E0;
    background-color: #E0E0E0;
    color: #FFF !important; }

.btn-outline-grey {
  border-color: #9E9E9E;
  background-color: transparent;
  color: #9E9E9E; }
  .btn-outline-grey:hover {
    background-color: #9E9E9E;
    color: #FFF !important; }

.progress-grey[value]::-webkit-progress-value {
  background-color: #9E9E9E; }

.progress-grey[value]::-moz-progress-bar {
  background-color: #9E9E9E; }

.progress-grey[value]::-ms-fill {
  background-color: #9E9E9E; }

@media screen and (min-width: 0\0) {
  .progress-grey .progress-bar {
    background-color: #9E9E9E; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9E9E9E !important; }

.border-grey {
  border: 1px solid #9E9E9E !important; }

.border-top-grey {
  border-top: 1px solid #9E9E9E; }

.border-bottom-grey {
  border-bottom: 1px solid #9E9E9E; }

.border-left-grey {
  border-left: 1px solid #9E9E9E; }

.border-right-grey {
  border-right: 1px solid #9E9E9E; }

.grey.darken-1 {
  color: #757575 !important; }

.bg-grey.bg-darken-1 {
  background-color: #757575 !important; }

.btn-grey.btn-darken-1 {
  border-color: #757575 !important;
  background-color: #757575 !important; }
  .btn-grey.btn-darken-1:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-darken-1 {
  border-color: #757575 !important;
  color: #757575 !important; }
  .btn-outline-grey.btn-outline-darken-1:hover {
    background-color: #757575 !important; }

.progress-grey.progress-darken-1[value]::-webkit-progress-value {
  background-color: #757575; }

.progress-grey.progress-darken-1[value]::-moz-progress-bar {
  background-color: #757575; }

.progress-grey.progress-darken-1[value]::-ms-fill {
  background-color: #757575; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-darken-1 .progress-bar {
    background-color: #757575; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #757575 !important; }

.border-grey.border-darken-1 {
  border: 1px solid #757575 !important; }

.border-top-grey.border-top-darken-1 {
  border-top: 1px solid #757575 !important; }

.border-bottom-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #757575 !important; }

.border-left-grey.border-left-darken-1 {
  border-left: 1px solid #757575 !important; }

.border-right-grey.border-right-darken-1 {
  border-right: 1px solid #757575 !important; }

.overlay-grey.overlay-darken-1 {
  background: #757575;
  /* The Fallback */
  background: rgba(117, 117, 117, 0.8); }

.grey.darken-2 {
  color: #616161 !important; }

.bg-grey.bg-darken-2 {
  background-color: #616161 !important; }

.btn-grey.btn-darken-2 {
  border-color: #616161 !important;
  background-color: #616161 !important; }
  .btn-grey.btn-darken-2:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-darken-2 {
  border-color: #616161 !important;
  color: #616161 !important; }
  .btn-outline-grey.btn-outline-darken-2:hover {
    background-color: #616161 !important; }

.progress-grey.progress-darken-2[value]::-webkit-progress-value {
  background-color: #616161; }

.progress-grey.progress-darken-2[value]::-moz-progress-bar {
  background-color: #616161; }

.progress-grey.progress-darken-2[value]::-ms-fill {
  background-color: #616161; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-darken-2 .progress-bar {
    background-color: #616161; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #616161 !important; }

.border-grey.border-darken-2 {
  border: 1px solid #616161 !important; }

.border-top-grey.border-top-darken-2 {
  border-top: 1px solid #616161 !important; }

.border-bottom-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #616161 !important; }

.border-left-grey.border-left-darken-2 {
  border-left: 1px solid #616161 !important; }

.border-right-grey.border-right-darken-2 {
  border-right: 1px solid #616161 !important; }

.overlay-grey.overlay-darken-2 {
  background: #616161;
  /* The Fallback */
  background: rgba(97, 97, 97, 0.8); }

.grey.darken-3 {
  color: #424242 !important; }

.bg-grey.bg-darken-3 {
  background-color: #424242 !important; }

.btn-grey.btn-darken-3 {
  border-color: #424242 !important;
  background-color: #424242 !important; }
  .btn-grey.btn-darken-3:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-darken-3 {
  border-color: #424242 !important;
  color: #424242 !important; }
  .btn-outline-grey.btn-outline-darken-3:hover {
    background-color: #424242 !important; }

.progress-grey.progress-darken-3[value]::-webkit-progress-value {
  background-color: #424242; }

.progress-grey.progress-darken-3[value]::-moz-progress-bar {
  background-color: #424242; }

.progress-grey.progress-darken-3[value]::-ms-fill {
  background-color: #424242; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-darken-3 .progress-bar {
    background-color: #424242; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #424242 !important; }

.border-grey.border-darken-3 {
  border: 1px solid #424242 !important; }

.border-top-grey.border-top-darken-3 {
  border-top: 1px solid #424242 !important; }

.border-bottom-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #424242 !important; }

.border-left-grey.border-left-darken-3 {
  border-left: 1px solid #424242 !important; }

.border-right-grey.border-right-darken-3 {
  border-right: 1px solid #424242 !important; }

.overlay-grey.overlay-darken-3 {
  background: #424242;
  /* The Fallback */
  background: rgba(66, 66, 66, 0.8); }

.grey.darken-4 {
  color: #212121 !important; }

.bg-grey.bg-darken-4 {
  background-color: #212121 !important; }

.btn-grey.btn-darken-4 {
  border-color: #212121 !important;
  background-color: #212121 !important; }
  .btn-grey.btn-darken-4:hover {
    border-color: #E0E0E0 !important;
    background-color: #E0E0E0 !important; }

.btn-outline-grey.btn-outline-darken-4 {
  border-color: #212121 !important;
  color: #212121 !important; }
  .btn-outline-grey.btn-outline-darken-4:hover {
    background-color: #212121 !important; }

.progress-grey.progress-darken-4[value]::-webkit-progress-value {
  background-color: #212121; }

.progress-grey.progress-darken-4[value]::-moz-progress-bar {
  background-color: #212121; }

.progress-grey.progress-darken-4[value]::-ms-fill {
  background-color: #212121; }

@media screen and (min-width: 0\0) {
  .progress-grey.progress-darken-4 .progress-bar {
    background-color: #212121; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #212121 !important; }

.border-grey.border-darken-4 {
  border: 1px solid #212121 !important; }

.border-top-grey.border-top-darken-4 {
  border-top: 1px solid #212121 !important; }

.border-bottom-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #212121 !important; }

.border-left-grey.border-left-darken-4 {
  border-left: 1px solid #212121 !important; }

.border-right-grey.border-right-darken-4 {
  border-right: 1px solid #212121 !important; }

.overlay-grey.overlay-darken-4 {
  background: #212121;
  /* The Fallback */
  background: rgba(33, 33, 33, 0.8); }

.shades.black {
  color: #000000 !important; }

.bg-shades.bg-black {
  background-color: #000000 !important; }

.btn-shades.btn-black {
  border-color: #000000 !important;
  background-color: #000000 !important; }
  .btn-shades.btn-black:hover {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-black {
  border-color: #000000 !important;
  color: #000000 !important; }
  .btn-outline-shades.btn-outline-black:hover {
    background-color: #000000 !important; }

.progress-shades.progress-black[value]::-webkit-progress-value {
  background-color: #000000; }

.progress-shades.progress-black[value]::-moz-progress-bar {
  background-color: #000000; }

.progress-shades.progress-black[value]::-ms-fill {
  background-color: #000000; }

@media screen and (min-width: 0\0) {
  .progress-shades.progress-black .progress-bar {
    background-color: #000000; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.border-shades.border-black {
  border: 1px solid #000000 !important; }

.border-top-shades.border-top-black {
  border-top: 1px solid #000000 !important; }

.border-bottom-shades.border-bottom-black {
  border-bottom: 1px solid #000000 !important; }

.border-left-shades.border-left-black {
  border-left: 1px solid #000000 !important; }

.border-right-shades.border-right-black {
  border-right: 1px solid #000000 !important; }

.overlay-shades.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.shades.white {
  color: #FFFFFF !important; }

.bg-shades.bg-white {
  background-color: #FFFFFF !important; }

.btn-shades.btn-white {
  border-color: #FFFFFF !important;
  background-color: #FFFFFF !important; }
  .btn-shades.btn-white:hover {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-white {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-shades.btn-outline-white:hover {
    background-color: #FFFFFF !important; }

.progress-shades.progress-white[value]::-webkit-progress-value {
  background-color: #FFFFFF; }

.progress-shades.progress-white[value]::-moz-progress-bar {
  background-color: #FFFFFF; }

.progress-shades.progress-white[value]::-ms-fill {
  background-color: #FFFFFF; }

@media screen and (min-width: 0\0) {
  .progress-shades.progress-white .progress-bar {
    background-color: #FFFFFF; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-shades.border-white {
  border: 1px solid #FFFFFF !important; }

.border-top-shades.border-top-white {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-shades.border-bottom-white {
  border-bottom: 1px solid #FFFFFF !important; }

.border-left-shades.border-left-white {
  border-left: 1px solid #FFFFFF !important; }

.border-right-shades.border-right-white {
  border-right: 1px solid #FFFFFF !important; }

.overlay-shades.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.shades.transparent {
  color: transparent !important; }

.bg-shades.bg-transparent {
  background-color: transparent !important; }

.btn-shades.btn-transparent {
  border-color: transparent !important;
  background-color: transparent !important; }
  .btn-shades.btn-transparent:hover {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-transparent {
  border-color: transparent !important;
  color: transparent !important; }
  .btn-outline-shades.btn-outline-transparent:hover {
    background-color: transparent !important; }

.progress-shades.progress-transparent[value]::-webkit-progress-value {
  background-color: transparent; }

.progress-shades.progress-transparent[value]::-moz-progress-bar {
  background-color: transparent; }

.progress-shades.progress-transparent[value]::-ms-fill {
  background-color: transparent; }

@media screen and (min-width: 0\0) {
  .progress-shades.progress-transparent .progress-bar {
    background-color: transparent; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent !important; }

.border-shades.border-transparent {
  border: 1px solid transparent !important; }

.border-top-shades.border-top-transparent {
  border-top: 1px solid transparent !important; }

.border-bottom-shades.border-bottom-transparent {
  border-bottom: 1px solid transparent !important; }

.border-left-shades.border-left-transparent {
  border-left: 1px solid transparent !important; }

.border-right-shades.border-right-transparent {
  border-right: 1px solid transparent !important; }

.overlay-shades.overlay-transparent {
  background: transparent;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.black {
  color: #000000; }

.bg-black {
  background-color: #000000; }

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000; }

.border-black {
  border: 1px solid #000000; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

.border-left-black {
  border-left: 1px solid #000000; }

.border-right-black {
  border-right: 1px solid #000000; }

.white {
  color: #FFFFFF; }

.bg-white {
  background-color: #FFFFFF; }

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF; }

.border-white {
  border: 1px solid #FFFFFF; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

.border-left-white {
  border-left: 1px solid #FFFFFF; }

.border-right-white {
  border-right: 1px solid #FFFFFF; }

.transparent {
  color: transparent; }

.bg-transparent {
  background-color: transparent; }

input:focus ~ .bg-transparent {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent; }

.border-transparent {
  border: 1px solid transparent; }

.border-top-transparent {
  border-top: 1px solid transparent; }

.border-bottom-transparent {
  border-bottom: 1px solid transparent; }

.border-left-transparent {
  border-left: 1px solid transparent; }

.border-right-transparent {
  border-right: 1px solid transparent; }
