
.tooltip {
    position: relative;
    display: inline!important;
    border-bottom: 1px dotted black;
    opacity: 1 !important;
  }
  
.title-d {
  font-size: 18px;
  font-weight: bolder;
  margin: 30px 0px;
}
.bg-color {
  width: 150px;
  margin: 0px auto;
  height: 110px;
  border-radius: 30px;
  padding: 15px;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bg-color-1 {
    background: rgb(8,163,48);
    background: linear-gradient(nulldeg, rgba(8,163,48,1) 0%, rgba(134,203,54,1) 100%);
}
.bg-color-2 {
    background: rgb(205,38,101);
    background: linear-gradient(nulldeg, rgba(205,38,101,1) 0%, rgba(247,71,137,1) 100%);
}
.bg-color-3 {
    background: rgb(110,7,153);
    background: linear-gradient(nulldeg, rgba(110,7,153,1) 0%, rgba(151,29,201,1) 100%);
}
.bg-color-4 {
    background: rgb(255,169,2);
    background: linear-gradient(nulldeg, rgba(255,169,2,1) 0%, rgba(255,218,0,1) 100%);
}
.bg-color-5 {
    background: rgb(11,132,222);
    background: linear-gradient(nulldeg, rgba(11,132,222,1) 0%, rgba(44,205,242,1) 100%);
}
.bg-color-6 {
    background: rgb(255,81,16);
    background: linear-gradient(nulldeg, rgba(255,81,16,1) 0%, rgba(255,128,58,1) 100%);
}
.bg-color-7 {
    background: rgb(235,54,54);
    background: linear-gradient(nulldeg, rgba(235,54,54,1) 0%, rgba(255,113,113,1) 100%);
}
