/*=========================================================================================
	File Name: vertical-overlay-menu.scss
	Description: A overlay style vertical menu with show and hide support. It support 
	light & dark version, filpped layout, right side icons, native scroll and borders menu 
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Robust - Responsive Admin Theme
	Version: 1.2
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.vertical-overlay-menu .content {
  margin-left: 0; }

.vertical-overlay-menu .navbar .navbar-header {
  float: left;
  width: 240px; }

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-container {
  margin-left: 0; }

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-header {
  float: left;
  width: auto; }

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: width .25s,opacity .25s,transform .25s;
  width: 240px;
  left: -240px; }
  .vertical-overlay-menu .main-menu .navigation .navigation-header .icon-ellipsis {
    display: none; }
  .vertical-overlay-menu .main-menu .navigation > li > a > i {
    font-size: 1.2rem;
    margin-right: 12px;
    float: left; }
    .vertical-overlay-menu .main-menu .navigation > li > a > i:before {
      transition: 200ms ease all; }
  .vertical-overlay-menu .main-menu .navigation > li > a > span {
    float: left; }
  .vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\ea0b";
    font-family: "icomoon";
    font-size: 1rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 10px;
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after {
    transform: rotate(90deg); }
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom: 55px; }
  .vertical-overlay-menu .main-menu .main-menu-footer {
    width: 240px; }

.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  transform: translate3d(240px, 0, 0);
  transition: width .25s,opacity .25s,transform .25s; }

.vertical-overlay-menu.menu-flipped .main-menu {
  right: -240px;
  left: inherit; }

.vertical-overlay-menu.menu-flipped .navbar .navbar-container {
  margin: 0;
  margin-right: 240px; }

.vertical-overlay-menu.menu-flipped .navbar .navbar-header {
  float: right; }

.vertical-overlay-menu.menu-flipped.menu-open .main-menu {
  transform: translate3d(-240px, 0, 0); }

@media (max-width: 991px) {
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom: 0px; } }
