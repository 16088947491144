/*=========================================================================================
    File Name: vertical-menu.scss
    Description: A calssic vertical style menu with expand and collops support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Robust - Responsive Admin Theme
    Version: 1.2
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
@media (min-width: 544px) {
  body.vertical-layout.vertical-menu.menu-flipped .main-menu {
    right: 0; } }

body.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-header {
  float: left;
  width: 240px; }

body.vertical-layout.vertical-menu.menu-expanded .navbar.navbar-brand-center .navbar-header {
  float: left;
  width: auto; }

body.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-container {
  margin-left: 240px; }

body.vertical-layout.vertical-menu.menu-expanded .main-menu {
  width: 240px;
  transition: 300ms ease all;
  backface-visibility: hidden; }
  body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation .navigation-header .icon-ellipsis {
    display: none; }
  body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > i {
    margin-right: 12px;
    float: left; }
    body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > i:before {
      font-size: 1.2rem; }
  body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > span {
    display: inline-block; }
    body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > span.tag {
      position: absolute;
      right: 20px; }
  body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\ea0b";
    font-family: "icomoon";
    font-size: 1rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 10px;
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.open > a:not(.mm-next):after {
    transform: rotate(90deg); }
  body.vertical-layout.vertical-menu.menu-expanded .main-menu .main-menu-footer {
    width: 240px; }

body.vertical-layout.vertical-menu.menu-expanded .content,
body.vertical-layout.vertical-menu.menu-expanded .footer {
  margin-left: 240px; }

body.vertical-layout.vertical-menu.menu-expanded .navbar-brand-center .content,
body.vertical-layout.vertical-menu.menu-expanded .navbar-brand-center .footer {
  margin-left: 0; }

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-header {
  float: left;
  width: 60px; }

body.vertical-layout.vertical-menu.menu-collapsed .navbar.navbar-brand-center .navbar-header {
  float: left;
  width: auto; }

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand {
  padding: 15px 0px; }

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-container {
  margin-left: 60px; }

body.vertical-layout.vertical-menu.menu-collapsed .main-menu {
  width: 60px;
  transform: translateZ(-160px) translateX(-160px);
  transform: translate3d(0, 0, 0);
  transition: 300ms ease all; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-footer {
    width: 60px; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-footer,
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-body .media-heading,
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-body .text-muted,
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-right {
    display: none; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-body {
    opacity: 0; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .user-content {
    padding: 20px 10px; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > span.menu-title, body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content a.menu-title {
    right: -240px;
    width: 240px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    background-color: #967ADC;
    border-color: #967ADC;
    padding: 11px 20px; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content {
    left: 60px;
    width: 240px;
    transition: visibility .25s,opacity .25s;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.02); }
    body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li {
      white-space: nowrap;
      position: relative; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li a {
        display: block;
        padding: 8px 20px 8px 20px; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub > a:not(.mm-next):after {
        content: "\ea0b";
        font-family: "icomoon";
        font-size: 1rem;
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 10px;
        transform: rotate(0deg);
        transition: -webkit-transform 0.2s ease-in-out; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.open > a:not(.mm-next):after {
        transform: rotate(90deg); }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation {
    overflow: visible; }
    body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header {
      padding: 30px 20px 8px 20px; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header .icon-ellipsis {
        display: block;
        padding: 12px 0px;
        text-align: center;
        font-size: 1.6rem; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header span {
        display: none; }
    body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a {
      padding: 8px 20px;
      text-align: center; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > span {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        right: -240px;
        width: 240px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        text-align: left;
        background-color: #967ADC;
        border-color: #967ADC;
        padding: 11px 20px; }
      body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > i {
        margin-right: 0;
        font-size: 1.2rem;
        line-height: 1;
        visibility: visible;
        opacity: 1; }
    body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > ul {
      display: none; }
    body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > span {
      display: none; }
  body.vertical-layout.vertical-menu.menu-collapsed .main-menu .mTSWrapper {
    overflow: visible; }

body.vertical-layout.vertical-menu.menu-collapsed .content,
body.vertical-layout.vertical-menu.menu-collapsed .footer {
  margin-left: 60px; }

body.vertical-layout.vertical-menu.menu-collapsed .navbar-brand-center .content,
body.vertical-layout.vertical-menu.menu-collapsed .navbar-brand-center .footer {
  margin-left: 0; }

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .content {
  margin: 0;
  margin-right: 60px; }

@media (min-width: 544px) {
  body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu {
    right: 0; }
    body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu span.menu-title {
      right: 60px; }
    body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu ul.menu-content {
      right: 60px;
      left: inherit; } }

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .navbar .navbar-header {
  float: right; }

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .navbar .navbar-container {
  margin: 0;
  margin-right: 60px; }

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .footer {
  margin: 0;
  margin-right: 60px; }

[data-textdirection="rtl"] body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a {
  padding: 12px 22px !important; }

[data-textdirection="rtl"] body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header .icon-ellipsis {
  padding: 12px 0px; }

@media (min-width: 992px) {
  body.vertical-layout.vertical-menu .main-menu {
    width: 240px; }
    body.vertical-layout.vertical-menu .main-menu .main-menu-footer {
      width: 240px; }
  body.vertical-layout.vertical-menu .navbar .navbar-header {
    width: 240px; }
  body.vertical-layout.vertical-menu .content,
  body.vertical-layout.vertical-menu .footer {
    margin-left: 240px; }
  body.vertical-layout.vertical-menu.menu-flipped .content {
    margin: 0;
    margin-right: 240px; }
  body.vertical-layout.vertical-menu.menu-flipped .navbar .navbar-header {
    float: right; }
  body.vertical-layout.vertical-menu.menu-flipped .navbar .navbar-container {
    margin: 0;
    margin-right: 240px; }
  body.vertical-layout.vertical-menu.menu-flipped .footer {
    margin: 0;
    margin-right: 240px; } }

@media (max-width: 991px) {
  body.vertical-layout.vertical-menu .main-menu {
    width: 60px; }
    body.vertical-layout.vertical-menu .main-menu .main-menu-footer {
      width: 240px; }
  body.vertical-layout.vertical-menu .navbar .navbar-header {
    width: 60px; }
  body.vertical-layout.vertical-menu .content,
  body.vertical-layout.vertical-menu .footer {
    margin-left: 60px; } }

@media (max-width: 767px) {
  body.vertical-layout.vertical-menu .content,
  body.vertical-layout.vertical-menu .footer {
    margin-left: 0; } }
