@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("./dashboard.css");
body {
  background-color: #e8eff5 !important;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif !important;
}
.select2-selection--multiple:before {
  content: "";
  position: absolute;
  right: 7px;
  top: 42%;
  border-top: 5px solid #888;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.btn{
  min-width: 105px;  
  background: #F5F5F5;
  border: 1px solid #4394C7!important;
  color: #4394C7;  
}
.bg {
  padding: 10px;
  color: #fff;
  border-radius: 10px;
}

.btn-light-info {
  color: #4790c3;
  background: #f0f9ff;
  border: 1px solid #c3d2e5;
  padding: 7px 15px;
  border-radius: 5px;
  margin: auto;
}

.btn-light-info {
  color: #4790c3;
}

.row_group {
  display: flex;
}

.row_group div {
  margin: auto;
}

.bg-purpal {
  background: #7c58a0;
}

.bg-yellow {
  background: #e6aa0b;
}
.bg-dark-yellow {
  background: #e5ab0c;
}

.bg-oragne {
  background: #f76208;
}

.bg-blue {
  background: #4792ca;
}

.bg-lightgreen {
  background: #99b802;
}

.bg-darkgreen {
  background: #7AAD4D !important;
}

.bg-red {
  background: #cd1d1d;
}

.bg-darkgray {
  background: #443e3e;
}

.border-theme {
  border-color: #174566 !important;
}

.bg-web-primary {
  background-color: #4793c7 !important;
}

.bg-dark-green {
  background-color: #7aad4d !important;
  color: #fff;
}

.bg-dark-primary {
  background-color: #4793c7 !important;
}

.bg-gray {
  background-color: #818181;
  color: #fff;
}

.btn-dark {
  background: #3b3a3a;
  color: #fff;
}

.btn-bordered-darkgray {
  background: #fff;
  border: 1px solid #3b3a3a !important;
  color: #3b3a3a;
}

.border-darken {
  border: 3px solid #aeaeae;
}

.input span {
  min-height: 37px;
}

.table-custom-border td {
  border: 3px solid #aeaeae !important;
}

.header-navbar .navbar-header {
  height: 5rem !important;
}

.has-sub a.menu-item .icon-record2 {
  font-size: 8px;
  position: relative;
  bottom: 2px;
}

/* Font Size */
.font-60 {
  font-size: 60px !important;
}

label.upload {
  border: 1px dotted #000;
  padding: 10px;
  cursor: pointer;
  background: #ddd;
}

.custom-file {
  opacity: 0;
}
/*  */
.centerhead thead th {
  text-align: center;
}

.breadcrumb {
  line-height: 98px !important;
  position: relative;
  top: 19px;
  font-size: 22px !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #fff !important;
}

nav a {
  color: #cfdee8;
}

.datetime {
  display: block;
  position: absolute;
  bottom: -4px;
  font-size: 12px;
  left: 14px;
}

.datetime i {
  position: relative;
  top: 3px;
  margin-right: 2px;
}

.form-group label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.float-right {
  float: right !important;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

.invalid-feedback span.form-error-icon.badge.badge-danger {
  background: red;
  font-size: 10px;
  color: #fff;
  padding: 2px 7px;
}

.invalid-feedback span.form-error-message {
  font-size: 10px;
  color: red;
  padding: 2px 7px;
}

.select2-container {
  display: block !important;
  max-width: 100%;
}

/* Tooltip container */
.input-group {
  z-index: 0;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 5px 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  /* bottom     : 125%; */
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  /* content     : ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.form-group legend.col-form-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 0;
}

#ponum {
  min-height: 38px;
}

.poremove {
  background: #c0c0c0;
  padding: 4px 10px;
  margin-right: 5px;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after {
  opacity: 1 !important;
}

body.vertical-layout.vertical-menu.menu-expanded .content,
body.vertical-layout.vertical-menu.menu-expanded .footer {
  margin-left: 270px !important;
}

/* navigation */
body.vertical-layout.vertical-menu.menu-expanded .main-menu {
  width: 270px !important;
}

body.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-header {
  float: left;
  width: 270px !important;
}

.main-menu.menu-dark .navigation > li ul .open > a {
  color: #dcdcdc;
}

.main-menu.menu-dark .navigation > li ul .open > a {
  font-size: 15px !important;
}

.main-menu.menu-dark .navigation li.hover > a,
.main-menu.menu-dark .navigation li.open > a {
  color: #ffffff;
}

.main-menu.menu-dark .navigation > li ul li > a {
  padding: 8px 20px 8px 26px !important;
}

.main-menu.menu-dark .navigation {
  background: #042842 !important;
  padding: 10px;
}

.main-menu.menu-dark .navigation > li {
  border: 1px solid #174566;
  margin: 10px 0px;
  border-radius: 5px;
}

.main-menu.menu-dark .navigation > li.open > ul {
  background: #042842 !important;
}

.main-menu.menu-dark .navigation > li ul .open {
  background: #042842 !important;
  font-size: 15px;
}

.main-menu.menu-dark .navigation > li ul .open > a {
  font-size: 15px !important;
}

.main-menu.menu-dark .navigation > li ul .open > ul {
  background: #042842 !important;
  margin-left: 15px;
}

.main-menu.menu-dark .navigation > li ul .active > a {
  color: #7aad4d !important;
  background: #042842 !important;
}

.active > a.menu-item:before {
  color: #7aad4d !important;
}

.main-menu.menu-dark .navigation > li ul .open .hover > a {
  background: #174566 !important;
}

.main-menu.menu-dark .navigation li a {
  color: #cfdee8 !important;
  font-size: 16px;
}

/*  */
.statusMessage {
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #000;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-top: 12rem;
}

.statusMessage span {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 2rem;
  cursor: pointer;
}

body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  .navigation
  > li
  > a {
  padding: 8px 9px !important;
  text-align: center;
}

.pointer-n {
  pointer-events: none;
}

div#goods_outward_booking_form_isMedicinalProducts {
  display: flex;
}

.form-check {
  flex: 1;
}

.form-check-input {
  margin-left: 0px !important;
}

span#pallets {
  display: flex;
  flex-wrap: wrap;
}

span#all {
  padding: 10px;
  cursor: pointer;
}

span.item_pallet {
  padding: 7px 10px;
  cursor: pointer;
  position: relative;
  margin: 3px 5px !important;
  display: flex;
}

span.item_pallet label {
  margin: auto;
}
span.item_pallet input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
}

span.item_pallet.active {
  border: 1px solid #000;
  border-radius: 50%;
}
.customer .form-check-label {
  padding-left: 2rem;
}
.options .form-check {
  position: relative;
}
.options .form-check-input {
  display: inline-block;
  border: 2px solid #ccc;
  color: green;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  width: 28px;
  height: 30px;
  margin-top: 0px;
}
.options .form-check-input:before {
  /* content: ""; */
  border: 2px solid #ccc;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  display: block;
  width: 28px;
  height: 30px;
  position: absolute;
  right: 0;
}
.options .form-check-input:checked:after {
  content: "\2713";
  display: inline-block;
  border: 2px solid #ccc;
  color: green;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  position: absolute;
  right: 0;
}
.options .form-group label {
  position: relative;
  top: 6px;
}

.options .form-check-input-cross {
  display: inline-block;
  border: 2px solid #ccc;
  color: green;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  position: relative;
  width: 28px;
  height: 30px;
}
.options .form-check-input-cross:before {
  /* content: ""; */
  border: 2px solid #ccc;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  display: block;
  width: 28px;
  height: 30px;
  position: absolute;
}
.options .form-check-input-cross:checked:after {
  content: "\00d7";
  display: inline-block;
  color: red;
  border: 2px solid #ccc;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  position: absolute;
  width: 28px;
  height: 30px;
}

.checkouter {
  display: inline-block;
  border: 2px solid #ccc;
  color: green;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  width: 28px;
  height: 28px;
}
.check:after {
  content: "\2713";
  display: inline-block;
  color: green;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
}

.crossouter {
  display: inline-block;
  border: 2px solid #ccc;
  color: red;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
  background: #fff;
  padding: 0px 6px;
  width: 28px;
  height: 28px;
}
.cross:after {
  content: "\00d7";
  display: inline-block;
  color: red;
  font-size: 18px;
  font-weight: bolder;
  opacity: 1 !important;
}

.submitDownload {
  background: #727272 !important;
}

/* .table-custom-border li {
  width: 33%;
  float: left;
  border: 2px solid #aeaeae;
  padding: 12px;
} */
.table-custom-border {
  padding: 0px;
}

.table-custom li {
  border: 2px solid #aeaeae;
  padding: 12px;
  flex: 1 0 33.33%;
}
.table-custom {
  padding: 0px;
  flex-wrap: wrap;
  display: flex;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-item {
  /* flex: 1 0 24%; */
  width: 25%;
}

.confirmation {
  position: fixed;
  top: 25%;
  width: 300px;
  background: #fff;
  padding: 10px;
  text-align: center;
  border: 1px solid #8888;
  right: 10%;
  z-index: 2;
}
.editdate {
  position: fixed;
  top: 25%;
  width: 300px;
  background: #fff;
  padding: 10px;
  text-align: center;
  border: 1px solid #8888;
  margin: 0 auto;
  right: 0;
  left: 0;
  z-index: 2;
}
td.activity {
  position: relative;
}
.activity:hover table {
  display: block;
}
.activity table {
  border: 1px solid #000;
  display: none;
  position: absolute;
  z-index: 999;
  top: 0;
  background: #fff;
  right: 0;
}

.bsty .switchery{height:20px}
.bsty .switchery>small{height:20px;width:20px;}
#agents_form_approve, .flexDiv{display: flex;width: 40%;align-items: baseline;padding-top: 8px;}
.flexDivN{display: flex;width: 100%;align-items: baseline;}
.hsdiv .form-group label{width: 85%;text-align: center;border-radius: 5px;border: none;}
.bg-seablue{background-color: #4394C7;}
.bg-seablue th{border: 0 !important;text-transform: uppercase;}
.btn-rc{border-radius: 5px !important;}

/* Customize the label (the customCheck) */
.customCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;
  margin: auto;
  height: 35px;
}

/* Hide the browser's default checkbox */
.customCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #4394C7;
}

/* On mouse-over, add a grey background color */
.customCheck:hover input ~ .checkmark {
  border-color: #4394C7;
}

/* When the checkbox is checked, add a blue background */
.customCheck input:checked ~ .checkmark {
  color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customCheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customCheck .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #2196F3;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mar5{margin: 5px;}
.mar-top10{margin-top: 10px;}
.mar-bott10{margin-bottom: 10px;}
.mar-bott20{margin-bottom: 20px;}
.mar-bott40{margin-bottom: 40px;}
.padding-0{padding:0 !important;}

@media (min-width: 768px) {
.col-5 .col-md-3{width: 20%;margin-bottom: 50px;}
}

input[type="radio"]{
  appearance: none;
  border: 1px solid #d3d3d3;
  width: 25px;
  height: 25px;
  content: none;
  outline: none;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

input[type="radio"]:checked {
 appearance: none;
 outline: none;
 padding: 0;
 content: none;
 border: none;
}

input[type="radio"]:checked::before{
 position: absolute;
 color: green !important;
 content: "\00A0\2713\00A0" !important;
 border: 1px solid #d3d3d3;
 font-weight: bolder;
 font-size: 18px;
 width: 25px;
 height: 25px;
}

.form-check-label{margin-left: 15px;}
.checkzero .form-check-label{padding-left: 0;/* margin-left: 0; */}
.width60{width: 60px !important;}
.width35p{width: 35% !important;}
input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control{
  line-height: 1 !important;
}
.text-center{text-align: center;}
#month option:disabled {
  background: #9ea39e;
}
.disabled label, .disabled .custom-file{cursor: not-allowed;}
.p-none input, .p-none select {background-color: #eceeef;opacity: 1;}
.rejectDocumentForm .modal-header {
  text-align: center;
  background: #4394C7;
  color: #fff;
  padding: 5px;
}
.rejectDocumentForm .modal-body,.rejectDocumentForm .modal-footer{padding: 20px 60px;}
.main-menu.menu-dark .navigation>li>ul>li.open>a, .main-menu.menu-dark .navigation>li>ul>li>a:hover{background: #162029;}
.main-menu.menu-dark .navigation >li>ul>li.open>ul>li>a:hover{background: #174566 !important}