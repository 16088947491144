/**
 *   Here's where everything gets included. You don't need
 *   to change anything here, and doing so might break
 *   stuff. Here be dragons and all that.
 */
/**
 *   Default variables
 *
 *   While these can be set with JavaScript, it's probably
 *   better and faster to just set them here, compile to
 *   CSS and include that instead to use some of that
 *   hardware-accelerated goodness.
 */
.unslider {
  position: relative;
  text-align: center;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.unslider-wrap {
  position: relative;
}
.unslider-wrap.unslider-carousel > li {
  float: left;
}
.unslider-vertical > ul {
  height: 100%;
}
.unslider-vertical li {
  float: none;
  width: 100%;
}
.unslider-fade {
  position: relative;
}
.unslider-fade .unslider-wrap li {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 8;
}
.unslider-fade .unslider-wrap li.unslider-active {
  z-index: 10;
}
.unslider ul, .unslider ol, .unslider li {
  list-style: none;
  /* Reset any weird spacing */
  margin: 0;
  padding: 0;
  border: none;
}
.unslider-arrow {
  position: absolute;
  left: 20px;
  z-index: 10;
  cursor: pointer;
}
.unslider-arrow.next {
  left: auto;
  right: 20px;
}

.unslider-nav ol {
  list-style: none;
  text-align: center;
}
.unslider-nav ol li {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: transparent;
  border-radius: 5px;
  overflow: hidden;
  text-indent: -999em;
  border: 2px solid #1D2B36;
  cursor: pointer;
}
.unslider-nav ol li.unslider-active {
  background: #1D2B36;
  cursor: default;
}

.unslider-arrow {
  display: block;
  width: 32px;
  height: 32px;
  top: 50%;
  right: -50px;
  left: auto;
  margin-top: -16px;
  overflow: hidden;
  border-radius: 32px;
  background: rgba(0,0,0,.2);
  /*text-indent: -999em;*/
  font-size: 0;
  opacity: .6;
  transition: opacity .2s;
  text-align: center;
  line-height: 32px;
}

.unslider-arrow.prev {
  left: 20px;
  right: auto;
}
.unslider-arrow.prev:before {
  font-family: 'icomoon';
  font-size: 1.3rem;
  content: '\ea07';
  color: #1D2B36;
}

.unslider-arrow.next:before {
  font-family: 'icomoon';
  font-size: 1.3rem;
  content: '\ea09';
  color: #1D2B36;
}